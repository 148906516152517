import { fs125ReadNotifications } from "../firestore_schemas/fs125ReadNotifications"
import { setDocIn_fs125ReadNotifications } from "../firestore_schemas/fs125ReadNotifications"
import { getDocFrom_fs125ReadNotifications } from "../firestore_schemas/fs125ReadNotifications"
import { setValueOfOptionalFields } from "../firestore_writers/include/general"

type Result = {
  debug_data: Array<string>;
  return_msg: string;
  success: boolean;
  error_title: string;
  error_summary: string;
  error_next_steps: string;
  document_id?: string;
};

export async function fs125Writer(
  w_type: string,
  fs1_uid: string,
  fs2_uid: string,
  fs3_uid: string,
  consumer_conversation_uid: string,
  data: any
) {
  const retval: Result = {
    debug_data: [],
    return_msg: "fs125_writer:fs125Writer ",
    success: true,
    error_title: "",
    error_summary: "",
    error_next_steps: "",
  };

  /// Validate arguments according to write type
  if ((w_type === "d" || w_type === "e") && (!fs1_uid || !fs2_uid || !fs3_uid || consumer_conversation_uid)) {
    retval.success = false;
    retval.return_msg += "Arguments missing.";
    retval.error_title = "Arguments missing.";
    retval.error_summary = "Arguments missing.";
    retval.error_next_steps = "Please use a correct arguments.";
    return retval;
  }
  /// <END> Validate arguments according to write w_type

  if (w_type === "n") {
    const fs125Data: fs125ReadNotifications = {
      consumer_contact_uid: data.consumer_contact_uid,
      conversation_uid: data.conversation_uid,
      read_message_time: data.read_message_time,
      read_notification_time: data.read_notification_time,
    } as fs125ReadNotifications;

    let response = await setDocIn_fs125ReadNotifications(fs1_uid, fs2_uid, fs3_uid, consumer_conversation_uid, fs125Data)

    if (!response.id || response.id == "" ) {
      retval.success = false;
      retval.return_msg += "Firestore write error.";
      retval.error_title = "Firestore write error.";
      retval.error_summary = "FS125 record not updated in firestore.";
      retval.error_next_steps = "Please reload page and try again.";
      return retval;
    }
    retval.document_id = response.id;
    return retval
  }
}

function firestoreWriteError(error:any) {
  // TODO - complete this
}
