<template>
  <div class="text-left">
    <h2 class="text-2xl font-bold text-center">Synergy Auto Solutions: Sales Dashboard User Guide</h2>

    <p class="mt-8">Upon successfully logging into Synergy Auto Solutions via <a class="text-blue-500 hover:text-blue-800 underline" :href="`${C_baseUrl}/login`">{{C_baseUrlPath}}/login</a>, you’ll be automatically directed to the Synergy Auto Solutions Sales Dashboard. Located at <a class="text-blue-500 hover:text-blue-800 underline" :href="`${C_baseUrl}/sales-dashboard`">{{C_baseUrlPath}}/sales-dashboard</a> this is the central hub where you’ll manage the majority of your activities related to auto part transactions.</p>
    <p class="mt-2">The Sales Dashboard is organized into two main sections: the Queue and My Conversations. These sections are designed to streamline your workflow and facilitate efficient handling of your sales activities. </p>
    <p class="mt-2">Situated on the left-hand side of the Sales Dashboard, the Queue serves as a real-time list of customer inquiries generated through the web widget on your website. The web widget functions as a lead capture tool, gathering essential details such as the customer’s name, phone number, vehicle specifics, and the auto parts they’re interested in. Upon completing the form, customers are placed in the Queue, awaiting engagement from your team.</p>
    <p class="mt-2">
      <span class="font-bold block">Optimizing Customer Engagement through the Queue</span>
      The Queue serves as a real-time list of customer inquiries generated through the web widget on your website. The web widget functions as a lead capture tool, gathering essential details such as the customer’s name, phone number, vehicle specifics, and the auto parts they’re interested in. Upon completing the form, customers are placed in the Queue, awaiting engagement from your team.
    </p>

    <p class="mt-2">
      <span class="font-bold block">Queue Access and Sorting</span>
      Every team member within your organization has visibility into the Queue. However, only one team member can initially claim a request to engage with a customer. Team members have the flexibility to sort Queue requests based on the customer’s wait time or their average rating from prior interactions.
    </p>

    <p class="mt-2">
      <span class="font-bold block">Customer Information</span>
      For each queued request, team members can see the customer's phone number, name, vehicle and part details, and the timestamp indicating when the request was made. An expandable drop-down arrow icon next to each request reveals additional information.
    </p>

    <p class="mt-2">
      <span class="font-bold block">Queue Options</span>
      Expanding a Queue entry presents four action buttons:
      <ol class="list-disc list-inside list-bullets">
        <li class="!mt-0 list-item">
          <span class="font-bold">Chat Now:</span>Initiates a real-time conversation with the customer. Clicking this moves the request to the team member’s My Conversations panel on the right side, making it inaccessible to others in the Queue.
        </li>
        <li class="!mt-0 list-item">
          <span class="font-bold">Profile:</span>
          Displays comprehensive customer information. The only mandatory field is the “Texting Phone Number”; other fields are optional. Always remember to hit “Save Changes” if any updates are made.
        </li>
        <li class="!mt-0 list-item">
          <span class="font-bold">Customer Notes:</span>
          Displays historical notes about the customer from prior interactions.
        </li>
        <li class="!mt-0 list-item">
          <span class="font-bold">Chat History:</span>
          Opens Conversation History, detailing past dialogues with the customer. This includes conversation start and end times, total messages, customer ratings, notes, identity verification status, and payment link status, if applicable.
        </li>
      </ol>
    </p>

    <p class="mt-2">
      <span class="font-bold block">Chat Initiation</span>
      Once a team member clicks the “Chat Now” button, the request shifts to their My Conversations panel on the right. The conversation automatically opens to display the initial message inviting the customer to chat, along with a URL link for the chat session. A follow-up message indicates that the team member has joined the chat. If the customer has closed their chat window by this point, an additional message is sent to notify them that a team member is ready to assist. By streamlining these functionalities, the Queue ensures that you can efficiently manage and prioritize your customer customer interactions, all within a unified dashboard.
    </p>

    <p class="mt-2">
      <span class="font-bold block">A Comprehensive Guide to My Conversations</span>
      My Conversations serves as your personalized workspace, containing both incoming requests from the Queue and conversations you’ve initiated using the “New Conversation” button. This section is unique to each team member, displaying only the active conversations that they are handling.
    </p>

    <p class="mt-2">
      <span class="font-bold block">Active Conversations</span>
      You can manage multiple conversations simultaneously and effortlessly switch between them. Simply click on the desired conversation listed in the My Conversations panel on the right to bring it into focus.
    </p>
    
    <p class="mt-2">
      <span class="font-bold block">Tabs in Active Conversations</span>
      Within each active conversation, you’ll find five main tabs to help you manage interactions:
      <ol class="list-disc list-inside list-bullets">
        <li class="!mt-0 list-item">
          <span class="font-bold">Customer Chat:</span>This is the primary interface for real-time messaging with the customer.
        </li>
        <li class="!mt-0 list-item">
          <span class="font-bold">Profile:</span>
          Provides detailed information about the customer, much like the Profile option in the Queue.
        </li>
        <li class="!mt-0 list-item">
          <span class="font-bold">Conversation History:</span>
          A record of all prior dialogues with the customer.
        </li>
        <li class="!mt-0 list-item">
          <span class="font-bold">Customer Notes:</span>
          Allows you to view or add notes about the customer for reference during future interactions.
        </li>
        <li class="!mt-0 list-item">
          <span class="font-bold">Yard Only Chat:</span>
          A separate chat area intended for internal team discussions, invisible to the customer.
        </li>
      </ol>
    </p>
    
    <p class="mt-2">
      <span class="font-bold block">Conversation Action Buttons</span>
      At the top of each conversation window, you’ll find a row of green action buttons:
      <ol class="list-disc list-inside list-bullets">
        <li class="!mt-0 list-item">
          <span class="font-bold">Payment Links:</span>Allows you to send secure payment links to the customer within the chat.
        </li>
        <li class="!mt-0 list-item">
          <span class="font-bold">Call Customer:</span>
          Enables direct calling to the customer’s phone.
        </li>
        <li class="!mt-0 list-item">
          <span class="font-bold">Invite Team Member:</span>
          Invites another team member to join the ongoing chat for collaborative handling.
        </li>
        <li class="!mt-0 list-item">
          <span class="font-bold">Move To Queue:</span>
          Transfers the conversation back to the main Queue, making it available for other team members to pick up.
        </li>
        <li class="!mt-0 list-item">
          <span class="font-bold">Move To Finished:</span>
          Closes the conversation and archives it.
        </li>
      </ol>
    </p>
    
    <p class="mt-2">
      <span class="font-bold block">Notification Bell Icon</span>
      Additionally, a notification bell icon is situated at the top right corner. Clicking on this will show you any new alerts or updates related to your active conversations.
      <br>
      In the Customer Chat tab, the color-coded outlines around messages serve to distinguish their origins:
      <ol class="list-disc list-inside list-bullets">
        <li class="!mt-0 list-item">
          <span class="font-bold">Green Outlined Messages:</span>These are system-generated messages from Synergy Auto Solutions. They notify you of actions such as messages sent to customers or when a team member joins or exits the conversation.
        </li>
        <li class="!mt-0 list-item">
          <span class="font-bold">Blue Outlined Messages:</span>
          These messages come directly from the customer.
        </li>
        <li class="!mt-0 list-item">
          <span class="font-bold">Gray Outlined Messages:</span>
          These are messages that you or another team member have sent to the customer.
        </li>
      </ol>
    </p>

    <p class="mt-2">
      <span class="font-bold block">Messaging Interface</span>
      At the bottom of the chat interface, you’ll find a message bar where you can type out your messages to the customer. To send the message, you have two options:
      <ol class="list-disc list-inside list-bullets">
        <li class="!mt-0 list-item">
          Click the green upward arrow button located to the right of the message bar.
        </li>
        <li class="!mt-0 list-item">
          Use the “Enter” key on your keyboard.
        </li>
      </ol>
    </p>
    
    <p class="mt-2">
      <span class="font-bold block">File Sharing Capabilities</span>
      To the left of the message bar, you’ll see a file icon. Clicking this allows you to upload and share files with the customer. Supported file types include photos, videos, and PDF documents.
    </p>

    <p class="mt-2">
      <span class="font-bold block">Generating and Managing Secure Payment Links</span>
      The Payment Links feature provides a straightforward way for team members to generate secure payment links for customers. Here’s how it works:
    </p>

    <p class="mt-2">
      <span class="font-bold block">Creating a Payment Link</span>
      <ol class="list-disc list-inside list-bullets">
        <li class="!mt-0 list-item">
          Click the “Payment Links” button located at the top of the conversation.
        </li>
        <li class="!mt-0 list-item">
          A form will pop up where you can input various details:
          <ol class="list-[circle] list-inside list-bullets !mt-0 pl-5">
            <li class="!mt-0 list-item">
              <span class="font-bold">Description:</span>
              This text will appear on the customer’s receipt and is useful for providing context about the payment.
            </li>
            <li class="!mt-0 list-item">
              <span class="font-bold">Amount ($):</span>
              The total amount the customer is expected to pay. This field is mandatory.
            </li>
            <li class="!mt-0 list-item">
              <span class="font-bold">Order Number:</span>
              This should correspond with the order number in your Yard Management System (YMS), if applicable.
            </li>
          </ol>
        </li>
        <li class="!mt-0 list-item">
          After filling out the form, click “Create New Pay Link” to generate the payment link.
        </li>
      </ol>

      Once you’ve clicked “Create New Pay Link”, the form will close, and a message containing the payment link will automatically appear in the Customer Chat. The customer can then click on the link, either from their text messages or directly within the chat, to proceed to a secure payment page.
    </p>

    
    <p class="mt-2">
      <span class="font-bold block">Managing Existing Payment Links</span>
      While on the Payment Links page, you’ll also see a list of Open Payment Links related to the customer in the current conversation. This feature is particularly useful if you need to modify an existing payment link. To do so:
      <ol class="list-disc list-inside list-bullets">
        <li class="!mt-0 list-item">
          Click the link you wish to modify from the Open Payment Links list.
        </li>
        <li class="!mt-0 list-item">
          Update the necessary fields—be it the amount, description, or order number. 
        </li>
        <li class="!mt-0 list-item">
          Click “Update Payment Link” to save the changes.
        </li>
      </ol>
    </p>

    <p class="mt-2">
      <span class="font-bold block">Stripe Identity Verification: Extra Security for High-Value Transactions</span>
      The Stripe Identity Verification feature adds an extra layer of security when processing higher-value transactions. If this feature is enabled by your business admin and the transaction amount exceeds the set minimum, team members will encounter the following options when creating a payment link:

      <p class="mt-2 pl-5">
        <span class="font-bold block">Identity Verification Popup</span>
        Upon clicking “Create New Pay Link”, a popup will appear presenting two choices:
        <ol class="list-disc list-inside list-bullets">
          <li class="!mt-0 list-item">
            <span class="font-bold">Accept the $1.75 charge for Identity Verification:</span>
            If chosen, the payment link will be withheld until the customer successfully completes the verification process
          </li>
          <li class="!mt-0 list-item">
            <span class="font-bold">Bypass Identity Verification:</span>
            The payment link will be sent immediately, without requiring identity verification.
          </li>
        </ol>
      </p>

      <p class="mt-2 pl-5">
        <span class="font-bold block">Verification Process</span>
        When Identity Verification is initiated, the customer will receive a link directing them to a secure page. Here they are required to:
        <ol class="list-disc list-inside list-bullets">
          <li class="!mt-0 list-item">
            Upload pictures of their identification
          </li>
          <li class="!mt-0 list-item">
            Take live selfies for confirmation
          </li>
        </ol>
        The system will then cross-verify the identification and selfies to confirm authenticity. Once approved, the payment link is dispatched to the customer. If the verification is declined, no payment link will be sent.
      </p>

      <p class="mt-2 pl-5">
        <span class="font-bold block">Bypassing Verification</span>
        If you bypass the Identity Verification or the transaction amount is below the minimum threshold—or if the feature is disabled altogether—no popup will appear, and the payment link will be sent immediately.
      </p>

      <p class="mt-2 pl-5">
        <span class="font-bold block">Verification and Payment Status</span>
        In the My Conversations panel on the right, you can monitor the real-time status of both Identity Verifications and payment links. Statuses could be:
        <ol class="list-disc list-inside list-bullets">
          <li class="!mt-0 list-item">
            <span class="font-bold">Verification:</span>
            Pending, Failed, or Verified
          </li>
          <li class="!mt-0 list-item">
            <span class="font-bold">Payment Link:</span>
            Pending, Paid, or Payment Issue
          </li>
        </ol>
        If multiple payment links are active for a single conversation, the status of each will be visible, though there will be a limit of one icon per status type.
      </p>
    </p>

    <p class="mt-2">
      <span class="font-bold block">Boosting Your Online Presence with the Online Reviews Feature</span>
      The Online Reviews feature serves as a post-payment engagement tool, aimed at collecting valuable feedback from customers and driving your business’s online reputation. When enabled, this is how it functions:

      <p class="mt-2 pl-5">
        <span class="font-bold block">Triggering Reviews</span>
        Upon successful payment, two events occur simultaneously:
        <ol class="list-disc list-inside list-bullets">
          <li class="!mt-0 list-item">
            A system-generated message appears in the Customer Chat, inviting the customer to leave a review.
          </li>
          <li class="!mt-0 list-item">
            A text message with similar content is also dispatched to the customer’s mobile device.
          </li>
        </ol>
      </p>

      <p class="mt-2 pl-5">
        <span class="font-bold block">Rating Process</span>
        Clicking the link provided in either the chat or the text message leads the customer to a simple rating interface where they can rate your business on a scale of 1-5 stars.
      </p>

      <p class="mt-2 pl-5">
        <span class="font-bold block">Review Routing</span>
        <ol class="list-disc list-inside list-bullets">
          <li class="!mt-0 list-item">
            <span class="font-bold">4-5 Stars:</span>
            Customers who rate your business with 4 or 5 stars are redirected to your designated Facebook or Google page to share their positive experience in a public review.
          </li>
          <li class="!mt-0 list-item">
            <span class="font-bold">3 Stars or Below:</span>
            Instead of guiding them to a public platform, the system prompts these customers to send feedback via email. This email is directed to employees who are part of a preset distribution list, allowing you to address any concerns privately.
          </li>
        </ol>
      </p>
    </p>

    <p class="mt-2">
      <span class="font-bold block">Real-Time Indicators: Navigating the Customer Chat Interface</span>
      Within the “Customer Chat” interface, real-time indicators provide valuable context and situational awareness. Here’s how they work:

      <p class="mt-2 pl-5">
        <span class="font-bold block">Typing Indicators</span>
        As messages are being composed, you’ll see a notice right above the message bar indicating who is currently typing. The prompt will display “Customer Name is typing” or “Team Member Name is typing”, allowing you to anticipate incoming messages.
      </p>

      <p class="mt-2 pl-5">
        <span class="font-bold block">Activity Status Dot</span>
        <ol class="list-disc list-inside list-bullets">
          <li class="!mt-0 list-item">
            <span class="font-bold">Uncolored Dot:</span>
            The customer has been inactive for more than 45 seconds on the conversation page.
          </li>
          <li class="!mt-0 list-item">
            <span class="font-bold">Green Dot:</span>
            The customer has been active within the last 15 seconds on the page.
          </li>
          <li class="!mt-0 list-item">
            <span class="font-bold">Yellow Dot:</span>
            The customer was active between 15 and 45 seconds ago but has not shown activity within the last 15 seconds.
          </li>
        </ol>
      </p>
    </p>

    <p class="mt-2">
      <span class="font-bold block">New Message Notifications</span>
      If you’re engaged in another conversation and receive a new message in a different chat, a notification will appear in your My Conversations list next to the chat with unread messages. This ensures you don’t miss any important customer interactions while multitasking.
    </p>

    <p class="mt-2">
      <span class="font-bold block">Streamlining Customer Calls with Third-Party Integration</span>
      The “Call Customer” button seamlessly integrates with third-party telephony applications like Skype, provided they are installed on your desktop. Here’s how it works:

      <p class="mt-2 pl-5">
        <span class="font-bold block">Initiating The Call</span>
        Clicking the “Call Customer” button will automatically launch your default phone application and initiate a call to the customer, saving you the steps of manual dialing.
      </p>

      <p class="mt-2 pl-5">
        <span class="font-bold block">Multiple Phone Numbers</span>
        If the customer’s profile contains more than one contact number, the system will prompt you to select which number you wish to dial. This ensures you are reaching out to the most appropriate or convenient line for the customer.
      </p>
    </p>

    <p class="mt-2">
      <span class="font-bold block">Inviting Team Members for Unified Customer Conversations</span>
      The “Invite Team Member” feature facilitates collaborative customer engagement, allowing multiple team members to be part of the same conversation. Here’s how it works:
      
      <p class="mt-2 pl-5">
        <span class="font-bold block">Inviting a Colleague:</span>
        <ol class="list-disc list-inside list-bullets">
          <li class="!mt-0 list-item">
            Click on the “Invite Team Member” button to bring up a dropdown menu of team members to select from.
          </li>
          <li class="!mt-0 list-item">
            From this menu, select the team member you’d like to invite to join the conversation.
          </li>
          <li class="!mt-0 list-item">
            Click “Send Invite”.
          </li>
        </ol>
      </p>

      <p class="mt-2 pl-5">
        <span class="font-bold block">Acceptance and Interaction</span>
        Upon sending the invite, the chosen team member will receive a popup notification on their Sales Dashboard, giving them the option to accept or decline the invitation.
        <ol class="list-disc list-inside list-bullets">
          <li class="!mt-0 list-item">
            <span class="font-bold">If accepted,</span>
            the conversation will be added to their My Conversations panel. They can then actively participate in the Customer Chat, offering a richer, more informed interaction for the customer.
          </li>
        </ol>
      </p>
    </p>

    <p class="mt-2">
      <span class="font-bold block">Yard-Only Chat: Team Discussions</span>
      For internal communications, between team members within an active conversation, the Yard Only Chat tab serves as a designated space. Here, you can exchange views, insights, or updates without the customer being privy to these discussions.
      <br><br>
      By leveraging the Invite Team Member feature, you can create a cohesive and comprehensive customer service experience, ensuring that all relevant staff are on the same page. 
    </p>

    <p class="mt-2">
      <span class="font-bold block">Operational Flexibility: How the Move To Queue Tool Works</span>
      The Move To Queue feature serves as a tool for operational flexibility. It allows you to smoothly transition a conversation from your personal workspace back to the general queue. Here’s why and how you might use it:
      
      <p class="mt-2 pl-5">
        <span class="font-bold block">Situational Use-Cases</span>
        If you’re leaving your station and the conversation requires ongoing attention, you can send it back to the queue for another team member to take over.
      </p>

      <p class="mt-2 pl-5">
        <span class="font-bold block">Specialization</span>
        If the conversation veers into a topic that another team member is more qualified to address, moving it to the queue allows the right person to pick it up.
      </p>

      <p class="mt-2 pl-5">
        <span class="font-bold block">How It Works</span>
        <ol class="list-disc list-inside list-bullets">
          <li class="!mt-0 list-item">Click on the “Move To Queue” button</li>
          <li class="!mt-0 list-item">Confirm your choice in the popup that appears</li>
        </ol>
        The conversation will immediately leave your My Conversations list and reappear in the collective queue, visible to all team members for reassignment.
        <br>
        By utilizing the Move To Queue function, you ensure that customer queries are always managed by the most appropriate and available team members, thereby maximizing efficiency and customer satisfaction.
      </p>
    </p>

    <p class="mt-2">
      <span class="font-bold block">Stay Informed: Navigating the Notification Bell Icon</span>
      Staying on top of real-time updates is crucial for a seamless customer service experience. The Notification Bell Icon, located at the top-right corner of your Sales Dashboard, serves as your command center for instant alerts.
    </p>

    <p class="mt-2">
      <span class="font-bold block">Indicator and Alerts</span>
      <ol class="list-disc list-inside list-bullets">
          <li class="!mt-0 list-item">
            <span class="font-bold">Unread Notification:</span>
            A red dot on the bell icon indicates that you have at least one unread notification.
          </li>
          <li class="!mt-0 list-item">
            <span class="font-bold">Types of Notifications:</span>
            You’ll receive alerts for various significant events:

            <ol class="list-[circle] list-inside list-bullets pl-5 !mt-0">
              <li class="!mt-0 list-item">
                Verification Approval or Decline
              </li>
              <li class="!mt-0 list-item">
                Payment Link Success or Failure
              </li>
              <li class="!mt-0 list-item">
                New Customer Messages
              </li>
            </ol>
          </li>
        </ol>

    </p>

    <p class="mt-2">
      <span class="font-bold block">Interacting with Notifications</span>
      <ol class="list-disc list-inside list-bullets pl-5">
        <li class="!mt-0 list-item">
          <span class="font-bold">Viewing Alerts:</span>
          Click on the bell icon to display a dropdown list of your unread notifications
        </li>
        <li class="!mt-0 list-item">
          <span class="font-bold">Understanding Details:</span>
          Each notification comes with concise information to help you grasp its context and required action.
        </li>
        <li class="!mt-0 list-item">
          <span class="font-bold">Clearing Notifications:</span>
          After reading, you can either click on individual notifications to go to the corresponding activity or clear them from the list to keep your notification center uncluttered.
        </li>
      </ol>

      By making effective use of the Notification Bell icon, you’re not just staying updated—you’re enhancing your capacity to respond promptly and appropriately to dynamic customer needs. 
    </p>

    <p class="mt-2">
      <span class="font-bold block">Navigating the Profile Tab for Customer Information and Business Associations</span>
      Navigating the Profile Tab is pivotal for customer relationship management. It’s your hub for detailed information about each customer and their associated businesses. Here’s how to get the most out of it.
    </p>

    <p class="mt-2">
      <span class="font-bold block">Viewing and Updating Customer Data</span>
      <ol class="list-disc list-inside list-bullets pl-5">
        <li class="!mt-0 list-item">
          <span class="font-bold">Access Profile:</span>
          Click the “Profile” tab within an active conversation to open up the customer’s profile.
        </li>
        <li class="!mt-0 list-item">
          <span class="font-bold">Edit Details:</span>
          All saved customer details, like first name, last name, email, and phone numbers will be visible. You can edit these details as necessary.
        </li>
      </ol>
    </p>

    <p class="mt-2">
      <span class="font-bold block">Managing Business Associations</span>
      Adding and managing business associations for a customer can be critical for tailored interactions. Here’s how to do it:

      <ol class="list-disc list-inside list-bullets pl-5">
        <li class="!mt-0 list-item">
          <span class="font-bold">Manage Business Associations: </span>
          Click “Manage Customer’s Business Associations” in the Profile tab to initiate the process.
        </li>
      </ol>

      <p class="mt-2 pl-5">
        <span class="font-bold block">For Existing Businesses</span>
        <ol class="list-disc list-inside list-bullets">
          <li class="!mt-0 list-item">
            <span class="font-bold">For Existing Businesses</span>
            A dropdown menu will appear with a list of existing businesses.
          </li>
          <li class="!mt-0 list-item">
            <span class="font-bold">Associate Customer:</span>
            Select the business you wish to associate with the customer, then click “Associate Customer With Existing Business”.
          </li>
        </ol>
      </p>

      <p class="mt-2 pl-5">
        <span class="font-bold block">For New Businesses</span>
        <ol class="list-disc list-inside list-bullets">
          <li class="!mt-0 list-item">
            Business Form: In the same section, you’ll find the option to fill out a Business Form for a new business. 
          </li>
          <li class="!mt-0 list-item">
            Create and Associate: Fill in the required fields, and click “Associate Customer With New Business” to link the customer with this new entity.
          </li>
        </ol>
      </p>

      Once completed, any businesses associated with the customer will then be displayed under their Profile for easy reference. Again, don’t forget to click “Save Changes” to update any newly added associations.
      <br><br>
      The Profile tab is an invaluable tool for gathering and updating customer data and for streamlining communication based on the customer’s business associations. Make sure you keep it updated for a more personalized and efficient customer service experience.
    </p>

    <p class="mt-2">
      <span class="font-bold block">Conversation History</span>
      This tab offers a historical overview of your active and finished conversations with the particular customer. To view the conversation history, simply click on the “Conversation History” tab in your active conversation window. 
    </p>

    <p class="mt-2">
      <span class="font-bold block">Customer Notes</span>
      This tab is your space for keeping internal notes about the customer. Type your note into the message box at the bottom and click “Submit Feedback” to save it. If you need to locate specific notes, use the search bar to find notes containing relevant keywords.
    </p>

    <p class="mt-2">
      <span class="font-bold block">Yard Only Chat</span>
      This is an internal chat functionality, allowing you to communicate privately with invited team members within the context of an active customer conversation. The message box allows for text input as well as uploads of photos, videos, or PDF files, similar to the “Customer Chat” tab.
    </p>

    <p class="mt-2">
      <span class="font-bold block">Completing a Conversation and Customer Rating</span>
      Conversations are an integral part of customer engagement, but they must come to an end eventually. Wrapping up a conversation in a structured way can provide valuable data for future interactions. Here’s how you can efficiently close a conversation and contribute to a customer’s overall rating in the Synergy Auto Solutions platform:

      <p class="mt-2 pl-5">
        <span class="font-bold block">Move To Finished</span>
        <ol class="list-disc list-inside list-bullets">
          <li class="!mt-0 list-item">
            The “Move To Finished” button is one of the green buttons at the top of your active conversations page.
          </li>
          <li class="!mt-0 list-item">
            Clicking this will prompt you with options to rate the customer or skip the rating process.
          </li>
        </ol>
      </p>

      <p class="mt-2 pl-5">
        <span class="font-bold block">Customer Rating</span>
        <ol class="list-disc list-inside list-bullets">
          <li class="!mt-0 list-item">
            Your rating of the customer will be factored into the customer’s overall rating. This helps your team to better understand the customer’s interaction history and behavior.
          </li>
          <li class="!mt-0 list-item">
            The overall rating is calculated as the average of all rated conversations for that customer.
          </li>
          <li class="!mt-0 list-item">
            If for some reason you do not want to rate the customer, you can select the “Skip Rating and Finish” option.
          </li>
        </ol>
      </p>

      <p class="mt-2 pl-5">
        <span class="font-bold block">Considerations When Rating</span>
        <ol class="list-disc list-inside list-bullets">
          <li class="!mt-0 list-item">
            <span class="font-bold">Honesty:</span>
            Your rating should be honest and reflect your overall sentiment about the interaction with the customer.
          </li>
          <li class="!mt-0 list-item">
            <span class="font-bold">Impartiality:</span>
            Try to be as objective as possible, basing your rating on the specific interaction rather than any preconceived notions.
          </li>
          <li class="!mt-0 list-item">
            <span class="font-bold">Consistency:</span>
            Consistent ratings across multiple team members and interactions provide the most accurate overall rating for the customer.
          </li>
        </ol>
      </p>
      By thoughtfully concluding your conversations and providing meaningful customer ratings, you contribute to the continuous improvement of customer service and interaction quality within your team.
    </p>

    <p class="mt-2">
      If you have any questions or require assistance, feel free to reach out to us at <a class="text-blue-500 hover:text-blue-800 underline" href="mailto:info@texnrewards.com">info@texnrewards.com</a> or submit a support request on our Help & Support page.
    </p>
  </div>
</template>

<script>
export default {
  name: "p5s2salesDashboard",

  data() {
    return {
      // 
    }
  },

  computed: {
    C_baseUrl() {
      if (window.G_dev_flag) {
        return "https://dev-dashboard.synergy-auto-solutions.com";
      }

      return "https://dashboard.synergy-auto-solutions.com";
    },

    C_baseUrlPath() {
      if (window.G_dev_flag) {
        return "dev-dashboard.synergy-auto-solutions.com";
      }

      return "dashboard.synergy-auto-solutions.com";
    }
  }
}
</script>