import { FirestoreDB } from "./include/creds";
import {
  DocPath,
  DataProblem,
  isValidRatingNumber,
  GetDocResult,
  RemoveDocResult,
} from "./include/misc";

import {
  collection,
  doc,
  setDoc,
  getDoc,
  deleteDoc,
  addDoc,
} from "firebase/firestore";

export type fs6ConsumerConversations = {
  conversation_uid: string;
  conversation_type: 1 | 2 | 3 | 4 | 5;
  conversation_status_codes: string;
  phone_number: string;
  conversation_employee_rating?: number;
  conversation_consumer_rating?: number;
  conversation_consumer_review?: string;
  assigned_employee_uid?: string;
  called_automated_phone_system_timestamp?: Date;
  entered_queue_timestamp: Date;
  chat_start_timestamp?: Date;
  chat_finished_timestamp?: Date;
  recycler_only_chat_employee_uid_list?: string[];
  invited_employee_only_chat_uid_list?: string[];
  conversation_invite_url?: string;
  last_message_timestamp?: Date;
};

export const IV_validation_problems: DataProblem[] = [];

export function getDataProblems(data: fs6ConsumerConversations): DataProblem[] {
  return IV_validation_problems;
}

function isStatusCodeValid(status_codes: string): boolean {
  const alphabet_lower = "abcdefghijklmnopqrstuvwxyz";

  for (let i = 0; i < status_codes.length; i++) {
    const is_lowercase_alphabetic = !alphabet_lower.includes(status_codes[i]);

    if (is_lowercase_alphabetic) {
      return false;
    }

    const is_char_repeated = status_codes.slice(0, i).includes(status_codes[i]);

    if (is_char_repeated) {
      return false;
    }
  }
  return true;
}

function isConversationTypeValid(conversation_type: number): boolean {
  const valid_conversation_types = [1, 2, 3, 4];

  if (!valid_conversation_types.includes(conversation_type)) {
    return false;
  }

  return true;
}

// We assume that this function is fast and will not block.
export function isValidData(data: fs6ConsumerConversations): boolean {
  // if (
  //   data.conversation_employee_rating !== undefined &&
  //   !isValidRatingNumber(data.conversation_employee_rating)
  // ) {
  //   logger.error("Data validation error");
  //   return false;
  // }

  // if (
  //   data.conversation_consumer_rating !== undefined &&
  //   !isValidRatingNumber(data.conversation_consumer_rating)
  // ) {
  //   logger.error("Data validation error");
  //   return false;
  // }

  // if (!isStatusCodeValid(data.conversation_status_codes)) {
  //   logger.error("Data validation error");
  //   return false;
  // }

  // if (!isConversationTypeValid(data.conversation_type)) {
  //   logger.error("Data validation error");
  //   return false;
  // }

  return true;
}

export async function getDocFrom_fs6ConsumerConversations(
  fs1_id: string,
  fs2_id: string,
  fs4_id: string,
  fs6_id: string
): Promise<GetDocResult> {
  const result: GetDocResult = {
    debug_data: [],
    return_msg: "fs6ConsumerConversations:getDocFrom_fs6ConsumerConversations ",
    success: true,
    doc_data: {},
  };

  if (!fs1_id || !fs2_id || !fs4_id || !fs6_id) {
    result.success = false;
    result.return_msg += "Invalid function arguments.";
    return await Promise.resolve(result);
  }

  const collectionRef = collection(
    FirestoreDB,
    "fs1RecyclerBusiness",
    fs1_id,
    "fs2RecyclerLocation",
    fs2_id,
    "fs4ConsumerContacts",
    fs4_id,
    "fs6ConsumerConversations"
  )

  const doc_ref = doc(collectionRef, fs6_id);

  const doc_data = (await getDoc(doc_ref)).data();

  if (doc_data === undefined) {
    result.success = false;
    result.return_msg +=
      "Could not find a document with the passed-in path arguments.";
    return await Promise.resolve(result);
  }

  result.doc_data = doc_data;
  return await Promise.resolve(result);
}

export async function addDocIn_fs6ConsumerConversations(
  fs1_id: string,
  fs2_id: string,
  fs4_id: string,
  data: fs6ConsumerConversations
): Promise<DocPath> {
  const result: DocPath = {
    id: "",
    path: "",
  };
  if (!isValidData(data)) {
    return await Promise.resolve(result);
  }

  const doc_ref = doc(
    collection(
      FirestoreDB,
      "fs1RecyclerBusiness",
      fs1_id,
      "fs2RecyclerLocation",
      fs2_id,
      "fs4ConsumerContacts",
      fs4_id,
      "fs6ConsumerConversations"
    )
  );

  data.conversation_uid = doc_ref.id;
  await setDoc(doc_ref, data);

  result.id = doc_ref.id;
  result.path = doc_ref.path;
  return await Promise.resolve(result);
}

export async function setDocIn_fs6ConsumerConversations(
  fs1_id: string,
  fs2_id: string,
  fs4_id: string,
  fs6_id: string,
  data: fs6ConsumerConversations
): Promise<DocPath> {
  const result: DocPath = {
    id: "",
    path: "",
  };
  if (!isValidData(data)) {
    return await Promise.resolve(result);
  }

  const collectionRef = collection(
    FirestoreDB,
    "fs1RecyclerBusiness",
    fs1_id,
    "fs2RecyclerLocation",
    fs2_id,
    "fs4ConsumerContacts",
    fs4_id,
    "fs6ConsumerConversations"
  )

  const doc_ref = doc(collectionRef, fs6_id)

  await setDoc(doc_ref, data);

  result.id = doc_ref.id;
  result.path = doc_ref.path;
  return await Promise.resolve(result);
}

export async function removeDocFrom_fs6ConsumerConversations(
  fs1_id: string,
  fs2_id: string,
  fs4_id: string,
  fs6_id: string
): Promise<RemoveDocResult> {
  const result: RemoveDocResult = {
    debug_data: [],
    return_msg:
      "fs6ConsumerConversations:removeDocFrom_fs6ConsumerConversations ",
    success: true,
  };

  if (!fs1_id || !fs2_id || !fs4_id || !fs6_id) {
    result.success = false;
    result.return_msg += "Invalid function arguments.";
    return await Promise.resolve(result);
  }

  const doc_ref = doc(
    collection(
      FirestoreDB,
      "fs1RecyclerBusiness",
      fs1_id,
      "fs2RecyclerLocation",
      fs2_id,
      "fs4ConsumerContacts",
      fs4_id,
      "fs6ConsumerConversations"
    ),
    fs6_id
  );

  await deleteDoc(doc_ref);
  return await Promise.resolve(result);
}
