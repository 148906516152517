<template>
  <div class="relative rounded-md shadow-sm" :class="containerClasses">
    <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
    <span class="text-gray-500 sm:text-sm">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
      <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"/>
      </svg>
    </span>
    </div>
    <input autocomplete="off" v-model="localValue" @input="updateValue" type="text" name="search" id="search" class="text-left" :class="inputClasses" placeholder="search" style="padding-left: 2rem; padding-right: 1.25rem; height: 30px;" />
  </div>
</template>

<script>
export default {
  name: "SearchBar",

  props: ["containerClasses", "inputClasses", "value"],

  data() {
    return {
      localValue: this.value
    }
  },

  methods: {
    updateValue(e) {
      this.$emit("input", e.target.value)
    }
  }

}
</script>