<template>
  <div id="p1s2c5">
    <div class="p-3">

      <!-- Start date -->
      <div class="flex items-center justify-start mb-2">
        <label for="startDate" class="font-medium text-sm text-left mr-1"
          >Start Date:
        </label>
        <input v-model="DV_startDate" id="startDate" type="date" name="startDate"/>
<!--         <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
          />
        </svg> -->
      </div>

      <!-- End date -->
      <div class="flex items-center justify-start mb-5">
        <label for="endDate" class="font-medium text-sm text-left mr-2"
          >End Date:
        </label>
        <input v-model="DV_endDate" id="endDate" type="date" name="endDate"/>
<!--         <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
          />
        </svg> -->
      </div>

      <!-- Sales persons list -->
      <div class="flex items-center justify-between">
        <label for="salePerson" class="font-medium text-sm text-left mr-1">
          Sales Person:
        </label>
        <div class="w-5/12">
          <select
            id="salePerson"
            name="salePerson"
            class="w-full rounded border border-gray-400 py-1 pl-2 pr-5 text-gray-500 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          >
            <option>All</option>
            <option>Jesus Richardson</option>
            <option>Leonard Grant</option>
            <option>Rhonda Hernandez</option>
            <option>Edmund Duncan</option>
            <option>Owen Reid</option>
            <option>Roland Parsons</option>
            <option>Johnnie Hale</option>
            <option>Yvette Cortez</option>
            <option>Essie Erickson</option>
            <option>Ken Rogers</option>
          </select>
        </div>
      </div>

      <!-- How user joined options -->
      <div class="flex items-center justify-between my-2">
        <label for="salePerson" class="font-medium text-sm text-left mr-1"
          >How Communication Started:
        </label>
        <div class="w-5/12">
          <select
            id="salePerson"
            name="salePerson"
            class="w-full rounded border border-gray-400 py-1 pl-2 pr-5 text-gray-500 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          >
            <option>All</option>
            <option>Web Widget</option>
            <option>Sales Person Initiated</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "p1s2c5CustomerEngagementFilters",

  data() {
    return {
      DV_startDate: "",
      DV_endDate: "",
    };
  },
};
</script>
