import { FirestoreDB } from "./include/creds";
import {
  DocPath,
  DataProblem,
  RemoveDocResult,
  GetDocResult,
} from "./include/misc";

import {
  collection,
  doc,
  setDoc,
  getDoc,
  deleteDoc,
} from "firebase/firestore";

export type fs128TrialAccountTokens = {
  trial_account_token: string;
  token_used: boolean;
  business_uid: string;
  email_address: string;
};

export function getDataProblems_fs128TrialAccountTokens(
  data: fs128TrialAccountTokens
): DataProblem[] {
  const dataproblems: DataProblem[] = [];
  return dataproblems;
}

// We assume that this function is fast and will not block.
export function isValidData(data: fs128TrialAccountTokens): boolean {
  return true;
}

export async function getDocFrom_fs128TrialAccountTokens(
  fs128_id: string
): Promise<GetDocResult> {
  const result: GetDocResult = {
    debug_data: [],
    return_msg: "fs128TrialAccountTokens:getDocFrom_fs128TrialAccountTokens",
    success: true,
    doc_data: {},
  };

  if (!fs128_id) {
    result.success = false;
    result.return_msg += "Invalid function arguments.";
    return await Promise.resolve(result);
  }

  const doc_ref = doc(collection(FirestoreDB, "fs128TrialAccountTokens"), fs128_id);

  const doc_data = (await getDoc(doc_ref)).data();
  if (doc_data === undefined) {
    result.success = false;
    result.return_msg +=
      "Could not find a document with the passed-in path arguments.";
    return await Promise.resolve(result);
  }

  result.doc_data = doc_data;
  return await Promise.resolve(result);
}

export async function addDocIn_fs128TrialAccountTokens(
  data: fs128TrialAccountTokens
): Promise<DocPath> {
  const result: DocPath = {
    id: "",
    path: "",
  };
  if (!isValidData(data)) {
    return await Promise.resolve(result);
  }

  const doc_ref = doc(collection(FirestoreDB, "fs128TrialAccountTokens"));

  data.business_uid = doc_ref.id;
  await setDoc(doc_ref, data);

  result.id = doc_ref.id;
  result.path = doc_ref.path;
  return await Promise.resolve(result);
}

export async function setDocIn_fs128TrialAccountTokens(
  fs128_id: string,
  data: fs128TrialAccountTokens
): Promise<DocPath> {
  const result: DocPath = {
    id: "",
    path: "",
  };
  if (!isValidData(data)) {
    return await Promise.resolve(result);
  }

  const doc_ref = doc(collection(FirestoreDB, "fs128TrialAccountTokens"), fs128_id);

  await setDoc(doc_ref, data);

  result.id = doc_ref.id;
  result.path = doc_ref.path;
  return await Promise.resolve(result);
}

export async function removeDocFrom_fs128TrialAccountTokens(
  fs128_id: string
): Promise<RemoveDocResult> {
  const result: RemoveDocResult = {
    debug_data: [],
    return_msg: "fs128TrialAccountTokens:removeDocFrom_fs128TrialAccountTokens ",
    success: true,
  };

  if (!fs128_id) {
    result.success = false;
    result.return_msg += "Invalid function arguments.";
    return await Promise.resolve(result);
  }

  const doc_ref = doc(collection(FirestoreDB, "fs128TrialAccountTokens", fs128_id));

  await deleteDoc(doc_ref);
  return await Promise.resolve(result);
}
