
export default {
  methods: {
    splitConsumerConversationUuid(consumer_conversation_uuid) {
      if (!consumer_conversation_uuid) { return [] };
      
      let consumer_uid, conversation_uid = consumer_conversation_uuid.split("-");
      return [consumer_uid, conversation_uid];
    },

    createConsumerConversationUuid(consumer_uid, conversation_uid) {
      return consumer_uid + "-" + conversation_uid;
    },
    
    formatPhoneNumber(phoneNumber) {
      if (!phoneNumber) { return ""; };
      return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
    },

    uuidv4() {
      return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
      );
    }

  }
}
