/* eslint-disable */
import { ajax } from "noquery-ajax";
import { httpsLogger } from "../p1s7/p1s7e1";

function verifyCardDetails(
  stripe_token: string,
  action_type: 1 | 2 | 3,
  recycler_business_uid: string,
  trial_token?: string,
  
) {
  return new Promise(function (resolve, reject) {
    let params = {
      firebase_uid: window.G_firebase_auth.IV_uid,
      firebase_token: window.G_firebase_auth.IV_id_token,
      p1s7e3_stripe_token: stripe_token,
      p1s7e3_action_type: action_type,
      recycler_uid: recycler_business_uid,
      ...(trial_token && { p1s7e3_trial_token: trial_token }),
    };

    ajax({
      url: window.G_cloud_functions_base_url + "p1s7e3VerifyCardDetails",
      headers: {
        Accept: "application/json",
      },
      method: "POST",
      dataType: "json",
      data: params,
      success: function (result) {
        let response_data = {
          success: true,
          response: result,
          request_params: params
        }
        resolve(response_data);
      },
      error: function (error) {
        let error_data = {
          success: false,
          request_object: error,
          request_params: params,
          file_name: "p1s7e3.ts",
          class_name: "p1s7e3",
          method_name: "verifyCardDetails",
          error_title: "Failed to verify card details..",
          error_description: "The server responded with an error when requesting for card verification.",
        };

        httpsLogger(error_data);
        reject(error_data);
      },
    });
  });
}

export default verifyCardDetails;
