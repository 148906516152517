<template>
  <div>
    <h2 class="text-2xl font-bold text-center">Submit Support Request</h2>
    <div id="contactForm" class="w-4/5 mx-auto mt-6">
      <div class="flex items-center text-left">
        <label class="w-40" for="name">Name:</label>
        <div class="w-1/2">
          <input autocomplete="off" type="text" id="name" name="name" v-model="DV_employeeInfo.name">
          <span class="text-left font-bold text-red-400" v-if="DV_employeeInfoErrors.name">{{ DV_employeeInfoErrors.name }}</span>
        </div>
      </div>

      <div class="flex items-center text-left mt-4">
        <label class="w-40" for="email">Email:</label>
        <div class="w-1/2">
          <input autocomplete="off" type="email" id="email" name="email" v-model="DV_employeeInfo.email">
          <span class="text-left font-bold text-red-400" v-if="DV_employeeInfoErrors.email">{{ DV_employeeInfoErrors.email }}</span>
        </div>
      </div>

      <div class="flex items-center text-left mt-4">
        <label class="w-40" for="phoneNumber">Phone Number:</label>
        <div class="w-1/2">
          <input autocomplete="off" type="number" id="phoneNumber" name="phoneNumber" v-model="DV_employeeInfo.number">
          <span class="text-left font-bold text-red-400" v-if="DV_employeeInfoErrors.number">{{ DV_employeeInfoErrors.number }}</span>
        </div>
      </div>

      <div class="w-full mt-4">
        <label class="block w-full text-left" for="message">Write your message here:</label>
        <div class="w-full">
          <textarea autocomplete="off" id="message" name="message" rows="4" class="mt-2 w-full" v-model="DV_employeeInfo.message"></textarea>
          <span class="text-left font-bold text-red-400" v-if="DV_employeeInfoErrors.message">{{ DV_employeeInfoErrors.message }}</span>
        </div>
      </div>

      <div class="w-full justify-start flex mt-4">
        <button class="btn btn-blue ml-0 cursor-pointer" :class="{'cursor-not-allowed': isLoading,}" @click="sendSubmitSupportRequest()">{{ isLoading ? "Submitting..." : "Submit"}}</button>
      </div>
    </div>
  </div>
</template>

<script>
import submitSupportRequest from "../../includes/server_calls/p1s3/p1s3e43.ts";
import dataValidator from "../../includes/bi1Datavalidation"
import { CR, RC } from "../../includes/bi2SuccessCodes"

export default {
  name: "p5s7submitSupportRequest",

  data() {
    return {
      isLoading: false,
      DV_employeeInfo: {
        name: "",
        email: "",
        number: null,
        message: ""
      },
      DV_employeeInfoErrors: {
        name: "",
        email: "",
        number: "",
        message: ""
      }
    }
  },

  methods: {
    formValidationCheck() {
      const CI = this;
      let fail_flag = false;
      let call_result  = {};

      // Profile validation
      call_result = dataValidator.is_string(CI.DV_employeeInfo.name, 0, 60);
      if (call_result[CR.success] !== RC.success) {
        CI.DV_employeeInfoErrors.name = "Name should be less than 60 characters";
        fail_flag = true;
      }

      // Email verification
      call_result = dataValidator.is_email_address(CI.DV_employeeInfo.email);
      if (call_result[CR.success] !== RC.success) {
        CI.DV_employeeInfoErrors.email = "Email address is invalid";
        fail_flag = true;
      }

      //Number verification
      call_result = dataValidator.is_string(CI.DV_employeeInfo.number, 10, 10);
      if (call_result[CR.success] !== RC.success) {
        CI.DV_employeeInfoErrors.number = "Phone number should be 10 digits";
        fail_flag = true;
      }

      //Number verification
      call_result = dataValidator.is_us_phone_number(CI.DV_employeeInfo.number);
      if (call_result[CR.success] !== RC.success) {
        CI.DV_employeeInfoErrors.number = "Valid US phone number is required.";
        fail_flag = true;
      }

      // Message verification
      call_result = dataValidator.is_string(CI.DV_employeeInfo.message, 1);
      if (call_result[CR.success] !== RC.success) {
        CI.DV_employeeInfoErrors.message = "Write a meaningful message";
        fail_flag = true;
      }

      if (fail_flag === true) { return false;}

      return true;
    },

    sendSubmitSupportRequest() {
      this.DV_employeeInfoErrors = { name: "",email: "",number: "",message: ""};
      this.isLoading = true;

      if (this.formValidationCheck() == false) {
        this.isLoading = false;
        return;
      }

      submitSupportRequest(
        window.G_bi2_data.IV_active_employee_info.business_uid,
        window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
        this.DV_employeeInfo.name,
        this.DV_employeeInfo.email,
        this.DV_employeeInfo.number,
        this.DV_employeeInfo.message
      ).then((resp) => {
        this.$awn.success(`Message successfully sent to Synergy support staff.<br><br> Someone will reach out to you as soon as possible at ${this.DV_employeeInfo.number} or ${this.DV_employeeInfo.email}`);
        this.DV_employeeInfo = { name: "",email: "",number: "",message: ""};
      }).catch((error) => {
        console.log(error);
        this.$awn.alert("Unable to submit response. Please try again later.");
      });
    }
  }


}
</script>