<template>
  <div id="p1s3c1" class="mt-4">
    <div class="block">
      <div class="border border-gray-400 shadow-sm rounded-xl">
        <div class="">

          <!-- Customer contacts tab button -->
          <button
            @click="(DV_activeFilterView = 'customerContacts'), $emit('filter-type', DV_activeFilterView)"
            :class="{ active: DV_activeFilterView == 'customerContacts' }"
            type="button"
            class="relative inline-flex items-center w-full justify-start rounded-tl-xl rounded-tr-xl border border-gray-300 px-4 py-2 text-sm font-bold text-gray-700 hover:bg-gray-50 focus:text-white focus:z-10 focus:bg-blue-900 focus:outline-none focus:bg-blue-900"
          >
            Customer Contacts
          </button>

          <p1s3c5CustomerFilters
            v-if="DV_activeFilterView == 'customerContacts'"
            :businessesList="businessesList"
            :salesEmployeesList="C_salesEmployeesList"
            :citiesList="C_consumerCitiesList"
            :statesList="C_consumerStatesList"
            @event-customer-type-filter-selected="setCustomerTypeFilter"
            @event-customer-rating-filter-selected="setCustomerRatingFilter"
            @event-customer-city-filter-selected="setCustomerCityFilter"
            @event-customer-state-filter-selected="setCustomerStateFilter"
            @event-customer-zipCode-selected="setCustomerZipCodeFilter"
            @event-customer-sales-person-filter-selected="setCustomerSalesPersonFilter"
            @event-customer-business-filter-selected="setCustomerBusinessFilter"
          />

          <!-- Business tab button -->
          <button
            :class="{ active: DV_activeFilterView == 'businesses' }"
            @click="(DV_activeFilterView = 'businesses'), $emit('filter-type', DV_activeFilterView)"
            type="button"
            class="relative inline-flex items-center w-full justify-start border border-gray-300 px-4 py-2 text-sm font-bold text-gray-700 hover:bg-gray-50 focus:text-white focus:z-10 focus:bg-blue-900 focus:outline-none focus:bg-blue-900"
          >
            Businesses
          </button>
          <!--  -->
          <p1s3c7BusinessFilters 
            v-if="DV_activeFilterView == 'businesses'" 
            :businessesList="businessesList"
            :P_citiesList="DV_businessCityList"
            :P_statesList="DV_businessStatesList"
            @event-business-city-filter-selected="setBusinessCityFilter"
            @event-business-state-filter-selected="setBusinessStateFilter"
            @event-business-customer-type-filter-selected="setBusinessTypeFilter"
          />

          <!-- Employees tab button -->
          <button
            v-if="C_canManageEmployees"
            :class="{ active: DV_activeFilterView == 'employees' }"
            @click="(DV_activeFilterView = 'employees'), $emit('filter-type', DV_activeFilterView)"
            type="button"
            class="relative inline-flex items-center w-full rounded-bl-xl rounded-br-xl border justify-start px-4 py-2 text-sm font-bold text-gray-700 hover:bg-gray-50 focus:text-white focus:z-10 focus:bg-blue-900 focus:outline-none focus:bg-blue-900"
          >
            Employees
          </button>
          <!--  -->
          <p1s3c6EmployeesFilters @event-employee-role-filter-selected="setEmpolyeeRoleFilter" v-if="DV_activeFilterView == 'employees'" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import p1s3c6EmployeesFilters from "./p1s3c6EmployeesFilters.vue";
import p1s3c7BusinessFilters from "./p1s3c7BusinessFilters.vue";
import p1s3c5CustomerFilters from "./p1s3c5CustomerFilters.vue";

export default {
  name: "p1s3c1Filters",

  components: { p1s3c5CustomerFilters, p1s3c7BusinessFilters, p1s3c6EmployeesFilters},

  props: ["businessesList"],

  data() {
    return {
      DV_activeFilterView: "customerContacts",
      DV_businessStatesList: [],
      DV_businessCityList: [],
      DV_consumerBusinesses: {},
      DV_employeeInfo: {},
      DV_employeePermissions: {}
    };
  },

  mounted() {
    const CI = this;

    CI.DV_consumerBusinesses = window.G_bi2_data.IV_consumer_businesses.value;
    CI.DV_employeeInfo = window.G_bi2_data.IV_active_employee_info;
    CI.DV_employeePermissions = window.G_bi2_data.IV_active_employee_info.permissions;
  },

  computed: {
    C_salesEmployeesList() {
      let employees = [];

      if (window.G_bi2_data && window.G_bi2_data.IV_recycler_employees && window.G_bi2_data.IV_recycler_employees.value) {
        const active_location = window.G_bi2_data.IV_active_employee_info.recycler_location_uid

        for (let empUid in window.G_bi2_data.IV_recycler_employees.value[active_location]) {

          let emp = window.G_bi2_data.IV_recycler_employees.value[active_location][empUid]
          if (emp["permissions"]["sales_person"]) {
            employees.push({name: `${emp.first_name} ${emp.last_name}`, uid: emp.employee_uid})
          }
        }
      }

      return employees;
    },

    C_consumerCitiesList() {
      let citiesList = ["Any"]
      let index = window.G_bi2_data.IV_al_consumer_contacts;

      if (window.G_bi2_data && window.G_bi2_data.IV_consumer_contacts && window.G_bi2_data.IV_consumer_contacts.value) {
        for (let consumerUid in window.G_bi2_data.IV_consumer_contacts.value) {
          let consumer = window.G_bi2_data.IV_consumer_contacts.value[consumerUid]
          let city = consumer[index.address_info_city_2d[0]][index.address_info_city_2d[1]];

          if (city && city.trim()) { citiesList.push(city) }
        }
      }

      return _.uniq(citiesList)
    },

    C_consumerStatesList() {
      let statesList = ["Any"]
      let index = window.G_bi2_data.IV_al_consumer_contacts;

      if (window.G_bi2_data && window.G_bi2_data.IV_consumer_contacts && window.G_bi2_data.IV_consumer_contacts.value) {
        for (let consumerUid in window.G_bi2_data.IV_consumer_contacts.value) {
          let consumer = window.G_bi2_data.IV_consumer_contacts.value[consumerUid]
          let state = consumer[index.address_info_state_2d[0]][index.address_info_state_2d[1]];

          if (state && state.trim()) { statesList.push(state) }
        }
      }

      return _.uniq(statesList)
    },

    C_canManageEmployees() {
      const CI = this;

      return (CI.DV_employeePermissions.admin === true || CI.DV_employeePermissions.manager === true);
    }
  },

  watch: {
    DV_employeeInfo: {
      handler() {
        const CI = this;
        CI.DV_employeePermissions = window.G_bi2_data.IV_active_employee_info.permissions;
      },
      deep: true
    },

    DV_consumerBusinesses: {
      handler() {
        let statesList = ["Any"];
        let cityList = ["Any"]

        if (window.G_bi2_data && window.G_bi2_data.IV_consumer_businesses && window.G_bi2_data.IV_consumer_businesses.value) {
          for (let businessUid in window.G_bi2_data.IV_consumer_businesses.value) {
            let business = window.G_bi2_data.IV_consumer_businesses.value[businessUid];
            if(business.state && business.state.trim() !== "") {
              statesList.push(business.state);
            }
            if(business.city && business.city.trim() !== "") {
              cityList.push(business.city);
            }          
          }
        }

        this.DV_businessStatesList = _.uniq(statesList);
        this.DV_businessCityList = _.uniq(cityList);
      },

      deep: true
    }
  },


  methods: {
    setEmpolyeeRoleFilter(filter) {
      this.$emit('event-employee-role-filter-selected', filter)
    },

    setCustomerRatingFilter(filter) {
      this.$emit('event-customer-rating-filter-selected', filter)
    },

    setCustomerCityFilter(filter) {
      this.$emit('event-customer-city-filter-selected', filter)
    },

    setCustomerStateFilter(filter) {
      this.$emit('event-customer-state-filter-selected', filter)
    },

    setCustomerZipCodeFilter(filter) {
      this.$emit("event-customer-zipCode-selected", filter)
    },

    setCustomerSalesPersonFilter(filter) {
      this.$emit('event-customer-sales-person-filter-selected', filter)
    },

    setCustomerBusinessFilter(filter) {
      this.$emit('event-customer-business-filter-selected', filter)
    },

    setCustomerTypeFilter(filter) {
      this.$emit("event-customer-type-filter-selected", filter)
    },

    setBusinessSalesPersonFilter(filter) {
      this.$emit('event-business-sales-person-filter-selected', filter)
    },

    setBusinessRatingFilter(filter) {
      this.$emit('event-business-rating-filter-selected', filter)
    },

    setBusinessCityFilter(filter) {
      this.$emit('event-business-city-filter-selected', filter)
    },

    setBusinessStateFilter(filter) {
      this.$emit('event-business-state-filter-selected', filter)
    },

    setBusinessTypeFilter(filter) {
      this.$emit("event-business-customer-type-filter-selected", filter)
    },

  }
};
</script>
