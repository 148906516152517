<template>
  <div class="">
    <!-- Final message with dashboard button -->
    <div
      v-if="P_creatingLocationStatus === 'finished'"
      class="pl-4 pr-4 flex flex-col items-center"
    >
      <h2 class="text-3xl mt-6 font-bold mb-2">
        Thank you for choosing Synergy Auto Solutions!
      </h2>
      <button
        v-if="DV_activeRenderMode > DV_renderModePhone"
        @click.stop="goToDashboard"
        class="btn btn-lime text-xl mt-4 mb-4 w-fit"
      >
        Go To Dashboard
      </button>

      <span class="font-bold text-xl text-left w-full"
        >Your Synergy Auto Solutions account is created and ready to go! Here's
        what's next:</span
      >

      <div class="text-left w-full">
        <span class="font-bold text-left">Dashboard Access:</span>
        <span class="text-left ml-2"
          >You can log in right away on a desktop computer to your Synergy Sales
          Dashboard.</span
        >
      </div>

      <div class="text-left w-full">
        <span class="font-bold text-left">What's in the Welcome Email?:</span>
        <span class="text-left ml-2"
          >A welcome email will be dispatched shortly to the business email you
          provided during setup. In the Welcome Email, you will find:</span
        >
      </div>

      <ol class="list-disc pl-5 list-bullets !mt-1 text-left w-full ml-5">
        <li class="!list-item !mt-1">
          <span class="font-bold">Integration Guide:</span> Instructions to
          optimize your platform experience, including procedures for team
          invitations, mobile payments, identity verification, online reviews,
          and integrating widgets for both your website and Facebook.
        </li>

        <li class="!list-item !mt-1">
          <span class="font-bold">Support & Training:</span> Resources to help
          you navigate the platform, access to training materials, and a guide
          on how to contact our team if you require assistance.
        </li>
      </ol>

      <p class="text-left mt-4 !mb-0 w-full">
        For any assistance or queries, our support team is just an email away.
        Contact us anytime at
        <a
          class="text-blue-500 hover:text-blue-800 underline"
          href="mailto:info@texnrewards.com"
          >info@texnrewards.com</a
        >. We appreciate your choice to work with Synergy Auto Solutions and are
        here to support your journey with our platform.
      </p>
      <p class="text-left mt-2 w-full">
        Please check your spam and junk folders if you do not see the email
        within 5 minutes. Keep an eye on your inbox for the Welcome Email with
        further instructions.
      </p>
    </div>

    <!-- Loading statuses -->
    <div
      v-else
      :class="{
        'text-xl': DV_activeRenderMode < DV_renderModeTablet,
        'text-3xl whitespace-nowrap': DV_activeRenderMode > DV_renderModePhone,
      }"
      class="m-auto text-center grid pl-5 pr-5 items-center"
    >
      <!-- User account Status -->
      <!-- Loading -->
      <svg
        v-if="P_creatingUserAccount == 'creating'"
        :style="DV_progressIconStyle"
        class="col-start-1 spin-clockwise w-full h-full"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
        />
      </svg>
      <div
        v-if="P_creatingUserAccount == 'creating'"
        class="col-start-2 text-left flex flex-col"
      >
        <span> Creating Email Login...</span>
        <span class="text-base">Hang on! This will take about 15 seconds</span>
      </div>

      <!-- Error -->
      <svg
        v-if="P_creatingUserAccount == 'error'"
        :style="DV_progressIconStyle"
        class="col-start-1"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
        />
      </svg>
      <div
        v-if="P_creatingUserAccount == 'error'"
        class="col-start-2 text-left"
      >
        There was an error while the email login.
      </div>

      <!-- Check -->
      <svg
        v-if="P_creatingUserAccount === 'finished'"
        :style="DV_progressIconStyle"
        class="col-start-1"
        viewBox="0 0 20 20"
        fill="currentColor"
        aria-hidden="true"
      >
        <path
          fill-rule="evenodd"
          d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
          clip-rule="evenodd"
        />
      </svg>
      <span
        v-if="P_creatingUserAccount === 'finished'"
        class="col-start-2 text-left"
      >
        Email Login Created
      </span>

      <!-- Business status -->
      <!-- Loading -->
      <svg
        v-if="P_creatingBusinessStatus == 'creating'"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        :style="DV_progressIconStyle"
        class="spin-clockwise"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
        />
      </svg>
      <div
        v-if="P_creatingBusinessStatus == 'creating'"
        class="col-start-2 text-left flex flex-col"
      >
        <span>Creating Recycler Business...</span>
        <span class="text-base"
          >“Almost there! This will take about 30 seconds</span
        >
      </div>

      <!-- Error -->
      <svg
        v-if="P_creatingBusinessStatus == 'error'"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        :style="DV_progressIconStyle"
        class=""
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
        />
      </svg>
      <div
        v-if="P_creatingBusinessStatus == 'error'"
        class="col-start-2 text-left break-all"
      >
        There was an error while creating the recycler business. Please email
        the details below to support@synergy-auto-solutions.com<br />
        {{ P_errorData.creationServerCalls.p1s3e20Error }}
      </div>

      <!-- Check -->
      <svg
        v-if="P_creatingBusinessStatus === 'finished'"
        :style="DV_progressIconStyle"
        class="flex-none col-start-1"
        viewBox="0 0 20 20"
        fill="currentColor"
        aria-hidden="true"
      >
        <path
          fill-rule="evenodd"
          d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
          clip-rule="evenodd"
        />
      </svg>
      <span
        v-if="P_creatingBusinessStatus === 'finished'"
        class="col-start-2 text-left"
        >Recycler Business Created</span
      >

      <!-- charging card via stripe status-->
      <!-- Loading -->
      <svg
        v-if="P_chargingCardStatus == 'processing'"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        :style="DV_progressIconStyle"
        class="spin-clockwise"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
        />
      </svg>
      <div
        v-if="P_chargingCardStatus == 'processing'"
        class="col-start-2 text-left flex flex-col"
      >
        <span
          >Processing
          {{ DV_trialToken.length > 0 ? "Trial " : "" }}Payment...</span
        >
        <span class="text-base"
          >“Almost there! This will take about 30 seconds</span
        >
      </div>

      <!-- Error -->
      <svg
        v-if="P_chargingCardStatus == 'error'"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        :style="DV_progressIconStyle"
        class=""
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
        />
      </svg>
      <div
        v-if="P_chargingCardStatus == 'error'"
        class="col-start-2 text-left break-all"
      >
        There was an error while processing your
        {{ DV_trialToken.length > 0 ? "trial " : "" }}payment. Please email the
        details below to support@synergy-auto-solutions.com<br />
        {{ P_errorData.creationServerCalls.p1s3e20Error }}
      </div>

      <!-- Check -->
      <svg
        v-if="P_chargingCardStatus === 'finished'"
        :style="DV_progressIconStyle"
        class="flex-none col-start-1"
        viewBox="0 0 20 20"
        fill="currentColor"
        aria-hidden="true"
      >
        <path
          fill-rule="evenodd"
          d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
          clip-rule="evenodd"
        />
      </svg>
      <span
        v-if="P_chargingCardStatus === 'finished'"
        class="col-start-2 text-left"
      >
        {{ DV_trialToken.length > 0 ? "Trial " : "" }}Payment Processed!
      </span>
      <!-- </end>charging card via stripe status-->

      <!-- Location status -->
      <!-- Loading -->
      <svg
        v-if="P_creatingLocationStatus == 'creating'"
        :style="DV_progressIconStyle"
        class="col-start-1 spin-clockwise"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
        />
      </svg>
      <span
        v-if="P_creatingLocationStatus == 'creating'"
        class="col-start-2 text-left"
        >Creating Recycler Location...</span
      >

      <!-- Error -->
      <svg
        v-if="P_creatingLocationStatus == 'error'"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        :style="DV_progressIconStyle"
        class=""
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
        />
      </svg>
      <div
        v-if="P_creatingLocationStatus == 'error'"
        class="col-start-2 text-left"
      >
        There was an error while creating the recycler location. Please email
        the details below to support@synergy-auto-solutions.com<br />
        {{ P_errorData.creationServerCalls.p1s3e12Error }}
        {{ P_errorData.creationServerCalls.p1s3e19Error }}
      </div>

      <!-- Check -->
      <svg
        v-if="P_creatingLocationStatus === 'finished'"
        :style="DV_progressIconStyle"
        class="flex-none col-start-1"
        viewBox="0 0 20 20"
        fill="currentColor"
        aria-hidden="true"
      >
        <path
          fill-rule="evenodd"
          d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
          clip-rule="evenodd"
        />
      </svg>
      <span
        v-if="P_creatingLocationStatus === 'finished'"
        class="col-start-2 text-left"
        >Recycler Location Created</span
      >
    </div>
  </div>
</template>

<script>
import m3RenderingSettings from "../../mixins/m3RenderingSettings";
import { reactive } from "vue";

export default {
  name: "p3s8AccountSetupFinalProgress",

  props: [
    "P_formData",
    "P_creatingBusinessStatus",
    "P_creatingLocationStatus",
    "P_creatingUserAccount",
    "P_errorData",
    "P_chargingCardStatus",
  ],

  mixins: [m3RenderingSettings],

  data() {
    return {
      DV_progressIconStyle: reactive({ width: "5vh", height: "5vh" }),
      DV_trialToken: window.G_trial_token,
      //
    };
  },

  mounted() {
    if (this.DV_activeRenderMode < this.DV_renderModeTablet) {
      this.DV_progressIconStyle = reactive({
        width: "3vh",
        height: "3vh",
        marginRight: "1.5vh",
      });
    }
  },

  computed: {
    C_businessEmail() {
      return this.P_formData.locationInfo.email || "your business email";
    },
  },

  methods: {
    goToDashboard() {
      location.assign(window.location.origin + "/sales-dashboard");
    },
  },
};
</script>
