<template>
  <!-- Customer respone time time -->
  <div id="p1s2c4" class="h-5/6">
    <h1 class="font-medium text-lg text-center my-3">Search Results</h1>
    <div class="overflow-hidden border border-gray-400 rounded-xl h-full m-3">
      <table class="w-full rounded-xl text-center">
        <thead class="border-b">
          <tr class="text-left font-medium bg-gray-100 h-9">
            <th scope="col">Contact Name</th>
            <th scope="col">Business Associations</th>
            <th scope="col">Customer Type</th>
            <th scope="col">Rating</th>
            <th scope="col">Sales Person</th>
            <th scope="col">City</th>
            <th scope="col">State</th>
            <th scope="col">Zip Code</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "p1s2c4CustomerResponseTime",
};
</script>
