import { FirestoreDB } from "./include/creds";
import { DocPath, RemoveDocResult } from "./include/misc";

import {
  collection,
  doc,
  deleteDoc,
  getDoc
} from "firebase/firestore";

export type fs93ConsumerOpenConversations = {
  consumer_contact_uid: string;
};

export function isValidData(data: fs93ConsumerOpenConversations): boolean {
  return true;
}

export async function removeDocFrom_fs93ConsumerOpenConversations(
  fs1_id: string,
  fs2_id: string,
  fs78_id: string,
  fs93_id: string
): Promise<RemoveDocResult> {
  const result: RemoveDocResult = {
    debug_data: [],
    return_msg:
      "fs93ConsumerOpenConversations:removeDocFrom_fs93ConsumerOpenConversations ",
    success: true,
  };

  if (!fs1_id || !fs2_id || !fs78_id || !fs93_id) {
    result.success = false;
    result.return_msg += "Invalid function arguments.";
    return await Promise.resolve(result);
  }

  const doc_ref = doc(
    collection(
      FirestoreDB,
      "fs1RecyclerBusiness",
      fs1_id,
      "fs2RecyclerLocation",
      fs2_id,
      "fs78PhoneNumberLookup",
      fs78_id,
      "fs93ConsumerOpenConversations"
    ),
    fs93_id
  );

  const doc_ref_obj = await getDoc(doc_ref);

  if (doc_ref_obj.exists()) {
    await deleteDoc(doc_ref);
  }

  return await Promise.resolve(result);
}
