<template>
  <div id="p1s1c1c2" class="border border-gray-400 p-2 rounded-xl sahdow-sm">
   
    <!-- Header - back / close / popup buttons -->
    <div class="grid grid-cols-2 items-center mb-3">
      
      <!-- Left - Back button -->
      <button @click="$emit('show-my-customers-view')" class="flex items-center flex-row">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 pr-1">
          <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"/>
        </svg>
        Back
      </button>
      
      <!-- Right Popuo / close buttons -->
      <div class="flex flex-row items-center justify-end">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 mr-3 cursor-pointer">
          <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"/>
        </svg>
        <svg @click="$emit('show-my-customers-view')" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"/>
        </svg>
      </div>
    </div>

    <!-- Profile View Container -->
    <h1 class="font-medium text-md pb-2">
      Profile of <span>{{C_customersFullName}} | {{DV_customer[DV_consumerContactsIndexes.recycler_location_name]}}</span>
    </h1>

    <!-- Profile header tabs -->
    <span class="isolate inline-flex w-full">
      <button
        @click="DV_activeView = 'summary'"
        type="button"
        class="relative rounded-bl-none inline-flex items-center justify-center rounded-l-md border border-gray-300 px-4 py-2 text-xs font-medium hover:bg-gray-50 focus:text-white focus:z-10 focus:bg-blue-900 focus:outline-none focus:bg-blue-900"
        :class="[DV_activeView == 'summary' ? 'text-white z-10 bg-blue-900 outline-none bg-blue-900' : 'text-gray-700']"
      >
        Summary
      </button>
      <button
        @click="DV_activeView = 'feedback'"
        type="button"
        class="relative -ml-px inline-flex items-center justify-center border border-gray-300 bg-white px-4 py-2 text-xs font-medium hover:bg-gray-50 focus:z-10 focus:text-white focus:z-10 focus:bg-blue-900 focus:outline-none focus:bg-blue-900"
        :class="[DV_activeView == 'feedback' ? 'text-white z-10 bg-blue-900 outline-none bg-blue-900' : 'text-gray-700']"
      >
        Feedback
      </button>
      <button
        @click="DV_activeView = 'history'"
        type="button"
        class="relative rounded-b-none -ml-px inline-flex items-center justify-center rounded-r-md border border-gray-300 px-4 py-2 text-xs font-medium hover:bg-gray-50 focus:text-white focus:z-10 focus:bg-blue-900 focus:outline-none focus:bg-blue-900"
        :class="[DV_activeView == 'history' ? 'text-white z-10 bg-blue-900 outline-none bg-blue-900' : 'text-gray-700']"
      >
        Conversation History
      </button>
    </span>

    <!-- Profile content -->
    <div class="border border-gray-400 rounded-b-xl rounded-r-xl p-4">
      
      <!-- Profile Summary -->
      <div v-if="DV_activeView == 'summary'">
        
        <!-- Contact Info -->
        <div class="flex flex-col items-start">
          <a v-if="DV_customer[DV_consumerContactsIndexes.email]" class="text-sm" :href="`mailto:${DV_customer[DV_consumerContactsIndexes.email]}`">{{DV_customer[DV_consumerContactsIndexes.email]}}</a>
          <a v-if="DV_customer[DV_consumerContactsIndexes.facebook_profile_url]" class="text-sm" :href="DV_customer[DV_consumerContactsIndexes.facebook_profile_url]">Open Facebook Profile</a>
        </div>
        
        <!-- Phone numbers -->
        <h1 class="text-left font-medium text-xl mb-2">Phone Numbers</h1>
        <div class="grid grid-cols-3 mb-3">
          <div class="col-span-2 text-left">
            <p class="text-sm">
              <span class="font-medium">Home:</span> <a>{{C_customerPhoneNumber}}</a>
            </p>
            <p class="text-sm">
              <span class="font-medium">Mobile:</span>
              <a href=""></a>
            </p>
            <p class="text-sm">
              <span class="font-medium">Work Cell:</span>
              <a href=""></a>
            </p>
          </div>
        </div>

        <!-- Address info -->
        <h1 class="text-left font-medium text-xl mb-2">Address</h1>
        <div  class="grid grid-cols-3 mb-3">
          <div class="col-span-2 text-left">
            <p v-if="DV_customer[DV_consumerContactsIndexes.address_info_address_line_1_2d[0]]" class="text-sm">
              {{DV_customer[DV_consumerContactsIndexes.address_info_address_line_1_2d[0]][DV_consumerContactsIndexes.address_info_address_line_1_2d[1]]}}
            </p>
            <p v-if="DV_customer[DV_consumerContactsIndexes.address_info_address_line_2_2d[0]]" class="text-sm">
              {{DV_customer[DV_consumerContactsIndexes.address_info_address_line_2_2d[0]][DV_consumerContactsIndexes.address_info_address_line_2_2d[1]]}}
            </p>
            <p class="text-sm">
              <span v-if="DV_customer[DV_consumerContactsIndexes.address_info_city_2d[0]]">
                {{DV_customer[DV_consumerContactsIndexes.address_info_city_2d[0]][DV_consumerContactsIndexes.address_info_city_2d[1]]}}
              </span> 
              <span v-if="DV_customer[DV_consumerContactsIndexes.address_info_state_2d[0]]">
                {{DV_customer[DV_consumerContactsIndexes.address_info_state_2d[0]][DV_consumerContactsIndexes.address_info_state_2d[1]]}}
              </span> 
              <span v-if="DV_customer[DV_consumerContactsIndexes.address_info_postal_code_2d[0]]">
                {{DV_customer[DV_consumerContactsIndexes.address_info_postal_code_2d[0]][DV_consumerContactsIndexes.address_info_postal_code_2d[1]]}}
              </span>
            </p>
          </div>
        </div>

        <!-- User rating -->
        <h1 class="text-left font-medium text-xl">Average Rating</h1>
        <div class="text-left text-sm italic mb-2">(Amazing rating is based on 90 sales person reviews over a period of a year)</div>
        <p v-if="DV_customer[DV_consumerContactsIndexes.average_employee_rating]" class="text-sm font-medium text-left mb-3">
          User rating: {{DV_customer[DV_consumerContactsIndexes.average_employee_rating]}}, 
        </p>
        <div v-else>
          No suffiecient history!
        </div>
        
        <!-- <h1 class="font-medium text-xl mb-2">Vehicle History</h1> -->
        <!-- <div class="mb-3 text-left">
          <p v-for="index in 2" :key="index" class="text-sm">
            <span class="font-medium">2022-01-05</span>
            <a href="">Honda civic 2004</a>
          </p>
        </div> -->
      </div>

      <!-- Feedback -->
      <p1s1c1c4CustomerFeedback v-if="DV_activeView == 'feedback'" />

      <!-- History -->
      <p1s1c1c5CustomerHistory @event-open-conversation="passOpenConversationEvent" v-if="DV_activeView == 'history'" />
    </div>
  </div>
</template>

<script>
import p1s1c1c4CustomerFeedback from "./p1s1c1c4CustomerFeedback.vue";
import p1s1c1c5CustomerHistory from "./p1s1c1c5CustomerHistory.vue";

export default {
  name: "p1s1c1c2ProfileSummary",

  props: ['P_selectedCustomerId'],

  components: { p1s1c1c4CustomerFeedback, p1s1c1c5CustomerHistory },

  data() {
    return {
      DV_activeView: "summary",
      DV_customer: {},
      DV_consumerContactsIndexes: window.G_bi2_data.IV_al_consumer_contacts
    };
  },

  mounted() {
    if (this.P_selectedCustomerId in window.G_bi2_data.IV_consumer_contacts.value == true) {
      this.DV_customer = window.G_bi2_data.IV_consumer_contacts.value[this.P_selectedCustomerId];
      this.$forceUpdate()
    }
  },

  computed: {
    C_customersFullName() {
      if (!this.DV_customer[this.DV_consumerContactsIndexes.first_name] && !this.DV_customer[this.DV_consumerContactsIndexes.last_name]) {
        return "Customer";
      }

      return `${this.DV_customer[this.DV_consumerContactsIndexes.first_name]} ${this.DV_customer[this.DV_consumerContactsIndexes.last_name]}`;
    },

    C_customerPhoneNumber() {
      if (this.DV_customer[this.DV_consumerContactsIndexes.phone_number_2d[0]]) {
        return (this.DV_customer[this.DV_consumerContactsIndexes.phone_number_2d[0]][this.DV_consumerContactsIndexes.phone_number_2d[1]] || "")
      }
      return "";
    }
  },

  methods: {
    passOpenConversationEvent(data) {
      this.$emit('event-open-conversation', data);
    }
  }
};
</script>

<style scoped>
a {
  color: blue !important;
  text-decoration: underline !important;
}
</style>
