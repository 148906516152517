<template>
  <div id="p1s1c1" class="h-full">
    <!-- Desktop View -->
    <div class="flex flex-col h-full">

      <!-- Top section -->
      <div class="grid" :class="{'h-[40%]': DV_activeRenderMode < DV_renderModeDesktop}">
        
        <!-- Header - logo + menu button -->
        <c9MainMenu P_activePage="dashboard"></c9MainMenu>
        
        <!-- Active business location -->
        <span class="mt-5 text-left"
          :class="{'text-xl': DV_activeRenderMode > DV_renderModeDesktopNarrow}"
        > Logged in as: <span class="font-bold">{{DV_loggedInUser.email || ""}} </span> <br>with 
          <span class="font-bold">{{C_activeRecyclerLocationName}}</span><br>
          <span class="font-bold">Business Texting Number: 
            <span class="selectable-text">{{C_activeBusinessTextingNumber}}</span>
          </span>
        </span>
        
        <!-- Header - user info -->
        <span class="font-bold text-center" :class="[DV_activeRenderMode > DV_renderModeDesktopNarrow ? 'text-2xl' : 'text-xl']" >Sales Dashboard</span>
      </div>
      
      <!-- Queue container -->
      <div class=" border border-gray-400 shadow-sm p-3 rounded-xl" :class="{'h-[60%]': DV_activeRenderMode == DV_renderModeDesktopNarrow, 'h-[77%]': DV_activeRenderMode > DV_renderModeDesktopNarrow }">
        <div class="h-full">
          
          <!-- Queue count -->
          <h1 class="font-bold mb-3 h-[4%]"><span>{{DV_queueConversationsCount}}</span> {{ DV_queueConversationsCount > 1 ? "Customers" : "Customer" }}  In Queue</h1>
          
          <!-- Customers queue view -->
          <div v-if="DV_activeView == 'myCustomers'" class="h-[94%]">
            
            <!-- Common active filters -->
            <p1s1c1m1SetActiveFilters 
              :P_customersTypeFilter="DV_customersTypeFilter"
              :P_customersSortFilter="DV_customersSortFilter"
              @event-set-customers-type-filter="setCustomersTypeFilter"
              @event-set-customers-sort-filter="setCustomersSortFilter"
            ></p1s1c1m1SetActiveFilters>
            
            <!-- Customers list -->
            <p1s1c2c3CustomersList
              v-show="DV_activeView == 'myCustomers'"
              :P_customersSortFilter="DV_customersSortFilter"
              @show-profile-summary="eventShowProfileSummary"
              @event-open-conversation="passEventOpenConversation"
              @event-open-profile-modal="passEventOpenProfileModal"
              @event-open-chat-log-modal="passEventOpenChatLogModal"
              @event-open-consumer-feedback-log-modal="passOpenConsumerFeedbackLogModal"
            />
          </div>
          <!-- END Customers queue view -->

          <!-- Profile view -->
          <p1s1c1c2ProfileSummary
            v-if="DV_activeView == 'summary'"
            @event-open-conversation="passOpenConversationEvent"
            @show-my-customers-view="DV_activeView = 'myCustomers'"
            :P_selectedCustomerId="DV_selectedCustomerId"
          />
        </div>
      </div>
    </div>
    
    <!-- Mobile View -->
    <div class="md:hidden block">
      <!-- Header - logo + menu button -->
      <c9MainMenu P_activePage="dashboard"></c9MainMenu>
      
      <!-- View buttons -->
      <div>        
        <span class="isolate inline-flex w-full mt-3">
          <button
            :class="[DV_activeMobileView == 'queue' ? 'active' : '']"
            @click="DV_activeMobileView = 'queue'; $emit('set-mobile-view', 'queue');"
            type="button"
            class="relative queue rounded-bl-none px-2 inline-flex items-center justify-center rounded-l-md border border-gray-300 px-4 py-2 text-xs font-medium text-gray-700 hover:bg-gray-50 focus:text-white focus:z-10 focus:outline-none focus:bg-blue-900"
          >
            Queue
          </button>
          <button
            :class="[DV_activeMobileView == 'conversation' ? 'active' : '']"
            @click="DV_activeMobileView = 'conversation'; $emit('set-mobile-view', 'conversation');"
            type="button"
            class="relative conversation rounded-b-none px-2 -ml-px inline-flex items-center justify-center rounded-r-md border border-gray-300 px-4 py-2 text-xs font-medium text-gray-700 hover:bg-gray-50 focus:text-white focus:z-10 focus:outline-none focus:bg-blue-900"
          >
            Active Conversations
          </button>
        </span>

        <div v-if="DV_activeMobileView == 'queue'" class="border border-gray-400 rounded-b-xl rounded-r-xl p-2">
          <div v-if="DV_filters == 'hide' && DV_activeView == 'myCustomers'">
            <div class="grid grid-cols-2 gap-2 mb-3">
              <div class="flex items-end">
                <button @click="DV_filters = 'show'" class="btn btn-lime px-1 py-2">
                  Set Active Filters 
                  <span>(0)</span>
                </button>
              </div>

              <div>
                <p class="font-medium">Sort By</p>
                <div class="flex items-center">
                  <select
                    id="queue-filters"
                    name="queue-filters"
                    class="w-full rounded-md border border-gray-400 py-2 pl-2 pr-5 text-gray-500 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  >
                    <option>Wait Time Customer Retain</option>
                    <option>CAD</option>
                    <option>EUR</option>
                  </select>
                </div>
              </div>
            </div>

            <p1s1c2c3CustomersList
              v-if="DV_activeView == 'myCustomers'"
              :P_customersSortFilter="DV_customersSortFilter"
              @show-profile-summary="DV_activeView = 'summary'"
              @event-open-conversation="passEventOpenConversation"
              @event-open-profile-modal="passEventOpenProfileModal"
              @set-mobile-view="(DV_activeMobileView = 'conversation'), $emit('set-mobile-view', 'conversation')"

            />
          </div>

          <p1s1c1m1SetActiveFilters 
            v-if="DV_filters == 'show'" 
            @show-queue="DV_filters = 'hide'"
          />

          <p1s1c1c7CustomerInformation 
            v-if="DV_activeView == 'summary'" 
            @queue_list="DV_activeView = 'myCustomers'"
            :selected-customer-id="DV_selectedCustomerId"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import p1s1c1c7CustomerInformation from "./p1s1c1c7CustomerInformation.vue";
import p1s1c1c2ProfileSummary from "./p1s1c1c2ProfileSummary.vue";
import p1s1c1m1SetActiveFilters from "./p1s1c1m1SetActiveFilters.vue";
import p1s1c2c3CustomersList from "./p1s1c2c3CustomersList.vue";
import c9MainMenu from "../shared/c9MainMenu.vue";
import m3RenderingSettings from "../../mixins/m3RenderingSettings.js";
import m2MiscDataFetchers from "../../mixins/m2MiscDataFetchers.js";

export default {
  name: "p1s1c1CustomersInQueue",

  mixins: [ m3RenderingSettings, m2MiscDataFetchers ],

  components: { 
  	p1s1c1m1SetActiveFilters, 
  	p1s1c2c3CustomersList, 
  	p1s1c1c2ProfileSummary, 
  	p1s1c1c7CustomerInformation,
  	c9MainMenu
  },

  data() {
    return {
      DV_activeMobileView: "queue",
      DV_activeView: "myCustomers",
      DV_filters: "hide",
      DV_isOptionsExpanded: false,
      DV_loggedInUser: {},
      DV_queueConversationsCount: 0,
      DV_customersTypeFilter: "all",
      DV_customersSortFilter: "time",
      DV_selectedCustomerId: null,
      DV_queueConversationsListItemIndexes: window.G_bi2_data.IV_al_consumer_conversation_details,
      DV_activeUserInfo: window.G_bi2_data.IV_active_employee_info,
      DV_queueConversations: {}
    };
  },

  mounted() {
    const CI = this;

    document.addEventListener('click', (e) => {
      if (e.target.classList.contains("menu-button")) { return; };
      CI.DV_isOptionsExpanded = false;
    }, false);

    CI.DV_loggedInUser = window.G_bi2_data.IV_loggedin_user_info;
    CI.DV_queueConversations = window.G_bi2_data.IV_conversation_queue_entries.value;
  },

  computed: {
    C_activeRecyclerLocationName() {
      let title = ""
      if (this.DV_activeUserInfo.recycler_location_uid &&
          this.DV_activeUserInfo.recycler_location_uid in window.G_bi2_data.IV_recyclers.value) {
            title += window.G_bi2_data.IV_recyclers.value[this.DV_activeUserInfo.recycler_location_uid].recycler_location_name
      }
      return title;
    },

    C_activeBusinessTextingNumber() {
      let number = ""
      if (this.DV_activeUserInfo.recycler_location_uid &&
          this.DV_activeUserInfo.recycler_location_uid in window.G_bi2_data.IV_recyclers.value) {
            number = window.G_bi2_data.IV_recyclers.value[this.DV_activeUserInfo.recycler_location_uid].text_number
      }
      return this.formatPhoneNumber(number);
    }
  },

  methods: {
    logoutUser() {
      this.DV_isOptionsExpanded = false;
      window.G_firebase_auth.signOut();
    },

    passEventOpenProfileModal(data) {
      this.$emit('event-open-profile-modal',data);
    },

    passEventOpenChatLogModal(data) {
      this.$emit('event-open-chat-log-modal',data);
    },

    passOpenConversationEvent(data) {
      this.$emit('event-open-conversation', data);
    },

    passOpenConsumerFeedbackLogModal(data) {
      this.$emit('event-open-consumer-feedback-log-modal',data);
    },

    setCustomersTypeFilter(option) {
      this.DV_customersTypeFilter = option;
    },

    setCustomersSortFilter(option) {
      this.DV_customersSortFilter = option;
    },

    eventShowProfileSummary(id) {
      this.DV_selectedCustomerId = id;
      this.DV_activeView = 'summary';
    },

    passEventOpenConversation(data) {
      this.$emit("event-open-conversation", data);
    }
  },

  beforeDestroy() {
    document.removeEventListener('click', (e) => {
      if (e.target.classList.contains("menu-button")) { return; };
      CI.DV_isOptionsExpanded = false;
    }, false);
  },

  watch: {
    DV_activeUserInfo: {
      handler() {
        this.$forceUpdate();
      },
      deep: true
    },

    DV_queueConversations: {
      handler() {
        const CI = this;
        let new_queue_count = 0;

        for (let entry_key in window.G_bi2_data.IV_conversation_queue_entries.value) {
          new_queue_count += 1;
        }
        CI.DV_queueConversationsCount = new_queue_count;
      },
      deep: true
    }
  }
};
</script>

<style scoped>
.active {
  background-color: var(--primary-color) !important;
  color: white !important;
}
</style>
