import { fs3Employees } from "../firestore_schemas/fs3Employees"
import { permissions_fs3Employees } from "../firestore_schemas/fs3Employees"
import { getDocFrom_fs3Employees } from "../firestore_schemas/fs3Employees"
import { setDocIn_fs3Employees } from "../firestore_schemas/fs3Employees"
import { removeDocFrom_fs3Employees } from "../firestore_schemas/fs3Employees"
import { setValueOfOptionalFields } from "../firestore_writers/include/general"

type Result = {
  debug_data: Array<string>;
  return_msg: string;
  success: boolean;
  error_title: string;
  error_summary: string;
  error_next_steps: string;
};

export async function fs3Writer(
  w_type: string,
  fs1_id: string,
  fs2_id: string,
  fs3_id: string,
  general_data: any,
  permissions: null | permissions_fs3Employees
) {

  const retval: Result = {
    debug_data: [],
    return_msg: "fs3_writer:fs3Writer ",
    success: true,
    error_title: "",
    error_summary: "",
    error_next_steps: "",
  };

  /// Validate arguments according to write type
  if (!fs1_id || !fs2_id || !fs3_id) {
    retval.success = false;
    retval.return_msg += "Insufficient arguments.";
    retval.error_title = "Insufficient arguments.";
    retval.error_summary = "business_uid or recycler_uid not set.";
    retval.error_next_steps = "Please provide correct business_uid snd recycler_uid.";
    return retval;
  }

  /// <END> Validate arguments according to write type

  // Delete record
  if (w_type == "d") {
    await removeDocFrom_fs3Employees(fs1_id, fs2_id, fs3_id)
    return retval
  }

  // Update existing record
  if (w_type == "e") {
    let response_data = await getDocFrom_fs3Employees(
      fs1_id,
      fs2_id,
      fs3_id,
    )

    if (response_data.success === false) {
      retval.success = false;
      retval.return_msg += "Record not found.";
      retval.error_title = "Record not found.";
      retval.error_summary = "FS3 record not found in firestore.";
      retval.error_next_steps = "Please reload page and try again.";
      return retval;
    }

    const fs3Data: fs3Employees = {
      employee_uid: response_data['doc_data'].employee_uid,
      firebase_account_uid: general_data.firebase_account_uid || response_data['doc_data'].firebase_account_uid,
      first_name: general_data.first_name || response_data['doc_data'].first_name,
      last_name: general_data.last_name || response_data['doc_data'].last_name,
      email_address: general_data.email_address || response_data['doc_data'].email_address,
      phone_number: general_data.phone_number || response_data['doc_data'].phone_number,
      extension: general_data.extension || response_data['doc_data'].extension,
      average_employee_rating: response_data['doc_data'].average_employee_rating,
      permissions: permissions || response_data['doc_data'].permissions,
      is_deactivated: setValueOfOptionalFields(general_data.is_deactivated, response_data['doc_data'].is_deactivated, false)
    } as fs3Employees;

    let response = await setDocIn_fs3Employees(
      fs1_id,
      fs2_id,
      fs3_id,
      fs3Data
    )

    if (!response.id || response.id == "" ) {
      retval.success = false;
      retval.return_msg += "Firestore write error.";
      retval.error_title = "Firestore write error.";
      retval.error_summary = "FS3 record not updated in firestore.";
      retval.error_next_steps = "Please reload page and try again.";
      return retval;
    }

    return retval
  }

}

function firestoreWriteError(error:any) {
  // TODO - complete this
}
