<template>
  <div id="p1s1c1m1">

    <!-- Filter buttons -->
    <div class="md:block hidden">

      <!-- Filter buttons -->
      <!-- <div v-if="!P_showFilterDropDown">
        <div class="grid grid-cols-12 items-center gap-2 my-2">
          <div class="col-span-2">
            <p class="text-left font-bold text-xs">Status Filters</p>
          </div>
          <div class="col-span-10">
            <span class="flex isolate inline-flex rounded-md shadow-sm w-full">
              <button @click.stop="DV_statusFilter = 'all'" :class="{'active': DV_statusFilter == 'all'}" type="button" class="filter-button left">Show All</button>
              <button @click.stop="DV_statusFilter = 'paid'" :class="{'active': DV_statusFilter == 'paid'}" type="button" class="-ml-px relative filter-button center w-full">Paid (0)</button>
              <button @click.stop="DV_statusFilter = 'failed'" :class="{'active': DV_statusFilter == 'failed'}" type="button" class="-ml-px relative filter-button center w-full">Failed Payment (0)</button>
              <button @click.stop="DV_statusFilter = 'invoices'" :class="{'active': DV_statusFilter == 'invoices'}" type="button" class="-ml-px relative filter-button center w-full">Invoices (0)</button>
              <button @click.stop="DV_statusFilter = 'quotes'" :class="{'active': DV_statusFilter == 'quotes'}" type="button" class="-ml-px relative filter-button center w-full">Quotes</button>
              <button @click.stop="DV_statusFilter = 'pre-quote'" :class="{'active': DV_statusFilter == 'pre-quote'}" type="button" class="-ml-px relative filter-button center w-full">Pre-Quote</button>
              <button @click.stop="DV_statusFilter = 'none'" :class="{'active': DV_statusFilter == 'none'}" type="button" class="relative -ml-px filter-button right">Unselect All</button>
            </span>
          </div>
        </div>
        <div class="mt-2 grid grid-cols-12 items-center gap-2">
          <div class="col-span-2">
            <p class="text-left font-bold text-xs">Last Activity Filters</p>
          </div>
          <div class="col-span-10">
            <span class="isolate inline-flex rounded-md shadow-sm w-full">
              <button @click.stop="DV_lastActivityFilter = '8-hours'" :class="{'active': DV_lastActivityFilter == '8-hours'}" type="button" class="filter-button left">8 Hours</button>
              <button @click.stop="DV_lastActivityFilter = '3-days'" :class="{'active': DV_lastActivityFilter == '3-days'}" type="button" class="-ml-px relative filter-button center w-full">3 Days</button>
              <button @click.stop="DV_lastActivityFilter = '1-week'" :class="{'active': DV_lastActivityFilter == '1-week'}" type="button" class="-ml-px relative filter-button center w-full">1 Week</button>
              <button @click.stop="DV_lastActivityFilter = '1-month'" :class="{'active': DV_lastActivityFilter == '1-month'}" type="button" class="-ml-px relative filter-button center w-full">1 Month</button>
              <button @click.stop="DV_lastActivityFilter = '3-month'" :class="{'active': DV_lastActivityFilter == '3-month'}" type="button" class="-ml-px relative filter-button center w-full">3 Month</button>
              <button @click.stop="DV_lastActivityFilter = '1-year'" :class="{'active': DV_lastActivityFilter == '1-year'}" type="button" class="-ml-px relative filter-button center w-full">1 Year</button>
              <button @click.stop="DV_lastActivityFilter = 'all'" :class="{'active': DV_lastActivityFilter == 'all'}" type="button" class="-ml-px relative filter-button center w-full">All Time</button>
              <button @click.stop="DV_lastActivityFilter = 'custom'" :class="{'active': DV_lastActivityFilter == 'custom'}" type="button" class="relative -ml-px filter-button right">Custom Range</button>
            </span>
          </div>
        </div>
      </div> -->

      <!-- Filter dropdown -->
      <div v-if="DV_showUnfinishedFeatures">
        <div class="text-left mb-2">
          <label class="font-medium text-sm" for="statusFilter">
            Status Filters
          </label>

          <select
            id="statusFilter"
            name="statusFilter"
            class="dropdown-select"
          >
            <option v-for="filter in DV_statusFilters" :key="filter">
              {{filter}}
            </option>
          </select>
        </div>

        <div class="text-left">
          <label class="font-medium text-sm" for="statusFilter">
            Last Activity Filters
          </label>

          <select
            id="statusFilter"
            name="statusFilter"
            class="dropdown-select"
          >
            <option v-for="filter in DV_lastActivityFilters" :key="filter">
              {{filter}}
            </option>
          </select>
        </div>
      </div>
    </div>

    <!-- Mobile view -->
    <div class="md:hidden block">

      <!-- Heading -->
      <div class="flex flex-row items-center justify-between my-3">
        <svg @click="$emit('show-live-chat')" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"/>
        </svg>
        <h1 class="font-bold text-sm">Change List Filter</h1>
        <svg @click="$emit('show-live-chat')" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"/>
        </svg>
      </div>
<!--       <div class="border border-gray-400 rounded-xl p-3 my-3">
        <h1 class="font-medium text-md text-center mb-3">
          Customers Rating Filters
        </h1>
        <div class="grid grid-cols-2 items-start my-2 gap-2 text-left">
          <label> <input type="checkbox" name="amazing" /> Amazing </label>
          <label> <input type="checkbox" name="good" /> Good </label>
        </div>
        <div class="grid grid-cols-2 items-start my-2 gap-2 text-left">
          <label> <input type="checkbox" name="average" /> Average </label>
          <label>
            <input type="checkbox" name="below-average" /> Below Average
          </label>
        </div>
        <div class="grid grid-cols-2 items-start my-2 gap-2 text-left">
          <label> <input type="checkbox" name="bad" /> Bad </label>
          <div>
            <button class="btn btn-lime px-3">Default</button>
          </div>
        </div>
      </div> -->

      <!-- Status filter section -->
      <div class="border border-gray-400 rounded-xl p-3 my-3">
        <h1 class="font-medium text-md text-center mb-3">Status Filters</h1>
        <div class="grid grid-cols-2 items-start my-2 gap-2 text-left">
          <label> <input type="checkbox" name="payment-link-created" /> Payment link created</label>
          <label> <input type="checkbox" name="paid" /> Paid </label>
        </div>

        <div class="grid grid-cols-2 items-start my-2 gap-2 text-left">
          <label><input type="checkbox" name="failed-payment" /> Failed Payment</label>
          <div> 
            <button class="btn btn-lime px-3">Default</button> 
          </div>
        </div>
      </div>

      <!-- Activity filter section -->
      <div class="border border-gray-400 rounded-xl p-3 my-3">
        <h1 class="font-medium text-md text-center mb-3">
          Last Activity Filters
        </h1>
        <div class="grid grid-cols-3 items-start my-2 gap-2 text-left">
          <label @click.stop="DV_lastActivityFilter = '8-hours'" :class="{'active': DV_lastActivityFilter == '8-hours'}"> <input type="checkbox" name="8hours" /> 8 Hours </label>
          <label @click.stop="DV_lastActivityFilter = '3-days'" :class="{'active': DV_lastActivityFilter == '3-days'}"> <input type="checkbox" name="3days" /> 3 Days </label>
          <label @click.stop="DV_lastActivityFilter = '1-week'" :class="{'active': DV_lastActivityFilter == '1-week'}"> <input type="checkbox" name="1week" /> 1 Week </label>
        </div>
        <div class="grid grid-cols-3 items-start my-2 gap-2 text-left">
          <label @click.stop="DV_lastActivityFilter = '1-month'" :class="{'active': DV_lastActivityFilter == '1-month'}"> <input type="checkbox" name="1month" /> 1 Month </label>
          <label @click.stop="DV_lastActivityFilter = '3-month'" :class="{'active': DV_lastActivityFilter == '3-month'}"> <input type="checkbox" name="3month" /> 3 Month </label>
          <label @click.stop="DV_lastActivityFilter = '1-year'" :class="{'active': DV_lastActivityFilter == '1-year'}"> <input type="checkbox" name="1year" /> 1 Year </label>
        </div>
        <div class="grid grid-cols-2 items-start my-2 gap-2 text-left">
          <label @click.stop="DV_lastActivityFilter = 'all'" :class="{'active': DV_lastActivityFilter == 'all'}"> <input type="checkbox" name="all-time" /> All Time </label>
          <div>
            <button class="btn btn-lime px-3">Default</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "p1s1c1m1SetListFilters",

  props: ["P_showFilterDropDown"],

  data() {
    return {
      DV_showUnfinishedFeatures: window.G_show_unfinished_features,
      DV_statusFilter: 'all',
      DV_lastActivityFilter: 'all',
      DV_statusFilters: ["Show All", "Lead (0)", "Invoiced (0)", "Paid (0)", "Failed (0)"],
      DV_lastActivityFilters: ["8 Hours", "3 Days", "1 Week", "1 Month", "3 Month", "1 Year", "All Time"]
    }
  }
};
</script>

<style scoped>
.btn-active {
  padding-left: 0.15rem !important;
  padding-right: 00.15rem !important;
}
</style>
