import { FirestoreDB } from "./include/creds";
import {
  DocPath,
  DataProblem,
  GetDocResult,
  RemoveDocResult,
} from "./include/misc";

import {
  collection,
  doc,
  setDoc,
  getDoc,
  deleteDoc,
} from "firebase/firestore";

export type fs37ConsumerBusinesses = {
  consumer_business_uid?: string;
  consumer_business_type_uids_list?: string[];
  primary_consumer_contact_uid?: string;
  name: string;
  city?: string;
  state?: string;
  postal_code?: number;
  address_line_1?: string;
  address_line_2?: string;
  website?: string;
  facebook_url?: string;
  consumer_contacts_uids_list?: string[];
  is_deactivated?: boolean;
};

export function getDataProblems_fs37ConsumerBusinesses(
  data: fs37ConsumerBusinesses
): DataProblem[] {
  const dataproblems: DataProblem[] = [];
  return dataproblems;
}

export function isValidData(data: fs37ConsumerBusinesses): boolean {

  return true;
}

export async function getDocFrom_fs37ConsumerBusinesses(
  fs1_id: string,
  fs2_id: string,
  fs37_id: string
): Promise<GetDocResult> {
  const result: GetDocResult = {
    debug_data: [],
    return_msg: "fs37ConsumerBusinesses:getDocFrom_fs37ConsumerBusinesses",
    success: true,
    doc_data: {},
  };

  if (!fs1_id || !fs2_id || !fs37_id) {
    result.success = false;
    result.return_msg += "Invalid function arguments.";
    return await Promise.resolve(result);
  }

  const doc_ref = doc(
    collection(
      FirestoreDB,
      "fs1RecyclerBusiness",
      fs1_id,
      "fs2RecyclerLocation",
      fs2_id,
      "fs37ConsumerBusinesses"
    ),
    fs37_id
  );

  const doc_data = (await getDoc(doc_ref)).data();
  if (doc_data === undefined) {
    result.success = false;
    result.return_msg +=
      "Could not find a document with the passed-in path arguments.";
    return await Promise.resolve(result);
  }

  result.doc_data = doc_data;
  return await Promise.resolve(result);
}

export async function addDocIn_fs37ConsumerBusinesses(
  fs1_id: string,
  fs2_id: string,
  data: fs37ConsumerBusinesses
): Promise<DocPath> {
  const result: DocPath = {
    id: "",
    path: "",
  };
  if (!isValidData(data)) {
    return await Promise.resolve(result);
  }

  const doc_ref = doc(
    collection(
      FirestoreDB,
      "fs1RecyclerBusiness",
      fs1_id,
      "fs2RecyclerLocation",
      fs2_id,
      "fs37ConsumerBusinesses"
    )
  );

  data.consumer_business_uid = doc_ref.id;
  await setDoc(doc_ref, data);

  result.id = doc_ref.id;
  result.path = doc_ref.path;
  return await Promise.resolve(result);
}

export async function setDocIn_fs37ConsumerBusinesses(
  fs1_id: string,
  fs2_id: string,
  fs37_id: string,
  data: fs37ConsumerBusinesses
): Promise<DocPath> {
  const result: DocPath = {
    id: "",
    path: "",
  };
  if (!isValidData(data)) {
    return await Promise.resolve(result);
  }

  const doc_ref = doc(
    collection(
      FirestoreDB,
      "fs1RecyclerBusiness",
      fs1_id,
      "fs2RecyclerLocation",
      fs2_id,
      "fs37ConsumerBusinesses"
    ),
    fs37_id
  );

  await setDoc(doc_ref, data);

  result.id = doc_ref.id;
  result.path = doc_ref.path;
  return await Promise.resolve(result);
}

export async function removeDocFrom_fs37ConsumerBusinesses(
  fs1_id: string,
  fs2_id: string,
  fs37_id: string
): Promise<RemoveDocResult> {
  const result: RemoveDocResult = {
    debug_data: [],
    return_msg: "fs37ConsumerBusinesses:removeDocFrom_fs37ConsumerBusinesses ",
    success: true,
  };

  if (!fs1_id || !fs2_id || !fs37_id) {
    result.success = false;
    result.return_msg += "Invalid function arguments.";
    return await Promise.resolve(result);
  }

  const doc_ref = doc(
    collection(
      FirestoreDB,
      "fs1RecyclerBusiness",
      fs1_id,
      "fs2RecyclerLocation",
      fs2_id,
      "fs37ConsumerBusinesses",
      fs37_id
    )
  );

  await deleteDoc(doc_ref);
  return await Promise.resolve(result);
}
