<template>
  <div id="c6">

    <!-- Heading -->
    <div class="flex flex-row items-center justify-between my-3 mx-2">
      <svg @click="$emit('show-live-chat-action')" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"/>
      </svg>
      <h1 class="font-bold text-sm">Submitted Vehicle Information</h1>
      <svg @click="$emit('show-live-chat-action')" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer">
        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"/>
      </svg>
    </div>

    <!-- Conversations details -->
    <div class="grid grid-cols-7 gap-2 px-2 items-center mb-5 mx-2">
      <div class="col-span-1 h-full items-start">
        <h1 class="font-bold text-lg text-left">B Q</h1>
      </div>
      <div class="col-span-6">
        <p class="text-left text-sm my-1">
          John Miller | Downtown Repair | Mechanic
          <a class="underline text-blue-500" href="#">972-221-9715</a>
        </p>
      </div>
    </div>

    <!-- Vehicles list -->
    <div class="mx-2">
      <p class="text-medium text-left text-sm my-2">
        <a class="underline text-blue-500" href="#">2003 Honda Civic</a>
      </p>
      <p class="text-medium text-left text-sm my-2">
        <a class="underline text-blue-500" href="#">GRG-155-1332</a>
      </p>
      <p class="text-medium text-left text-sm my-2">
        <a class="underline text-blue-500" href="#">2005 Doge Ram</a>
      </p>
      <p class="text-medium text-left text-sm my-2">
        <a class="underline text-blue-500" href="#">1K0-3254-231</a>
      </p>
    </div>

    <!-- Buttons -->
    <button @click="$emit('show-live-chat-action')" class="btn btn-lime my-5">
      Return To Live Chat
    </button>
  </div>
</template>

<script>
export default {
  name: "c6SubmittedVehicleInfo",
};
</script>
