<template>
    <div class="image-viewer">
      <div v-if="DV_selectedImages.length" class="modal" @click="closeModal">
        <div class="modal-content" @click.stop>
          <div v-for="index in DV_selectedImages" :key="index" class="modal-image-container">
            <button @click="downloadImage(P_imageLinks[index])" class="download-button cursor-pointer rounded-lg">
              <svg xmlns="http://www.w3.org/2000/svg" height="30px" viewBox="0 -960 960 960" width="30px" fill="#e8eaed">
                <path d="M480-320 280-520l56-58 104 104v-326h80v326l104-104 56 58-200 200ZM240-160q-33 0-56.5-23.5T160-240v-120h80v120h480v-120h80v120q0 33-23.5 56.5T720-160H240Z"/>
              </svg>
            </button>
            <img :src="P_imageLinks[index]" class="modal-image" />
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Vue from 'vue';
  
  export default {
    name: 'c11ImageViewer',
  
    props: {
      P_imageLinks: {
        type: Array,
        required: true
      }
    },
 
    data() {
      return {
        DV_selectedImages: []
      };
    },
  
    mounted() {
      // Select all images initially
      this.DV_selectedImages = this.P_imageLinks.map((_, index) => index);
    },

    methods: {
      toggleSelectImage(index) {
        const i = this.DV_selectedImages.indexOf(index);
        if (i === -1) {
          this.DV_selectedImages.push(index);
        } else {
          this.DV_selectedImages.splice(i, 1);
        }
      },
      
      closeModal() {
        this.DV_selectedImages = [];
        this.$emit('close');
      },

      downloadImage(content) {
        const base64String = content.split(',')[1]; // Remove the data:image/jpeg;base64, part
        const byteString = atob(base64String);
        
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const intArray = new Uint8Array(arrayBuffer);

        for (let i = 0; i < byteString.length; i++) {
          intArray[i] = byteString.charCodeAt(i);
        }

        const blob = new Blob([intArray], { type: 'image/png' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        
        a.href = url;
        a.download = 'image.png';
        a.click();
        
        URL.revokeObjectURL(url); // Clean up
      },
    },
  };
  </script>
  
  <style scoped>
  .image-viewer {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  .image-container {
    cursor: pointer;
  }
  .image-container img {
    max-width: 100px;
    max-height: 100px;
    transition: transform 0.2s;
  }
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1000;
  }
  .modal-content {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  .modal-image-container {
    position: relative;
  }
  .modal-image {
    max-width: 500px;
    max-height: 500px;
  }
  .download-button {
    position: absolute;
    top: 5px;
    right: 5px;
    padding: 1px 1px;
    z-index: 1002;
    background-color: #5cb54c;
  }
  </style>
  