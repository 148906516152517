import { fs130RecyclerTermsAndConditions } from "../firestore_schemas/fs130RecyclerTermsAndConditions"
import { getDocFrom_fs130RecyclerTermsAndConditions } from "../firestore_schemas/fs130RecyclerTermsAndConditions"
import { setDocIn_fs130RecyclerTermsAndConditions } from "../firestore_schemas/fs130RecyclerTermsAndConditions"
import { addDocIn_fs130RecyclerTermsAndConditions } from "../firestore_schemas/fs130RecyclerTermsAndConditions"
import { removeDocFrom_fs130RecyclerTermsAndConditions } from "../firestore_schemas/fs130RecyclerTermsAndConditions"

type Result = {
  debug_data: Array<string>;
  return_msg: string;
  success: boolean;
  error_title: string;
  error_summary: string;
  error_next_steps: string;
};

export async function fs130Writer(
  w_type: string,
  fs1_id: string,
  fs2_id: string,
  type_number: number,
  data={type_number: 0, type_description: "", transaction_terms_and_conditions: ""}
) {

  const retval: Result = {
    debug_data: [],
    return_msg: "fs130_writer:fs130Writer ",
    success: true,
    error_title: "",
    error_summary: "",
    error_next_steps: "",
  };

  /// Validate arguments according to write type
  if (!fs1_id || !fs2_id) {
    retval.success = false;
    retval.return_msg += "Insufficient arguments.";
    retval.error_title = "Insufficient arguments.";
    retval.error_summary = "business_uid or recycler_uid not set.";
    retval.error_next_steps = "Please provide correct business_uid snd recycler_uid.";
    return retval;
  }

  if (!type_number) {
    retval.success = false;
    retval.return_msg += "Insufficient arguments.";
    retval.error_title = "Insufficient arguments.";
    retval.error_summary = "type_number is not set.";
    retval.error_next_steps = "Please provide correct type_number.";
    return retval;
  }
  /// <END> Validate arguments according to write type

  // Delete record
  if (w_type == "d") {
    await removeDocFrom_fs130RecyclerTermsAndConditions(fs1_id, fs2_id, type_number)
    return retval
  }

  // Create new record
  if (w_type == "n") {
    await addDocIn_fs130RecyclerTermsAndConditions(
      fs1_id,
      fs2_id,
      type_number,
      {type_number: data.type_number, type_description: data.type_description, transaction_terms_and_conditions: data.transaction_terms_and_conditions}
    )
    return retval
  }

  // Update/edit
  if (w_type == "e") {
    let response_data = await getDocFrom_fs130RecyclerTermsAndConditions(fs1_id,fs2_id,type_number)

    if (response_data.success === false) {
      retval.success = false;
      retval.return_msg += "Record not found.";
      retval.error_title = "Record not found.";
      retval.error_summary = "FS130 record not found in firestore.";
      retval.error_next_steps = "Please reload page and try again.";
      return retval;
    }

    const fs130_data: fs130RecyclerTermsAndConditions = {
      type_number: data.type_number,
      type_description: data.type_description,
      transaction_terms_and_conditions: data.transaction_terms_and_conditions
    } as fs130RecyclerTermsAndConditions;

    await setDocIn_fs130RecyclerTermsAndConditions(
      fs1_id,
      fs2_id,
      type_number,
      fs130_data
    )
    return retval
  }
}
