import { FirestoreDB } from "./include/creds";
import {
  DocPath,
  DataProblem,
  //isUrlValid,
  //OurLogger,
  //isStringTooLong,
  GetDocResult,
  RemoveDocResult,
} from "./include/misc";
//const logger: OurLogger = OurLogger.getInstance();

import {
  getFirestore,
  CollectionReference,
  collection,
  DocumentData,
  doc,
  setDoc,
  getDocs,
  getDoc,
  deleteDoc,
} from "firebase/firestore";

export type fs34RecyclerStaffOnlyMessages = {
  recycler_only_message_uid?: string;
  employee_uid: string;
  message_type: 1 | 2 | 3;
  message_content?: string;
  uploaded_file_link?: string;
  timestamp: Date;
  file_name?: string;
  uploaded_file_size?: number;
};

export function getDataProblems_fs34RecyclerStaffOnlyMessages(
  data: fs34RecyclerStaffOnlyMessages
): DataProblem[] {
  const dataproblems: DataProblem[] = [];
  return dataproblems;
}

export function isValidData(data: fs34RecyclerStaffOnlyMessages): boolean {
      if (data.message_content === undefined) {
        return false;
      }
  return true;
}

export async function getDocFrom_fs34RecyclerStaffOnlyMessages(
  fs1_id: string,
  fs2_id: string,
  fs4_id: string,
  fs6_id: string,
  fs34_id: string
): Promise<GetDocResult> {
  const result: GetDocResult = {
    debug_data: [],
    return_msg:
      "fs34RecyclerStaffOnlyMessages:getDocFrom_fs34RecyclerStaffOnlyMessages",
    success: true,
    doc_data: {},
  };

  if (!fs1_id || !fs2_id || !fs4_id || !fs6_id || !fs34_id) {
    result.success = false;
    result.return_msg += "Invalid function arguments.";
    return await Promise.resolve(result);
  }

  const doc_ref = doc(
    collection(
      FirestoreDB,
      "fs1RecyclerBusiness",
      fs1_id,
      "fs2RecyclerLocation",
      fs2_id,
      "fs4ConsumerContacts",
      fs4_id,
      "fs6ConsumerConversations",
      fs6_id,
      "fs34RecyclerStaffOnlyMessages",
      fs34_id
    )
  );

  const doc_data = (await getDoc(doc_ref)).data();
  if (doc_data === undefined) {
    result.success = false;
    result.return_msg +=
      "Could not find a document with the passed-in path arguments.";
    return await Promise.resolve(result);
  }

  result.doc_data = doc_data;
  return await Promise.resolve(result);
}

export async function addDocIn_fs34RecyclerStaffOnlyMessages(
  fs1_id: string,
  fs2_id: string,
  fs4_id: string,
  fs6_id: string,
  data: fs34RecyclerStaffOnlyMessages
): Promise<DocPath> {
  const result: DocPath = {
    id: "",
    path: "",
  };

  if (!isValidData(data)) {
    return await Promise.resolve(result);
  }

  const doc_ref = doc(
    collection(
      FirestoreDB,
      "fs1RecyclerBusiness",
      fs1_id,
      "fs2RecyclerLocation",
      fs2_id,
      "fs4ConsumerContacts",
      fs4_id,
      "fs6ConsumerConversations",
      fs6_id,
      "fs34RecyclerStaffOnlyMessages"
    )
  );

  data.recycler_only_message_uid = doc_ref.id;
  await setDoc(doc_ref, data);

  result.id = doc_ref.id;
  result.path = doc_ref.path;
  //save new uid
  await setDoc(doc_ref, data);
  return await Promise.resolve(result);
}

export async function setDocIn_fs34RecyclerStaffOnlyMessages(
  fs1_id: string,
  fs2_id: string,
  fs4_id: string,
  fs6_id: string,
  fs34_id: string,
  data: fs34RecyclerStaffOnlyMessages
): Promise<DocPath> {
  const result: DocPath = {
    id: "",
    path: "",
  };
  if (!isValidData(data)) {
    return await Promise.resolve(result);
  }

  const doc_ref = doc(
    collection(
      FirestoreDB,
      "fs1RecyclerBusiness",
      fs1_id,
      "fs2RecyclerLocation",
      fs2_id,
      "fs4ConsumerContacts",
      fs4_id,
      "fs6ConsumerConversations",
      fs6_id,
      "fs34RecyclerStaffOnlyMessages",
      fs34_id
    )
  );

  await setDoc(doc_ref, data);

  result.id = doc_ref.id;
  result.path = doc_ref.path;
  return await Promise.resolve(result);
}

export async function removeDocFrom_fs34RecyclerStaffOnlyMessages(
  fs1_id: string,
  fs2_id: string,
  fs4_id: string,
  fs6_id: string,
  fs34_id: string
): Promise<RemoveDocResult> {
  const result: RemoveDocResult = {
    debug_data: [],
    return_msg:
      "fs34RecyclerStaffOnlyMessages:removeDocFrom_fs34RecyclerStaffOnlyMessages ",
    success: true,
  };

  if (!fs1_id || !fs2_id || !fs4_id || !fs6_id || !fs34_id) {
    result.success = false;
    result.return_msg += "Invalid function arguments.";
    return await Promise.resolve(result);
  }

  const doc_ref = doc(
    collection(
      FirestoreDB,
      "fs1RecyclerBusiness",
      fs1_id,
      "fs2RecyclerLocation",
      fs2_id,
      "fs4ConsumerContacts",
      fs4_id,
      "fs6ConsumerConversations",
      fs6_id,
      "fs34RecyclerStaffOnlyMessages",
      fs34_id
    )
  );

  await deleteDoc(doc_ref);
  return await Promise.resolve(result);
}
