<template>
  <div id="p1s2" class="m-5 grid grid-cols-10 gap-4">
    <div class="col-span-3">

      <!-- Header - logo + menu button -->
      <c9MainMenu P_activePage="contacts"></c9MainMenu>

      <p1s2c1ReportTypes @report-type="checkReportType" />
    </div>

    <!-- Download report buttons -->
    <div class="col-span-7 mt-4">
      <div class="border border-gray-400 rounded-xl h-full">
        <div class="p-3">
          <div class="flex items-start justify-between">
            <h1 class="font-bold text-lg">{{ DV_textHeading }}</h1>
            <div class="flex">
              <button class="btn btn-lime mx-3">Download PDF</button>
              <button class="btn btn-lime mx-3">Download Report CSV</button>
            </div>
          </div>
        </div>

        <p1s2c2CustomerEngagement
          v-if="DV_activeReportType == 'customerEngagement'"
        />

        <p1s2c3QueueResponseTime
          v-if="DV_activeReportType == 'queueResponseTime'"
        />

        <p1s2c4CustomerResponseTime
          v-if="DV_activeReportType == 'customerResponseTime'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import p1s2c4CustomerResponseTime from "./p1s2c4CustomerResponseTime.vue";
import p1s2c3QueueResponseTime from "./p1s2c3QueueResponseTime.vue";
import p1s2c2CustomerEngagement from "./p1s2c2CustomerEngagement.vue";
import p1s2c1ReportTypes from "./p1s2c1ReportTypes.vue";
import c9MainMenu from "../shared/c9MainMenu.vue";

export default {
  name: "p1s2Reporting",

  components: { 
    p1s2c1ReportTypes, 
    p1s2c2CustomerEngagement, 
    p1s2c3QueueResponseTime, 
    p1s2c4CustomerResponseTime,
    c9MainMenu
  },

  data() {
    return {
      DV_activeReportType: "customerEngagement",
      DV_textHeading: "Customer Engagement"
    };
  },

  mounted() {
    // 
  },

  methods: {
    checkReportType(data) {
      if (data == "customerEngagement") {
        this.DV_activeReportType = "customerEngagement";
        this.DV_textHeading = "Customer Engagement";
      }

      if (data == "queueResponseTime") {
        this.DV_activeReportType = "queueResponseTime";
        this.DV_textHeading = "Queue Response Times";
      }

      if (data == "customerResponseTime") {
        this.DV_activeReportType = "customerResponseTime";
        this.DV_textHeading = "Response Times";
      }
    },
  },

  beforeDestroy() {
    // 
  }
};
</script>

<style scoped>
button {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}
</style>
