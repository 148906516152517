import { fs73EmployeeConversationInviteFolderEntries } from "../firestore_schemas/fs73EmployeeConversationInviteFolderEntries"
import { getDocFrom_fs73EmployeeConversationInviteFolderEntries } from "../firestore_schemas/fs73EmployeeConversationInviteFolderEntries"
import { setDocIn_fs73EmployeeConversationInviteFolderEntries } from "../firestore_schemas/fs73EmployeeConversationInviteFolderEntries"
import { removeDocFrom_fs73EmployeeConversationInviteFolderEntries } from "../firestore_schemas/fs73EmployeeConversationInviteFolderEntries"

type Result = {
  debug_data: Array<string>;
  return_msg: string;
  success: boolean;
  error_title: string;
  error_summary: string;
  error_next_steps: string;
};

export async function fs73Writer(
  w_type: string,
  fs1_id: string,
  fs2_id: string,
  fs3_employee_uid="",
  fs4_consumer_contact_uid="",
  fs6_conversation_uid="",
  data: any
) {

  const retval: Result = {
    debug_data: [],
    return_msg: "fs73_writer:fs73Writer ",
    success: true,
    error_title: "",
    error_summary: "",
    error_next_steps: "",
  };

  /// Validate arguments according to write type
  if (!fs1_id || !fs2_id) {
    retval.success = false;
    retval.return_msg += "Insufficient arguments.";
    retval.error_title = "Insufficient arguments.";
    retval.error_summary = "business_uid or recycler_uid not set.";
    retval.error_next_steps = "Please provide correct business_uid snd recycler_uid.";
    return retval;
  }

  if ((w_type == "e" || w_type == "d") && (!fs3_employee_uid || !fs6_conversation_uid || !fs4_consumer_contact_uid)) {
    retval.success = false;
    retval.return_msg += "Insufficient arguments.";
    retval.error_title = "Insufficient arguments.";
    retval.error_summary = "fs3_employee_uid or fs6_conversation_uid or fs4_consumer_contact_uid is not set.";
    retval.error_next_steps = "Please provide correct fs3_employee_uid and fs6_conversation_uid and ßfs4_consumer_contact_uid.";
    return retval;
  }
  /// <END> Validate arguments according to write type

  // Delete record
  if (w_type == "d") {
    await removeDocFrom_fs73EmployeeConversationInviteFolderEntries(fs1_id, fs2_id, fs3_employee_uid, `${fs4_consumer_contact_uid}-${fs6_conversation_uid}`)
    return retval
  }

  if (w_type == "e") {
    let response_data = await getDocFrom_fs73EmployeeConversationInviteFolderEntries(
      fs1_id,
      fs2_id,
      fs3_employee_uid,
      `${fs4_consumer_contact_uid}-${fs6_conversation_uid}`
    )

    if (response_data.success === false) {
      retval.success = false;
      retval.return_msg += "Record not found.";
      retval.error_title = "Record not found.";
      retval.error_summary = "FS73 record not found in firestore.";
      retval.error_next_steps = "Please reload page and try again.";
      return retval;
    }

    const fs73Data: fs73EmployeeConversationInviteFolderEntries = {
      consumer_uid: data['consumer_uid'] || response_data['doc_data'].consumer_uid,
      conversation_uid: data['conversation_uid'] || response_data['doc_data'].conversation_uid,
      invited_timestamp: data['invited_timestamp'] || response_data['doc_data'].invited_timestamp,
      inviting_employee_uid: data['inviting_employee_uid'] || response_data['doc_data'].inviting_employee_uid,
      last_response: data['last_response'] || response_data['doc_data'].last_response,
      response_timestamp: data['response_timestamp'] || response_data['doc_data'].response_timestamp
    } as fs73EmployeeConversationInviteFolderEntries;

    let response = await setDocIn_fs73EmployeeConversationInviteFolderEntries(
      fs1_id,
      fs2_id,
      fs3_employee_uid,
      `${fs4_consumer_contact_uid}-${fs6_conversation_uid}`,
      fs73Data
    )

    if (!response.id || response.id === "" ) {
      retval.success = false;
      retval.return_msg += "Firestore write error.";
      retval.error_title = "Firestore write error.";
      retval.error_summary = "FS73 record not updated in firestore.";
      retval.error_next_steps = "Please reload page and try again.";
      return retval;
    }

    return retval
  }

  // Create new record
  if (w_type == "n") {
    const fs73Data: fs73EmployeeConversationInviteFolderEntries = {
      consumer_uid: fs4_consumer_contact_uid,
      conversation_uid: fs6_conversation_uid,
      invited_timestamp: new Date(),
      inviting_employee_uid: window.G_bi2_data.IV_active_employee_info.employee_uid,
      last_response: null,
      response_timestamp: null
    } as fs73EmployeeConversationInviteFolderEntries;

    let response = await setDocIn_fs73EmployeeConversationInviteFolderEntries(
      fs1_id,
      fs2_id,
      fs3_employee_uid,
      `${fs4_consumer_contact_uid}-${fs6_conversation_uid}`,
      fs73Data
    )

    if (!response.id || response.id === "" ) {
      retval.success = false;
      retval.return_msg += "Firestore write error.";
      retval.error_title = "Firestore write error.";
      retval.error_summary = "FS73 record not updated in firestore.";
      retval.error_next_steps = "Please reload page and try again.";
      return retval;
    }
    
    return retval
  }

  // TODO - check if we need edit/update?
  // TODO - handle edit/upsert operations?
}

function firestoreWriteError(error:any) {
  // TODO - complete this
}
