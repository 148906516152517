<template>

  <!-- Mobile view -->
  <div id="c1c3" class="md:hidden block w-full">
    <div v-show="activeView == 'liveChatAction'">

      <!-- Conversation detail -->
      <div class="grid grid-cols-8 gap-2 px-2 items-center cursor-pointer">
        <div class="col-span-1 h-full items-start">
          <h1 class="font-bold text-lg text-left">B Q</h1>
        </div>
        <div class="col-span-6">
          <p class="text-left text-sm my-1">
            John Miller | Downtown Repair | Body Shop | Quotes:
            12435653($1000) 132456($100)
          </p>
        </div>
        <div class="col-span-1 h-full items-start">
          <div class="flex flex-row justify-end">
            <svg @click="$emit('show-live-chat')" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"/>
            </svg>
          </div>
        </div>
      </div>

      <!-- Messages list -->
      <div class="border border-gray-400 rounded-xl p-3 text-left">
        <p>
          <span class="sender font-medium">Fred</span>
          <span class="time font-medium">2:50 pm:</span>
          <span class="sender-message">
            what parts do you need for the civic 2004?</span
          >
        </p>

        <p>
          <span class="receiver-customer font-medium">John</span>
          <span class="time font-medium">2:54 pm:</span>
          <span class="message">an engine</span>
        </p>

        <p>
          <span class="sender font-medium">Fred</span>
          <span class="time font-medium">2:50 pm:</span>
          <span class="sender-message">
            which version can you send me the picture of cars VIN ?</span
          >
        </p>

        <!-- Images section -->
        <div
          @click="$emit('show-image')"
          class="border border-gray-400 rounded w-20 h-20 cursor-pointer text-center items-center flex"
        >
          image of VIN
        </div>
      </div>

      <!-- Send message section -->
      <div class="grid grid-cols-6 gap-2 items-center mt-2">
        <textarea id="message" rows="1"></textarea>
        <div class="col-span-2 flex flex-row items-center justify-end">
          <button class="btn btn-lime">Send</button>
        </div>
      </div>

      <!-- buttons section -->
      <div class="grid grid-cols-2 items-center gap-2 mt-3 mx-2">
        <button @click="activeView = 'paymentLinks'" class="col-span-1 btn btn-lime px-1 w-full">
          Payment Links
        </button>
        <button class="col-span-1 btn btn-lime px-1 w-full">
          Upload Image
        </button>
      </div>

      <!-- buttons section -->
      <div class="grid grid-cols-1 items-center gap-2 my-3 mx-2">
        <button @click="activeView = 'submittedVehicleInfo'" class="btn btn-lime px-1 w-full">
          Submitted Vehicle / Part Information
        </button>
      </div>

      <!-- buttons section -->
      <div class="grid grid-cols-5 items-center gap-2 mx-2 mb-2">
        <button @click="activeView = 'moreActions'" class="col-span-2 btn btn-lime px-1 w-full">
          More Actions
        </button>

        <button @click="activeView = 'finishConversation'" class="col-span-3 btn btn-lime px-1 w-full">
          Finish Conversation
        </button>
      </div>
    </div>

    <c1m1MoreActions
      v-if="activeView == 'moreActions'"
      :conversation_uid = this.conversastion_uid
      :consumer_uid = this.consumer_uid
      @show-live-chat-action="activeView = 'liveChatAction'"
    />

    <p1s1c2c5PaymentLinks
      v-if="activeView == 'paymentLinks'"
      @show-live-chat-action="activeView = 'liveChatAction'"
    />

    <c6SubmittedVehicleInfo
      v-if="activeView == 'submittedVehicleInfo'"
      @show-live-chat-action="activeView = 'liveChatAction'"
    />

    <p1s1c2c3FinishConversation
      v-if="activeView == 'finishConversation'"
      @show-live-chat-action="activeView = 'liveChatAction'"
      @show-live-chat="$emit('show-live-chat'), (activeView = 'liveChatAction')"
    />
  </div>
</template>

<script>
import p1s1c2c3FinishConversation from "../p1/p1s1c2c3FinishConversation.vue";
import c6SubmittedVehicleInfo from "../c1/c6SubmittedVehicleInfo.vue";
import p1s1c2c5PaymentLinks from "../p1/p1s1c2c5PaymentLinks.vue";
import c1m1MoreActions from "../c1/c1m1MoreActions.vue";

export default {
  name: "c1c3LiveChatActions",

  props: ["conversastion_uid","consumer_uid"],
  components: { c1m1MoreActions, p1s1c2c5PaymentLinks, c6SubmittedVehicleInfo, p1s1c2c3FinishConversation },

  data() {
    return {
      activeView: "liveChatAction",
    };
  },
};
</script>
