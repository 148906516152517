/* eslint-disable */
import { ajax } from "noquery-ajax";
import { httpsLogger } from "../p1s7/p1s7e1";

function sendScheduleDemoRequest(full_name: String,
  phone_number: String, business_name: String, email_address: String, needs: String) {
  return new Promise(function (resolve, reject) {
    let params = {
      p1s3e21_name: full_name,
      p1s3e21_phone_number: phone_number,
      p1s3e21_business_name: business_name,
      p1s3e21_email_address: email_address,
      p1s3e21_business_needs: needs
    };

    ajax({
      url:
        window.G_cloud_p1s3_base_url +
        "p1s3e21ScheduleDemo",
      headers: {
        Accept: "application/json",
      },
      method: "POST",
      dataType: "json",
      data: params,
      success: function (result) {
        resolve(result);
      },
      error: function (error) {
        let error_data = {
          request_object: error,
          input_data: params,
          file_name: "p1s3e21.ts",
          class_name: "p1s3e21",
          method_name: "sendScheduleDemoRequest",
          error_title: "Failed to schedule demo request",
          error_description:
            "The server responded with an error when trying to send schedule demo request",
        };

        httpsLogger(error_data);
        reject(error_data);
      },
    });
  });
}

export default sendScheduleDemoRequest;
