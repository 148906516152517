import { fs34RecyclerStaffOnlyMessages } from "../firestore_schemas/fs34RecyclerStaffOnlyMessages"
import { addDocIn_fs34RecyclerStaffOnlyMessages } from "../firestore_schemas/fs34RecyclerStaffOnlyMessages"
import { removeDocFrom_fs34RecyclerStaffOnlyMessages } from "../firestore_schemas/fs34RecyclerStaffOnlyMessages"

type Result = {
  debug_data: Array<string>;
  return_msg: string;
  success: boolean;
  error_title: string;
  error_summary: string;
  error_next_steps: string;
};

export async function fs34Writer(
  w_type: string,
  fs1_id: string,
  fs2_id: string,
  fs4_consumer_contact_uid: string,
  fs6_conversation_uid: string,
  fs37_message_uid: string,
  data={employee_uid: "", message_content: ""}
) {

  const retval: Result = {
    debug_data: [],
    return_msg: "fs34_writer:fs34Writer ",
    success: true,
    error_title: "",
    error_summary: "",
    error_next_steps: "",
  };

  /// Validate arguments according to write type
  if (!fs1_id || !fs2_id || !fs4_consumer_contact_uid || !fs6_conversation_uid) {
    retval.success = false;
    retval.return_msg += "Insufficient arguments.";
    retval.error_title = "Insufficient arguments.";
    retval.error_summary = "business_uid or recycler_uid not set.";
    retval.error_next_steps = "Please provide correct business_uid snd recycler_uid.";
    return retval;
  }

  if((w_type === "d" || w_type === "e") &&  !fs37_message_uid) {
    retval.success = false;
    retval.return_msg += "fs13_id argument missing.";
    retval.error_title = "fs13_id argument missing.";
    retval.error_summary = "Employee feedback record UID missing.";
    retval.error_next_steps = "Please provide correct Employee feedback record UID.";
    return retval;
  }

  /// <END> Validate arguments according to write type
  // Delete record
  if (w_type == "d") {
    await removeDocFrom_fs34RecyclerStaffOnlyMessages(fs1_id, fs2_id, fs4_consumer_contact_uid,
      fs6_conversation_uid,fs37_message_uid);
    return retval
  }

  // Create new record
  if (w_type == "n") {
    await addDocIn_fs34RecyclerStaffOnlyMessages(
      fs1_id,
      fs2_id,
      fs4_consumer_contact_uid,
      fs6_conversation_uid,
      {
        employee_uid: data.employee_uid,
        message_type: 1,
        message_content: data.message_content,
        timestamp: new Date(),
      }
    )
    return retval
  }

}

function firestoreWriteError(error:any) {
  // TODO - complete this
}
