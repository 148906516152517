import { getStorage, ref, getDownloadURL } from "firebase/storage";
import requestEmailReceipt from '../includes/server_calls/p1s5/p1s5e7.ts'
import JSZip from 'jszip';

export default{
  data() {
    return {
      DV_downloadingReceipts: {
        loading: false,
        conversationUid: ''
      },
      DV_errorWhileDownloading: false
    }
  },
  
  methods:{
    classifyCustomerRating(rating) {
      if (!rating) { return "" }

      let reviewsRating = {
        "Amazing": _.range(90, 101),
        "Good": _.range(80, 90),
        "Average": _.range(70, 80), 
        "Below Average": _.range(60, 70),
        "Bad": _.range(50, 60)
      }

      return Object.keys(reviewsRating).find((key) => reviewsRating[key].includes(Math.round(rating)))
    },

    customerConversationsInLimit(uuid){
      let consumerConversationCount = window.G_bi2_data.IV_consumer_conversation_counts.value;
      return consumerConversationCount[uuid] === undefined || consumerConversationCount[uuid] < 3
    },

    downloadDocument(fileName, url) {
      const storage = getStorage();
      const gsReference = ref(storage, url);
      
      // start downloading
      fetch(url)
      .then(response => response.blob())
      .then(blob => {
        // create a URL for the blob
        const blobUrl = URL.createObjectURL(blob);

        // create an anchor element for downloading
        const a = document.createElement('a');
        a.href = blobUrl;
        a.download = `${fileName}.pdf`;

        // trigger a click event to start the download
        a.click();

        // clean up by revoking the blob URL
        URL.revokeObjectURL(blobUrl);
      })
      .catch(error => {
        console.error('Download failed:', error);
      });
    },

    downloadMedia(url) {
      const storage = getStorage();
      const httpsReference = ref(storage, url);

      getDownloadURL(httpsReference)
      .then((url) => {

        // This can be downloaded directly:
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = (event) => {
          const blob = xhr.response;

          // Create a link for the user to download the blob
          const blobUrl = URL.createObjectURL(blob);
          const downloadLink = document.createElement('a');
          downloadLink.classList.add('hide');
          downloadLink.href = blobUrl;
          downloadLink.download = `${blob["type"].replace("/", ".")}`; // Specify the desired filename
          downloadLink.textContent = 'Download Image';

          // Append the link to the document
          document.body.appendChild(downloadLink);

          // Trigger a click event to start the download and remove the element
          downloadLink.click();
          downloadLink.remove();

          // Clean up by revoking the blob URL
          URL.revokeObjectURL(blobUrl);
        };
        xhr.open('GET', url);
        xhr.send();

      })
      .catch((error) => {
        this.$awn.alert("Error while downloading. Please try again later.");
      });
    },

    availableDownloadReceipts(consumer_uid, conversation_uid) {
      let paidPaymentLinks = []
      let consumerPaymentLinks = this.DV_consumerConversationPaymentLinks[`${consumer_uid}-${conversation_uid}`]
      
      // no receipts generated
      if (!consumerPaymentLinks || Object.values(consumerPaymentLinks) === 0) { return false;}

      paidPaymentLinks = Object.values(consumerPaymentLinks).filter((link) => { return link['payment_status'] == 'paid'})
      return paidPaymentLinks;
    },

    async startReceiptDownloads(consumer_uid, conversation_uid, chat_start_timestamp, e) {
      // prevent event propagation
      e.stopPropagation();
      e.preventDefault();

      // not allowing multiple calls
      if (this.DV_downloadingReceipts['loading']) { return; } 

      this.DV_downloadingReceipts['loading'] = true;
      this.DV_downloadingReceipts['conversationUid'] = conversation_uid;
      let paidPaymentLinks = this.availableDownloadReceipts(consumer_uid, conversation_uid)
      let allReceipts = [];
      if (paidPaymentLinks.length == 0) { return; }

      paidPaymentLinks.forEach((link) => {
        let receipt;
        if (this.DV_errorWhileDownloading) { return }

        let params = {
          consumer_uid: consumer_uid,
          recycler_business_uid: window.G_bi2_data.IV_active_employee_info.business_uid,
          recycler_location_uid: window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
          conversation_uid: conversation_uid,
          payment_link_uid: link.payment_link_uid,
          email: "placeholder@email.com",
          request_type: 1,
        };

        // downloading the receipt
        receipt = requestEmailReceipt(params)
        .then((resp) => {
          try {
            if (resp.content_receipt) {
              const decodedData = atob(resp.content_receipt); // decode the Base64 data
              const blob = new Blob([decodedData], { type: 'application/pdf' }) // convert the decoded data into a PDF Blob
              const file = new File([blob], resp.card_holder_name + "_" + resp.date_paid, { type: 'application/pdf' }) // create a file object fro mthe blob with the specified file name
              return file
            }
          } catch (e) {
            this.DV_errorWhileDownloading = true;
          }
        })

        allReceipts.push(receipt);
      })

      const allReceiptsLength = allReceipts.length;


      // waiting for all the promises to be fulfilled
      const contents = await Promise.all(allReceipts);

      if (this.DV_errorWhileDownloading) {
        this.DV_downloadingReceipts['loading'] = false;
        this.DV_downloadingReceipts['conversationUid'] = '';
        this.$awn.alert( "Failed to download the PDFs. Please reload page and try again. Or contact support if the issue persist.");
      }

      // skip zipping the file if the number of receipts retreived is only 1
      if (allReceiptsLength === 1) {
        const url = URL.createObjectURL(contents[0]);
        
        // create a download link
        const downloadLink = document.createElement('a');
        downloadLink.classList.add('hide');
        downloadLink.href = url;
        downloadLink.download = contents[0].name;
        
        // add to body and start download
        document.body.appendChild(downloadLink);
        downloadLink.click();
       
        // clean up 
        document.body.removeChild(downloadLink);
        URL.revokeObjectURL(url);

      } else {      
        // create zip file
        try {
          this.createZipFile(contents)
          .then((zipBlob) => {
            const url = URL.createObjectURL(zipBlob);

            // create a download link
            const downloadLink = document.createElement('a');
            downloadLink.classList.add('hide');
            downloadLink.href = url;

            downloadLink.download = 'Receipts_' + chat_start_timestamp + '.zip';

            // add to body and start download
            document.body.appendChild(downloadLink);
            downloadLink.click();

            // clean up 
            document.body.removeChild(downloadLink);
            URL.revokeObjectURL(url);
          }) 
        } catch (e) {
          this.DV_downloadingReceipts['loading'] = false;
          this.DV_downloadingReceipts['conversationUid'] = '';
          this.$awn.alert( "Failed to download the PDFs. Please reload page and try again. Or contact support if the issue persist.");
        }
      }

      this.DV_downloadingReceipts['loading'] = false;
      this.DV_downloadingReceipts['conversationUid'] = '';
    },

    async createZipFile(contents) {
      const zip = new JSZip();

      // Add each PDF blob to the zip
      contents.forEach((blob) => {
        zip.file(blob.name, blob);
      });

      // Generate the zip file
      const content = await zip.generateAsync({ type: 'blob' });
      return content;
    }
  }
}