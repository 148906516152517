import { fs131EmployeeActivityStatuses } from "../firestore_schemas/fs131EmployeeActivityStatuses"
import { setDocIn_fs131EmployeeActivityStatuses } from "../firestore_schemas/fs131EmployeeActivityStatuses"

type Result = {
  debug_data: Array<string>;
  return_msg: string;
  success: boolean;
  error_title: string;
  error_summary: string;
  error_next_steps: string;
  document_id?: string;
};

export async function fs131Writer(
  w_type: string,
  fs1_id: string,
  fs2_id: string,
  fs3_id: string,
  data={
    last_activity_time: new Date()
  }) {

  const retval: Result = {
    debug_data: [],
    return_msg: "fs131_writer:fs131Writer ",
    success: true,
    error_title: "",
    error_summary: "",
    error_next_steps: "",
  };

  /// Validate arguments according to write type
  if(( w_type === "e") && (!fs1_id || !fs2_id || !fs3_id )) {
    retval.success = false;
    retval.return_msg += "Insufficient arguments.";
    retval.error_title = "Insufficient arguments.";
    retval.error_summary = "business_uid or recycler_uid or employee_uid not set.";
    retval.error_next_steps = "Please provide correct business_uid, recycler_uid and employee_uid.";
    return retval;
  }
  /// <END> Validate arguments according to write w_type

  if (w_type === "e") {

    const fs131Data: fs131EmployeeActivityStatuses = {
      last_activity_time: data.last_activity_time,
    } as fs131EmployeeActivityStatuses;

    let response = await setDocIn_fs131EmployeeActivityStatuses(
      fs1_id,
      fs2_id,
      fs3_id,
      fs131Data
    )

    if (!response.id || response.id == "" ) {
      retval.success = false;
      retval.return_msg += "Firestore write error.";
      retval.error_title = "Firestore write error.";
      retval.error_summary = "FS131 record not updated in firestore.";
      retval.error_next_steps = "Please reload page and try again.";
      return retval;
    }
    retval.document_id = response.id;
    return retval
  }
}
