<template>
  <div id="c1">

    <!-- Heading -->
    <header 
      v-if="DV_activeRenderMode > DV_renderModePhone"
      :class="{
        'col-start-1 row-start-4 col-span-2 py-5': DV_activeRenderMode > DV_renderModeTablet,
        'col-start-3 col-span-4 row-start-4': DV_activeRenderMode < DV_renderModeDesktopNarrow, 
      }"
      class="flex justify-between mx-auto w-[95vw]">
      
      <div class="flex">
        <img class="w-20" :src="require('../assets/images/synergy_icon.png')"/>
        <img class="w-72 h-auto" :src="require('../assets/images/synergy_text.png')"/>
      </div>

      <!-- Buttons -->
      <div class="flex items-center justify-end gap-4 items-center">
        <a class="btn btn-lime px-3" @click.stop="showScheduleDemoModal">Schedule a Demo</a>
        <a class="btn btn-lime px-3" href="https://synergy-auto-solutions.com/">Home</a>
        <a v-if="P_showLogoutButton" @click.stop="logoutUser" class="btn btn-lime px-3">Logout</a>
      </div>
    </header>
    
    <!-- Mobile view -->
    <header v-if="DV_activeRenderMode < DV_renderModeTablet">
      <div class="w-full flex flex-col items-center pt-2">
        <div class="flex flex-row pb-3 gap-y-1 w-fit items-center">
          <img class="w-[10vw] mr-1" :src="require('../assets/images/synergy_icon.png')"/>
          <span class="font-bold text-2xl">Synergy Auto Solutions</span>                      
        </div>
        <span class="pb-5 gap-8 flex flex-row items-center justify-between">
          <a class="btn btn-lime px-3 row-start-2 col-start-2" href="https://synergy-auto-solutions.com/">Home</a>
          <a class="btn btn-lime row-start-2 col-start-3" @click.stop="showScheduleDemoModal">Schedule a Demo</a>
          <a v-if="P_showLogoutButton" @click.stop="logoutUser" class="btn btn-lime px-3">Logout</a>
        </span>
      </div>
    </header>

    <c1c2ScheduleDemo ref="scheduleDemoPopupModal"></c1c2ScheduleDemo>
  </div>
</template>

<script>
import c1c2ScheduleDemo from "./c1/c1c2ScheduleDemo.vue"
import m3RenderingSettings from "../mixins/m3RenderingSettings"

export default {
  name: "c1Header",

  props: ['P_accountSetupView', 'P_showLogoutButton'],

  mixins: [ m3RenderingSettings ],

  data() {
    return {
      DV_isAuthenticated: false
    };
  },

  mounted() {
    // 
  },

  components: {
    c1c2ScheduleDemo
  },

  methods: {
    logoutUser() {
      window.G_firebase_auth.signOut();
    },

    showScheduleDemoModal() {
      this.$refs.scheduleDemoPopupModal.showSelf();
    },

    logoutUser() {
      window.G_firebase_auth.signOut();
    },
  },

  computed: {
    // 
  },

  watch: {
    // 
  }
};
</script>

<style scoped lang="less">
a {
  color: white !important;
}
</style>
