<template>
  <div id="c8" @click.stop="" class="border border-gray-400 rounded-b-xl p-2" 
    :class="{
      'h-4/5': C_showFullPage,
      'h-4/5': DV_activeRenderMode > DV_renderModeDesktopNarrow,
      'h-[65%]': DV_activeRenderMode == DV_renderModeDesktopNarrow
    }"
  >
    <div v-show="DV_loading" class="my-32">
      <h2 class="text-xl text-gray-400">Loading...</h2>
    </div>

    <div v-show="!DV_loading" class="h-full relative">
      <!-- Mobile view -->
      <h1 class="md:hidden block font-bold text-xl">Yard Only Chat</h1>
  
      <!-- Chat header -->
      <div class="flex flex-row items-center justify-start">
            
        <!-- Search for chat -->
        <SearchBar v-if="DV_showUnfinishedFeatures" containerClasses="mt-1 mr-3" inputClasses="h-9" />
  
  
        <!-- New tab full screen button -->
        <div class="ml-auto">
          <svg v-show="DV_showFullScreenButton" @click="openFullScreen" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer">
            <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"/>
          </svg>
        </div>
      </div>
      
      <div class="h-[92%] mt-3">
        <div id="c8_yard-messages-container" 
          class="border border-gray-400 rounded-xl overflow-scroll p-1 hide-scrollbar"
          :class="{
            'h-[90%]': DV_activeRenderMode > DV_renderModeDesktopNarrow,
            'h-4/5': DV_activeRenderMode == DV_renderModeDesktopNarrow
          }"
        >     
          <!-- Chat messages list -->
          <div class="w-full px-5 flex flex-col justify-between selectable-text">
            <div v-for="message in DV_messagesList" :class="{'not-sent-chat-message': message[DV_ecmIndexes.timestamp].nanoseconds == 'not-sent'}" class="flex flex-col mt-2">
              <div v-if="message[DV_ecmIndexes.message_uid]" class="flex flex-col items-start chat-message left mb-2">
                <!-- show employee above messages from employees-->
                <span>{{ getEmployeeName(message[DV_ecmIndexes.employee_uid])}} | {{moment.unix(message[DV_ecmIndexes.timestamp].seconds).format('MM-DD-YYYY h:mm A')}}</span>
                <!-- </end> show above messages from employees-->
                <span v-if="message[DV_ecmIndexes.message_type] == 1"
                v-html="message[DV_ecmIndexes.message_content]"
                 class="w-full truncate">
                </span>
                <div
                  v-else-if="(message[DV_ecmIndexes.file_type] == 1)" 
                   class="flex flex-col">
                    <img                   
                    style="max-height: 50vh;"
                    :src="message[DV_ecmIndexes.file_url]"
                    >
                    <a 
                      class="text-blue-700 cursor-pointer"
                      @click="downloadMedia(message[DV_ecmIndexes.file_url])"
                    >
                      <b>Download Image</b>
                    </a>
                </div>
                  <span 
                  class="text-lg"
                  v-else-if="(message[DV_ecmIndexes.file_type] > 1)"
                  >
                    Uploaded File:
                    <a 
                      @click="downloadMedia(message[DV_ecmIndexes.file_url])"
                    class="text-blue-700 break-words cursor-pointer"> {{ message[DV_ecmIndexes.file_name] }} <span v-if="getUploadedFileSize(message)">({{getUploadedFileSize(message)}})</span>
                    </a>
                  </span>

                  <span v-else class="deleted-message">
                    Deleted message
                  </span>
               

              </div>
            </div>
          </div>
        </div>
  
        <!-- Send message section -->
        <div class="flex absolute bottom-[-8px] w-full">
           <!-- Upload file -->
           <button @click.stop="showFileUpload" class="mr-2 cursor-pointer" :disabled="DV_sendingMessage">
              <img class="w-10" :src="require('../../assets/images/upload_file_icon.png')" />
            </button>
  
          <!-- Typing area -->
          <div class="w-full">
            <textarea :disabled="DV_sendingMessage" v-model="DV_messageContent" id="messages" rows="1" class="border-2 w-full mt-2 pr-12 hide-scrollbar" v-on:keydown.enter="sendMessage"></textarea>
            <input id="image-upload-input" ref="messageImageInput" class="hidden" type="file" @change="handleMessageImageUpload" />
          </div>
  
          <!-- Send button -->
          <button :disabled="DV_sendingMessage" @click="sendMessage" class="btn-lime mb-2 w-9 h-9 flex items-center justify-center absolute right-1 rounded-full !p-0 top-[12px]">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 10.5L12 3m0 0l7.5 7.5M12 3v18" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import SearchBar from '../shared/SearchBar.vue';
import employeeSendFile from '../../includes/server_calls/p1s5/p1s5e6'
import { fs34Writer } from "../../includes/firestore_writers/fs34_writer.ts"
import generalFunctions from '../../mixins/generalFunctions.js'
import m3RenderingSettings from '../../mixins/m3RenderingSettings.js';

export default {
  name: "c8RecyclerOnlyChat",

  components: {SearchBar },
  
  props: ["P_consumerUid","P_conversationUid","P_readOnly"],

  mixins: [ generalFunctions, m3RenderingSettings ],
  
  data() {
    return {
      DV_loading: false,
      DV_messagesList: [],
      DV_loadedMessagesList: [],
      DV_messageContent: "",
      DV_ecmIndexes: window.G_bi2_data.IV_al_employee_conversations,
      DV_showFullScreenButton: true,
      DV_userEmployeeUid: window.G_bi2_data.IV_active_employee_info.employee_uid,
      DV_employeesData : window.G_bi2_data.IV_recycler_employees.value,
      DV_consumerConversationUuid: "",
      moment: moment,
      DV_showUnfinishedFeatures: window.G_show_unfinished_features,
      DV_sendingMessage: false,
    };
  },

  mounted() {
    this.updateLoadedConversation(0, this);
  },

  methods: {
    updateLoadedConversation(retry_count = 0,class_instance) {
      const CI = this;
      CI.DV_loading = true;

      if(!CI.C_consumerUid || !CI.C_conversationUid ) { return;}
      CI.DV_consumerConversationUuid = CI.C_consumerUid + "-" + CI.C_conversationUid;

      // wait for loading account info
      if(window.G_bi2_data.IV_active_employee_info.business_uid == "" ||
        window.G_bi2_data.IV_active_employee_info.recycler_location_uid == ""
      ) {
        setTimeout(function() {CI.updateLoadedConversation(retry_count, class_instance)}, 200);
        return;
      }

      if( retry_count === 0) {
        window.G_bi2_data.startYardOnlyChatListener(
        window.G_bi2_data,
        window.G_bi2_data.IV_active_employee_info.business_uid,
        window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
        CI.C_consumerUid,
        CI.C_conversationUid
      );
      }
      
      if (retry_count >= 50) {
        console.error("c8RecyclerOnlyChat:loadRecyclerChat loading data error")
      }
      
      if(CI.DV_consumerConversationUuid in window.G_bi2_data.IV_employee_conversations.value === false) {
        setTimeout(function() {CI.updateLoadedConversation(++retry_count, class_instance)}, 200);
        return;
      }

      CI.DV_loadedMessagesList = window.G_bi2_data.IV_employee_conversations.value[CI.DV_consumerConversationUuid];
      this.DV_loading = false;
    },

    getEmployeeName(employee_uid) {
      if(employee_uid in this.DV_employeesData === false )  {
        return "";
      }
    return this.DV_employeesData[employee_uid].first_name + " " + this.DV_employeesData[employee_uid].last_name;
    },
 
    async sendMessage(e) {
      const CI = this;

      if (e.keyCode == 13) { e.preventDefault(); }

      if (!CI.DV_messageContent) { return; }
      CI.DV_sendingMessage = true;

      var unSendMessageUid = new Date().getTime().toString(); // fake uid of message

      // Show the message immediately in unsent form
      let unSendMessage = [
        unSendMessageUid,
        1, // text message
        window.G_bi2_data.IV_active_employee_info.employee_uid, // self employ uid,
        {
          nanoseconds: "not-sent", // fake nanoseconds value to show un-sent status in chat
          seconds: Math.round(new Date().getTime()/1000)
        },
        CI.DV_messageContent
      ]

      CI.DV_messagesList.push(unSendMessage);
      CI.$forceUpdate();
      setTimeout(CI.scrollChatToBottom, 50)

      fs34Writer(
        "n",
        window.G_bi2_data.IV_active_employee_info.business_uid,
        window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
        window.G_bi2_data.IV_employees_active_conversations.value[this.DV_consumerConversationUuid].consumer_uid,
        window.G_bi2_data.IV_employees_active_conversations.value[this.DV_consumerConversationUuid].conversation_uid,
        null,
        {
          employee_uid: window.G_bi2_data.IV_active_employee_info.employee_uid,
          message_content: this.DV_messageContent
        }
      ).then((resp) => {
        setTimeout(CI.scrollChatToBottom, 50)
      }).catch((error) => {        
        CI.$awn.alert(error.error_title)
      });

      // Remove the unsent message from the list. 
      let index = CI.DV_messagesList.findIndex(item => item[0] == unSendMessageUid)
      if (index > 0) {CI.DV_messagesList.splice(index, 1)}

      CI.DV_messageContent = "";
      CI.DV_sendingMessage = false;
    },

    openFullScreen() {
      let routeData = this.$router.resolve({ path: `/recyclerOnlyChat?conversationUId=${this.C_conversationUid}&consumerUid=${this.C_consumerUid}`});
      window.open(routeData.href, "_blank");
    },

    showFileUpload() {
      this.$refs.messageImageInput.click();
    },

    handleMessageImageUpload(e) {
      const CI = this;
      CI.DV_messageContent = "";

      e.stopPropagation();
      e.preventDefault();

      var files = []
      files = e.target.files;

      if (!files || !files[0]) { return; } 

      if (files.length > 1) {
        this.$awn.alert("Please choose only one file per message.");
        return;
      }

      // validate size
      var file = files[0];
      if (file.size > (30*(1024*1024))) {
        this.$awn.alert("File size must be less than 30 megabytes.");
        return;
      }

      CI.DV_sendingMessage = true;

      // set data
      var reader = new FileReader();

      reader.onload = (obj) => {
        var unSendMessageUid = new Date().getTime().toString(); // fake uid of message
        // Show the message immediately in unsent form
        let unSendMessage = [
          unSendMessageUid,
          1, // text message
          window.G_bi2_data.IV_active_employee_info.employee_uid, // self employ uid,
          {
            nanoseconds: "not-sent", // fake nanoseconds value to show un-sent status in chat
            seconds: Math.round(new Date().getTime()/1000)
          },
          `Uploading ${CI.getUploadingFileType(file)}`
        ]

        CI.DV_messagesList.push(unSendMessage);
        CI.$forceUpdate();
        setTimeout(CI.scrollChatToBottom, 50)

        // prepare params
        let params = {
          recycler_uid: window.G_bi2_data.IV_active_employee_info.business_uid,
          recycler_location_uid: window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
          consumer_uid: CI.C_consumerUid,
          employee_uid: window.G_bi2_data.IV_active_employee_info.employee_uid,
          conversation_uid: CI.C_conversationUid,
          file_destination: 1,
          file_category: (CI.getUploadingFileType(file) === "Image" ? 1 : 2),
          content: obj.srcElement.result,
          file_name: `${CI.C_conversationUid}-${new Date().getTime()}-${file.name}`
        }

        employeeSendFile(params).then((resp) => {
          let index = CI.DV_messagesList.findIndex(item => item[0] == unSendMessageUid)
          if (index > 0) {CI.DV_messagesList.splice(index, 1)}
          setTimeout(CI.scrollChatToBottom, 50)
          CI.$forceUpdate();
          CI.DV_sendingMessage = false;
        }).catch((error) => {
          // Remove the unsent message from the list. 
          let index = CI.DV_messagesList.findIndex(item => item[0] == unSendMessageUid)
          if (index > 0) {CI.DV_messagesList.splice(index, 1)}
          CI.DV_sendingMessage = false;          
          CI.$awn.alert(error.error_title)
        });
      }

      reader.readAsDataURL(file)
    },

    employeeName(employeeUid) {
      let employee = window.G_bi2_data.IV_recycler_employees.value[employeeUid]
      return (window.G_bi2_data.IV_active_employee_info.employee_uid == employeeUid) ? "You" : employee.first_name + " " + employee.last_name
    },

    employeesMessage(employeeUid) {
      return window.G_bi2_data.IV_active_employee_info.employee_uid != employeeUid 
    },

    scrollChatToBottom() {
      const objDiv = document.getElementById("c8_yard-messages-container");
      objDiv.scrollTop = objDiv.scrollHeight;
    },

    getUploadingFileType(file) {
      const acceptedImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
      const acceptedImageExtensions = ['.jpg', '.jpeg', '.png', '.gif'];
      const acceptedDocExtensions = ['.doc', '.docx', '.pdf'];
      const acceptedDocTypes = ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.ms-word.document.macroEnabled.12', 'application/pdf'];
      const acceptedVideoTypes = [ 'video/mp4', 'video/mpeg', 'video/quicktime', 'video/x-msvideo', 'video/x-flv', 'video/x-matroska', 'video/webm', 'video/x-ms-wmv'];
      const acceptedVideoExtensions = ['.mp4', '.mpeg', '.mov', '.avi', '.flv', '.mkv', '.webm', '.wmv'];

      const fileType = file.type.toLowerCase();;
      const fileName = file.name;
      const fileExtension = fileName.substr(fileName.lastIndexOf('.')).toLowerCase();

      if (acceptedImageTypes.includes(fileType) || acceptedImageExtensions.includes(fileExtension)) {
        return "Image";
      }

      if (acceptedDocTypes.includes(fileType) || acceptedDocExtensions.includes(fileExtension)) {
        return "File";
      }

      if (acceptedVideoTypes.includes(fileType) || acceptedVideoExtensions.includes(fileExtension)) {
        return "Video";
      }

      return "File";
    },

    getUploadedFileSize(message) {
      const CI = this;

      let file_size_in_bytes = message[CI.DV_ecmIndexes.file_size]

      if (!file_size_in_bytes) { return null; }

      if (file_size_in_bytes < 1024) {
        return `${file_size_in_bytes} bytes`
      }

      let file_size_in_kbs = (file_size_in_bytes / 1024).toFixed(2);
      if (file_size_in_kbs < 1024) {
        return `${file_size_in_kbs} KB`
      }

      let file_size_in_mbs = (file_size_in_kbs / 1024).toFixed(2);
      return `${file_size_in_mbs} MB`
    }
  },
  watch: {
    P_consumerUid() {
      this.updateLoadedConversation(0, this)
    },

    P_conversationUid() {
      this.updateLoadedConversation(0, this)
    },
    
    DV_loadedMessagesList() {
      if (this.DV_loadedMessagesList.length > 0) { 
        this.DV_messagesList = _.sortBy(this.DV_loadedMessagesList, (item) => item[3].seconds);
        setTimeout(() => {this.scrollChatToBottom();}, 1000);
      }
    }
  },

  computed: {
    C_showFullPage() {
      return this.$route.name == "recyclerOnlyChat"
    },

    textMessagesList() {
      if (!this.conversation || !this.conversation.uuid) {
        return []
      }
      
      if (this.conversation.uuid in window.G_bi2_data.IV_employee_conversations.value === false) {
        return []
      }

      return window.G_bi2_data.IV_employee_conversations.value[this.conversation.uuid];
    },

    C_conversationUid() {
      let conversationUId = this.P_conversationUid;

      if (this.C_showFullPage && this.$route.query["conversationUId"]) {
        conversationUId = this.$route.query["conversationUId"];
        this.DV_showFullScreenButton = false;
      }

      return conversationUId
    },

    C_consumerUid() {
      let consumerUid = this.P_consumerUid;

      if (this.C_showFullPage && this.$route.query["consumerUid"]) {
        consumerUid = this.$route.query["consumerUid"];
        this.DV_showFullScreenButton = false;
      }

      return consumerUid;
    },
  }
};
</script>
