/* eslint-disable */
import { ajax } from "noquery-ajax";
import { httpsLogger } from "../p1s7/p1s7e1";

function alertInactiveConsumer(
  recycler_uid: string,
  recycler_location_uid: string,
  consumer_contact_uid: string,
  conversation_uid: string
) {
  return new Promise(function (resolve, reject) {
    let params = {
      firebase_uid: window.G_firebase_auth.IV_uid,
      firebase_token: window.G_firebase_auth.IV_id_token,
      recycler_uid: recycler_uid,
      recycler_location_uid: recycler_location_uid,
      consumer_contact_uid: consumer_contact_uid,
      conversation_uid: conversation_uid
    };

    ajax({
      url: window.G_cloud_p1s3_base_url + "p1s3e48AlertInactiveConsumer",
      headers: {
        Accept: "application/json",
      },
      method: "POST",
      dataType: "json",
      data: params,
      success: function (result) {
        let response_data = {
          success: true,
          response: result,
          request_params: params
        }
        resolve(response_data);
      },
      error: function (error) {
        let error_data = {
          success: false,
          request_object: error,
          request_params: params,
          file_name: "p1s3e48.ts",
          class_name: "p1s3e48",
          method_name: "alertInactiveConsumer",
          error_title: "Failed to send text message to the consumer.",
          error_description: "The server responded with an error when sending a text message to the consumer.",
        };

        httpsLogger(error_data);
        reject(error_data);
      },
    });
  });
}

export default alertInactiveConsumer;
