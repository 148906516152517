import { fs76RecyclerLocationInvitesSubcollection } from "../firestore_schemas/fs76RecyclerLocationInvitesSubcollection"
import { getDocFrom_fs76RecyclerLocationInvitesSubcollection } from "../firestore_schemas/fs76RecyclerLocationInvitesSubcollection"
import { setDocIn_fs76RecyclerLocationInvitesSubcollection } from "../firestore_schemas/fs76RecyclerLocationInvitesSubcollection"
import { removeDocFrom_fs76RecyclerLocationInvitesSubcollection } from "../firestore_schemas/fs76RecyclerLocationInvitesSubcollection"

type Result = {
  debug_data: Array<string>;
  return_msg: string;
  success: boolean;
  error_title: string;
  error_summary: string;
  error_next_steps: string;
};

export async function fs76Writer(
  w_type: string,
  fs5_email_id: string,
  fs76_id: string,
  data: fs76RecyclerLocationInvitesSubcollection
) {

  const retval: Result = {
    debug_data: [],
    return_msg: "fs76_writer:fs76Writer ",
    success: true,
    error_title: "",
    error_summary: "",
    error_next_steps: "",
  };

  /// Validate arguments according to write type
  if (!fs5_email_id) {
    retval.success = false;
    retval.return_msg += "Insufficient arguments.";
    retval.error_title = "Insufficient arguments.";
    retval.error_summary = "fs5_email_id not set.";
    retval.error_next_steps = "Please provide correct fs5_email_id.";
    return retval;
  }
  if ((w_type == "d" || w_type == "e" || w_type == "u") && !fs76_id ) {
    retval.success = false;
    retval.return_msg += "Insufficient arguments.";
    retval.error_title = "Insufficient arguments.";
    retval.error_summary = "fs76_id not set.";
    retval.error_next_steps = "Please provide correct fs76_id.";
    return retval;
  }
  /// <END> Validate arguments according to write type

  // Delete record
  if (w_type == "d") {
    await removeDocFrom_fs76RecyclerLocationInvitesSubcollection(fs5_email_id, fs76_id)
    return retval
  }

  // Edit record
  if (w_type == "e") {
    // TODO - check if we need edit?
  }

  // TODO - check if we need new?
  // TODO - handle new operations
}

function firestoreWriteError(error:any) {
  // TODO - complete this
}
