/* eslint-disable */
import { ajax } from "noquery-ajax";
import { watch } from "vue";
import { Dictionary } from "vue-router/types/router";

type StartsWithFs = `fs${number}`;

interface LogBrowserErrorArguments {
  file_name: string;
  endpoint_name?: string;
  class_name?: string;
  method_name: string;
  error_code: Number;
  error_summary: string;
  call_stack?: string;
  input_data?: string;
  firebase_uid?: string;
  consumer_uid?: string;
  recycler_uid?: string;
  recycler_location_uid?: string;
  conversation_uid?: string;
  employee_uid?: string;
  other_document_keys?: Record<StartsWithFs, string>;
  error_title: string;
  error_description: string;
  error_next_step?: string;
}

interface RequestErrorObject {
  status: number;
  statusText: string;
  responseType: string;
  responseText: string;
}
interface HttpsLoggerArguments {
  request_object: RequestErrorObject;
  file_name: string;
  class_name?: string;
  method_name: string;
  request_params?: object;
  error_title: string;
  error_description: string;
  error_next_step?: string;
}

export function logBrowserError({
  file_name,
  endpoint_name,
  class_name,
  method_name,
  error_code,
  error_summary,
  call_stack,
  input_data,
  firebase_uid,
  consumer_uid,
  recycler_uid,
  recycler_location_uid,
  conversation_uid,
  employee_uid,
  other_document_keys,
  error_title,
  error_description,
  error_next_step,
}: LogBrowserErrorArguments) {
  return new Promise(function (resolve, reject) {

    if(!firebase_uid) { firebase_uid = window?.G_bi2_data?.IV_loggedin_user_info?.firebase_uid;}
    if(!recycler_uid) {recycler_uid = window?.G_bi2_data?.IV_active_employee_info?.business_uid;}
    if(!recycler_location_uid) {recycler_location_uid = window?.G_bi2_data?.IV_active_employee_info?.recycler_location_uid;}
    if(!employee_uid) { employee_uid = window?.G_bi2_data?.IV_active_employee_info?.employee_uid;}

    let timestamp = new Date(Date.now());
    let params = {
      p1s7e1_timestamp: timestamp.toJSON(),
      p1s7e1_endpoint_name: endpoint_name,
      p1s7e1_file_name: file_name,
      ...(class_name && { p1s7e1_class_name: class_name }),
      p1s7e1_method_name: method_name,
      p1s7e1_error_code: error_code,
      p1s7e1_error_summary: error_summary,
      ...(call_stack && { p1s7e1_call_stack: call_stack }),
      ...(input_data && { p1s7e1_input_data: input_data }),
      ...(firebase_uid && { p1s7e1_firebase_uid: firebase_uid }),
      ...(consumer_uid && { p1s7e1_consumer_uid: consumer_uid }),
      ...(recycler_uid && { p1s7e1_recycler_uid: recycler_uid }),
      ...(recycler_location_uid && {
        p1s7e1_recycler_location_uid: recycler_location_uid,
      }),
      ...(conversation_uid && { p1s7e1_conversation_uid: conversation_uid }),
      ...(employee_uid && { p1s7e1_employee_uid: employee_uid }),
      ...(other_document_keys && {
        p1s7e1_other_document_keys: other_document_keys,
      }),
      p1s7e1_error_title: error_title,
      p1s7e1_error_description: error_description,
      ...(error_next_step && { p1s7e1_error_next_step: error_next_step }),
    };

    ajax({
      url: window.G_cloud_functions_base_url + "p1s7e1BrowserErrorLogging",
      headers: {
        Accept: "application/json",
      },
      method: "POST",
      data: params,

      success: function (result) {
        let response_data = {
          success: true,
          response: result,
          request_params: params
        }
        resolve(response_data);
      },
      error: function (error) {
        let error_data = {
          success: false,
          request_object: error,
          request_params: params,
          file_name: "p1s7e1.ts"
        }
        reject(error_data);
      },
    });
  });
}

export function httpsLogger({
  request_object,
  file_name,
  class_name,
  method_name,
  request_params,
  error_title,
  error_description,
  error_next_step,
}: HttpsLoggerArguments) {
  let error_code = 1;
  let error_summary = "N/A";
  let input_data = "N/A";

  if (request_object.status !== 0) {
    error_code = request_object.status;
  }
  if (request_object.statusText !== "") {
    error_summary = request_object.statusText;
  }
  if (request_object.responseType !== "") {
    error_title = request_object.responseType;
  }

  try {
    input_data = JSON.stringify(request_params);
  } catch {
    input_data = "JSON conversion of input data failed";
  }

  let error_data = {
    input_data: input_data,
    error_code: error_code,
    file_name: file_name,
    method_name: method_name,
    class_name: class_name,
    call_stack: request_object.responseText,
    error_title: error_title,
    error_summary: error_summary,
    error_description: error_description,
    ...(error_next_step && { error_next_step: error_next_step }),
  };

  logBrowserError(error_data);
}
