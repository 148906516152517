import { fs83PaymentApiKeys } from "../firestore_schemas/fs83PaymentApiKeys"
import { setDocIn_fs83PaymentApiKeys } from "../firestore_schemas/fs83PaymentApiKeys"
import { getDocFrom_fs83PaymentApiKeys } from "../firestore_schemas/fs83PaymentApiKeys"
import { addDocIn_fs83PaymentApiKeys } from "../firestore_schemas/fs83PaymentApiKeys"
import { setValueOfOptionalFields } from "../firestore_writers/include/general"

type Result = {
  debug_data: Array<string>;
  return_msg: string;
  success: boolean;
  error_title: string;
  error_summary: string;
  error_next_steps: string;
  document_id?: string;
};

export async function fs83Writer(
  w_type: string,
  fs83_id: string,
  data: any
 ) {

  const retval: Result = {
    debug_data: [],
    return_msg: "fs83_writer:fs83Writer ",
    success: true,
    error_title: "",
    error_summary: "",
    error_next_steps: "",
  };

  /// Validate arguments according to write type  
  if (w_type === "e" && !fs83_id) {
    retval.success = false;
    retval.return_msg += "fs83_id argument missing.";
    retval.error_title = "fs83_id argument missing.";
    retval.error_summary = "PaymentApiKeys record UID missing.";
    retval.error_next_steps = "Please provide correct PaymentApiKeys record UID.";
    return retval;
  }
  /// <END> Validate arguments according to write w_type

  if (w_type === "e") {
    let response_data = await getDocFrom_fs83PaymentApiKeys(fs83_id)

    if (response_data.success === false) {
      retval.success = false;
      retval.return_msg += "Record not found.";
      retval.error_title = "Record not found.";
      retval.error_summary = "FS83 record not found in firestore.";
      retval.error_next_steps = "Please reload page and try again.";
      return retval;
    }

    const fs83Data: fs83PaymentApiKeys = {
      business_uid: data.business_uid || response_data["doc_data"].business_uid,
      client_id: data.client_id || response_data["doc_data"].client_id,
      payment_processor_id: data.payment_processor_id || response_data["doc_data"].payment_processor_id,
      price_id: data.price_id || response_data["doc_data"].price_id,
      private_api_key: data.private_api_key || response_data["doc_data"].private_api_key,
      public_api_key: data.public_api_key || response_data["doc_data"].public_api_key,
      recycler_location_uid: data.recycler_location_uid || response_data["doc_data"].recycler_location_uid,
      site_id: data.site_id || response_data["doc_data"].site_id,
      axe_public_api_key_reference: data.axe_public_api_key_reference || response_data["doc_data"].axe_public_api_key_reference || "",
      axe_private_api_key_reference: data.axe_private_api_key_reference || response_data["doc_data"].axe_private_api_key_reference || ""
    } as fs83PaymentApiKeys;
 
    let response = await setDocIn_fs83PaymentApiKeys(
      fs83_id,
      fs83Data
    )

    if (!response.id || response.id == "" ) {
      retval.success = false;
      retval.return_msg += "Firestore write error.";
      retval.error_title = "Firestore write error.";
      retval.error_summary = "FS83 record not updated in firestore.";
      retval.error_next_steps = "Please reload page and try again.";
      return retval;
    }
    retval.document_id = response.id;
    return retval
  }

  if (w_type === "n") {
    const fs83Data: fs83PaymentApiKeys = {
      business_uid: data.business_uid,
      client_id: data.client_id || "",
      payment_processor_id: data.payment_processor_id || "",
      price_id: data.price_id || "",
      private_api_key: data.private_api_key || "",
      public_api_key: data.public_api_key || "",
      recycler_location_uid: data.recycler_location_uid,
      site_id: data.site_id || "",
      axe_public_api_key_reference: data.axe_public_api_key_reference || "",
      axe_private_api_key_reference: data.axe_private_api_key_reference || ""
    } as fs83PaymentApiKeys;

    let response = await addDocIn_fs83PaymentApiKeys(fs83Data)
    if (!response.id || response.id == "" ) {
      retval.success = false;
      retval.return_msg += "Firestore write error.";
      retval.error_title = "Firestore write error.";
      retval.error_summary = "FS83 record not created in firestore.";
      retval.error_next_steps = "Please reload page and try again.";
      return retval;
    }

    retval.document_id = response.id;
    return retval
  }
}

function firestoreWriteError(error:any) {
  // TODO - complete this
}
