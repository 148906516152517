<template>
  <div id="p1s1c2c6" class="">

    <!-- Heading -->
    <div class="flex flex-row items-center justify-between my-3">
      <svg @click="$emit('payment-links-list')" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"/>
      </svg>
      <h1 class="font-bold text-sm">Payment Link</h1>
      <svg @click="$emit('payment-links-list')" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer">
        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"/>
      </svg>
    </div>

    <!-- Payment link form -->
    <div class="border border-gray-400 rounded-xl p-2">
      <h1 class="font-bold text-lg text-center">Modify Payment Link</h1>
      <form class="w-9/12 mx-auto">
        <label for="description" class="font-medium text-sm text-left mb-2 mt-3">
          Discription For Customer
        </label>
        <input autocomplete="off" v-model="DV_description" id="description" type="text" name="description" class="shadow-sm focus:shadow-outline mb-3 w-full appearance-none border rounded p-2 text-gray-700 leading-tight focus:outline-none  enabled:hover:border-gray-400 required:border-red-500 disabled:opacity-75"/>
        <label for="amount" class="font-medium text-sm text-left mb-2 mt-3">
          Amount
        </label>
        <input autocomplete="off" v-model="DV_amount" id="amount" type="text" name="amount" class="shadow-sm mb-3 w-full appearance-none border rounded p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline enabled:hover:border-gray-400 required:border-red-500 disabled:opacity-75"/>
        <label for="invoiceNo" class="font-medium text-sm text-left mb-2 mt-3">
          YMS Invoice No
        </label>
        <input autocomplete="off" v-model="DV_invoiceNo" id="invoiceNo" type="text" name="invoiceNo" class="shadow-sm mb-3 w-full appearance-none border rounded p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline enabled:hover:border-gray-400 required:border-red-500 disabled:opacity-75"/>
        <div class="grid grid-cols-2 gap-2 items-center mt-3">
          <button type="button" @click.stop="sendUpdatedPaymentLink" class="btn btn-lime">
            Save
          </button>
          <button type="button" @click.stop="$emit('payment-links-list')" class="btn btn-lime">
            Discard
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "p1s1c2c6NewPaymentLink",

  props: ["editPaymetLink", "newPaymentIndex"],

  data() {
    return {
      DV_invoiceNo: "",
      DV_amount: "",
      DV_description: "",
      DV_updatedPaymentLink: {},
      DV_newPaymentLink: {},
    };
  },

  mounted() {
    if (this.editPaymetLink != undefined && this.newPaymentIndex == false) {
      this.DV_amount = this.editPaymetLink.amount;
      this.DV_description = this.editPaymetLink.description;
    }
  },

  methods: {
    sendUpdatedPaymentLink() {
      if (this.newPaymentIndex == false) {
        this.DV_updatedPaymentLink = {
          index: this.editPaymetLink.index,
          amount: this.amount,
          description: this.description,
        };

        this.$emit("updateListItem", this.DV_updatedPaymentLink);
      } else {
        this.DV_newPaymentLink = {
          amount: this.amount,
          description: this.description,
        };

        this.$emit("addNewListItem", this.DV_newPaymentLink);
      }

      this.DV_amount = "";
      (this.DV_invoiceNo = ""), (this.DV_description = "");
      this.DV_updatedPaymentLink = {};
      this.DV_newPaymentLink = {};
    },
  },
};
</script>
