/* eslint-disable */
import { ajax } from "noquery-ajax";
import { httpsLogger } from "../p1s7/p1s7e1";

interface Arguments {
  recycler_uid: string;
  recycler_location_uid: string;
  phone_number: string;
  employee_uid: string;
  consumer_uid: string;
  conversation_uid: string;
  message_type: number;
  content: string;
  file_name?: string;

}

function sendMessageToConsumer({
  recycler_uid,
  recycler_location_uid,
  employee_uid,
  consumer_uid,
  conversation_uid,
  message_type,
  content,
  file_name
  
}: Arguments) {
  return new Promise(function (resolve, reject) {
    let params = {
      firebase_uid: window.G_firebase_auth.IV_uid,
      firebase_token: window.G_firebase_auth.IV_id_token,
      recycler_uid: recycler_uid,
      recycler_location_uid: recycler_location_uid,
      employee_uid: employee_uid,
      consumer_contact_uid: consumer_uid,
      conversation_uid: conversation_uid,
      p1s5e2_firebase_uid: window.G_firebase_auth.IV_uid,
      p1s5e2_sender_uid: employee_uid,
      ...(file_name && {p1s5e2_file_name: file_name}),
      p1s5e2_message_type: message_type,
      p1s5e2_content: content,
      firebase_email: window.G_firebase_auth.IV_email_address
    };

    ajax({
      url: window.G_cloud_p1s5_base_url + "p1s5e2EmployeeSendMessageToConsumer",
      headers: {
        Accept: "application/json",
      },
      method: "POST",
      dataType: "json",
      data: params,
      success: function (result) {
        let response_data = {
          success: true,
          response: result,
          request_params: params
        }
        resolve(response_data);
      },
      error: function (error) {
        let error_data = {
          success: false,
          request_object: error,
          request_params: params,
          file_name: "p1s5e2.ts",
          class_name: "p1s5e2",
          method_name: "sendMessageToConsumer",
          error_title: "Failed to send message to customer",
          error_description:
            "The server responded with an error when trying to send a message to customer",
        };

        httpsLogger(error_data);
        reject(error_data);
      },
    });
  });
}

export default sendMessageToConsumer;
