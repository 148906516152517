<template>
  <div id="c9">

    <!-- Heading -->
    <div v-if="P_showHeader" class="m-3">
      <div class="flex items-center justify-between p-3">
        <nav class="flex" aria-label="Breadcrumb">
          <ol class="inline-flex items-center space-x-1 md:space-x-3">
            <li class="inline-flex items-center">
              <svg @click="$emit('newBusiness')" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 mr-7 cursor-pointer">
                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"/>
              </svg>
              <span @click="$emit('newBusiness')" class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 cursor-pointer">
                Businesses
              </span>
            </li>
            <li aria-current="page">
              <div class="flex items-center">
                <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path>
                </svg>
                <span class="text-sm font-medium text-gray-500 dark:text-gray-400">
                  {{ P_businessFormType === 'editBusiness' ? 'Edit Business' : 'Create New Business' }}
                </span>
              </div>
            </li>
          </ol>
        </nav>
        <svg @click="$emit('newBusiness')" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer">
          <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
        </svg>
      </div>
    </div>

    <!-- Business info form -->
    <div v-if="DV_businessNotFound && (P_businessFormType == 'editBusiness')" class="mt-16 text-xl border w-max m-auto p-4 border-orange-400 text-orange-400">
      Something went wrong, we cannot find the business details. Please reload page and try again. If issue presist please contact support.
    </div>


    <div v-else class="m-5">
      <div class="w-full mx-auto flex-col items-center justify-start my-3">
        <span class="flex items-center">
          <label class="text-gray-700 font-medium whitespace-nowrap  text-left md:pr-5 pr-2" for="business_name">
            Business Name *
          </label>
          <input autocomplete="off" v-model="DV_businessInfoForm.name" id="business_name" type="text" name="business_name" class="w-full text-left"/>
        </span>

        <div class="flex-wrap my-4 whitespace-nowrap">
          <h1 class="text-gray-700 font-medium text-left">Business Type</h1>
          <span class="flex mt-4 w-full">
            <span class="flex items-center w-1/3">
              <input autocomplete="off" v-model="DV_businessInfoForm.type" type="checkbox" id="consumer-type-body-shop-type" value="body-shop" class="cursor-pointer">
              <label for="consumer-type-body-shop-type" class="ml-1 cursor-pointer">Body Shop</label>
            </span>

            <span class="flex items-center w-1/3">
              <input autocomplete="off" v-model="DV_businessInfoForm.type" type="checkbox" id="consumer-type-exporter-type" value="exporter" class="cursor-pointer">
              <label for="consumer-type-exporter-type" class="ml-1 cursor-pointer">Exporter</label>
            </span>

            <span class="flex items-center w-1/3">
              <input autocomplete="off" v-model="DV_businessInfoForm.type" type="checkbox" id="consumer-type-insurance-adjuster-type" value="insurance-adjuster" name="insurance-adjuster" class="cursor-pointer">
              <label for="consumer-type-insurance-adjuster-type" class="ml-1 cursor-pointer">Insurance Adjuster</label>
            </span>
          </span>

          <span class="flex mt-2 w-full">
            <span class="flex items-center w-1/3">
              <input autocomplete="off" v-model="DV_businessInfoForm.type" type="checkbox" id="consumer-type-mechanic-type" value="mechanic" class="cursor-pointer">
              <label for="consumer-type-mechanic-type" class="ml-1 cursor-pointer">Mechanic</label>
            </span>

            <span class="flex items-center w-1/3">
              <input autocomplete="off" v-model="DV_businessInfoForm.type" type="checkbox" id="consumer-type-reseller-type" value="reseller" class="cursor-pointer">
              <label for="consumer-type-reseller-type" class="ml-1 cursor-pointer">Reseller</label>
            </span>

            <span class="flex items-center w-1/3">
              <input autocomplete="off" v-model="DV_businessInfoForm.type" type="checkbox" id="consumer-type-retail-type" value="retail" class="cursor-pointer">
              <label for="consumer-type-retail-type" class="ml-1 cursor-pointer">Retail</label>
            </span>
          </span>
        </div>
      </div>

      <div class="grid grid-cols-2 gap-4 lg:whitespace-nowrap">
        <div class="w-full mx-auto flex items-center justify-start my-3 col-span-1">
          <label class="text-gray-700 font-medium  md:pr-4 pr-2 text-left" for="address_1">
            Address Line 1
          </label>
          <input autocomplete="off" v-model="DV_businessInfoForm.addressLine1" id="address_1" type="text" name="address_1"/>
        </div>
        <div class="w-full mx-auto flex items-center justify-start my-3">
          <label class="text-gray-700 font-medium  md:pr-4 pr-2 text-left" for="address_2">
            Address Line 2
          </label>
          <input autocomplete="off" v-model="DV_businessInfoForm.addressLine2" id="address_2" type="text" name="address_2"/>
        </div>
       
      </div>

      <div class="grid grid-cols-3 gap-4 lg:whitespace-nowrap">
    
        <div class="w-full mx-auto flex items-center justify-start my-3">
          <label class="text-gray-700 font-medium  md:pr-1 pr-2 text-left" for="city">
            City
          </label>
          <input autocomplete="off" v-model="DV_businessInfoForm.city" id="city" type="text" name="city"/>
        </div>
        <div class="w-full mx-auto flex items-center justify-start my-3">
          <label class="text-gray-700 font-medium  md:pr-1 pr-2 text-left" for="state">
            State
          </label>
          <select v-model="DV_businessInfoForm.state" id="state" name="state">
            <option v-for="state in DV_statesList" :key="state">{{state}}</option>
          </select>
        </div>
        <div class="w-full mx-auto flex items-center justify-start my-3">
          <label class="text-gray-700 font-medium  md:pr-1 pr-2 text-left" for="zip_code">
            Zip Code
          </label>
          <input autocomplete="off" class="w-fit" v-model="DV_businessInfoForm.postalCode" id="zip_code" maxlength="5" size="5" type="text" name="zip_code"/>
          </div>
      </div>

      <!-- Social media platform info -->
      <div class="w-full mx-auto flex items-center justify-start my-3">
        <label class="text-gray-700 font-medium text-left" :class="P_isModal ? 'w-[17%]' : 'w-[11%]'" for="website">
          Website
        </label>
        <input autocomplete="off" v-model="DV_businessInfoForm.website" id="website" type="text" class="text-left" name="website"/>
      </div>

      <div class="w-full mx-auto flex items-center justify-start my-3">
        <label class="text-gray-700 font-medium text-left" :class="P_isModal ? 'w-[17%]' : 'w-[11%]'" for="facebook_url">
          Facebook URL
        </label>
        <input autocomplete="off" v-model="DV_businessInfoForm.facebookUrl" id="facebook_url" class="text-left" type="text" name="facebook_url"/>
      </div>

      <!-- Buttons -->
      <div class="my-4 justify-center items-center flex m-auto">
        <button 
          v-if="P_businessFormType == 'editBusiness'" 
          class="btn btn-lime text-sm w-44"
          @click.stop="saveBusiness" 
        >
          {{ DV_savingConsumerBusiness ? 'Saving Business...' : 'Save Changes' }}
        </button>
        
        <button 
          v-else 
          @click.stop="saveBusiness" 
          class="btn btn-lime text-sm"
        >
          {{ P_newBusinessButtonTitle || ( DV_savingConsumerBusiness ? 'Creating Business...' : 'Create New Business') }}
        </button>
        
        <button 
          @click="$emit('event-back')" 
          class="btn btn-lime text-sm w-44 ml-4"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { getStatesList, getCitiesList} from "../../includes/us_states.js";
import { fs37Writer } from "../../includes/firestore_writers/fs37_writer"
import AWN from "awesome-notifications";

export default {
  name: "c9EditNewBusinessForm",

  props: ["P_businessFormType" , "P_showHeader", "P_consumerUid", "P_newBusinessButtonTitle", "P_businessUid", "P_isModal"],

  data() {
    return {
      DV_statesList: ["Alabama","Alaska","Arizona","Arkansas","California","Colorado","Connecticut","Delaware","Florida","Georgia","Hawaii","Idaho","Illinois","Indiana","Iowa","Kansas","Kentucky","Louisiana","Maine","Maryland","Massachusetts","Michigan","Minnesota","Mississippi","Missouri","Montana","Nebraska","Nevada","New Hampshire","New Jersey","New Mexico","New York","North Carolina","North Dakota","Ohio","Oklahoma","Oregon","Pennsylvania","Rhode Island","South Carolina","South Dakota","Tennessee","Texas","Utah","Vermont","Virginia","Washington","West Virginia","Wisconsin","Wyoming"],
      DV_customersType: [],
      DV_savingConsumerBusiness: false,
      DV_businessNotFound: false,
      DV_emptyBusinessInfoForm: {
        name: "",
        type: [],
        addressLine1: "",
        addressLine2: "",
        state: "",
        city: "",
        postalCode: "",
        website: "",
        facebookUrl: ""
      },
      DV_businessInfoForm: {}
    };
  },

  mounted() {
    const CI = this;
    CI.DV_businessInfoForm = Object.assign({}, CI.DV_emptyBusinessInfoForm);
    this.loadBusinessData();
  },

  computed: {
    // 
  },

  watch: {
    P_businessUid() { this.loadBusinessData(); }
  },

  methods: {
    loadBusinessData() {
      const CI = this;
      
      if (CI.P_businessUid) {
        let business = window.G_bi2_data.IV_consumer_businesses.value[CI.P_businessUid];
        
        if (business) {
          CI.DV_businessInfoForm = {
            name: business.name,
            type: Object.assign([], business.consumer_business_type_uids_list),
            addressLine1: business.address_line_1,
            addressLine2: business.address_line_2,
            state: business.state,
            city: business.city,
            postalCode: business.postal_code,
            website: business.website,
            facebookUrl: business.facebook_url
          }
        } else {
          CI.DV_businessNotFound = true;
        }
      }
    },

    async saveBusiness() {
      const CI = this;
      CI.DV_savingConsumerBusiness = true;
      
      if (CI.DV_businessInfoForm.name.trim() == "") {
        CI.$awn.alert("Please provide business name.");
        CI.DV_savingConsumerBusiness = false;
        return;
      }

      if (this.P_consumerUid != null && this.P_consumerUid != undefined && this.P_consumerUid != "") {
        this.DV_businessInfoForm.primaryConsumerContactUid = this.P_consumerUid;
        this.DV_businessInfoForm.consumerContactsUidsList = [this.P_consumerUid];
      }

      let saveForm = "n";
      let fs37_id = "";

      if (this.P_businessFormType === 'editBusiness') {
        saveForm = "e";
        fs37_id = this.P_businessUid;
      }

      const fs37Resp = await fs37Writer(
        saveForm,
        window.G_bi2_data.IV_active_employee_info.business_uid,
        window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
        fs37_id, 
        CI.DV_businessInfoForm
      )

      if (fs37Resp.success == false) {
        CI.$awn.alert(`There was an issue saving consumer business. ${fs37Resp.error_next_steps}`);
        CI.DV_savingConsumerBusiness = false;
        return;
      } else {
        CI.DV_savingConsumerBusiness = false;
        CI.$emit("new-business-created",fs37Resp.new_document_id);
        CI.$emit('event-back');
      }
    }
  }
};
</script>
