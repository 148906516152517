import { FirestoreDB } from "./include/creds";
import {
  DocPath,
  DataProblem,
  GetDocResult,
  RemoveDocResult,
} from "./include/misc";

import { collection, doc, deleteDoc, setDoc, getDoc } from "firebase/firestore";

export type fs27ConsumerConversationQueue = {
  conversation_uid: string;
  consumer_contact_uid: string;
  entered_queue_timestamp: Date;
  left_queue_timestamp: Date;
  consumer_live_chat_active_time: Date;
};

export function getDataProblems_fs27ConsumerConversationQueue(
  data: fs27ConsumerConversationQueue
): DataProblem[] {
  const dataproblems: DataProblem[] = [];
  return dataproblems;
}

export function isValidData(data: fs27ConsumerConversationQueue): boolean {
  return true;
}

export async function getDocFrom_fs27ConsumerConversationQueue(
  fs1_id: string,
  fs2_id: string,
  fs27_id: string
): Promise<GetDocResult> {
  const result: GetDocResult = {
    debug_data: [],
    return_msg:
      "fs27ConsumerConversationQueue:getDocFrom_fs27ConsumerConversationQueue",
    success: true,
    doc_data: {},
  };

  if (!fs1_id || !fs2_id || !fs27_id) {
    result.success = false;
    result.return_msg += "Invalid function arguments.";
    return await Promise.resolve(result);
  }

  const doc_ref = doc(
    collection(
      FirestoreDB,
      "fs1RecyclerBusiness",
      fs1_id,
      "fs2RecyclerLocation",
      fs2_id,
      "fs27ConsumerConversationQueue",
      fs27_id
    )
  );

  const doc_data = (await getDoc(doc_ref)).data();
  if (doc_data === undefined) {
    result.success = false;
    result.return_msg +=
      "Could not find a document with the passed-in path arguments.";
    return await Promise.resolve(result);
  }

  result.doc_data = doc_data;
  return await Promise.resolve(result);
}

export async function setDocIn_fs27ConsumerConversationQueue(
  fs1_id: string,
  fs2_id: string,
  fs6_id: string,
  data: fs27ConsumerConversationQueue
): Promise<DocPath> {
  const result: DocPath = {
    id: "",
    path: "",
  };
  if (!isValidData(data)) {
    return await Promise.resolve(result);
  }

  const doc_ref = doc(
    collection(
      FirestoreDB,
      "fs1RecyclerBusiness",
      fs1_id,
      "fs2RecyclerLocation",
      fs2_id,
      "fs27ConsumerConversationQueue",
      fs6_id
    )
  );

  await setDoc(doc_ref, data);

  result.id = doc_ref.id;
  result.path = doc_ref.path;
  return await Promise.resolve(result);
}

export async function removeDocFrom_fs27ConsumerConversationQueue(
  fs1_id: string,
  fs2_id: string,
  fs27_id: string
): Promise<RemoveDocResult> {
  const result: RemoveDocResult = {
    debug_data: [],
    return_msg:
      "fs27ConsumerConversationQueue:removeDocFrom_fs27ConsumerConversationQueue ",
    success: true,
  };

  if (!fs1_id || !fs2_id || !fs27_id) {
    result.success = false;
    result.return_msg += "Invalid function arguments.";
    return await Promise.resolve(result);
  }

  const doc_ref = doc(
      FirestoreDB,
      "fs1RecyclerBusiness",
      fs1_id,
      "fs2RecyclerLocation",
      fs2_id,
      "fs27ConsumerConversationQueue",
      fs27_id
  );

  await deleteDoc(doc_ref);
  return await Promise.resolve(result);
}
