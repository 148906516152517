/* eslint-disable */
import { ajax } from "noquery-ajax";
import { httpsLogger } from "../p1s7/p1s7e1";

function submitSupportRequest(
  recycler_uid: string,
  recycler_location_uid: string,
  name: string,
  email_address: string,
  phone_number: number,
  message_content: string
) {
  return new Promise(function (resolve, reject) {
    let params = {
      firebase_uid: window.G_firebase_auth.IV_uid,
      firebase_token: window.G_firebase_auth.IV_id_token,
      recycler_uid: recycler_uid,
      recycler_location_uid: recycler_location_uid,
      p1s3e43_name: name,
      p1s3e43_email_address: email_address,
      p1s3e43_phone_number: phone_number,
      p1s3e43_message_content: message_content
    };

    ajax({
      url: window.G_cloud_p1s3_base_url + "p1s3e43SubmitSupportRequest",
      headers: {
        Accept: "application/json",
      },
      method: "POST",
      dataType: "json",
      data: params,
      success: function (result) {
        let response_data = {
          success: true,
          response: result,
          request_params: params
        }
        resolve(response_data);
      },
      error: function (error) {
        let error_data = {
          success: false,
          request_object: error,
          request_params: params,
          file_name: "p1s3e43.ts",
          class_name: "p1s3e43",
          method_name: "submitSupportRequest",
          error_title: "Failed to submit support request.",
          error_description: "The server responded with an error when submitting support request.",
        };

        httpsLogger(error_data);
        reject(error_data);
      },
    });
  });
}

export default submitSupportRequest;
