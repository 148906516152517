import { FirestoreDB } from "./include/creds";
import { DocPath } from "./include/misc";

import {
  collection,
  doc,
  setDoc,
  getDoc,
} from "firebase/firestore";

export type fs109StripeIdentityVerificationPlans = {
  stripe_identity_verification_enabled: boolean;
  minimum_transaction_amount: number;
};

// We assume that this function is fast and will not block.
export function isValidData(
  data: fs109StripeIdentityVerificationPlans
): boolean {
  return true;
}

export async function setDocIn_fs109StripeIdentityVerificationPlans(
  business_uid: string,
  recycler_location_uid: string,
  data: fs109StripeIdentityVerificationPlans
): Promise<DocPath> {
  const result: DocPath = {
    id: "",
    path: "",
  };
  if (!isValidData(data)) {
    return await Promise.resolve(result);
  }

  const col = collection(
    FirestoreDB,
    "fs1RecyclerBusiness",
    business_uid,
    "fs2RecyclerLocation",
    recycler_location_uid,
    "fs109StripeIdentityVerificationPlans"
  );
  const DocRef = doc(col, recycler_location_uid);
  await setDoc(DocRef, data);

  result.id = DocRef.id;
  result.path = DocRef.path;
  return await Promise.resolve(result);
}
