import { fs37ConsumerBusinesses } from "../firestore_schemas/fs37ConsumerBusinesses"
import { getDocFrom_fs37ConsumerBusinesses } from "../firestore_schemas/fs37ConsumerBusinesses"
import { setDocIn_fs37ConsumerBusinesses } from "../firestore_schemas/fs37ConsumerBusinesses"
import { addDocIn_fs37ConsumerBusinesses } from "../firestore_schemas/fs37ConsumerBusinesses"
import { removeDocFrom_fs37ConsumerBusinesses } from "../firestore_schemas/fs37ConsumerBusinesses"
import { setValueOfOptionalFields } from "../firestore_writers/include/general"

type Result = {
  debug_data: Array<string>;
  return_msg: string;
  success: boolean;
  error_title: string;
  error_summary: string;
  error_next_steps: string;
  new_document_id?: string;
};

export async function fs37Writer(
  w_type: string,
  fs1_id: string,
  fs2_id: string,
  fs37_id: string,
  data: any
) {

  const retval: Result = {
    debug_data: [],
    return_msg: "fs37_writer:fs37Writer ",
    success: true,
    error_title: "",
    error_summary: "",
    error_next_steps: "",
    new_document_id: "",
  };

  /// Validate arguments according to write type
  if (!fs1_id || !fs2_id) {
    retval.success = false;
    retval.return_msg += "Insufficient arguments.";
    retval.error_title = "Insufficient arguments.";
    retval.error_summary = "business_uid or recycler_uid not set.";
    retval.error_next_steps = "Please provide correct business_uid and recycler_uid.";
    return retval;
  }

  if ((w_type == "e" || w_type == "d") && !fs37_id) {
    retval.success = false;
    retval.return_msg += "Insufficient arguments.";
    retval.error_title = "Insufficient arguments.";
    retval.error_summary = "fs37_id not set.";
    retval.error_next_steps = "Please provide correct fs37_id.";
    return retval;
  }
  /// <END> Validate arguments according to write type

  // Delete record
  if (w_type == "d") {
    await removeDocFrom_fs37ConsumerBusinesses(fs1_id, fs2_id, fs37_id)
    return retval
  }

  // Update existing record
  if (w_type == "e") {
    let response_data = await getDocFrom_fs37ConsumerBusinesses(
      fs1_id,
      fs2_id,
      fs37_id,
    )

    if (response_data.success === false) {
      retval.success = false;
      retval.return_msg += "Record not found.";
      retval.error_title = "Record not found.";
      retval.error_summary = "FS4 record not found in firestore.";
      retval.error_next_steps = "Please reload page and try again.";
      return retval;
    }

    //// Calculate new uids list
    let contacts_uids_list = data.consumerContactsUidsList || [];
    contacts_uids_list = contacts_uids_list.concat(response_data['doc_data'].consumer_contacts_uids_list || []);
    contacts_uids_list = Array.from(new Set(contacts_uids_list));

    let deleted_uids = [] as Array<string>;

    contacts_uids_list.forEach((uid: string) => {
      if (uid.includes("delete-")) {
        deleted_uids.push(uid.replace("delete-", ""))
      }
    });
    contacts_uids_list = contacts_uids_list.filter((uid: string) => {
      let clean_uid = uid.replace("delete-", "");

      if (deleted_uids.includes(clean_uid)) { return false; }
      return true
    })

    //// </end> Calculate new uids list

    const fs37Data: fs37ConsumerBusinesses = {
      consumer_business_type_uids_list: data.type || response_data["doc_data"].consumer_business_type_uids_list || [],
      primary_consumer_contact_uid: data.primaryConsumerContactUid || response_data["doc_data"].primary_consumer_contact_uid || null,
      name: data.name || response_data["doc_data"].name,
      city: data.city || response_data["doc_data"].city || "",
      state: data.state || response_data["doc_data"].state || "",
      consumer_business_uid: response_data["doc_data"].consumer_business_uid,
      postal_code: data.postalCode || response_data["doc_data"].postal_code,
      address_line_1: data.addressLine1 || response_data["doc_data"].address_line_1,
      address_line_2: data.addressLine1 || response_data["doc_data"].address_line_2,
      website: data.website || response_data["doc_data"].website,
      facebook_url: data.facebookUrl || response_data["doc_data"].facebook_url,
      consumer_contacts_uids_list: contacts_uids_list,
      is_deactivated: setValueOfOptionalFields(data.is_deactivated, response_data['doc_data'].is_deactivated, false),
    } as fs37ConsumerBusinesses;

    let response = await setDocIn_fs37ConsumerBusinesses(
      fs1_id,
      fs2_id,
      fs37_id,
      fs37Data
    )

    if (!response.id || response.id == "" ) {
      retval.success = false;
      retval.return_msg += "Firestore write error.";
      retval.error_title = "Firestore write error.";
      retval.error_summary = "FS37 record not updated in firestore.";
      retval.error_next_steps = "Please reload page and try again.";
      return retval;
    }
    
    retval.new_document_id = response.id;
    return retval
  }

  if (w_type == "n") {
    const fs37Data: fs37ConsumerBusinesses = {
      consumer_business_type_uids_list: data.type,
      primary_consumer_contact_uid: data.primaryConsumerContactUid || null,
      name: data.name,
      city: data.city,
      state: data.state,
      postal_code: data.postalCode,
      address_line_1: data.addressLine1,
      address_line_2: data.addressLine2,
      website: data.website,
      facebook_url: data.facebookUrl,
      consumer_contacts_uids_list: data.consumerContactsUidsList || []
    } as fs37ConsumerBusinesses;

    let response = await addDocIn_fs37ConsumerBusinesses(
      window.G_bi2_data.IV_active_employee_info.business_uid,
      window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
      fs37Data
    )

    //TODO update consumer_bussiness_uid field with the new record UID

    if (!response.id || response.id == "" ) {
      retval.success = false;
      retval.return_msg += "Firestore write error.";
      retval.error_title = "Firestore write error.";
      retval.error_summary = "FS37 record not created in firestore.";
      retval.error_next_steps = "Please reload page and try again.";
      return retval;
    }

    retval.new_document_id = response.id;
    return retval;
  }


}

function firestoreWriteError(error:any) {
  // TODO - complete this
}
