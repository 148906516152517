<template>
  <div id="p1s2c1" class="mt-4">
    <div class="block">
      <h1 class="font-bold text-2xl text-left mt-5">Reporting Dashboard</h1>
      <p class="text-left mb-1">{{C_employeeEmail || ""}}</p>

      <!-- Reporting navbar -->
      <div class="border border-gray-400 shadow-sm p-3 rounded-xl">
        <h1 class="text-lg font-bold text-center mb-3">Report Types</h1>
        <div class="border border-gray-400 shadow-sm rounded-xl">

          <!-- Customer engagement filters section -->
          <button
            @click="(DV_activeFilterView = 'customerEngagement'), $emit('report-type', DV_activeFilterView)"
            :class="{ active: DV_activeFilterView == 'customerEngagement' }"
            type="button"
            class="relative inline-flex items-center w-full justify-start rounded-tl-xl rounded-tr-xl border border-gray-300 px-4 py-2 text-sm font-bold text-gray-700 hover:bg-gray-50 focus:text-white focus:z-10 focus:bg-blue-900 focus:outline-none focus:bg-blue-900"
          >
            Customer Engagement
          </button>

          <p1s2c5CustomerEngagementFilters
            v-if="DV_activeFilterView == 'customerEngagement'"
          />

          <!-- Customer queue response time filter -->
          <button
            :class="{ active: DV_activeFilterView == 'queueResponseTime' }"
            @click=" (DV_activeFilterView = 'queueResponseTime'), $emit('report-type', DV_activeFilterView)"
            type="button"
            class="relative inline-flex items-center w-full justify-start border border-gray-300 px-4 py-2 text-sm font-bold text-gray-700 hover:bg-gray-50 focus:text-white focus:z-10 focus:bg-blue-900 focus:outline-none focus:bg-blue-900"
          >
            Queue Response Times
          </button>

          <p1s2c6QueueResponseTimeFilters
            v-if="DV_activeFilterView == 'queueResponseTime'"
          />

          <!-- Customer response time filter -->
          <button
            :class="{ active: DV_activeFilterView == 'customerResponseTime' }"
            @click="(DV_activeFilterView = 'customerResponseTime'), $emit('report-type', DV_activeFilterView)"
            type="button"
            class="relative inline-flex items-center w-full rounded-bl-xl rounded-br-xl border justify-start px-4 py-2 text-sm font-bold text-gray-700 hover:bg-gray-50 focus:text-white focus:z-10 focus:bg-blue-900 focus:outline-none focus:bg-blue-900"
          >
            Customers
          </button>

          <p1s2c7CustomerResponseTimeFilters
            v-if="DV_activeFilterView == 'customerResponseTime'"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import p1s2c7CustomerResponseTimeFilters from "./p1s2c7CustomerResponseTimeFilters.vue";
import p1s2c5CustomerEngagementFilters from "./p1s2c5CustomerEngamentFilters.vue";
import p1s2c6QueueResponseTimeFilters from "./p1s2c6QueueResponseTimeFilters.vue";

export default {
  name: "p1s2c1ReportTypes",

  components: { p1s2c5CustomerEngagementFilters, p1s2c6QueueResponseTimeFilters, p1s2c7CustomerResponseTimeFilters },

  data() {
    return {
      DV_activeFilterView: "customerEngagement",
      DV_employeesInfo: {}
    };
  },

  mounted() {
    this.DV_employeesInfo = window.G_bi2_data.IV_recycler_employees;
  },

  computed: {
    C_employeeEmail() {
      let activeEmployeeUid = window.G_bi2_data.IV_active_employee_info.employee_uid;
    
      if (activeEmployeeUid in window.G_bi2_data.IV_recycler_employees.value === true) {
        let employeeInfo = window.G_bi2_data.IV_recycler_employees.value[activeEmployeeUid];

        return employeeInfo.email_address;
      }

      return "";
    }
  },

  watch: {
    DV_employeesInfo: {
      handler() {
        this.$forceUpdate();
      },

      deep: true
    }
  }
};
</script>
