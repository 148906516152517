import { fs4ConsumerContacts } from "../firestore_schemas/fs4ConsumerContacts"
import { getDocFrom_fs4ConsumerContacts } from "../firestore_schemas/fs4ConsumerContacts"
import { setDocIn_fs4ConsumerContacts } from "../firestore_schemas/fs4ConsumerContacts"
import { removeDocFrom_fs4ConsumerContacts } from "../firestore_schemas/fs4ConsumerContacts"
import { setValueOfOptionalFields } from "../firestore_writers/include/general"
import { PhoneNumber } from "../firestore_schemas/fs4ConsumerContacts"
import { AddressInfo } from "../firestore_schemas/fs4ConsumerContacts"

type Result = {
  debug_data: Array<string>;
  return_msg: string;
  success: boolean;
  error_title: string;
  error_summary: string;
  error_next_steps: string;
};

export async function fs4Writer(
  w_type: string,
  fs1_id: string,
  fs2_id: string,
  fs4_id: string,
  data: any
) {

  const retval: Result = {
    debug_data: [],
    return_msg: "fs4_writer:fs4Writer ",
    success: true,
    error_title: "",
    error_summary: "",
    error_next_steps: "",
  };

  /// Validate arguments according to write type
  if (!fs1_id || !fs2_id || !fs4_id) {
    retval.success = false;
    retval.return_msg += "Insufficient arguments.";
    retval.error_title = "Insufficient arguments.";
    retval.error_summary = "business_uid or recycler_uid not set.";
    retval.error_next_steps = "Please provide correct business_uid snd recycler_uid.";
    return retval;
  }

  /// <END> Validate arguments according to write type

  // Delete record
  if (w_type == "d") {
    await removeDocFrom_fs4ConsumerContacts(fs1_id, fs2_id, fs4_id)
    return retval
  }

  // Update existing record
  if (w_type == "e") {
    let response_data = await getDocFrom_fs4ConsumerContacts(
      fs1_id,
      fs2_id,
      fs4_id,
    )

    if (response_data.success === false) {
      retval.success = false;
      retval.return_msg += "Record not found.";
      retval.error_title = "Record not found.";
      retval.error_summary = "FS4 record not found in firestore.";
      retval.error_next_steps = "Please reload page and try again.";
      return retval;
    }
    const fs4Data: fs4ConsumerContacts = {
      consumer_contact_uid: data.consumer_contact_uid || response_data['doc_data'].consumer_contact_uid || "",
      first_name: setValueOfOptionalFields(data.first_name, response_data['doc_data'].first_name, ""),
      last_name: setValueOfOptionalFields(data.last_name, response_data['doc_data'].last_name, ""),
      facebook_profile_url: setValueOfOptionalFields(data.facebook_profile_url, response_data['doc_data'].facebook_profile_url, ""),
      email: setValueOfOptionalFields(data.email, response_data['doc_data'].email, ""),
      firebase_account_uid: data.firebase_account_uid || response_data['doc_data'].firebase_account_uid || "",
      phone_number: data.primary_phone_number || response_data['doc_data'].phone_number,
      address_info: setValueOfOptionalFields(data.address_info, response_data['doc_data'].address_info, ""),
      average_consumer_rating: data.average_consumer_rating || response_data['doc_data'].average_consumer_rating || null,
      consumer_account_uid: data.consumer_account_uid || response_data['doc_data'].consumer_account_uid || "",
      average_employee_rating: data.average_employee_rating || response_data['doc_data'].average_employee_rating || null,
      assigned_employees: data.assigned_employees || response_data['doc_data'].assigned_employees || [],
      landline_phone_numbers: data.landline_phone_numbers || response_data['doc_data'].landline_phone_numbers || "",
      customer_types: data.customer_types || response_data['doc_data'].customer_types || "",
      is_deactivated: setValueOfOptionalFields(data.is_deactivated, response_data['doc_data'].is_deactivated, false),
      shipping_address_info: setValueOfOptionalFields(data.shipping_address_info, response_data['doc_data'].shipping_address_info, ""),
    } as fs4ConsumerContacts;

    let response = await setDocIn_fs4ConsumerContacts(
      fs1_id,
      fs2_id,
      fs4_id,
      fs4Data
    )

    if (!response.id || response.id == "" ) {
      retval.success = false;
      retval.return_msg += "Firestore write error.";
      retval.error_title = "Firestore write error.";
      retval.error_summary = "FS4 record not updated in firestore.";
      retval.error_next_steps = "Please reload page and try again.";
      return retval;
    }

    return retval
  }

  // TODO - check if we need new?
  // TODO - handle new operations
}

function firestoreWriteError(error:any) {
  // TODO - complete this
}
