import { fs43RecentQueueEntries } from "../firestore_schemas/fs43RecentQueueEntries"
import { getDocFrom_fs43RecentQueueEntries } from "../firestore_schemas/fs43RecentQueueEntries"
import { setDocIn_fs43RecentQueueEntries } from "../firestore_schemas/fs43RecentQueueEntries"
import { removeDocFrom_fs43RecentQueueEntries } from "../firestore_schemas/fs43RecentQueueEntries"

type Result = {
  debug_data: Array<string>;
  return_msg: string;
  success: boolean;
  error_title: string;
  error_summary: string;
  error_next_steps: string;
};

export async function fs43Writer(
  w_type: string,
  fs1_id: string,
  fs2_id: string,
  fs27_consumer_uid="",
  fs27_conversation_uid="",
  data={consumer_uid: "", conversation_uid: ""}
) {

  const retval: Result = {
    debug_data: [],
    return_msg: "fs43_writer:fs43Writer ",
    success: true,
    error_title: "",
    error_summary: "",
    error_next_steps: "",
  };

  /// Validate arguments according to write type
  if (!fs1_id || !fs2_id) {
    retval.success = false;
    retval.return_msg += "Insufficient arguments.";
    retval.error_title = "Insufficient arguments.";
    retval.error_summary = "business_uid or recycler_uid not set.";
    retval.error_next_steps = "Please provide correct business_uid snd recycler_uid.";
    return retval;
  }

  if (w_type == "e" || w_type == "d" && (!fs27_conversation_uid)) {
    retval.success = false;
    retval.return_msg += "Insufficient arguments.";
    retval.error_title = "Insufficient arguments.";
    retval.error_summary = "conversation_uid is not set.";
    retval.error_next_steps = "Please provide correct conversation_uid.";
    return retval;
  }
  /// <END> Validate arguments according to write type

  // Delete record
  if (w_type == "d") {
    await removeDocFrom_fs43RecentQueueEntries(fs1_id, fs2_id, fs27_consumer_uid, fs27_conversation_uid)
    return retval
  }

  // Create new record
  if (w_type == "n") {
    await setDocIn_fs43RecentQueueEntries(
      fs1_id,
      fs2_id,
      fs27_consumer_uid,
      fs27_conversation_uid,
      {conversation_uid: data.conversation_uid,
      consumer_contact_uid: data.consumer_uid}
    )
    return retval
  }

  // TODO - check if we need edit/update?
  // TODO - handle edit/upsert operations?
}

function firestoreWriteError(error:any) {
  // TODO - complete this
}
