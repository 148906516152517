<template>
  <div 
    :class="{
      'h-full': P_showingWithinModal,
      'h-4/5': DV_activeRenderMode > DV_renderModeDesktopNarrow,
      'h-[65%]': DV_activeRenderMode == DV_renderModeDesktopNarrow
    }"
  >
    <div v-show="DV_loading" style="margin: 5vw 5vh">
      Loading...
    </div>

    <div id="c4" v-show="!DV_loading" @click.stop="" class="border border-gray-400 rounded-b-xl p-2 h-full" :class="[C_showInNewTab ? 'm-3' : '']">
      <!-- Desktop view -->
      <div class="flex flex-col gap-2">
    
        <div v-if="DV_showUnfinishedFeatures" class="md:col-span-3 col-span-9">
    
          <!-- Search section -->
          <SearchBar containerClasses="mt-1" inputClasses="" />
    
          <!-- Filters section -->
          <div class="grid grid-cols-11 items-center gap-2 mt-3">
            <div class="col-span-2">
              <p class="text-right font-bold text-xs">Sort By:</p>
            </div>
            <div class="col-span-9">
              <span class="isolate inline-flex rounded-md shadow-sm w-full">
                <button type="button" class="btn-active filter-button left text-xxs ">
                  Vehicle
                </button>
                <button type="button" class="btn-active filter-button text-xxs">
                  Date Started
                </button>
                <button type="button" class="btn-active filter-button text-xxs right">
                  Date Finished
                </button>
              </span>
            </div>
          </div>
        </div>
    
        <!-- Body -->
        <!--header and New tab full screen button -->
        <div class="flex flex-row w-full"> 
          <h1 class="pr-9 font-bold text-xl justify-start">Conversation History</h1>
        <div v-if="C_consumerHistoryPresent" class=" flex justify-end">
          <svg v-if="DV_fullScreenButton" @click="openFullScreen" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer">
            <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"/>
          </svg>
        </div>
      </div>
      <!-- </end>header and New tab full screen button -->
    
    
      <span v-if="!C_conversationHistory || !C_consumerHistoryPresent" class="text-1xl" > No previous conversations finished with
        <span v-if="DV_consumerContact[DV_ccIndexes.first_name] || DV_consumerContact[DV_ccIndexes.last_name]" class="font-bold">
          {{ DV_consumerContact[DV_ccIndexes.first_name] }} {{ DV_consumerContact[DV_ccIndexes.last_name] }}.
        </span>
        <span v-else>this customer.</span>
      </span>
      
      <div v-if="C_conversationHistory" class="">

          <!-- Conversations list -->
          <div v-if="C_conversationHistory && DV_showUnfinishedFeatures" class="md:hidden block border border-gray-400 rounded-xl">
            <div v-for="conv in C_conversationHistory" :key="conv[DV_chIndexes.consumer_conversation_uid]" class="border-b p-2 text-left">
              <p class="text-xs font-medium">
                <span>{{DV_consumerContact[DV_ccIndexes.first_name]}} {{DV_consumerContact[DV_ccIndexes.last_name]}}</span> <span>Honda Civic 2003</span>
              </p>
              <p class="text-xs font-medium">
                Started <span> {{moment(conv[DV_chIndexes.chat_start_timestamp])}}</span>
                Finished <span> {{moment(conv[DV_chIndexes.chat_finished_timestamp]).format('MM-DD-YYYY h:mm A')}} </span>
              </p>
            </div>
          </div>
    
          <!-- Filters section -->
          <div v-if="C_conversationHistory && DV_showUnfinishedFeatures" class="md:block hidden border border-gray-400 rounded-xl p-2">
            <div class="">
              <div class="text-left">
                <p class="md:text-left text-center font-bold text-xs">
                  Filters
                </p>
              </div>
              <div class="flex items-center justify-left md:justify-start mb-1">
                <p class="text-xxs mr-1 w-[17%] text-left">Year Range</p>
                <select id="start_year" type="number" name="start_year" class="shadow-sm mr-2 text-xxs w-1/3"></select>
                <select id="end_year" type="number" name="end_year" class="shadow-sm mr-2 text-xxs w-1/3"></select>
              </div>
              <div class="flex items-center justify-left md:justify-start mb-1">
                <p class="text-xxs mr-1 w-[17%] text-left">Month Range</p>
                <select id="start_month" type="number" name="start_month" class="shadow-sm mr-2 text-xxs w-1/3"></select>
                <select id="end_month" type="number" name="end_month" class="shadow-sm mr-2 text-xxs w-1/3"></select>
              </div>
              <div class="flex items-center justify-left md:justify-start mb-1">
                <p class="text-xxs mr-1 w-[17%] text-left">Date Range</p>
                <select id="start_day" type="number" name="start_day" class="shadow-sm mr-2 text-xxs w-1/3"></select>
                <select id="end_day" type="number" name="end_day" class="shadow-sm mr-2 text-xxs w-1/3"></select>
              </div>
            </div>
            <div class="grid grid-cols-14 items-center gap-2 my-3">
              <div class="col-span-2">
                <p class="text-left font-bold text-xs">Weekday</p>
              </div>
              <div class="md:col-span-6 col-span-12">
                <span class="isolate inline-flex rounded-md shadow-sm w-full">
                  <button type="button" class="text-xxs filter-button left">
                    Mon
                  </button>
                  <button type="button" class="filter-button text-xxs">
                    Tue
                  </button>
                  <button type="button" class="filter-button text-xxs">
                    Wed
                  </button>
                  <button type="button" class="filter-button text-xxs">
                    Thu
                  </button>
                  <button type="button" class="filter-button text-xxs">
                    Fri
                  </button>
                  <button type="button" class="filter-button text-xxs">
                    Sat
                  </button>
                  <button type="button" class="filter-button right text-xxs">
                    Sun
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
    
      </div>
    
      <div 
        class="flex flex-col gap-2 mt-3 h-[90%]" 
        :class="{'!h-[82%]': DV_activeRenderMode == DV_renderModeDesktopNarrow}"
      >
        
        <!-- Mobile view filters -->
        <div class="md:hidden block border border-gray-400 rounded-xl p-2 col-span-9">
          <div class="grid md:grid-cols-7 grid-cols-8 gap-2 items-center">
            <div class="md:col-span-1 col-span-8">
              <p class="md:text-lef text-center t font-bold text-xs">Filters</p>
            </div>
            <div class="md:col-span-2 col-span-8 flex items-center justify-center md:justify-start">
              <p class="text-xxs mr-1">Year Range</p>
              <input autocomplete="off" id="start_year" type="number" name="start_year" class="shadow-sm w-3/12 mr-2 text-xxs"/>
              <input autocomplete="off" id="end_year" type="number" name="end_year" class="shadow-sm w-3/12 text-xxs"/>
            </div>
            <div class="md:col-span-2 col-span-4 flex items-center justify-center md:justify-start">
              <p class="text-xxs mr-1">Month Range</p>
              <input autocomplete="off" id="start_month" type="number" name="start_month" class="shadow-sm w-2/12 mr-2 text-xxs"/>
              <input autocomplete="off" id="end_month" type="number" name="end_month" class="shadow-sm w-2/12 text-xxs"/>
            </div>
            <div class="md:col-span-2 col-span-4 flex items-center justify-center md:justify-start">
              <p class="text-xxs mr-1">Date Range</p>
              <input autocomplete="off" id="start_date" type="number" name="start_date" class="shadow-sm w-2/12 mr-2 text-xxs"/>
              <input autocomplete="off" id="end_date" type="number" name="end_date" class="shadow-sm w-2/12 text-xxs"/>
            </div>
          </div>
          <div class="grid grid-cols-12 items-center gap-2 my-3">
            <div class="md:col-span-2 col-span-12">
              <p class="md:text-left text-center font-bold text-xs">Weekday</p>
            </div>
            <div class="md:col-span-6 col-span-12">
              <span class="isolate inline-flex rounded-md shadow-sm w-full">
                <button type="button" class="text-xxs filter-button left">
                  Mon
                </button>
                <button type="button" class="filter-button text-xxs">
                  Tue
                </button>
                <button type="button" class="filter-button text-xxs">
                  Wed
                </button>
                <button type="button" class="filter-button text-xxs">
                  Thu
                </button>
                <button type="button" class="filter-button text-xxs">
                  Fri
                </button>
                <button type="button" class="filter-button text-xxs">
                  Sat
                </button>
                <button type="button" class="filter-button right text-xxs">
                  Sun
                </button>
              </span>
            </div>
          </div>
        </div>
        
        <!-- Conversation history list -->
        <div v-if="C_conversationHistory && C_consumerHistoryPresent" class="md:block border border-gray-400 rounded-xl max-h-full overflow-y-scroll hide-scrollbar">
          <div class="max-h-full overflow-scroll-y">
            <div 
              v-for="conv in C_conversationHistory"
              :key="conv[DV_chIndexes.consumer_conversation_uid]" 
              class=" flex flex-row p-2 text-left relative"
              :class="[Object.keys(C_conversationHistory).length > 1 ? 'border-b' : '']"
              v-if="conv[DV_chIndexes.chat_start_timestamp] !== null"
            >
              <div
                v-if="conv[DV_chIndexes.chat_start_timestamp] !== null"
                @click.stop="loadConversation(conv[DV_chIndexes.consumer_conversation_uid])"
              >
                <div class="flex cursor-pointer">
                  <div class="flex flex-col">
                    <span v-if="conv[DV_chIndexes.chat_start_timestamp] !== null" >
                      Started on {{moment(conv[DV_chIndexes.chat_start_timestamp].toDate()).format('MM-DD-YYYY')}} {{moment(conv[DV_chIndexes.chat_start_timestamp].toDate()).format('h:mm A')}}
                    </span>
      
                    <span v-if="conv[DV_chIndexes.chat_finished_timestamp] !== null" >
                      Finished on {{moment(conv[DV_chIndexes.chat_finished_timestamp].toDate()).format('MM-DD-YYYY')}} at 
                      {{moment(conv[DV_chIndexes.chat_finished_timestamp].toDate()).format('h:mm A')}}
                    </span>
                  </div>
                  
                  <div v-if="partInformation(conv[DV_chIndexes.consumer_conversation_uid]) !== ''" class="ml-5">
                    {{ partInformation(conv[DV_chIndexes.consumer_conversation_uid]) }} 
                  </div>

                  <div v-show="availableDownloadReceipts(conv[DV_chIndexes.consumer_uid], conv[DV_chIndexes.consumer_conversation_uid]).length > 0" class="absolute right-44 btn btn-lime h-[fit-content] index-10 cursor-pointer" :class="{'cursor-not-allowed': DV_downloadingReceipts['loading']}" @click="startReceiptDownloads(conv[DV_chIndexes.consumer_uid], conv[DV_chIndexes.consumer_conversation_uid],moment(conv[DV_chIndexes.chat_start_timestamp].toDate()).format('MM-DD-YYYY'), $event)">
                    {{ (DV_downloadingReceipts['loading'] && DV_downloadingReceipts['conversationUid'] == conv[DV_chIndexes.consumer_conversation_uid]) ? "Downloading..." : "Download Receipt(s)" }}
                  </div>
          
                  <div class="absolute right-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-maximize-2">
                      <polyline points="15 3 21 3 21 9"/>
                      <polyline points="9 21 3 21 3 15"/>
                      <line x1="21" y1="3" x2="14" y2="10"/>
                      <line x1="3" y1="21" x2="10" y2="14"/>
                    </svg>
                  </div>
                </div>

                <div class="flex cursor-pointer">
                  <div v-if="(conv[DV_chIndexes.conversation_consumer_rating] !== null) && (conv[DV_chIndexes.chat_finished_timestamp] !== null)" class="mr-4">
                    Rating: {{ DV_ratingMap[conv[DV_chIndexes.conversation_consumer_rating]] }}
                  </div>

                  <div class="mr-4">
                    {{ getChatMessageCount(`${conv[DV_chIndexes.consumer_uid]}-${conv[DV_chIndexes.consumer_conversation_uid]}`) }}
                  </div>

                  <div v-if="(conv[DV_chIndexes.chat_start_timestamp] !== null) && (conv[DV_chIndexes.chat_finished_timestamp] !== null)">
                    Chat Duration: {{ getTotalChatTime(conv[DV_chIndexes.chat_start_timestamp], conv[DV_chIndexes.chat_finished_timestamp]) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    
        <!-- Search section -->
        <div v-if="DV_showUnfinishedFeatures && C_conversationHistory" class="md:col-span-7 col-span-9">
          <div class="border border-gray-400 rounded-xl p-3 md:h-full h-64 w-full">
            <SearchBar containerClasses="mt-1" inputClasses="md:w-5/12 w-7/12 py-1" />
            <div>
            <p v-for="message in DV_selectedConversationMessages"
            :class="{'mt-5 text-left': message[DV_ccmIndexes.message_sender] === 2,
            'mt-5 text-right': message[DV_ccmIndexes.message_sender] === 1,
            'mt-5 bg-emerald-100 text-center': message[DV_ccmIndexes.message_sender] === 5
          }"> {{ message[DV_ccmIndexes.message_content] }}</p>
          </div>
          </div>
          <!-- messages from previous selected conversation-->
          
        </div>
      </div>
      
      <!-- popup modal for chat log messages-->
      <mod1CustomerChatLog
        ref="conversationLogPopupModal"
        :P_consumerUid="C_consumerUid"
        :P_conversationUid="DV_selectedConversationUid"
        :P_conversationStartTime="DV_selectedConversationStartTime"
        :P_conversationEndTime="DV_selectedConversationEndTime"
        :P_isChatModuleParent="P_isChatModuleParent"
      ></mod1CustomerChatLog>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import SearchBar from '../shared/SearchBar.vue';
import mod1CustomerChatLog from '../shared/mod1CustomerChatLog.vue'
import m3RenderingSettings from '../../mixins/m3RenderingSettings.js';
import _ from "lodash"
import generalFunctions from '../../mixins/generalFunctions.js';

export default {
  name: "c4CustomerHistory",

  props: ['P_consumerUid','P_showActiveConversations', 'P_showingWithinModal', 'P_isChatModuleParent'],

  components: { SearchBar,mod1CustomerChatLog },

  mixins: [m3RenderingSettings, generalFunctions],

  data() {
    return {
      DV_loading: false,
      DV_recyclerEmployees: {},
      DV_ccIndexes: window.G_bi2_data.IV_al_consumer_contacts,
      DV_selectedConversationMessages: [],
      DV_ccmIndexes: window.G_bi2_data.IV_al_consumer_conversation_messages,
      DV_showUnfinishedFeatures: window.G_show_unfinished_features,
      DV_conversationHistory: null,
      DV_chIndexes: window.G_bi2_data.IV_al_consumer_contact_conversation_history_index,
      DV_fullScreenButton: true,
      DV_consumerContact: {},
      DV_selectedConversationUid: "",
      DV_selectedConversationStartTime: null,
      DV_selectedConversationEndTime: null,
      moment: moment,
      DV_conversation: window.G_bi2_data.IV_consumer_conversation_details.value,
      DV_conversationIndexes: window.G_bi2_data.IV_al_consumer_conversation_details,
      DV_ratingMap: {
        100: "Amazing",
        85: "Good",
        70: "Average",
        60: "Below Average",
        50: "Bad"
      },
      DV_consumerConversationPaymentLinks: window.G_bi2_data.IV_consumer_conversation_payment_links,
    };
  },

  mounted() {
    this.loadCustomerData(0, this);
    // Populate dropdown filter
    // this.populateYear();
    // this.populateMonths();
    // this.populateDays();
  },
  watch:{

    P_consumerUid() {
      this.loadCustomerData(0, this);
    }
  },

  methods: {
    loadConversation(conversation_uid) {
      const CI = this;
      CI.DV_selectedConversationUid = conversation_uid;
      
      CI.DV_selectedConversation = CI.DV_conversationHistory.find((conv) => {
        return (conv[CI.DV_chIndexes.consumer_conversation_uid] == conversation_uid);
      });
      
      if (!CI.DV_selectedConversation) {
        CI.$awn.warning("Unable to fetch the conversation information.");
        return;
      }

      CI.DV_selectedConversationStartTime = CI.DV_selectedConversation[CI.DV_chIndexes.chat_start_timestamp];
      CI.DV_selectedConversationEndTime = CI.DV_selectedConversation[CI.DV_chIndexes.chat_finished_timestamp];

      CI.$refs.conversationLogPopupModal.showSelf();
    },

    loadCustomerData(retryCount = 0, classInstance) {
      const CI = classInstance;
      CI.DV_loading = true;

      if (retryCount > 500) {
        CI.DV_conversationHistory = null;
        CI.DV_loading = false;
        return;
      }

      // wait for loading data when opening in new tab
      if (Object.keys(window.G_bi2_data.IV_consumer_contact_conversation_history_index.value).length == 0 ||
        Object.keys(window.G_bi2_data.IV_consumer_contacts.value).length == 0
      ) {
        setTimeout(function() {CI.loadCustomerData(++retryCount, classInstance)}, 1000)
        return;
      }

      CI.DV_loading = false;

      // set data
      if (CI.C_consumerUid in window.G_bi2_data.IV_consumer_contact_conversation_history_index.value) {
        let conversationHistory = window.G_bi2_data.IV_consumer_contact_conversation_history_index.value[CI.C_consumerUid];
        
        // arrange the history in order
        let unfinishedConversations = Object.values(conversationHistory).filter((conv) => {return !conv[CI.DV_chIndexes.chat_finished_timestamp]})
        let finishedConversations = Object.values(conversationHistory).filter((conv) => {return conv[CI.DV_chIndexes.chat_finished_timestamp]})
        unfinishedConversations = _.sortBy(unfinishedConversations, conv => conv[CI.DV_chIndexes.chat_start_timestamp]).reverse()
        finishedConversations = _.sortBy(finishedConversations, conv => conv[CI.DV_chIndexes.chat_finished_timestamp]).reverse()
        CI.DV_conversationHistory = [...unfinishedConversations, ...finishedConversations]
      } else {
        CI.DV_conversationHistory = null;
      }

      if (CI.C_consumerUid in window.G_bi2_data.IV_consumer_contacts.value) {
        CI.DV_consumerContact = window.G_bi2_data.IV_consumer_contacts.value[CI.C_consumerUid];
      }

      if (CI.C_showInNewTab ) {
        CI.DV_fullScreenButton = false;
      }
    },

    openFullScreen() {
      let routeData = this.$router.resolve({ path: `/customerHistory?consumerUid=${this.C_consumerUid}` });
      window.open(routeData.href, "_blank");
    },

    populateYear() {
      let year_satart = 1940;
      let year_end = (new Date).getFullYear(); // current year
      let year_selected = "Select year";

      let option = '';
      option = '<option>Year</option>'; // first option

      for (let i = year_satart; i <= year_end; i++) {
          let selected = (i === year_selected ? ' selected' : '');
          option += '<option value="' + i + '"' + selected + '>' + i + '</option>';
      }

      document.getElementById("start_year").innerHTML = option;
      document.getElementById("end_year").innerHTML = option;
    },

    populateMonths() {
      let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
      var month_selected = (new Date).getMonth(); // current month
      var option = '';
      option = '<option>Month</option>'; // first option

      for (let i = 0; i < months.length; i++) {
          let month_number = (i + 1);

          // value month number with 0. [01 02 03 04..]
          let month = (month_number <= 9) ? '0' + month_number : month_number;

          let selected = (i === month_selected ? ' selected' : '');
          option += '<option value="' + month + '"' + selected + '>' + months[i] + '</option>';
      }
      document.getElementById("start_month").innerHTML = option;
      document.getElementById("end_month").innerHTML = option;
    },

    populateDays() {
      let day_selected = "Day"; // current day
      let option = '';
      option = '<option>Day</option>'; // first option

      for (let i = 1; i < 32; i++) {
          // value day number with 0. 01 02 03 04..
          let day = (i <= 9) ? '0' + i : i;

          let selected = (i === day_selected ? ' selected' : '');
          option += '<option value="' + day + '"' + selected + '>' + day + '</option>';
      }

      document.getElementById("start_day").innerHTML = option;
      document.getElementById("end_day").innerHTML = option;
    },

    partInformation(conversationId) {
      let uid = `${this.C_consumerUid}-${conversationId}`
      let text = ""
      if(this.DV_conversation[uid][this.DV_conversationIndexes.ma1_vehicles_and_parts_array] === undefined) {
        return text;
      }

      if (this.DV_conversation[uid][this.DV_conversationIndexes.ma1_vehicles_and_parts_array][this.DV_conversationIndexes.ma1i_vehicle_model_name]) {
        text += `${this.DV_conversation[uid][this.DV_conversationIndexes.ma1_vehicles_and_parts_array][this.DV_conversationIndexes.ma1i_vehicle_model_name]}`
      }

      if (this.DV_conversation[uid][this.DV_conversationIndexes.ma1_vehicles_and_parts_array][this.DV_conversationIndexes.ma1i_vehicle_year]) {
        text += ` ${this.DV_conversation[uid][this.DV_conversationIndexes.ma1_vehicles_and_parts_array][this.DV_conversationIndexes.ma1i_vehicle_year]}`
      }

      if (this.DV_conversation[uid][this.DV_conversationIndexes.ma1_vehicles_and_parts_array][this.DV_conversationIndexes.ma1i_vehicle_make_name]) {
        text += ` ${this.DV_conversation[uid][this.DV_conversationIndexes.ma1_vehicles_and_parts_array][this.DV_conversationIndexes.ma1i_vehicle_make_name]}`
      }

      if (this.DV_conversation[uid][this.DV_conversationIndexes.ma1_vehicles_and_parts_array][this.DV_conversationIndexes.ma1i_interchange_part_number]) {
        text += ` ${this.DV_conversation[uid][this.DV_conversationIndexes.ma1_vehicles_and_parts_array][this.DV_conversationIndexes.ma1i_interchange_part_number]}`  
      }
      

      return text
    },

    getChatMessageCount(conversation_uid) {
      let msgCount = window.G_bi2_data.IV_consumer_conversation_messages.value[conversation_uid].length;
      return `Total Chat ${msgCount > 1 ? 'Messages' : 'Message'}: ${msgCount}`
    },

    getTotalChatTime(startTime, endTime) {
      let end = moment(endTime.toDate())
      let start = moment(startTime.toDate())
      let duration = moment.duration(end.diff(start))

      let time = ""
      if (duration.years() > 0) { time += `${duration.years()} ${duration.years() > 1 ? 'years' : 'year'} `}
      if (duration.months() > 0) { time += `${duration.months()} ${duration.months() > 1 ? 'months' : 'month'} `}
      if (duration.weeks() > 0) { time += `${duration.weeks()} ${duration.weeks() > 1 ? 'weeks' : 'week'} `}
      if (duration.days() > 0) { time += `${duration.days()} ${duration.days() > 1 ? 'days' : 'day'} `}
      if (duration.hours() > 0) { time += `${duration.hours()} ${duration.hours() > 1 ? 'hours' : 'hour'} `}
      if (duration.minutes() > 0) { time += `${duration.minutes()} ${duration.minutes() > 1 ? 'minutes' : 'minute'} `}
      if (duration.seconds() > 0) { time += `${duration.seconds()} ${duration.seconds() > 1 ? 'seconds' : 'second'} `}

      return time
    }
  },

  computed: {
    C_consumerUid() {
      let consumerUid = this.P_consumerUid;

      if (this.C_showInNewTab && this.$route.query["consumerUid"]) {
        consumerUid = this.$route.query["consumerUid"];
      }

      return consumerUid;
    },

    C_showInNewTab() {
      if (this.$route.name == "customerHistory") { return true; }

      return false;
    },

    C_conversationHistory() {
      return this.DV_conversationHistory
    },

    C_consumerHistoryPresent() {
      if (!this.C_conversationHistory) { return false}

      let uuids = Object.keys(this.C_conversationHistory)
      let historyExists = false;

      uuids.forEach((uid) => {
        if (this.C_conversationHistory[uid][this.DV_chIndexes.chat_start_timestamp] !== null) {
          historyExists = true;
          return;
        }
      })

      return historyExists;
    },
  }
};
</script>

<style>
.dropdown-menu {
    max-height: 280px;
    overflow-y: auto;
}
</style>
