<template>
  <div id="p1s3c11">
    <!-- Heading -->
    <div class="m-3">
      <div class="flex items-center justify-between p-3">
        <nav class="flex" aria-label="Breadcrumb">
          <ol class="inline-flex items-center space-x-1 md:space-x-3">
            <li class="inline-flex items-center">

              <!-- Back Button -->
              <svg @click="$emit('event-back')" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 mr-7 cursor-pointer">
                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"/>
              </svg>
              <span @click="$emit('event-back')" class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 cursor-pointer">
                Employees
              </span>
            </li>
            <li aria-current="page">
              <div class="flex items-center">
                <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path>
                </svg>
                <span v-if="DV_buttonsDiv == 'newEmployee'" class="text-sm font-medium text-gray-500 dark:text-gray-400">
                  Invite An Employee
                </span>
                <span v-if="DV_buttonsDiv == 'editEmployee'" class="text-sm font-medium text-gray-500 dark:text-gray-400">
                  Edit Employee
                </span>
              </div>
            </li>
          </ol>
        </nav>
        <svg @click="$emit('event-back')" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer">
          <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
        </svg>
      </div>
    </div>

    <!-- Invite a new Employee -->
    <div v-if="P_mode === 'new'" class="gap-6 flex flex-col mx-auto flex items-start my-3 w-3/5">
      <div class="flex flex-row w-full justify-start">
        <label class="text-gray-700 font-medium text-left w-1/4" for="email">
          Enter Email Address:
        </label>
        <div class="w-3/4">
          <input class="text-left" autocomplete="off" id="p1s3c11_invite_email" type="email" v-model="DV_inviteEmail" name="email"/>
        </div>
      </div>

      <div class="flex flex-row w-full justify-start">
        <label class="text-gray-700 font-medium text-left w-1/4" for="first_name">
          Enter First Name:
        </label>
        <div class="w-3/4">
          <input autocomplete="off" id="p1s3c11_employee_first_name" class="text-left" type="text" v-model="DV_employeeFirstName"/>
        </div>
      </div>

      <div class="flex flex-row w-full justify-start">
        <label class="text-gray-700 font-medium text-left w-1/4" for="last_name">
          Enter Last Name:
        </label>
        <div class="w-3/4">
          <input autocomplete="off" id="p1s3c11_employee_last_name" class="text-left" type="text" v-model="DV_employeeLastName"/>
        </div>
      </div>

      <!-- Roles of employees -->
      <div class="flex justify-start items-center justify-between w-full">
        <h1 class="text-gray-700 font-medium no-wrap text-left w-1/4 mr-5">Select the roles the employee should have</h1>

        <!-- 
        <span class="isolate gap-5 inline-flex rounded-md shadow-sm w-full justify-between w-3/4">
        -->
        <span class="isolate gap-5 inline-flex rounded-md shadow-sm w-full ml-10">
          <span>
            <input class="mr-1 cursor-pointer" id="p1s3c11_invite-permission-admin" v-model="DV_invitePermissions.admin" type="checkbox">
            <label class="cursor-pointer" for="p1s3c11_invite-permission-admin">Admin</label>
          </span>

          <!-- Manager 
          <span>
            <input class="mr-1 cursor-pointer" id="p1s3c11_invite-permission-manager" v-model="DV_invitePermissions.manager" type="checkbox">
            <label class="cursor-pointer" for="p1s3c11_invite-permission-manager">Manager</label>
          </span>
          -->
          
          <span>
            <input class="mr-1 cursor-pointer" id="p1s3c11_invite-permission-sales-person" v-model="DV_invitePermissions.sales_person" type="checkbox">
            <label class="cursor-pointer" for="p1s3c11_invite-permission-sales-person">Sales Person</label>
          </span>
        </span>
      </div>

      <div class="text-left w-full flex items-end ml-2">
          <span v-if="DV_inviteMessage['text'] !== ''" class="text-sm" :class="[DV_inviteMessage['status'] == 200 ? 'text-green-700' : 'text-red-700']">{{ DV_inviteMessage["text"] }}</span>
      </div>

      <button :disabled="DV_isLoading" @click.stop="createInvite" class="btn self-center btn-lime w-52">
        <span v-if="!DV_isLoading">Invite New Employee</span>

        <svg v-else class="h-6 w-6 text-gray-400 spin-anticlock mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
        </svg>
      </button>   
    </div>

    <!-- Edit Employee info -->
    <div v-if="P_mode === 'edit'" class="m-5 relative w-3/5 mx-auto">
      
      <div class="w-full flex items-center justify-start my-3">
        <label class="text-gray-700 font-medium text-xs text-left w-1/5" for="f_name">
          First Name *
        </label>
        <input class="text-left w-full" autocomplete="off" id="f_name" type="text" name="f_name" v-model="DV_unsavedEmployee.first_name"/>
      </div>
      
      <div class="w-full flex items-center justify-start my-3">
        <label class="text-gray-700 font-medium text-xs text-left w-1/5" for="l_name">
          Last Name *
        </label>
        <input class="text-left w-full" autocomplete="off" id="l_name" type="text" v-model="DV_unsavedEmployee.last_name" name="l_name"/>
      </div>
      
      <div class="w-full flex items-center justify-start my-3">
        <label class="text-gray-700 font-medium text-xs text-left w-1/5" for="email">
          Email Address *
        </label>
        <input class="text-left w-full" autocomplete="off" id="email" type="email" v-model="DV_unsavedEmployee.email_address" name="email"/>
      </div>

      <div class="w-full flex items-center justify-start my-3">
        <label class="text-gray-700 font-medium text-xs text-left w-1/5" for="phone_number">
          Phone Number *
        </label>
        
        <input class="text-left w-full" autocomplete="off" id="phone_number" type="text" v-model="DV_unsavedEmployee.phone_number" name="phone_number"/>
      </div>

      <div class="w-full flex items-center justify-start my-3">
        <label class="text-gray-700 font-medium text-xs text-left w-1/5" for="extension">
          Extension
        </label>
        
        <input class="text-left w-full" autocomplete="off" id="extension" type="text" v-model="DV_unsavedEmployee.extension" name="extension"/>
      </div>

      <!-- Roles of employees -->
      <div class="w-full flex items-center justify-start my-4">
        <label class="text-left text-xs font-medium w-1/5">Roles *</label>

        <div class="flex items-start justify-between w-full">
          <span class="flex items-center w-full">
            <input id="sales_person" type="checkbox" name="sales_person" value="sales_person" v-model="DV_unsavedRoles" class="cursor-pointer"/>
            <label for="sales_person" class="ml-2 cursor-pointer">Sales Person</label>
          </span>

          <!--
          <span class="flex items-center w-full">
            <input id="manager" type="checkbox" name="manager" value="manager" v-model="DV_unsavedRoles" class="cursor-pointer"/>
            <label for="manager" class="ml-2 cursor-pointer">Manager</label>
          </span>
          -->
          
          <span class="flex items-center w-full">
            <input id="admin" type="checkbox" name="admin" value="admin" v-model="DV_unsavedRoles" class="cursor-pointer"/>
            <label for="admin" class="ml-2 cursor-pointer">Admin</label>
          </span>
        </div>
      </div>

      <!-- Buttons -->
      <span v-if="DV_updateMessage['text'] !== ''" class="text-sm absolute bottom-10 left-0" :class="[DV_updateMessage['status'] == 200 ? 'text-green-700' : 'text-red-700']">{{ DV_updateMessage["text"] }}</span>
      <div v-if="DV_buttonsDiv == 'editEmployee'" class="grid grid-cols-3 gap-2 my-4 justify-start flex">
        <div class="flex justify-start items-center col-span-1">
          <button :disabled="DV_isLoading" @click.stop="saveChanges" class="btn btn-lime text-sm mr-4">Save Changes</button>
          <button @click="$emit('event-back')" class="btn btn-lime text-sm">Discard Changes</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {fs3Writer} from "../../includes/firestore_writers/fs3_writer.ts"
import createEmployeeInvite from "../../includes/server_calls/p1s3/p1s3e18.ts"
import dataValidator from "../../includes/bi1Datavalidation"
import {CR,RC} from "../../includes/bi2SuccessCodes"

export default {
  name: "p1s3c11EditNewEmployeeForm",

  props: ["employeeForm", "employee", "P_mode"],

  data() {
    return {
      DV_inviteEmail: "",
      DV_employeeFirstName: "",
      DV_employeeLastName: "",
      DV_invitePermissions: {
        admin: false,
        manager: false,
        sales_person: false
      },
      DV_buttonsDiv: "",
      DV_unsavedEmployee: {
        email_address: "",
        employee_uid: "",
        first_name: "",
        last_name: "",
        phone_number: "",
        extension: "",
        permissions: {
          admin: false,
          manager: false,
          report_viewer: false,
          sales_person: false,
        },
      },
      DV_inviteMessage: {status: null, text: ""},
      DV_updateMessage: {status: null, text: ""},
      DV_isLoading: false,
      DV_unsavedRoles: []
    };
  },

  mounted() {
    if (this.employee) { 
      this.DV_unsavedEmployee = Object.assign({}, this.employee)

      Object.keys(this.employee["permissions"]).forEach((key) => { 
        if (this.employee["permissions"][key]) { 
          this.DV_unsavedRoles.push(key)
        }
      })
    }
    this.DV_buttonsDiv = this.employeeForm ;
  },

  methods: {
    createInvite() {
      this.DV_isLoading = true;
      this.DV_inviteMessage["status"] = null;
      this.DV_inviteMessage["text"] = "";

      let call_result = dataValidator.is_email_address(this.DV_inviteEmail);

      // valid email
      if (call_result[CR.success] !== RC.success) {
        this.DV_isLoading = false;
        this.DV_inviteMessage["status"] = 400;
        this.DV_inviteMessage["text"] = "Email address is invalid";
        return;
      }

      if (this.DV_employeeFirstName == "" || this.DV_employeeLastName == "") {
        this.DV_isLoading = false;
        this.DV_inviteMessage["status"] = 400;
        this.DV_inviteMessage["text"] = "Employee first and last name are required";
        return;
      }

      if (!this.DV_invitePermissions.admin && !this.DV_invitePermissions.manager && !this.DV_invitePermissions.sales_person) {
        this.DV_isLoading = false;
        this.DV_inviteMessage["status"] = 400;
        this.DV_inviteMessage["text"] = "Please select a role of employee";
        return;
      }

      // employee already exits
      let employees_list = window.G_bi2_data.IV_recycler_employees.value
      for (let uid in employees_list) {
        if (employees_list[uid]["email_address"] == this.DV_inviteEmail) {
          this.DV_isLoading = false;
          this.DV_inviteMessage["status"] = 400;
          this.DV_inviteMessage["text"] = "Employee already exists";
          return;
        }
      }

      createEmployeeInvite(
        window.G_bi2_data.IV_active_employee_info.business_uid,
        window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
        this.DV_inviteEmail,
        this.DV_employeeFirstName,
        this.DV_employeeLastName,
        this.DV_invitePermissions
      ).then((resp) => {
        this.DV_isLoading = false;

        if (resp.success) {
          this.DV_inviteMessage["status"] = 200;
          this.DV_inviteMessage["text"] = "Employee Successfully Invited";
          this.DV_inviteEmail = "";
          setTimeout(() =>{ this.$emit("event-back") }, 1500)
        } else {
          this.DV_inviteMessage["status"] = 400;
          this.DV_inviteMessage["text"] = "Error occurred. Please try again";
        }
      });
    },

    saveChanges() {
      this.DV_isLoading = true;
      this.DV_updateMessage["status"] = null;
      this.DV_updateMessage["text"] = "";
      let roles = [];

      //get rid of undefined values to prevent firestore errors
      let permissions = this.DV_unsavedEmployee.permissions;
      for(let entry_key in permissions) {
        if(permissions[entry_key] === undefined)  {
          delete(permissions[entry_key]);
        }
      }

      roles = Object.keys(this.DV_unsavedEmployee["permissions"])
      roles.forEach((role) => { this.DV_unsavedEmployee["permissions"][role] = this.DV_unsavedRoles.includes(role) })

      let call_result = dataValidator.is_email_address(this.DV_unsavedEmployee["email_address"]);

      // valid email  
      if (call_result[CR.success] !== RC.success) {
        this.DV_isLoading = false;
        this.DV_updateMessage["status"] = 400;
        this.DV_updateMessage["text"] = "Email address is invalid";
        return;
      }

      if ((this.DV_unsavedEmployee["first_name"] == "") || (this.DV_unsavedEmployee["last_name"] == "")) {
        this.DV_isLoading = false;
        this.DV_updateMessage["status"] = 400;
        this.DV_updateMessage["text"] = "Employee first and last name are required";
        return;
      }

      if (!this.DV_unsavedEmployee.permissions["admin"] && !this.DV_unsavedEmployee.permissions["manager"] && !this.DV_unsavedEmployee.permissions["report_viewer"] && !this.DV_unsavedEmployee.permissions["sales_person"]) {
        this.DV_isLoading = false;
        this.DV_updateMessage["status"] = 400;
        this.DV_updateMessage["text"] = "Please select a role of employee";
        return;
      }

      fs3Writer("e",
        window.G_bi2_data.IV_active_employee_info.business_uid,
        window.G_bi2_data.IV_active_employee_info.recycler_location_uid,      
        this.employee.employee_uid,
        this.DV_unsavedEmployee,
        permissions
      ).then((resp) => {
        this.DV_isLoading = false;

        if (resp.success) {
          this.DV_updateMessage["status"] = 200;
          this.DV_updateMessage["text"] = "Information Saved";
          setTimeout(() =>{ this.$emit("event-back") }, 1500)
        } else {
          this.DV_updateMessage["status"] = 400;
          this.DV_updateMessage["text"] = "Error occurred. Please try again";
        }
      });
    }
  },
};
</script>
