import { FirestoreDB } from "./include/creds";
import {
  DocPath,
  DataProblem,
  GetDocResult,
  RemoveDocResult,
} from "./include/misc";

import { collection, doc, deleteDoc, setDoc, getDoc } from "firebase/firestore";

export type fs76RecyclerLocationInvitesSubcollection = {
  recycler_business_uid: string;
  recycler_location_uid: string;
  permissions: string;
  invite_time: Date;
  recycler_business_name: string;
  recycler_location_name: string;
  subcollection_doc_id: string
};

export function isValidData(
  data: fs76RecyclerLocationInvitesSubcollection
): boolean {
  return true;
}

export async function getDocFrom_fs76RecyclerLocationInvitesSubcollection(): Promise<GetDocResult> {
  const result: GetDocResult = {
    debug_data: [],
    return_msg:
      "fs76RecyclerLocationInvitesSubcollection:getDocFrom_fs76RecyclerLocationInvitesSubcollection",
    success: true,
    doc_data: {},
  };

  // TODO - impliment function
  return await Promise.resolve(result);
}

export async function setDocIn_fs76RecyclerLocationInvitesSubcollection(): Promise<DocPath> {
  const result: DocPath = {
    id: "",
    path: "",
  };


  // TODO - impliment function
  return await Promise.resolve(result);
}

export async function removeDocFrom_fs76RecyclerLocationInvitesSubcollection(
  fs5_email_id: string,
  fs76_id: string
): Promise<RemoveDocResult> {
  const result: RemoveDocResult = {
    debug_data: [],
    return_msg:
      "fs76RecyclerLocationInvitesSubcollection:removeDocFrom_fs76RecyclerLocationInvitesSubcollection ",
    success: true,
  };

  const doc_ref = doc(
    collection(
      FirestoreDB,
      "fs5RecyclerLocationInvites",
      fs5_email_id,
      "fs76RecyclerLocationInvitesSubcollection"
    ),

    fs76_id
  );

  await deleteDoc(doc_ref);
  return await Promise.resolve(result);
}
