<template>
  <div>
    <div id="p1s1c2c3" v-show="DV_rateConversation == 'hide'" class="mx-2">

      <!-- Heading -->
      <div class="flex flex-row items-center justify-between my-3">
        <svg @click="$emit('show-live-chat-action')" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"/>
        </svg>
        <h1 class="font-bold text-sm">Finish Conversation</h1>
        <svg @click="$emit('show-live-chat-action')" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"/>
        </svg>
      </div>

      <!-- Conversation info -->
      <div class="grid grid-cols-7 gap-2 px-2 items-center mb-3">
        <div class="col-span-1 h-full items-start">
          <h1 class="font-bold text-lg text-left">B Q</h1>
        </div>
        <div class="col-span-6">
          <p class="text-left text-sm my-1">
            John Miller | Downtown Repair | Mechanic
            <a class="underline text-blue-500" href="#">972-221-9715</a>
          </p>
        </div>
      </div>

      <!-- Back button -->
      <button @click="$emit('show-live-chat-action')" class="btn btn-lime my-3">
        Return To Live Chat
      </button>

      <p class="text-center my-3">
        Please rate the interactions you had with the customer during this
        conversation.
      </p>

      <!-- Rating button -->
      <div class="grid grid-cols-2 gap-4 my-3">
        <button @click="$emit('show-live-chat')" class="btn btn-lime">
          Amazing
        </button>
        <button @click="$emit('show-live-chat')" class="btn btn-lime">Good</button>
      </div>
      <div class="grid grid-cols-2 gap-4 my-3">
        <button @click="$emit('show-live-chat')" class="btn btn-lime">
          Average
        </button>
        <button @click="DV_rateConversation = 'show'" class="btn btn-lime">
          Below Average
        </button>
      </div>
      <div class="grid grid-cols-2 gap-4 my-3">
        <button @click="DV_rateConversation = 'show'" class="btn btn-lime">
          Bad
        </button>
      </div>
      <button @click="$emit('show-live-chat')" class="btn btn-lime my-3">
        I don't Want To Give A Rating
      </button>
    </div>

    <p1s1c2c7RateTheConversation
      v-show="DV_rateConversation == 'show'"
      @finish_conversation="DV_rateConversation = 'hide'"
      @show-live-chat="$emit('show-live-chat')"
    />
  </div>
</template>

<script>
import p1s1c2c7RateTheConversation from "./p1s1c2c7RateTheconversation.vue";

export default {
  name: "p1s1c2c3FinishConversation",

  components: { p1s1c2c7RateTheConversation},

  data() {
    return {
      DV_rateConversation: "hide",
    };
  },
};
</script>
