import { FirestoreDB } from "./include/creds";
import {
  DocPath,
  DataProblem,
  isPostalCodeValid,
  isEmailValid,
  RemoveDocResult,
  GetDocResult
} from "./include/misc";

import {
  getFirestore,
  CollectionReference,
  collection,
  DocumentData,
  doc,
  setDoc,
  deleteDoc,
  getDocs,
  getDoc,
} from "firebase/firestore";

export type fs30EmployeeAccountRecyclerJoins = {
  employee_uid: string;
  recycler_location_uid: string;
  business_uid: string;
};

// We assume that this function is fast and will not block.
export function isValidData(data: fs30EmployeeAccountRecyclerJoins): boolean {
  return true;
}

export async function setDocIn_fs30EmployeeAccountRecyclerJoins(
  documentId: string,
  key_ref_employee_uid: string,
  data: fs30EmployeeAccountRecyclerJoins
): Promise<DocPath> {
  const result: DocPath = {
    id: "",
    path: "",
  };
  if (!isValidData(data)) {
    return await Promise.resolve(result);
  }

  const fs29_Ref = collection(FirestoreDB, "fs29FirebaseAccounts");
  const doc_Ref1 = doc(fs29_Ref, documentId);

  const col = collection(doc_Ref1, "fs30EmployeeAccountRecyclerJoins");
  const docRef = doc(col, key_ref_employee_uid);
  await setDoc(docRef, data);

  result.id = docRef.id;
  result.path = docRef.path;

  return await Promise.resolve(result);
}

export async function removeDocFrom_fs30EmployeeAccountRecyclerJoins(
  documentId: string,
  key_ref_employee_uid: string,
): Promise<RemoveDocResult> {
  const result: RemoveDocResult = {
    debug_data: [],
    return_msg:
      "fs30EmployeeAccountRecyclerJoins:removeDocFrom_fs30EmployeeAccountRecyclerJoins ",
    success: true,
  };

  const fs29_Ref = collection(FirestoreDB, "fs29FirebaseAccounts");
  const doc_Ref1 = doc(fs29_Ref, documentId);

  const col = collection(doc_Ref1, "fs30EmployeeAccountRecyclerJoins");
  const docRef = doc(col, key_ref_employee_uid);
  await deleteDoc(docRef);

  return await Promise.resolve(result);
}

export async function getDocFrom_fs30EmployeeAccountRecyclerJoins(
): Promise<GetDocResult> {
  const result: GetDocResult = {
    debug_data: [],
    return_msg: "fs30EmployeeAccountRecyclerJoins:getDocFrom_fs30EmployeeAccountRecyclerJoins",
    success: true,
    doc_data: {},
  };

  // TODO implement the body.

  return await Promise.resolve(result);
}
