
export default {
  data() {
    return {      
    DV_renderModeDesktop: window.G_render_mode_desktop,
    DV_renderModeDesktopNarrow: window.G_render_mode_desktop_narrow,
    DV_renderModeTablet: window.G_render_mode_tablet,
    DV_renderModePhone: window.G_render_mode_phone,
    DV_activeRenderMode: 0,
    DV_showUnfinishedFeatures: window.G_show_unfinished_features,
    }
  },
  mounted() {
    this.DV_activeRenderMode = window.G_active_render_mode;
  }
}
