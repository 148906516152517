/* eslint-disable */
import { ajax } from "noquery-ajax";
import { httpsLogger } from "../p1s7/p1s7e1";

function createEmployeeInvite(
  recycler_uid: string,
  recycler_location_uid: string,
  employee_email: string,
  first_name: string,
  last_name: string,
  permissions: {
    admin?: boolean,
    manager?: boolean,
    report_viewer?: boolean,
    sales_person?: boolean
  },
) {
  return new Promise(function (resolve, reject) {
    let permissions_string = "";
    if (permissions.admin === true) {
      permissions_string += "a";
    }
    if (permissions.manager === true) {
      permissions_string += "b";
    }
    if (permissions.sales_person === true) {
      permissions_string += "c";
    }
    if (permissions.report_viewer === true) {
      permissions_string += "d";
    }

    var params = {
      firebase_uid: window.G_firebase_auth.IV_uid,
      firebase_token: window.G_firebase_auth.IV_id_token,
      firebase_email: window.G_firebase_auth.IV_email_address,
      recycler_uid: recycler_uid,
      recycler_location_uid: recycler_location_uid,
      p1s3e18_employee_email: employee_email,
      p1s3e18_employee_first_name: first_name,
      p1s3e18_employee_last_name: last_name,
      p1s3e18_permissions: permissions_string,
    };

    ajax({
      url: window.G_cloud_p1s3_base_url + "p1s3e18CreateEmployeeInvite",
      headers: {
        Accept: "application/json",
      },
      method: "POST",
      dataType: "json",
      data: params,

      success: function (result) {
        let response_data = {
          success: true,
          response: result,
          request_params: params
        }
        resolve(response_data);
      },

      error: function (error) {
        let error_data = {
          success: false,
          request_object: error,
          request_params: params,
          file_name: "p1s3e18.ts",
          class_name: "p1s3e18",
          method_name: "createEmployeeInvite",
          error_title: "Failed to create an employee invite",
          error_description:
            "The server responded with an error when trying to create an employee invite",
        };

        httpsLogger(error_data);
        reject(error_data);
      },
    });
  });
}

export default createEmployeeInvite;
