import { fs6ConsumerConversations } from "../firestore_schemas/fs6ConsumerConversations"
import { getDocFrom_fs6ConsumerConversations } from "../firestore_schemas/fs6ConsumerConversations"
import { setDocIn_fs6ConsumerConversations } from "../firestore_schemas/fs6ConsumerConversations"
import { removeDocFrom_fs6ConsumerConversations } from "../firestore_schemas/fs6ConsumerConversations"

type Result = {
  debug_data: Array<string>;
  return_msg: string;
  success: boolean;
  error_title: string;
  error_summary: string;
  error_next_steps: string;
};

export async function fs6Writer(
  w_type: string,
  fs1_id: string,
  fs2_id: string,
  fs4_id: string,
  fs6_id: string,
  data: any
) {

  const retval: Result = {
    debug_data: [],
    return_msg: "fs6_writer:fs6Writer ",
    success: true,
    error_title: "",
    error_summary: "",
    error_next_steps: "",
  };

  /// Validate arguments according to write type
  if (!fs1_id || !fs2_id || !fs4_id || !fs6_id) {
    retval.success = false;
    retval.return_msg += "Insufficient arguments.";
    retval.error_title = "Insufficient arguments.";
    retval.error_summary = "business_uid or recycler_uid not set.";
    retval.error_next_steps = "Please provide correct business_uid snd recycler_uid.";
    return retval;
  }
  /// <END> Validate arguments according to write type

  // Delete record
  if (w_type == "d") {
    await removeDocFrom_fs6ConsumerConversations(fs1_id, fs2_id, fs4_id, fs6_id)
    return retval
  }

  // Create new record
  if (w_type == "e") {
    let response_data = await getDocFrom_fs6ConsumerConversations(
      fs1_id,
      fs2_id,
      fs4_id,
      fs6_id
    )

    if (response_data.success === false) {
      retval.success = false;
      retval.return_msg += "Record not found.";
      retval.error_title = "Record not found.";
      retval.error_summary = "FS6 record not found in firestore.";
      retval.error_next_steps = "Please reload page and try again.";
      return retval;
    }

    let existing_data = response_data["doc_data"];

    //// Calculate updated status_codes
   
    
    if(data.conversation_status_codes) {
      var new_status_codes_value = data.conversation_status_codes;
      new_status_codes_value += response_data['doc_data'].conversation_status_codes || "";
      new_status_codes_value = Array.from(new Set(new_status_codes_value.split(''))).join('');
      // If the new value for conversation is queued, remove the `b`, `z`
      if (data.conversation_status_codes.includes("a")) {
        new_status_codes_value = new_status_codes_value.replaceAll('b', '').replace('z', '');
      }

      // If the new value for conversation is started, remove the `a`, `z`
      if (data.conversation_status_codes.includes("b")) {
        new_status_codes_value = new_status_codes_value.replaceAll('a', '').replace('z', '');
      }

      // If the new value for conversation is finished, remove the `b`, `a`
      if (data.conversation_status_codes.includes("z")) {
        new_status_codes_value = new_status_codes_value.replaceAll('a', '').replace('b', '');
      }
    }
    //// </end> Calculate updated status_codes

    //// Calculate updated recycler_only_chat_employee_uid_list
    let existing_recycler_only_chat_employee_uid_list = existing_data.recycler_only_chat_employee_uid_list || [];
    let new_recycler_only_chat_employee_uid_list = data.recycler_only_chat_employee_uid_list || [];

    // Remove with deleted-flag from existing entries
    new_recycler_only_chat_employee_uid_list.forEach((uid: string) => {
      if (uid.includes("deleted-")) {
        let index = existing_recycler_only_chat_employee_uid_list.findIndex(uid.replace("deleted-",""))
        if (index > -1) {
          existing_recycler_only_chat_employee_uid_list.splice(index, 1);
        }
      }
    })
    
    // Remove with deleted-flag from new entries
    new_recycler_only_chat_employee_uid_list = new_recycler_only_chat_employee_uid_list.filter((uid: string) => !uid.includes("deleted-"))
    let combined_recycler_only_chat_employee_uid_list = existing_recycler_only_chat_employee_uid_list.concat(new_recycler_only_chat_employee_uid_list)
    combined_recycler_only_chat_employee_uid_list = Array.from(new Set(combined_recycler_only_chat_employee_uid_list));
    //// </end> Calculate updated recycler_only_chat_employee_uid_list


    //// Calculate updated invited_employee_only_chat_uid_list
    let existing_invited_employee_only_chat_uid_list = response_data['doc_data'].invited_employee_only_chat_uid_list || [];
    let new_invited_employee_only_chat_uid_list = data.invited_employee_only_chat_uid_list || [];

    // Remove with deleted-flag from existing entries
    new_invited_employee_only_chat_uid_list.forEach((uid: string) => {
      if (uid.includes("deleted-")) {
        let index = existing_invited_employee_only_chat_uid_list.findIndex((id: string) => { return id == uid.replace("deleted-","")} )
        if (index > -1) {
          existing_invited_employee_only_chat_uid_list.splice(index, 1);
        }
      }
    })
    
    // Remove with deleted-flag from new entries
    new_invited_employee_only_chat_uid_list = new_invited_employee_only_chat_uid_list.filter((uid: string) => { return !uid.includes("deleted-") })
    let combined_invited_employee_only_chat_uid_list = existing_invited_employee_only_chat_uid_list.concat(new_invited_employee_only_chat_uid_list)
    combined_invited_employee_only_chat_uid_list = Array.from(new Set(combined_invited_employee_only_chat_uid_list));
    //// </end> Calculate updated invited_employee_only_chat_uid_list

    const fs6Data: fs6ConsumerConversations = {
      conversation_uid: fs6_id,
      conversation_invite_url: data.conversation_invite_url || response_data['doc_data'].conversation_invite_url,
      conversation_type: data.conversation_type || response_data['doc_data'].conversation_type,
      conversation_status_codes: new_status_codes_value || response_data['doc_data'].conversation_status_codes,
      phone_number: data.phone_number || response_data['doc_data'].phone_number,
      conversation_employee_rating: data.conversation_employee_rating || response_data['doc_data'].conversation_employee_rating,
      conversation_consumer_rating: data.conversation_consumer_rating || response_data['doc_data'].conversation_consumer_rating,
      conversation_consumer_review: data.conversation_consumer_review || response_data['doc_data'].conversation_consumer_review,
      assigned_employee_uid: data.assigned_employee_uid || response_data['doc_data'].assigned_employee_uid,
      called_automated_phone_system_timestamp: data.called_automated_phone_system_timestamp || response_data['doc_data'].called_automated_phone_system_timestamp,
      entered_queue_timestamp: data.entered_queue_timestamp || response_data['doc_data'].entered_queue_timestamp,
      chat_start_timestamp: data.chat_start_timestamp || response_data['doc_data'].chat_start_timestamp,
      chat_finished_timestamp: data.chat_finished_timestamp || response_data['doc_data'].chat_finished_timestamp,
      recycler_only_chat_employee_uid_list: combined_recycler_only_chat_employee_uid_list,
      invited_employee_only_chat_uid_list: combined_invited_employee_only_chat_uid_list,
    } as fs6ConsumerConversations;

    let response = await setDocIn_fs6ConsumerConversations(
      fs1_id,
      fs2_id,
      fs4_id,
      fs6_id,
      fs6Data
    )

    if (!response.id || response.id === "" ) {
      retval.success = false;
      retval.return_msg += "Firestore write error.";
      retval.error_title = "Firestore write error.";
      retval.error_summary = "FS6 record not updated in firestore.";
      retval.error_next_steps = "Please reload page and try again.";
      return retval;
    }

    return retval
  }

  // TODO - check if we need new?
  // TODO - handle new operations
}

function firestoreWriteError(error:any) {
  // TODO - complete this
}
