import { FirestoreDB } from "./include/creds";
import {
  DocPath,
  DataProblem,
  RemoveDocResult,
  GetDocResult,
} from "./include/misc";

import {
  collection,
  doc,
  setDoc,
  getDoc,
  deleteDoc,
  updateDoc
} from "firebase/firestore";

export type fs83PaymentApiKeys = {
  business_uid: string;
  client_id?: string;
  payment_processor_id: number;
  price_id?: string;
  private_api_key?: string;
  public_api_key?: string;
  recycler_location_uid: string;
  site_id?: string;
  axe_public_api_key_reference?: string;
  axe_private_api_key_reference?: string;
};

export function getDataProblems_fs83PaymentApiKeys(
  data: fs83PaymentApiKeys
): DataProblem[] {
  const dataproblems: DataProblem[] = [];
  return dataproblems;
}

// We assume that this function is fast and will not block.
export function isValidData(data: fs83PaymentApiKeys): boolean {
  return true;
}

export async function getDocFrom_fs83PaymentApiKeys(
  fs83_id: string,
): Promise<GetDocResult> {
  const result: GetDocResult = {
    debug_data: [],
    return_msg: "fs83PaymentApiKeys:getDocFrom_fs83PaymentApiKeys",
    success: true,
    doc_data: {},
  };

  if (!fs83_id) {
    result.success = false;
    result.return_msg += "Invalid function arguments.";
    return await Promise.resolve(result);
  }

  const doc_ref = doc(
    collection(
      FirestoreDB,
      "fs83PaymentApiKeys"
    ),
    fs83_id
  );

  const doc_data = (await getDoc(doc_ref)).data();
  if (doc_data === undefined) {
    result.success = false;
    result.return_msg +=
      "Could not find a document with the passed-in path arguments.";
    return await Promise.resolve(result);
  }

  result.doc_data = doc_data;

  
  return await Promise.resolve(result);
}

export async function addDocIn_fs83PaymentApiKeys(
  data: fs83PaymentApiKeys
): Promise<DocPath> {
  const result: DocPath = {
    id: "",
    path: "",
  };
  if (!isValidData(data)) {
    return await Promise.resolve(result);
  }

  const doc_ref = doc(collection(FirestoreDB, "fs83PaymentApiKeys"))
  await setDoc(doc_ref, data);

  result.id = doc_ref.id;
  result.path = doc_ref.path;

  //set id to fs_2 record
  const doc_ref1 = doc(collection(FirestoreDB, "fs1RecyclerBusiness", data.business_uid, "fs2RecyclerLocation"), data.recycler_location_uid)
  await updateDoc(doc_ref1, {payment_api_keys_lookup_uid: doc_ref.id});
  
  
  // TODO - finish function body.
  return await Promise.resolve(result);
}

export async function setDocIn_fs83PaymentApiKeys(
  fs83_id: string,
  data: fs83PaymentApiKeys
): Promise<DocPath> {
  const result: DocPath = {
    id: "",
    path: "",
  };
  
  if (!isValidData(data)) {
    return await Promise.resolve(result);
  }

  const fs83_Ref = collection(FirestoreDB, "fs83PaymentApiKeys");
  const doc_Ref1 = doc(fs83_Ref, fs83_id);

  await setDoc(doc_Ref1, data);

  result.id = doc_Ref1.id;
  result.path = doc_Ref1.path;

  return await Promise.resolve(result);
}

export async function removeDocFrom_fs83PaymentApiKeys(
  fs83_id: string,
): Promise<RemoveDocResult> {
  const result: RemoveDocResult = {
    debug_data: [],
    return_msg: "fs83PaymentApiKeys:removeDocFrom_fs83PaymentApiKeys ",
    success: true,
  };

  // TODO - finish function body.
  return await Promise.resolve(result);
}
