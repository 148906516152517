/* eslint-disable */
import { ajax } from "noquery-ajax";
import { httpsLogger } from "../p1s7/p1s7e1";

interface Params {
  firebase_uid: any;
  firebase_token: any;
  recycler_uid: string;
  recycler_location_uid: string;
  p1s3e49_contacts_list: any;
  p1s3e49_spreadsheet_content: string;
  p1s3e49_file_name: string;
}

function importConsumerContacts(
  recycler_uid: string,
  recycler_location_uid: string,
  contacts_list: any,
  spreadsheet_content: string,
  file_name: string,
) {
  return new Promise(function (resolve, reject) {
    let params: Params = {
      firebase_uid: window.G_firebase_auth.IV_uid,
      firebase_token: window.G_firebase_auth.IV_id_token,
      recycler_uid: recycler_uid,
      recycler_location_uid: recycler_location_uid,
      p1s3e49_contacts_list: contacts_list,
      p1s3e49_spreadsheet_content: spreadsheet_content,
      p1s3e49_file_name: file_name,
    };

    ajax({
      url: window.G_cloud_p1s3_base_url + "p1s3e49ImportConsumerContacts",
      headers: {
        Accept: "application/json",
      },
      method: "POST",
      dataType: "json",
      data: params,
      success: function (result) {
        let response_data = {
          success: true,
          response: result,
          request_params: params
        }
        resolve(response_data);
      },
      error: function (error) {
        let error_data = {
          success: false,
          request_object: error,
          request_params: params,
          file_name: "p1s3e49.ts",
          class_name: "p1s3e49",
          method_name: "importConsumerContacts",
          error_title: "Failed to import consumer contacts.",
          error_description: "The server responded with an error when importing consumer contacts.",
        };

        httpsLogger(error_data);
        reject(error_data);
      },
    });
  });
}

export default importConsumerContacts;
