import { FirestoreDB } from "./include/creds";
import {
  DocPath,
  DataProblem,
  RemoveDocResult,
  GetDocResult,
} from "./include/misc";

import {
  collection,
  doc,
  setDoc,
  getDoc,
  deleteDoc,
} from "firebase/firestore";

export type fs130RecyclerTermsAndConditions = {
  type_number: number;
  type_description: string;
  transaction_terms_and_conditions: string;
};

export function getDataProblems_fs130RecyclerTermsAndConditions(
  data: fs130RecyclerTermsAndConditions
): DataProblem[] {
  const dataproblems: DataProblem[] = [];
  return dataproblems;
}

// We assume that this function is fast and will not block.
export function isValidData(data: fs130RecyclerTermsAndConditions): boolean {
  return true;
}

export async function getDocFrom_fs130RecyclerTermsAndConditions(
  fs1_id: string,
  fs2_id: string,
  type_number: number
): Promise<GetDocResult> {
  const result: GetDocResult = {
    debug_data: [],
    return_msg: "fs130RecyclerTermsAndConditions:getDocFrom_fs130RecyclerTermsAndConditions",
    success: true,
    doc_data: {},
  };

  if (!fs1_id || !fs2_id || !type_number) {
    result.success = false;
    result.return_msg += "Invalid function arguments.";
    return await Promise.resolve(result);
  }

  const collectionRef = collection(FirestoreDB, "fs130RecyclerTermsAndConditions")

  const doc_ref = doc(collectionRef, fs1_id + "-" + fs2_id + "-" + type_number);
  const doc_data = (await getDoc(doc_ref)).data();
  if (doc_data === undefined) {
    result.success = false;
    result.return_msg +=
      "Could not find a document with the passed-in path arguments.";
    return await Promise.resolve(result);
  }

  result.doc_data = doc_data;
  return await Promise.resolve(result);
}

export async function addDocIn_fs130RecyclerTermsAndConditions(
  fs1_id: string,
  fs2_id: string,
  type_number: number,
  data: fs130RecyclerTermsAndConditions
): Promise<DocPath> {
  const result: DocPath = {
    id: "",
    path: "",
  };
  if (!isValidData(data)) {
    return await Promise.resolve(result);
  }

  const collectionRef = collection( FirestoreDB, "fs130RecyclerTermsAndConditions")

  const doc_ref = doc(collectionRef, fs1_id + "-" + fs2_id + "-" + type_number);

  await setDoc(doc_ref, data);

  result.id = doc_ref.id;
  result.path = doc_ref.path;
  return await Promise.resolve(result);
}

export async function setDocIn_fs130RecyclerTermsAndConditions(
  fs1_id: string,
  fs2_id: string,
  type_number: number,
  data: fs130RecyclerTermsAndConditions
): Promise<DocPath> {
  const result: DocPath = {
    id: "",
    path: "",
  };

  if (!isValidData(data)) {
    return await Promise.resolve(result);
  }

  const collectionRef = collection( FirestoreDB, "fs130RecyclerTermsAndConditions")

  const doc_ref = doc(collectionRef, fs1_id + "-" + fs2_id + "-" + type_number);
  await setDoc(doc_ref, data);

  result.id = doc_ref.id;
  result.path = doc_ref.path;
  return await Promise.resolve(result);
}

export async function removeDocFrom_fs130RecyclerTermsAndConditions(
  fs1_id: string,
  fs2_id: string,
  type_number: number,
): Promise<RemoveDocResult> {
  const result: RemoveDocResult = {
    debug_data: [],
    return_msg: "fs130RecyclerTermsAndConditions:removeDocFrom_fs130RecyclerTermsAndConditions ",
    success: true,
  };

  if (!fs1_id || !fs2_id || !type_number) {
    result.success = false;
    result.return_msg += "Invalid function arguments.";
    return await Promise.resolve(result);
  }

  const doc_ref = doc(
    collection(
      FirestoreDB,
      "fs130RecyclerTermsAndConditions",
      fs1_id + "-" + fs2_id + "-" + type_number
    )
  );

  await deleteDoc(doc_ref);
  return await Promise.resolve(result);
}
