<template>
  <div id="p1s1c2c5">

    <!-- Conversation info -->
    <div class="grid grid-cols-7 gap-2 px-2 items-center cursor-pointer border-b">
      <div class="col-span-1 h-full items-start">
        <h1 class="font-bold text-lg text-left">B Q</h1>
      </div>
      <div class="col-span-6">
        <p class="text-left text-sm my-1">
          John Miller | Downtown Repair | Body Shop | Quotes: 12435653($1000)
          132456($100)
        </p>
      </div>
    </div>

    <div v-show="DV_newPaymentLink == 'hide'">

      <!-- Heading -->
      <div class="grid grid-cols-3 items-center my-3 gap-2 mx-2">
        <h1 class="col-start-2 col-end-3 font-bold text-sm">Payment Links</h1>
        <div class="col-span-1 flex h-full justify-end items-center">
          <svg @click="$emit('show-live-chat-action')" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"/>
          </svg>
        </div>
      </div>

      <!-- Payment links list -->
      <div class="border border-gray-400 rounded-xl p-2 mt-3">
        <div class="border border-gray-400 rounded-xl overflow-hidden overflow-y-scroll">
          <div
            v-for="(paymentLink, index) in DV_paymentLinksList"
            :key="paymentLink.index"
            @click="toggleMessages(index)"
            :class="{ active: DV_paymentlist[index] == true }"
            class="border-b border-gray-400 p-2"
          >
            <p class="font-medium text-left text-sm paymentDescription">
              {{ paymentLink.description }}
            </p>
            <p class="font-medium text-left text-sm paymentAmountStatus">
              {{ paymentLink.amount }}
            </p>
            <div v-show="DV_paymentlist[index] == true" class="grid grid-cols-2 gap-2 items-center mt-3">
              <button type="button" @click.stop="modifyLink(index)" class="btn btn-lime">
                Modify
              </button>
              <button type="button" @click.stop="cancelPaymentLink(index)" class="btn btn-lime">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- New payment link button -->
      <div class="px-2 my-5">
        <button type="button" @click.stop="createNewPaymentLink" class="btn btn-lime">
          Create New Payment Link
        </button>
      </div>
    </div>

    <p1s1c2c6NewPaymentLink
      v-if="DV_newPaymentLink == 'show'"
      :editPaymetLink="DV_modifyPaymetLink"
      :newPaymentIndex="DV_createNewLink"
      @payment-links-list="DV_newPaymentLink = 'hide'"
      @updateListItem="checkForUpdate"
      @addNewListItem="addNewPaymentLink"
    />
  </div>
</template>

<script>
import p1s1c2c6NewPaymentLink from "./p1s1c2c6NewPaymentLink.vue";

export default {
  name: "p1s1c2c5PaymentLinks",

  components: { p1s1c2c6NewPaymentLink },

  data() {
    return {
      DV_paymentDescription: "",
      DV_paymentAmountStatus: "",
      DV_paymentlist: [],
      DV_newPaymentLink: "hide",
      DV_paymentLinksList: [
        {
          index: 0,
          amount: "Pending",
          description: "Honda Civic 2003 Exaust pipe",
        },
      ],
      DV_modifyPaymetLink: {},
      DV_createNewLink: false,
    };
  },

  methods: {
    createNewPaymentLink() {
      this.DV_newPaymentLink = "show";
      this.DV_createNewLink = true;
    },

    checkForUpdate(data) {
      this.DV_paymentLinksList[data.index] = data;
      this.$forceUpdate();
      this.DV_newPaymentLink = "hide";
    },

    modifyLink(index) {
      (this.DV_paymentDescription =
        document.getElementsByClassName("paymentDescription")[index].innerText),
        (this.DV_paymentAmountStatus = document.getElementsByClassName(
          "paymentAmountStatus"
        )[index].innerText),
        (this.DV_modifyPaymetLink = this.DV_paymentLinksList[index]);
      this.DV_newPaymentLink = "show";
      this.$forceUpdate();
    },

    toggleMessages(index) {
      this.DV_paymentlist[index] = !this.DV_paymentlist[index];
      this.$forceUpdate();
    },

    addNewPaymentLink(data) {
      let maxIndex = Math.max(...this.DV_paymentLinksList.map((o) => o.index));
      data.index = maxIndex + 1;
      this.DV_paymentLinksList.push(data);
      this.DV_newPaymentLink = "hide";
      (this.DV_createNewLink = false), this.$forceUpdate();
    },

    cancelPaymentLink(index) {
      let itemIndex = this.DV_paymentLinksList.findIndex(
        (paymentLink) => paymentLink.index == index
      );
      this.DV_paymentLinksList.splice(itemIndex, 1);
      this.$forceUpdate();
    },
  },
};
</script>
