<template>
    <div>
      <h2 class="text-2xl font-bold text-center">Synergy Auto Solutions: Legal Documents</h2>
      <ol class="list-number text-left">
        <li>
          <a class="underline text-blue-500 block cursor-pointer" @click.stop="downloadDocument('Terms of Service', 'https://storage.googleapis.com/synergy-auto-solutions-public-documents/recycler_staff_website/legal_documents/Terms_of_Service.pdf')">Terms of Service</a>
        </li>
        <li class="!mt-0">
          <a class="underline text-blue-500 block cursor-pointer" @click.stop="downloadDocument('Acceptable Use Policy', 'https://storage.googleapis.com/synergy-auto-solutions-public-documents/recycler_staff_website/legal_documents/Acceptable_Use_Policy.pdf')">Acceptable Use Policy</a>
        </li>
        <li class="!mt-0">
          <a class="underline text-blue-500 cursor-pointer" @click.stop="downloadDocument('Payment Service Terms', 'https://storage.googleapis.com/synergy-auto-solutions-public-documents/recycler_staff_website/legal_documents/Payment_Service_Terms.pdf')">Payment Service Terms</a>
        </li>
        <li class="!mt-0">
          <a class="underline text-blue-500 cursor-pointer" @click.stop="downloadDocument('Privacy Policy', 'https://storage.googleapis.com/synergy-auto-solutions-public-documents/recycler_staff_website/legal_documents/Privacy_Policy.pdf')">Privacy Policy</a>
        </li>
      </ol>
    </div>
  </template>
  
  <script>
  import generalFunctions from "../../mixins/generalFunctions.js";

  export default {
    name: "p5s6legalDocuments",

    mixins: [ generalFunctions ]
  }
  </script>