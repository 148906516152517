<template>
  <div id="p4s1" class="h-full">


    <div v-if="!C_showSettingsForm">
      <!-- Header - logo + menu button -->
      <div class="p-8">
        <c9MainMenu P_customClass="p4s1-c9-container" P_activePage="settings"></c9MainMenu>
      </div>

      <!-- Authenticating / Loading businesses -->
      <div v-if="DV_isCheckingAuthentication || DV_loadingBusinesses" class="bg-white w-6/12 mx-auto mt-40 border border-gray-400 rounded p-12">
        Loading account information...
      </div>

      <!-- No business view -->
      <div v-else-if="DV_noBusinessFound">
        <div class="flex items-center justify-end mt-5 mr-5">
          <button class="btn btn-lime px-3" @click="logoutUser()">Logout</button>
        </div>

        <div class="flex flex-col items-center w-6/12 mx-auto mt-40 border border-gray-400 rounded p-12">
          Your account is not associated with any recycler business. If you own a recycler business please click below to setup your recycler business admin account.
          <button @click.stop="redirectToAccountSetup" class="btn btn-lime text-sm mr-3 w-48 mt-12">Setup Recycler Business</button>
        </div>
      </div>
    </div>   

    <!-- Main content -->
    <div v-if="C_showSettingsForm" class="m-5 grid grid-cols-10 gap-4">
      
      <!-- Side Menu -->
      <div class="col-span-3">
        <!-- Header - logo + menu button -->
        <c9MainMenu P_activePage="settings"></c9MainMenu>

        <h1 class="font-bold text-2xl text-left mt-5">Settings</h1>
        <p class="text-left mb-1">{{C_employeeEmail || ""}}</p>

        <ul class="flex flex-col">
          <li class="mt-0" v-if="C_isEmployeeAdmin || DV_isEmployeeOwner">
            <a
              @click.stop="DV_activeTab = 'business'; DV_activeSubPage = 'p4s2BusinessInformation'"
              :class="{ active: DV_activeTab == 'business' }"
              class="cursor-pointer relative inline-flex items-center w-full justify-start rounded-tl-xl rounded-tr-xl border border-gray-300 px-4 py-2 text-sm font-bold text-gray-700 hover:bg-gray-50 focus:text-white focus:z-10 focus:bg-blue-900 focus:outline-none focus:bg-blue-900"
              >Business Information</a
            >
          </li>
          <li class="mt-0" v-if="C_isEmployeeAdmin || DV_isEmployeeOwner">
            <a
              @click.stop="DV_activeTab = 'integrations'; DV_activeSubPage = 'p4s3Integrations'"
              :class="{ active: DV_activeTab == 'integrations' }"
              class="cursor-pointer relative inline-flex items-center w-full justify-start border border-gray-300 px-4 py-2 text-sm font-bold text-gray-700 hover:bg-gray-50 focus:text-white focus:z-10 focus:bg-blue-900 focus:outline-none focus:bg-blue-900"
              >Integrate With Synergy</a
            >
          </li>
          <li class="mt-0">
            <a
              @click.stop="DV_activeTab = 'account'; DV_activeSubPage = 'p4s1AccountSetting'"
              :class="{ active: DV_activeTab == 'account' }"
              class="cursor-pointer relative inline-flex items-center w-full justify-start rounded-bl-xl rounded-br-xl border border-gray-300 px-4 py-2 text-sm font-bold text-gray-700 hover:bg-gray-50 focus:text-white focus:z-10 focus:bg-blue-900 focus:outline-none focus:bg-blue-900"
              >Account Settings</a
            >
          </li>
        </ul>        
      </div>
      
      <!-- Main container -->
      <div v-if="C_showSettingsForm" style="height: 95vh; overflow: scroll" class="col-span-7 p-12 border border-gray-400 rounded-xl !overflow-x-hidden hide-scrollbar">
        <component
          :is="DV_activeSubPage"
        ></component>
      </div>
    </div>
  </div>
</template>

<script>
import p4s1AccountSetting from "../components/p4/p4s1AccountSetting"
import p4s2BusinessInformation from "../components/p4/p4s2BusinessInformation"
import p4s3Integrations from "../components/p4/p4s3Integrations"
import c9MainMenu from "../components/shared/c9MainMenu"

export default {
  name: "p4SettingsView",

  props: [],

  components: {
    p4s1AccountSetting,
    p4s2BusinessInformation,
    p4s3Integrations,
    c9MainMenu
  },

  data() {
    return {
      DV_activeTab: "business",
      DV_activeSubPage: "p4s2BusinessInformation",
      DV_isCheckingAuthentication: true,
      DV_noBusinessFound: false,
      DV_loadingBusinesses: true,
      DV_employeePermissions: {},
      DV_isEmployeeOwner: {},
      DV_employeeInfo: {},
    }
  },

  mounted() {
    const CI = this;

    CI.authenticationCheck(0);
    CI.DV_employeeInfo = window.G_bi2_data.IV_active_employee_info;
    CI.DV_employeePermissions = window.G_bi2_data.IV_active_employee_info.permissions;
  },

  computed: {
    C_showSettingsForm() {
      return !(this.DV_loadingBusinesses || this.DV_isCheckingAuthentication || this.DV_noBusinessFound)
    },

    C_isEmployeeAdmin() {
      return this.DV_employeePermissions.admin === true;
    },

    C_employeeEmail() {
      let activeEmployeeUid = window.G_bi2_data.IV_active_employee_info.employee_uid;
    
      if (activeEmployeeUid in window.G_bi2_data.IV_recycler_employees.value === true) {
        let employeeInfo = window.G_bi2_data.IV_recycler_employees.value[activeEmployeeUid];

        return employeeInfo.email_address;
      }

      return "";
    }
  },

  methods: {
    authenticationCheck(retry_count=0) {
      const CI = this;

      if (retry_count >= 40 && window.location.pathname !== "/login" && window.location.pathname !== "/") {
          location.assign(window.location.origin + `/login`);
        return;
      }

      if (window.G_firebase_auth.IV_is_user_logged_in !== true) {
        setTimeout( function() {CI.authenticationCheck(++retry_count)}, 100);
        return
      };

      CI.DV_isCheckingAuthentication = false;
      CI.dataLoadedCheck(0);
    },

    dataLoadedCheck(retry_count=1) {
      const CI = this;

      if (retry_count >= 40 &&
        window.location.pathname !== "/login" && 
        window.location.pathname !== "/" && 
        window.location.pathname !== "/account-setup"
     ) {
      CI.DV_noBusinessFound = true;
      CI.DV_loadingBusinesses = false;
      return;
    }

      if (!window.G_bi2_data.IV_active_employee_info.business_uid || !window.G_bi2_data.IV_active_employee_info.recycler_location_uid) {
        setTimeout( function() {CI.dataLoadedCheck(++retry_count)}, 500);
        return
      };

      CI.DV_noBusinessFound = false;
      CI.DV_loadingBusinesses = false;
    }
  },

  watch: {
    DV_employeeInfo: {
      handler() {
        const CI = this;
        CI.DV_employeePermissions = window.G_bi2_data.IV_active_employee_info.permissions;
        CI.DV_isEmployeeOwner = window.G_bi2_data.IV_active_employee_info.is_business_owner === true;
      },
      deep: true
    }
  }
}
</script>

<style>
.p4s1-c9-container img {
  width: 10%;
}
</style>