<template>
  <div id="p1s2c6">
    <div class="p-3">

      <!-- Start time filter -->
      <div class="flex items-center justify-start mb-2">
        <label for="startDate" class="font-medium text-sm text-left mr-1"
          >Start Date:
        </label>
        <input v-model="DV_startDate" id="startDate" type="date" name="startDate"/>
        <!-- <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
          />
        </svg> -->
      </div>

      <!-- End time filter -->
      <div class="flex items-center justify-start mb-5">
        <label for="endDate" class="font-medium text-sm text-left mr-2"
          >End Date:
        </label>
        <input v-model="DV_endDate" id="endDate" type="date" name="endDate"/>
<!--         <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
          />
        </svg> -->
      </div>

      <!-- Customer type filters -->
      <div class="">
        <h1 class="text-sm font-medium text-left mb-2">Customer Type</h1>
        <span class="isolate inline-flex rounded-md shadow-sm w-full mb-2">
          <button type="button" class="filter-button left">
            Mechanic
          </button>
          <button type="button" class="filter-button">
            Body Shop
          </button>
          <button type="button" class="filter-button right">
            Reseller
          </button>
        </span>
        <span class="isolate inline-flex rounded-md shadow-sm w-full mb-2">
          <button type="button" class="filter-button left">
            Retail
          </button>
          <button type="button" class="filter-button">
            Insurance Adjuster
          </button>
          <button type="button" class="filter-button right">
            Exporter
          </button>
        </span>
      </div>

      <!-- Customer rating filters -->
      <div class="my-5">
        <h1 class="text-sm font-medium text-left mb-1">Customer Rating</h1>
        <span class="isolate inline-flex rounded-md shadow-sm w-full mb-2">
          <button type="button" class="filter-button left">
            Any
          </button>
          <button type="button" class="filter-button">
            Amazing
          </button>
          <button type="button" class="filter-button right">
            Good
          </button>
        </span>
        <span class="isolate inline-flex rounded-md shadow-sm w-full mb-2">
          <button type="button" class="filter-button left">
            Average
          </button>
          <button type="button" class="filter-button">
            Below Average
          </button>
          <button type="button" class="filter-button right">
            Bad
          </button>
        </span>
      </div>

      <!-- Sales persons list -->
      <div class="flex items-center justify-between">
        <label for="salePerson" class="font-medium text-sm text-left mr-1">
          Sales Person:
        </label>
        <div class="w-5/12">
          <select id="salePerson" name="salePerson" class="dropdown-select">
            <option>All</option>
            <option>Web Widget</option>
            <option>Sales Person Initiated</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "p1s2c6QueueResponseTimeFilters",

  data() {
    return {
      DV_startDate: "",
      DV_endDate: "",
    };
  },
};
</script>
