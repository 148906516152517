import { FirestoreDB } from "./include/creds";
import {
  DocPath,
  DataProblem,
  RemoveDocResult,
  GetDocResult,
} from "./include/misc";

import {
  collection,
  doc,
  setDoc,
  getDoc,
  deleteDoc,
} from "firebase/firestore";

export type fs1RecyclerBusiness = {
  business_uid: string;
  business_name: string;
  is_archived?: boolean;
  phone?: string;
  owner_email?: string;
  owner_firebase_uid?: string;
  billing_method_uid?: string;
};

export function getDataProblems_fs1RecyclerBusiness(
  data: fs1RecyclerBusiness
): DataProblem[] {
  const dataproblems: DataProblem[] = [];
  return dataproblems;
}

// We assume that this function is fast and will not block.
export function isValidData(data: fs1RecyclerBusiness): boolean {
  return true;
}

export async function getDocFrom_fs1RecyclerBusiness(
  fs1_id: string
): Promise<GetDocResult> {
  const result: GetDocResult = {
    debug_data: [],
    return_msg: "fs1RecyclerBusiness:getDocFrom_fs1RecyclerBusiness",
    success: true,
    doc_data: {},
  };

  if (!fs1_id) {
    result.success = false;
    result.return_msg += "Invalid function arguments.";
    return await Promise.resolve(result);
  }

  const doc_ref = doc(collection(FirestoreDB, "fs1RecyclerBusiness"), fs1_id);

  const doc_data = (await getDoc(doc_ref)).data();
  if (doc_data === undefined) {
    result.success = false;
    result.return_msg +=
      "Could not find a document with the passed-in path arguments.";
    return await Promise.resolve(result);
  }

  result.doc_data = doc_data;
  return await Promise.resolve(result);
}

export async function addDocIn_fs1RecyclerBusiness(
  data: fs1RecyclerBusiness
): Promise<DocPath> {
  const result: DocPath = {
    id: "",
    path: "",
  };
  if (!isValidData(data)) {
    return await Promise.resolve(result);
  }

  const doc_ref = doc(collection(FirestoreDB, "fs1RecyclerBusiness"));

  data.business_uid = doc_ref.id;
  await setDoc(doc_ref, data);

  result.id = doc_ref.id;
  result.path = doc_ref.path;
  return await Promise.resolve(result);
}

export async function setDocIn_fs1RecyclerBusiness(
  fs1_id: string,
  data: fs1RecyclerBusiness
): Promise<DocPath> {
  const result: DocPath = {
    id: "",
    path: "",
  };
  if (!isValidData(data)) {
    return await Promise.resolve(result);
  }

  const doc_ref = doc(collection(FirestoreDB, "fs1RecyclerBusiness"), fs1_id);

  await setDoc(doc_ref, data);

  result.id = doc_ref.id;
  result.path = doc_ref.path;
  return await Promise.resolve(result);
}

export async function removeDocFrom_fs1RecyclerBusiness(
  fs1_id: string
): Promise<RemoveDocResult> {
  const result: RemoveDocResult = {
    debug_data: [],
    return_msg: "fs1RecyclerBusiness:removeDocFrom_fs1RecyclerBusiness ",
    success: true,
  };

  if (!fs1_id) {
    result.success = false;
    result.return_msg += "Invalid function arguments.";
    return await Promise.resolve(result);
  }

  const doc_ref = doc(collection(FirestoreDB, "fs1RecyclerBusiness", fs1_id));

  await deleteDoc(doc_ref);
  return await Promise.resolve(result);
}
