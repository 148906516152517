import { FirestoreDB } from "./include/creds";
import {
  DocPath,
  DataProblem,
  // isValidRatingNumber,
  // isUrlValid,
  // isEmailValid,
  GetDocResult,
  RemoveDocResult,
} from "./include/misc";

import {
  collection,
  doc,
  setDoc,
  getDoc,
  deleteDoc,
} from "firebase/firestore";

export type PhoneNumber = {
  phone_type: 1 | 2;
  phone_number: string;
  extension?: string;
  description?: string;
};

export type AddressInfo = {
  city: string;
  state: string;
  postal_code: number;
  address_line_1: string;
  address_line_2?: string;
};

export type ShippingAddressInfo = {
  city: string;
  state: string;
  postal_code: number;
  address_line_1: string;
  address_line_2?: string;
}

export type VerifiedOutputs = {
  first_name: string,
  last_name: string,
  city: string,
  country: string,
  line1: string,
  line2: string,
  postal_code: string,
  state: string,
  time_verified: Date,
  matched_profile: number
}

export type fs4ConsumerContacts = {
  consumer_contact_uid: string;
  first_name: string;
  last_name: string;
  facebook_profile_url?: string;
  email?: string;
  firebase_account_uid?: string;
  phone_number: PhoneNumber;
  address_info: AddressInfo;
  average_consumer_rating?: number;
  consumer_account_uid: string;
  average_employee_rating?: number;
  assigned_employees?: string[];
  landline_phone_numbers?: string;
  customer_types?: string;
  is_deactivated?: boolean;
  verified_outputs: VerifiedOutputs;
  shipping_address_info?: ShippingAddressInfo;
};

// We assume that this function is fast and will not block.
export function isValidData(data: fs4ConsumerContacts): boolean {
  // if (
  //   data.average_consumer_rating !== undefined &&
  //   !isValidRatingNumber(data.average_consumer_rating)
  // ) {
  //   return false;
  // }

  // if (
  //   data.average_employee_rating !== undefined &&
  //   !isValidRatingNumber(data.average_employee_rating)
  // ) {
  //   return false;
  // }

  // if (data.email !== undefined && !isEmailValid(data.email)) {
  //   return false;
  // }

  // if (
  //   data.facebook_profile_url !== undefined &&
  //   !isUrlValid(data.facebook_profile_url)
  // ) {
  //   return false;
  // }

  return true;
}

export async function getDocFrom_fs4ConsumerContacts(
  fs1_id: string,
  fs2_id: string,
  fs4_id: string
): Promise<GetDocResult> {
  const result: GetDocResult = {
    debug_data: [],
    return_msg: "fs4ConsumerContacts:getDocFrom_fs4ConsumerContacts",
    success: true,
    doc_data: {},
  };

  if (!fs1_id || !fs2_id || !fs4_id) {
    result.success = false;
    result.return_msg += "Invalid function arguments.";
    return await Promise.resolve(result);
  }

  const doc_ref = doc(
    collection(
      FirestoreDB,
      "fs1RecyclerBusiness",
      fs1_id,
      "fs2RecyclerLocation",
      fs2_id,
      "fs4ConsumerContacts"
    ),
    fs4_id
  );

  const doc_data = (await getDoc(doc_ref)).data();
  if (doc_data === undefined) {
    result.success = false;
    result.return_msg +=
      "Could not find a document with the passed-in path arguments.";
    return await Promise.resolve(result);
  }

  result.doc_data = doc_data;
  return await Promise.resolve(result);
}

export async function addDocIn_fs4ConsumerContacts(
  fs1_id: string,
  fs2_id: string,
  data: fs4ConsumerContacts
): Promise<DocPath> {
  const result: DocPath = {
    id: "",
    path: "",
  };
  if (!isValidData(data)) {
    return await Promise.resolve(result);
  }

  const doc_ref = doc(
    collection(
      FirestoreDB,
      "fs1RecyclerBusiness",
      fs1_id,
      "fs2RecyclerLocation",
      fs2_id,
      "fs4ConsumerContacts"
    )
  );

  data.consumer_contact_uid = doc_ref.id;
  await setDoc(doc_ref, data);

  result.id = doc_ref.id;
  result.path = doc_ref.path;
  return await Promise.resolve(result);
}

export async function setDocIn_fs4ConsumerContacts(
  fs1_id: string,
  fs2_id: string,
  fs4_id: string,
  data: fs4ConsumerContacts
): Promise<DocPath> {
  const result: DocPath = {
    id: "",
    path: "",
  };
  if (!isValidData(data)) {
    return await Promise.resolve(result);
  }

  const doc_ref = doc(
    collection(
      FirestoreDB,
      "fs1RecyclerBusiness",
      fs1_id,
      "fs2RecyclerLocation",
      fs2_id,
      "fs4ConsumerContacts"
    ),
    fs4_id
  );

  await setDoc(doc_ref, data);

  result.id = doc_ref.id;
  result.path = doc_ref.path;
  return await Promise.resolve(result);
}

export async function removeDocFrom_fs4ConsumerContacts(
  fs1_id: string,
  fs2_id: string,
  fs4_id: string
): Promise<RemoveDocResult> {
  const result: RemoveDocResult = {
    debug_data: [],
    return_msg: "fs4ConsumerContacts:removeDocFrom_fs4ConsumerContacts ",
    success: true,
  };

  if (!fs1_id || !fs2_id || !fs4_id) {
    result.success = false;
    result.return_msg += "Invalid function arguments.";
    return await Promise.resolve(result);
  }

  const doc_ref = doc(
    collection(
      FirestoreDB,
      "fs1RecyclerBusiness",
      fs1_id,
      "fs2RecyclerLocation",
      fs2_id,
      "fs4ConsumerContacts"
    ),
    fs4_id
  );

  await deleteDoc(doc_ref);
  return await Promise.resolve(result);
}
