<template>
  <div id="p1s3" class="m-5 grid grid-cols-10 gap-4">
    <div class="col-span-3">

      <!-- Header - logo + menu button -->
      <c9MainMenu P_activePage="contacts"></c9MainMenu>

      <h1 class="font-bold text-2xl text-left mt-5">Contacts</h1>
      <p class="text-left mb-1">{{C_employeeEmail || ""}}</p>
      
      <p1s3c1Filters
        :businessesList="C_businessesList"
        @event-employee-role-filter-selected="setEmpolyeeRoleFilter"
        @event-customer-rating-filter-selected="setCustomerRatingFilter"
        @event-customer-city-filter-selected="setCustomerCityFilter"
        @event-customer-state-filter-selected="setCustomerStateFilter"
        @event-customer-zipCode-selected="setCustomerZipCodeFilter"
        @event-customer-sales-person-filter-selected="setCustomerSalesPersonFilter"
        @event-customer-type-filter-selected="setCustomerTypeFilter"
        @event-customer-business-filter-selected="setCustomerBusinessFilter"
        @event-business-city-filter-selected="setBusinessCityFilter"
        @event-business-state-filter-selected="setBusinessStateFilter"
        @event-business-customer-type-filter-selected="setBusinessTypeFilter"
        @filter-type="managementView"
      />
      <!--  -->
    </div>

    <div class="col-span-7 mt-4">
      <div class="border border-gray-400 rounded-xl h-full">
        <!--  -->
        <p1s3c2CustomersManagement 
          v-if="DV_showView == 'customerContacts'" 
          :businessesList="C_businessesList"
          :customerRatingFilter="DV_customerRatingFilter"
          :consumerBusinessFilter="DV_consumerBusinessFilter"
          :consumerCityFilter="DV_consumerCityFilter"
          :consumerStateFilter="DV_consumerStateFilter"
          :consumerZipCodeFilter="DV_consumerZipCodeFilter"
          :consumerTypeFilter="DV_consumerTypeFilter"
          :consumerSalesPersonFilter="DV_consumerSalesPersonFilter"
        />

        <p1s3c3BusinessesManagement 
          v-if="DV_showView == 'businesses'"
          :P_businessCityFilter="DV_businessCityFilter"
          :P_businessStateFilter="DV_businessStateFilter"
          :P_businessTypeFilter="DV_businessTypeFilter"
        />

        <p1s3c4EmployeesManagement :selectedRoleFilter="DV_employeeRoleFilter" v-if="DV_showView == 'employees'" />
      </div>
    </div>
  </div>
</template>

<script>
import p1s3c4EmployeesManagement from "./p1s3c4EmployeesManagement.vue";
import p1s3c3BusinessesManagement from "./p1s3c3BusinessesManagement.vue";
import p1s3c2CustomersManagement from "./p1s3c2CustomersManagement.vue";
import c9MainMenu from "../shared/c9MainMenu.vue";
import p1s3c1Filters from "./p1s3c1Filters.vue";

export default {
  name: "p1s3UserManagement",

  components: { 
    p1s3c1Filters, 
    p1s3c2CustomersManagement, 
    p1s3c3BusinessesManagement, 
    p1s3c4EmployeesManagement,
    c9MainMenu
  },

  data() {
    return {
      DV_showView: "customerContacts",
      DV_employeeRoleFilter: ["all", "sales-person", "manager", "admin"],
      DV_customerRatingFilter: ["none-specified", "bad", "below-average", "average", "good", "amazing"],
      DV_consumerCityFilter: "Any",
      DV_consumerStateFilter: "Any",
      DV_consumerZipCodeFilter: "",
      DV_consumerSalesPersonFilter: "Any",
      DV_consumerTypeFilter: ["none-specified", "exporter", "insurance-adjuster", "retail", "body-shop", "mechanic", "reseller"],
      DV_consumerBusinessFilter: "Any",
      DV_businessCityFilter: "Any",
      DV_businessStateFilter: "Any",
      DV_businessZipCodeFilter: "",
      DV_businessTypeFilter: ["none-specified", "exporter", "insurance-adjuster", "retail", "body-shop", "mechanic", "reseller"],
      DV_employeesInfo: {}
    };
  },

  mounted() {
    this.DV_employeesInfo = window.G_bi2_data.IV_recycler_employees;
  },

  computed: {
    C_businessesList() {
      let businesses = [];

      if (window.G_bi2_data && window.G_bi2_data.IV_consumer_businesses && window.G_bi2_data.IV_consumer_businesses.value) {
        for (let bussUid in window.G_bi2_data.IV_consumer_businesses.value) {
          let buss = window.G_bi2_data.IV_consumer_businesses.value[bussUid]
          if (buss.name && buss.name.trim()) { businesses.push({name: buss.name, uid: bussUid}) }
        }
      }

      return businesses;
    },

    C_employeeEmail() {
      let activeEmployeeUid = window.G_bi2_data.IV_active_employee_info.employee_uid;
    
      if (activeEmployeeUid in window.G_bi2_data.IV_recycler_employees.value === true) {
        let employeeInfo = window.G_bi2_data.IV_recycler_employees.value[activeEmployeeUid];

        return employeeInfo.email_address;
      }

      return "";
    }
  },

  methods: {
    managementView(data) {
      if (data == "customerContacts") {
        this.DV_showView = "customerContacts";
      }

      if (data == "businesses") {
        this.DV_showView = "businesses";
      }

      if (data == "employees") {
        this.DV_showView = "employees";
      }
    },

    setEmpolyeeRoleFilter(filter) {
      this.DV_employeeRoleFilter = filter 
    },

    setCustomerRatingFilter(filter) {
      this.DV_customerRatingFilter = filter
    },

    setCustomerCityFilter(filter) {
      this.DV_consumerCityFilter = filter
    },

    setCustomerStateFilter(filter) {
      this.DV_consumerStateFilter = filter
    },

    setCustomerZipCodeFilter(filter) {
      this.DV_consumerZipCodeFilter = filter
    },

    setCustomerSalesPersonFilter(filter) {
      this.DV_consumerSalesPersonFilter = filter
    },

    setCustomerBusinessFilter(filter) {
      this.DV_consumerBusinessFilter = filter
    },

    setCustomerTypeFilter(filter) {
      this.DV_consumerTypeFilter = filter
    },


    setBusinessCityFilter(filter) {
      this.DV_businessCityFilter = filter
    },

    setBusinessStateFilter(filter) {
      this.DV_businessStateFilter = filter
    },

    setBusinessTypeFilter(filter) {
      this.DV_businessTypeFilter = filter
    },
  },

  watch: {
    DV_employeesInfo: {
      handler() {
        this.$forceUpdate();
      },

      deep: true
    }
  }
};
</script>

<style scoped>
button {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}
</style>

<style>
table tbody td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
