<template>
  <div id="c3" @click.stop="" class="border border-gray-400 rounded-b-xl p-2 relative" 
    :class="{
      'h-[95vh]': DV_showingFullScreen,
      '!h-[60vh]': P_showingWithinModal,
      'h-4/5': DV_activeRenderMode > DV_renderModeDesktopNarrow,
      'h-[65%]': DV_activeRenderMode == DV_renderModeDesktopNarrow
    }"
    >
    <h1 class="md:hidden font-bold text-xl">Notes History</h1>

    <!-- Heading -->
    <div class="flex items-center center-self m-auto w-fit-content relative h-[4%]">      
      <h1 class="pr-9 font-bold text-xl">Notes History</h1>

      <!-- Searchbar -->
      <SearchBar v-model="DV_searchText"/>

      <svg v-show="!DV_showingFullScreen" @click="openFullScreen" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer absolute right-0">
        <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"/>
      </svg>
    </div>

    <div id="feedback-message-container" 
      class="border border-gray-400 rounded-xl mt-4 overflow-y-scroll hide-scrollbar"
      :class="{
        '!h-[40vh]': P_showingWithinModal,
        'h-3/4': DV_activeRenderMode > DV_renderModeDesktopNarrow,
        'h-[65%]': DV_activeRenderMode == DV_renderModeDesktopNarrow
      }"
      >
      <div v-show="DV_loading" class="my-32 flex justify-center">
        <h2 class="text-xl text-gray-400">Loading...</h2>
      </div>

      <div v-show="!DV_loading" v-for="message in DV_feedbackList" :key="message.uid" class=" flex flex-row border-b p-2 text-left">

        <p class="font-medium">
          <span v-if="message.employee_uid in DV_employeesData">Employee: {{ DV_employeesData[message.employee_uid].first_name }} {{ DV_employeesData[message.employee_uid].last_name }} |</span>
            Date: <span> {{moment(message.timestamp).format('MM-DD-YYYY h:mm A')}} </span>
          <br><span>{{message.message_content}}</span>
        </p>
        <p class="font-medium">
        </p>
      </div>
    </div>

    <div class="flex flex-col items-start bottom-2 w-[98%] absolute bottom-2">
      <!-- Feedback area -->
      <span class="text-left w-full mt-4 mb-2 no-wrap">
        Write feedback about this customer: <span id="response-message" class="text-sm float-right" :class="[DV_responseMessage.includes('successfully added') ? 'text-green-400' : 'text-red-400']">{{ DV_responseMessage }}</span>
      </span>
      <div class="flex w-full flex-row items-center">
        <textarea v-model="DV_feedbackText" id="messages" rows="2" class="border focus:outline-none border-gray-400 rounded-xl shadow w-full mr-4" v-on:keydown.enter="submitFeedback"></textarea>
        <button @click.stop="submitFeedback" class="btn btn-lime text-sm h-9">Submit Feedback</button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import SearchBar from '../shared/SearchBar.vue';
import { fs13Writer } from '../../includes/firestore_writers/fs13_writer.ts';
import moment from 'moment';
import _ from 'lodash'
import m3RenderingSettings from '../../mixins/m3RenderingSettings.js';

export default {
  name: "c3CustomerFeedback",

  components: { SearchBar },

  props: ['P_consumerUid', 'P_conversationUid', 'P_showingWithinModal'],

  mixins: [m3RenderingSettings],

  data() {
    return {
      DV_responseMessage: "",
      DV_loading: false,
      DV_loadingFinished: false,
      DV_showingFullScreen: false,
      DV_feedbackText: "",
      DV_isCheckingAuthentication: true,
      DV_allConsumerFeedbacks: {},
      DV_formattedCustomerFeedbacks: {},
      DV_feedbackList: [],
      DV_consumerFeedbackIndexes: window.G_bi2_data.IV_al_consumer_feedback_logs,
      DV_employeesData : window.G_bi2_data.IV_recycler_employees.value,
      moment: moment,
      DV_searchText: "",
    };
  },

  mounted() {
    if (window.location.pathname == "/customerFeedback") {
      this.DV_showingFullScreen = true;
    }
    setTimeout( function() {
      if(this.DV_loadingFinished === false) {
        this.DV_loading = true;        
      }
      }, 1000);

    this.authenticationCheck(0);
  },

  methods: {
    authenticationCheck(retry_count) {
      const CI = this;

      if (retry_count >= 40 && window.location.pathname !== "/login" && window.location.pathname !== "/") {
        location.assign(window.location.origin + `/login`);
        return;
      }

      if (window.G_firebase_auth.IV_is_user_logged_in !== true) {
        setTimeout( function() {CI.authenticationCheck(++retry_count)}, 100);
        return
      };

      this.DV_isCheckingAuthentication = false;
      this.loadConsumerData();
    },

    loadConsumerData(retry_count=0) {
      const CI = this;

      if (retry_count >= 40) { return; }

      if (!window.G_bi2_data.IV_active_employee_info.business_uid || !window.G_bi2_data.IV_active_employee_info.recycler_location_uid) {
        setTimeout( function() {CI.loadConsumerData(++retry_count)}, 100);
        return
      };

      window.G_bi2_data.startConsumerFeedbackLogListener(
        window.G_bi2_data,
        window.G_bi2_data.IV_active_employee_info.business_uid,
        window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
        CI.C_consumerUid
      )

      CI.DV_allConsumerFeedbacks = window.G_bi2_data.IV_consumer_feedback_logs.value[this.C_consumerUid];
    },

    openFullScreen() {
      let routeData = this.$router.resolve({ path: `/customerFeedback?consumerUid=${this.C_consumerUid}`});
      window.open(routeData.href, "_blank");
    },

    async submitFeedback() {  
      if (!this.DV_feedbackText.trim()) {
        this.DV_responseMessage = "Cannot submit blank feedback.";

        let errorArea = document.getElementById("response-message");
        errorArea.classList.remove("hidden");
        setTimeout(() => {errorArea.classList.add("hidden");}, 5000)
        return;
      }
      let resp = await fs13Writer(
        "n",
        window.G_bi2_data.IV_active_employee_info.business_uid,
        window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
        this.C_consumerUid,
        null,
        {
          employee_uid: window.G_bi2_data.IV_active_employee_info.employee_uid,
          conversation_uid: this.C_consumerUid,
          message_content: this.DV_feedbackText
        }
      )

      if (resp.success) {
        this.DV_responseMessage = "Feedback successfully added.";

        let errorArea = document.getElementById("response-message");
        errorArea.classList.remove("hidden");
        setTimeout(() => {errorArea.classList.add("hidden");}, 5000)
      } else {
        this.DV_responseMessage = "Failed to submit feedback.";

        let errorArea = document.getElementById("response-message");
        errorArea.classList.remove("hidden");
        setTimeout(() => {errorArea.classList.add("hidden");}, 5000)
      }

      this.DV_feedbackText = "";
    },

    scrollListToBottom() {
      const objDiv = document.getElementById("feedback-message-container");
      objDiv.scrollTop = objDiv.scrollHeight;
    },

    populateConsumerFeedbackList() {
      const CI = this;

      if (!CI.C_consumerUid) {
        CI.DV_loading = false;
        CI.DV_loadingFinished = true;
        return 
      }

      CI.DV_formattedCustomerFeedbacks = {};
      for (let fs13Uid in CI.DV_allConsumerFeedbacks) {
        let message = CI.DV_allConsumerFeedbacks[fs13Uid];

        Vue.set(CI.DV_formattedCustomerFeedbacks, fs13Uid, {
          message_type: message[CI.DV_consumerFeedbackIndexes.ma1i_type],
          message_content: message[CI.DV_consumerFeedbackIndexes.ma1i_content],
          uid: fs13Uid,
          timestamp: message[CI.DV_consumerFeedbackIndexes.ma1i_timestamp].seconds*1000,
          employee_uid: message[CI.DV_consumerFeedbackIndexes.ma1i_employee_uid]
        })
      }

      CI.DV_feedbackList = _.orderBy(CI.DV_formattedCustomerFeedbacks, ["timestamp"], 'asc');

      setTimeout(function() {
        CI.DV_loading = false;
        CI.scrollListToBottom();
      }, 500);
    },

    filterFeedbackList() {
      const CI = this;

      var filteredList = _.filter(CI.DV_formattedCustomerFeedbacks, (item) => {

        if (CI.DV_searchText) {

          if (item.timestamp && moment(item.timestamp).format('MM-DD-YYYY h:mm A').toLowerCase().includes(CI.DV_searchText.toLowerCase())) { return true;}
          if (item.message_content && item.message_content.toLowerCase().includes(CI.DV_searchText.toLowerCase())) { return true;}
          
          if (item.uid) {
            let name = CI.DV_employeesData[item.employee_uid].first_name + " " + CI.DV_employeesData[item.employee_uid].last_name;
            return name.toLowerCase().includes(CI.DV_searchText.toLowerCase());
          }
        } else {
          return true;
        }

        return false;
      })

      CI.DV_feedbackList = _.orderBy(filteredList, ["timestamp"], 'desc')
    }
  },

  watch: {
    DV_allConsumerFeedbacks: {
      handler() {
        this.populateConsumerFeedbackList()
      },
      deep: true
    },

    P_consumerUid: {
      handler() {
        this.loadConsumerData();
      }
    },

    DV_searchText: {
      handler() {
        this.filterFeedbackList();
      }
    }
  },
  
  computed: {
    C_consumerUid() {
      let consumerUid = this.P_consumerUid;

      if (this.$route.name == "customerFeedback" && this.$route.query["consumerUid"]) {
        consumerUid = this.$route.query["consumerUid"];
      }

      return consumerUid;
    }
  },
};
</script>

