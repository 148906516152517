<template>
  <div id="p1s3c6">
    <div class="p-3 hide-scrollbar">
      <h1 class="text-lg font-bold text-center">Filters</h1>

      <!-- Role filters -->
      <div class="my-5">
        <h1 class="text-sm font-medium text-left mb-2">Roles</h1>
        <span class="flex justify-between">
          <span class="flex items-center">
            <input type="checkbox" id="employee-role-all" name="all" value="all" class="cursor-pointer" v-model="DV_employeeRoleFilter" @click="toggleEmployeeRoleFilter()"/>
            <label for="all" class="ml-2">All</label>
          </span>

          <span class="flex items-center ml-2">
            <input type="checkbox" name="admin" value="admin" class="cursor-pointer" v-model="DV_employeeRoleFilter"/>
            <label for="admin" class="ml-2">Admin</label>
          </span>

          <!-- Manager
          <span class="flex items-center ml-2">
            <input type="checkbox" name="manager" value="manager" class="cursor-pointer" v-model="DV_employeeRoleFilter"/>
            <label for="manager" class="ml-2">Manager</label>
          </span> 
          -->

          <span class="flex items-center">
            <input type="checkbox" name="sales-person" value="sales-person" class="cursor-pointer" v-model="DV_employeeRoleFilter"/>
            <label for="sales-person" class="ml-2">Sales Person</label>
          </span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "p1s3c6EmployeesFilters",

  data() {
    return {
      DV_employeeRoleFilter: ["all", "sales-person", "manager", "admin"]
    };
  },

  methods: {
    selectFilter(filter) {
      this.DV_employeeRoleFilter = filter;
      this.$emit("event-employee-role-filter-selected", filter)
    },

    toggleEmployeeRoleFilter() {
      let element = document.getElementById("employee-role-all");

      if (element.checked) {
        this.DV_employeeRoleFilter = ["all", "sales-person", "manager", "admin"];
      } else {
        this.DV_employeeRoleFilter = [];
      }
    }
  },

  watch: {
    DV_employeeRoleFilter() {
      if (this.DV_employeeRoleFilter.includes("all") && this.DV_employeeRoleFilter.length < 5) {
        const index = this.DV_employeeRoleFilter.indexOf("all");
        this.DV_employeeRoleFilter.splice(index, 1);
      }

      this.$emit("event-employee-role-filter-selected", this.DV_employeeRoleFilter);
    }
  }
};
</script>
