<template>
  <div id="p1s1c1c4">

    <!-- Heading -->
    <p class="italic text-sm font-medium text-left mb-3">
      Amazing rating is based on 90 sales person reviews over a period of a year
    </p>

    <!-- Reviews list -->
    <div v-if="DV_feedbackHistoryEnteries.length > 0" class="border border-gray-400 rounded-xl shadow-sm py-3">
      <div v-for="entry in DV_feedbackHistoryEnteries" :key="index" class="border-b px-1">
        <p class="text-sm font-medium pl-2 text-left">
          <span> {{entry.timestamp}} </span> <span> {{entery.consumer_name}}: </span>
          <span> {{entry.rating}} </span>
        </p>
        <p class="text-sm pl-3 text-left">
          {{entry.feedback_text}}
        </p>
      </div>
    </div>
    <div v-else>
      No feedback history found for this consumer contact!
    </div>
  </div>
</template>

<script>
export default {
  name: "p1s1c1c4CustomerFeedback",

  data() {
    return {
      DV_feedbackHistoryEnteries: []
    }
  }
};
</script>
