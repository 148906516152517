import { fs112ConsumerConversationNotifications } from "../firestore_schemas/fs112ConsumerConversationNotifications"
import { removeDocFrom_fs112ConsumerConversationNotifications } from "../firestore_schemas/fs112ConsumerConversationNotifications"
import { setDocIn_fs112ConsumerConversationNotifications } from "../firestore_schemas/fs112ConsumerConversationNotifications"
import { getDocFrom_fs112ConsumerConversationNotifications } from "../firestore_schemas/fs112ConsumerConversationNotifications"
import { setValueOfOptionalFields } from "../firestore_writers/include/general"

type Result = {
  debug_data: Array<string>;
  return_msg: string;
  success: boolean;
  error_title: string;
  error_summary: string;
  error_next_steps: string;
  document_id?: string;
};

export async function fs112Writer(
  w_type: string,
  fs1_uid: string,
  fs2_uid: string,
  fs112_uid: string,
  data: any
) {
  const retval: Result = {
    debug_data: [],
    return_msg: "fs112_writer:fs112Writer ",
    success: true,
    error_title: "",
    error_summary: "",
    error_next_steps: "",
  };

  /// Validate arguments according to write type
  if ((w_type === "d" || w_type === "e") && (!fs1_uid || !fs2_uid || !fs112_uid)) {
    retval.success = false;
    retval.return_msg += "Arguments missing.";
    retval.error_title = "Arguments missing.";
    retval.error_summary = "Arguments missing.";
    retval.error_next_steps = "Please use a correct arguments.";
    return retval;
  }
  /// <END> Validate arguments according to write w_type

  if (w_type == "n") {
    // 
  }

  if (w_type == "e") {
    let response_data = await getDocFrom_fs112ConsumerConversationNotifications(
      fs1_uid,
      fs2_uid,
      fs112_uid
    )

    if (response_data.success === false) {
      retval.success = false;
      retval.return_msg += "Record not found.";
      retval.error_title = "Record not found.";
      retval.error_summary = "fs112 record not found in firestore.";
      retval.error_next_steps = "Please reload page and try again.";
      return retval;
    }

    // We'll only update the force_mark_unread here
    const fs112Data: fs112ConsumerConversationNotifications = {
      type: response_data['doc_data'].type,
      timestamp: response_data['doc_data'].timestamp,
      summary: response_data['doc_data'].summary,
      description: response_data['doc_data'].description,
      json_data: response_data['doc_data'].json_data,
      consumer_conversation_uid: response_data['doc_data'].consumer_conversation_uid,
      consumer_uid: response_data['doc_data'].consumer_uid,
      conversation_uid: response_data['doc_data'].conversation_uid,
      payment_link_uid: response_data['doc_data'].payment_link_uid,
      force_mark_unread: setValueOfOptionalFields(data.force_mark_unread, response_data['doc_data'].force_mark_unread, false)
    } as fs112ConsumerConversationNotifications;

    let response = await setDocIn_fs112ConsumerConversationNotifications(fs1_uid, fs2_uid, fs112_uid, fs112Data);

    if (!response.id || response.id == "" ) {
      retval.success = false;
      retval.return_msg += "Firestore write error.";
      retval.error_title = "Firestore write error.";
      retval.error_summary = "FS112 record not updated in firestore.";
      retval.error_next_steps = "Please reload page and try again.";
      return retval;
    }

    retval.document_id = response.id;
    return retval
  }

  // Delete record
  if (w_type == "d") {
    await removeDocFrom_fs112ConsumerConversationNotifications(fs1_uid, fs2_uid, fs112_uid);
    return retval
  }
}

function firestoreWriteError(error:any) {
  // TODO - complete this
}
