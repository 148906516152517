<template>
  <div id="p1s3c3" class="h-full">

    <!-- Business info -->
    <div v-if="DV_activeTab == 'businessList'" class="h-full">

      <!-- Heading -->
      <div class="flex items-center justify-between m-3">
        <SearchBar v-model="DV_searchBarValue" containerClasses="w-5/12 my-2" inputClasses="w-full" />

        <div class="flex items-center justify-between">
          <!-- Toggle deactivated view -->
          <div v-if="C_canReactivateBusiness" class="flex items-center">
            <span class="flex-grow flex flex-col mr-4" id="toggleLabel">
              <span style="line-height: 1rem" class="font-bold text-gray-900">
                Show Deactivated Businesses
              </span>
            </span>

            <label 
              aria-pressed="false" 
              aria-labelledby="toggleLabel" 
              class="toggle-label toggle-btn"
              :class="[DV_showDeactivatedBusinesses ? 'bg-blue-600' : 'bg-gray-200']"
            >
              <span class="sr-only">Use setting</span>
              
              <input 
                type="checkbox" 
                hidden="true"
                class="opacity-0 w-0 h-0"
                v-model="DV_showDeactivatedBusinesses"
                :class="[DV_showDeactivatedBusinesses ? 'toggle bg-blue-600' : 'bg-gray-200']"
              >
              <span aria-hidden="true" class="slider toggle-btn-slider"></span>
            </label>
          </div>
          
          <!-- Create new business button -->
          <button @click="DV_activeTab = 'newbusiness'" class="btn btn-lime ml-4">
            Create New Business
          </button>
        </div>
      </div>

      <!-- Business info table -->
      <div class="overflow-hidden border border-gray-400 rounded-xl m-3 h-[84vh] overflow-scroll hide-scrollbar">
        <table class="w-full rounded-xl text-center">
          <thead class="border-b">
            <tr class="text-left font-medium bg-gray-100 h-9">
              <th scope="col" class="text-center">Business Name</th>
              <th scope="col" class="text-center">Business Type</th>
              <th scope="col" class="text-center">City</th>
              <th scope="col" class="text-center">State</th>
              <th scope="col" class="text-center"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item of DV_filteredConsumerBusinesses" class="border-b bg-white h-20">
              <td>{{ item.name }}</td>
              <td>{{ businessType(item) }}</td>
              <td>{{ item.city }}</td>
              <td>{{ item.state }}</td>
              <td>
                <div class="flex justify-center flex-wrap">
                  <button @click="editBusiness(item)" class="btn btn-lime text-sm px-2 mr-2 mb-2 w-24">
                    Profile
                  </button>
                  <button v-if="item.is_deactivated !== true" @click.stop="deactivateBusiness(item)" class="btn btn-lime text-sm px-2 mr-2 mb-2 w-24">
                    Deactivate
                  </button>
                  <button v-if="C_canReactivateBusiness && item.is_deactivated === true" @click.stop="activateBusiness(item)" class="btn btn-lime text-sm px-2 mb-2 mr-2 w-24">
                    Reactivate
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    
    <div v-if="DV_activeTab == 'newbusiness'">
      <c9EditNewBusinessForm
        @newBusiness="DV_activeTab = 'businessList'"
        :P_businessFormType="'newBusiness'"
        :P_showHeader="true"
        @event-back="DV_activeTab = 'businessList'"
      />
    </div>
    
    <div v-if="DV_activeTab == 'editbusiness'">
      <c9EditNewBusinessForm
        @newBusiness="DV_activeTab = 'businessList'"
        :P_businessFormType="'editBusiness'"
        :P_showHeader="true"
        :P_businessUid="DV_editingBusinessUid"
        @event-back="DV_activeTab = 'businessList'"
      />
    </div>
  </div>
</template>

<script>
import _ from "lodash"
import c9EditNewBusinessForm from "../c1/c9EditNewBusinessForm.vue";
import { fs37Writer } from "../../includes/firestore_writers/fs37_writer"
import SearchBar from "../shared/SearchBar.vue";
import m2MiscDataFetchers from "../../mixins/m2MiscDataFetchers"
import AWN from "awesome-notifications";

export default {
  name: "p1s3c3BusinessesManagement",

  components: { c9EditNewBusinessForm, SearchBar},

  mixins: [ m2MiscDataFetchers ],

  props: ["P_businessStateFilter", "P_businessCityFilter" , "P_businessTypeFilter"],

  data() {
    return {
      DV_activeTab: "businessList",
      DV_consumerBusinesses: {},
      DV_filteredConsumerBusinesses: [],
      DV_searchBarValue: "",
      DV_editingBusinessUid: null,
      DV_employeePermissions: {},
      DV_employeeInfo: {},
      DV_showDeactivatedBusinesses: false
    };
  },

  mounted() {
    const CI = this;

    CI.DV_consumerBusinesses = window.G_bi2_data.IV_consumer_businesses.value;
    CI.DV_employeeInfo = window.G_bi2_data.IV_active_employee_info;
    CI.DV_employeePermissions = window.G_bi2_data.IV_active_employee_info.permissions;
  },

  computed: {
    C_canReactivateBusiness() {
      const CI = this;

      return (CI.DV_employeePermissions.admin === true || CI.DV_employeePermissions.manager === true);
    }
  },

  methods: {
    editBusiness(business) {
      this.DV_editingBusinessUid = business.consumer_business_uid
      this.DV_activeTab = "editbusiness"
    },

    regenerateFilteredBusinessList() {
      const CI = this;

      let businesses = [];
      let business_type_filter_length = 0;

      if (CI.P_businessTypeFilter !== undefined) {
        business_type_filter_length = CI.P_businessTypeFilter.length;
      }

      let loop1 = 0;
      let loop2 = 0;
      let tmp_str1 = "";
      let type_matches = false;
      let city_matches = false;
      let match_any_city = false;
      let state_matches = false;
      let match_any_state = false;
      let search_bar_matches = false;
      let match_any_search_bar_value = false;

      if (CI.P_businessStateFilter === "Any" || CI.P_businessStateFilter === "") {
        match_any_state = true;
      }
      if (CI.P_businessCityFilter === "Any" || CI.P_businessCityFilter === "") {
        match_any_city = true;
      }
      if (CI.DV_searchBarValue === undefined || CI.DV_searchBarValue === "") {
        match_any_search_bar_value = true;
      }

      for (let consumer_business_uid in CI.DV_consumerBusinesses) {
        state_matches = false;
        city_matches = false;
        search_bar_matches = false;

        let consumer_business = CI.DV_consumerBusinesses[consumer_business_uid]

        // Filtering the deactivated businesses based on the DV_showDeactivatedBusinesses
        if (CI.DV_showDeactivatedBusinesses) {
          if (consumer_business.is_deactivated === false) {
            continue;
          }
        } else {
          if (consumer_business.is_deactivated === true) {
            continue;
          }
        }

        //// check if the business type matches        
        // no filters set or all filter means match everything
        type_matches = false;

        if (CI.P_businessTypeFilter.length == 7) {
          type_matches = true;
        } else {
          let commonBusinessTypes = _.intersection(CI.P_businessTypeFilter, consumer_business.consumer_business_type_uids_list);
          type_matches = commonBusinessTypes.length > 0;

          if ((consumer_business.consumer_business_type_uids_list.length == 0) && CI.P_businessTypeFilter.includes("none-specified"))
          { type_matches = true }
        }
        ////</end> check if the business type matches

        //// Search bar
        if (match_any_search_bar_value === true
          || consumer_business.name.toLowerCase().indexOf(CI.DV_searchBarValue.toLowerCase()) !== -1
          || consumer_business.city.toLowerCase().indexOf(CI.DV_searchBarValue.toLowerCase()) !== -1
          || consumer_business.state.toLowerCase().indexOf(CI.DV_searchBarValue.toLowerCase()) !== -1
        ) {
          search_bar_matches = true;
        }

        // only do the expensive check on the business type if nothing else matched
        if (search_bar_matches === false ) {
          tmp_str1 = consumer_business.consumer_business_type_uids_list.map((item) => {return _.startCase(item.replace('-', ' '))}).join(",");
          if (tmp_str1.toLowerCase().indexOf(CI.DV_searchBarValue.toLowerCase())  !== -1) {
            search_bar_matches = true;
          }
          tmp_str1 = "";
        }
        ////</end> Search bar

        // City matching
        if (match_any_city === true || consumer_business.city === CI.P_businessCityFilter) {
          city_matches = true;
        }
        // State matching
        if (match_any_state === true || consumer_business.state === CI.P_businessStateFilter) {
          state_matches = true;
        }
        
        if (search_bar_matches && type_matches && state_matches && city_matches) {
          businesses.push(consumer_business)
        }
      }
      CI.DV_filteredConsumerBusinesses = _.sortBy(businesses, business => business.name);
    },

    businessType(business) {
      if (business.consumer_business_type_uids_list.length > 0) {
        let businesses = business.consumer_business_type_uids_list.map((item) => {return _.startCase(item.replace('-', ' '))})
        return businesses.join(", ");
      }

      return ""
    },

    deactivateBusiness(business) {
      const CI = this;

      let notifier = new AWN();

      notifier.confirm(
        // Label
        "Are you sure you want to deactivate this business? Once deactivated, only Managers or Admins can reactivate them.",
        
        // On OK
        async function() {
          fs37Writer(
            "e",
            window.G_bi2_data.IV_active_employee_info.business_uid,
            window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
            business.consumer_business_uid,
            {is_deactivated: true}
          ).then((resp) => {
            if (resp.success === true) {
              CI.$awn.success("Business deactivated successfully.")
            } else {
              CI.$awn.success("There was an issue while deactivating the business.")
            }
          }).catch((error) => {
            CI.$awn.success("There was an issue while deactivating the business.")
          });
        },

        // On cancel
        function() {},

        // Other options
        {
          labels: {
            confirm: 'Deactivate Business',
            confirmOk: "Confirm",
            confirmCancel: "Cancel"
          }
        }
      );
    },

    activateBusiness(business) {
      const CI = this;

      if (!CI.C_canReactivateBusiness) { return; }

      let notifier = new AWN();

      notifier.confirm(
        // Label
        "Are you sure you want to reactivate this business? Once reactivated, it will be visible in the normal tab.",
        
        // On OK
        async function() {
          fs37Writer(
            "e",
            window.G_bi2_data.IV_active_employee_info.business_uid,
            window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
            business.consumer_business_uid,
            {is_deactivated: false}
          ).then((resp) => {
            if (resp.success === true) {
              CI.$awn.success("Business reactivated successfully.")
            } else {
              CI.$awn.success("There was an issue while reactivating the business.")
            }
          }).catch((error) => {
            CI.$awn.success("There was an issue while reactivating the business.")
          });
        },

        // On cancel
        function() {},

        // Other options
        {
          labels: {
            confirm: 'Reactivate Business',
            confirmOk: "Confirm",
            confirmCancel: "Cancel"
          }
        }
      );
    },
  },

  watch: {
    DV_employeeInfo: {
      handler() {
        const CI = this;
        CI.DV_employeePermissions = window.G_bi2_data.IV_active_employee_info.permissions;
      },
      deep: true
    },

    P_businessStateFilter: { handler() { this.regenerateFilteredBusinessList();} },
    P_businessCityFilter: { handler() { this.regenerateFilteredBusinessList();} },
    P_businessTypeFilter: { handler() { this.regenerateFilteredBusinessList();} },
    DV_searchBarValue: { handler() { this.regenerateFilteredBusinessList();} },
    DV_showDeactivatedBusinesses: { handler() {this.regenerateFilteredBusinessList();} },
    DV_consumerBusinesses: {
      handler() { this.regenerateFilteredBusinessList();},
      deep: true
    }
  }
};
</script>
