<template>
  <div id="mod6">
    <PopupModal ref="mod6InviteTeamMemberToConversation" :P_SmallModal="true" :P_showClose="true" @close-modal="closeSelf">

      <!-- Title -->
      <div slot="title"> 
        <h3 class=" text-2xl font-bold"> Invite Team Member</h3>
      </div>
    
      <template slot="body">
        <span v-if="DV_successMessage !== ''" class="font-bold self-center text-center text-2xl text-green-500">
          {{ DV_successMessage }}
        </span>
        
        <div v-if="DV_successMessage === ''" class="flex flex-col items-center pt-4 gap-6">
          
          <span v-if="DV_errorMessage !== ''" class="text-center text-xl text-red-500">
            {{ DV_errorMessage }}
          </span>
          
          <span class="text-2xl text-center">Select a team member you want to collaborate with for this conversation</span>
          
          <div class="flex flex-row gap-4 items-center">
            <select
              id="invite-team-member"
              name="invite-member"
              class="dropdown-select"
              @change="updateSelectedMember()"
            >
              <option v-for="(emp, uid) in C_teamMembersList" :id="uid">
                {{emp.first_name}} {{emp.last_name}}
              </option>
            </select>
          </div>
          <span class=" pt-4 text-2xl text-center">Press "Send Invite" button below</span>
          <button @click.stop="inviteEmployee" type="button" class="mt-2 btn btn-lime text-sm w-auto mr-1">Send Invite</button>
        </div>
      </template>
    </PopupModal>
  </div>
</template>

<script>
import AWN from "awesome-notifications";
import PopupModal from './PopupModal.vue';
import { fs73Writer } from "../../includes/firestore_writers/fs73_writer.ts"
import { fs6Writer } from "../../includes/firestore_writers/fs6_writer.ts"

export default {
  name: "mod6InviteTeamMemberToConversation",

  props: ['P_consumerUid','P_conversationUid'],

  components: {PopupModal },

  data() {
    return {
      DV_consumerConversationUuid: "",
      DV_selectedMemberToInviteUid: "Select Employee",
      DV_errorMessage: "",
      DV_successMessage: "",
    };
  },

  mounted() {
    // 
  },
  watch:{
    // 
  },

  methods: {
    showSelf() {
      this.DV_successMessage = "";
      this.$refs.mod6InviteTeamMemberToConversation.show();
    },

    closeSelf() {
      this.DV_successMessage = "";
      this.$refs.mod6InviteTeamMemberToConversation.hide();
    },

    updateSelectedMember() {
      let list = document.getElementById('invite-team-member');
      this.DV_selectedMemberToInviteUid = list.options[list.selectedIndex].id;
    },

    async inviteEmployee() {
      // Validate

      if ( this.DV_selectedMemberToInviteUid in window.G_bi2_data.IV_recycler_employees.value === false) {
        this.DV_selectedMemberToInviteUid = "";
        this.DV_errorMessage = "Please choose an employee from dropdown to send invite.";
        return;
      }

      // fs73_writer.ts writes the employee conversation invite to the fs72 document whose document matches the employee's UID
      const fs73Resp = await fs73Writer(
        "n",
        window.G_bi2_data.IV_active_employee_info.business_uid,
        window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
        this.DV_selectedMemberToInviteUid,
        this.P_consumerUid,
        this.P_conversationUid,
        {}
      );

      if (fs73Resp.success == false) {
        this.$awn.alert("There was an error inviting the employee, please reload page and try again.");
        return;
      }

      // fs6_writer.ts sets adds the invite employee UID to fs6 > invited_employee_only_chat_uid_list 
      const fs6Resp = await fs6Writer(
        "e",
        window.G_bi2_data.IV_active_employee_info.business_uid,
        window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
        this.P_consumerUid,
        this.P_conversationUid,
        {
          invited_employee_only_chat_uid_list: [this.DV_selectedMemberToInviteUid]
        }
      )

      if (fs6Resp.success == true) {
        this.DV_successMessage = `${window.G_bi2_data.IV_recycler_employees.value[this.DV_selectedMemberToInviteUid].first_name} ${window.G_bi2_data.IV_recycler_employees.value[this.DV_selectedMemberToInviteUid].last_name} has been invited to this conversation`;
        setTimeout(this.closeSelf,2000);
      } else {
        this.DV_errorMessage ="There was an error inviting the employee, please reload the page and try again.";
      }
    }    
  },

  computed: {
    C_teamMembersList() {
      const allEmployees = window.G_bi2_data.IV_recycler_employees.value || [];
      let filteredEmployees = {};

      for (let uid in allEmployees) {
        let emp = allEmployees[uid];

        if (emp.employee_uid == window.G_bi2_data.IV_active_employee_info.employee_uid) {
          continue;
        }
        //don't show users who have not accepted an invite
        if(emp.first_name === '--') {continue;}

        filteredEmployees[uid] = emp;
      }

      return filteredEmployees;
    }
  }
};
</script>
