<template>
  <div id="mod5" class="">
    <PopupModal ref="mod5CustomerHistory" :P_fullModal="true" :P_showClose="true" @close-modal="closeSelf">

      <!-- Title -->
      <div slot="title"> 
        <h3  class="font-bold"></h3>
      </div>
      
      <!-- Body -->
      <template slot="body">
        <div class="pb-10" style="height: 60vh">
          <c4CustomerHistory
              v-if="DV_showModal"
              :P_consumerUid="P_consumerUid"
              :P_showingWithinModal="true"
          ></c4CustomerHistory>
        </div>
      </template>
    </PopupModal>
  </div>
</template>

<script>
import moment from 'moment';
import PopupModal from './PopupModal.vue';
import c4CustomerHistory from "../c1/c4CustomerHistory.vue"


export default {
  name: "mod5CustomerHistory",

  props: ['P_consumerUid', 'P_viewType'],

  components: {PopupModal, c4CustomerHistory },

  data() {
    return {
      DV_ccIndexes: window.G_bi2_data.IV_al_consumer_contact_index,
      DV_consumerContact: null,
      DV_conversationInfo: null,
      DV_consumerConversationUuid: "",
      moment: moment,
      DV_showModal: false,
    };
  },

  mounted() {
    // 
  },

  watch:{
    // 
  },

  methods: {
    showSelf() {
      this.DV_showModal = true;
      this.$refs.mod5CustomerHistory.show();        
    },

    closeSelf() {
      this.$refs.mod5CustomerHistory.hide();
      this.DV_showModal = false;
    }, 
  },
};
</script>
