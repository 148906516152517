<template>
  <PopupModal ref="scheduleDemoPopupModal" :P_largeModal="true" :P_showClose="true" @close-modal="closeSelf">
    
    <template v-slot:title> <h3 class="font-bold mt-2 text-xl"> Schedule A Demo</h3> </template>
    <template v-slot:icon>
      <div class="flex flex-row">
        <img style="object-fit: contain; height:10vh;" src="../../assets/images/synergy_icon.png">
        <img style="object-fit: contain; height:10vh;" src="../../assets/images/synergy_text_black.png">
    </div>
    </template>
    <template v-slot:body>
      <div class="block mt-4 border border-gray-400 shadow-sm p-3 rounded-xl">
        Please fill out the fields below and we will have someone contact you within 2 business days.
        <div class="flex flex-col items-start w-full">
          <span class="mt-2 text-left ">Your Name: </span>
          <input autocomplete="off" class="w-full" v-model="DV_name"  type="text" name="name">
          <span class="mt-2 text-left ">Phone Number: </span>
          <input autocomplete="off" v-model="DV_phone" style="width:10rem;" type="text" name="phone_number">
          <span class="mt-2 text-left">Business Name: </span>
          <input autocomplete="off" class="w-full" v-model="DV_businessName" type="text" name="business-name">
          <span class="mt-2 text-left">Email Address: </span>
          <input autocomplete="off" class="w-full" v-model="DV_emailAddress"  type="text" name="email-address">
          <span class="mt-2 text-left">Please summarize your business needs below to help us provide you with relevant information during your demo. </span>
          <textarea autocomplete="off" class="w-full" v-model="DV_notes" rows="4"  name="notes"></textarea>
          <div v-show="DV_response['message'].length > 0" :class="[DV_response['success'] ? 'text-green-400' : 'text-red-400']">{{ DV_response["message"] }}</div>
          <div class="mt-5 px-4 py-2 text-2xl rounded font-semibold green-gradient cursor-pointer" @click.stop="scheduleDemo"
            >Submit Request</div>
        </div>
      </div>
    </template>
  </PopupModal>
</template>

<script>
  import PopupModal from "../shared/PopupModal.vue";
  import sendScheduleDemoRequest from "../../includes/server_calls/p1s3/p1s3e21.ts";

  export default {
    name: "c1c2ScheduleDemo",

    components: { PopupModal },

    data() {
      return {
        DV_name: "",
        DV_phone: "",
        DV_businessName: "",
        DV_emailAddress: "",
        DV_notes: "",
        DV_response: {success: false, message: ""}
      }
    },

    mounted() {
    },

    methods: {
      scheduleDemo() {
        sendScheduleDemoRequest(this.DV_name,this.DV_phone,this.DV_businessName,this.DV_emailAddress,this.DV_notes).then((resp) => {
          this.DV_response["success"] = true;
          this.DV_response["message"] = "Demo request successfully sent. We will have someone contact you within 2 business days";
          
          setTimeout(() => {
            this.DV_response["message"] = ""; 
            this.DV_name= ""
            this.DV_phone= ""
            this.DV_businessName= ""
            this.DV_emailAddress= ""
            this.DV_notes= ""
            this.closeSelf();
          }, 2000)
        }).catch((err) => {
          this.DV_response["success"] = false;
          this.DV_response["message"] = "Something went wrong. Please try again later."

          setTimeout(() => {
            this.DV_response["message"] = "";
            this.DV_name= ""
            this.DV_phone= ""
            this.DV_businessName= ""
            this.DV_emailAddress= ""
            this.DV_notes= ""
            this.closeSelf();
          }, 2000)
        });
      },

      showSelf() {
        this.$refs.scheduleDemoPopupModal.show();
      },

      closeSelf() {
        this.$refs.scheduleDemoPopupModal.hide();
      }
    }

  }
</script>