<template>
  <!-- Customer engagement pie chart -->
  <div id="p1s2c2" class="rounded-lg overflow-hidden">
    <canvas class="p-10" id="chartPie"></canvas>
  </div>
</template>

<script>
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

export default {
  name: "p1s2c2CustomerEngagement",

  data() {
    return {
      dataPie: {
        labels: ["Customer initiated conversations", "Employee initiated conversations"],
        datasets: [
          {
            label: "My First Dataset",
            data: [300, 150],
            backgroundColor: ["rgb(133, 105, 241)", "rgb(164, 101, 241)"],
            hoverOffset: 4,
          },
        ],
      },
      configPie: {
        type: "pie",
        data: {},
        options: {
          responsive: true,
          maintainAspectRatio: false,
        },
      },
    };
  },

  mounted() {
    this.configPie["data"] = this.dataPie;
    var chartBar = new Chart(
      document.getElementById("chartPie"),
      this.configPie
    );
  },
};
</script>
