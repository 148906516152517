<template>
  <div id="c1m2">
  
    <!-- Heading -->
    <div class="flex flex-row items-center justify-between mb-3">
      <div class="">
        <button @click="$emit('returnTo')" class="flex items-center flex-row">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 pr-1">
            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"/>
          </svg>
        </button>
      </div>

      <h1 class="font-bold text-sm">Chat Image</h1>
      <div class="flex flex-row items-center justify-end">
        <svg @click="$emit('returnTo')" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"/>
        </svg>
      </div>
    </div>

    <!-- Conversation detail -->
    <div class="grid grid-cols-7 items-center">
      <div class="col-span-1">
        <h1 class="font-bold text-lg text-left">B Q</h1>
      </div>
      <div class="col-span-5">
        <p class="text-left text-sm my-1">
          John Miller | Downtown Repair | Body Shop | Quotes: 12435653($1000)
          132456($100)
        </p>
      </div>
    </div>

    <!-- Image area -->
    <div class="h-60 w-full full-image-view border border-gray-400 rounded-xl items-center justify-center flex">
      Image Of VIN
    </div>

    <!-- Return button -->
    <div class="flex items-center justify-center">
      <button @click="$emit('returnTo')" class="btn btn-lime mt-10">
        Return To Live Chat
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "c1m2ImageViewer",

  data() {
    return {};
  },
};
</script>
