import { fs30EmployeeAccountRecyclerJoins } from "../firestore_schemas/fs30EmployeeAccountRecyclerJoins"
import { getDocFrom_fs30EmployeeAccountRecyclerJoins } from "../firestore_schemas/fs30EmployeeAccountRecyclerJoins"
import { setDocIn_fs30EmployeeAccountRecyclerJoins } from "../firestore_schemas/fs30EmployeeAccountRecyclerJoins"
import { removeDocFrom_fs30EmployeeAccountRecyclerJoins } from "../firestore_schemas/fs30EmployeeAccountRecyclerJoins"

type Result = {
  debug_data: Array<string>;
  return_msg: string;
  success: boolean;
  error_title: string;
  error_summary: string;
  error_next_steps: string;
};

export async function fs30Writer(
  w_type: string,
  firebase_uid: string,
  employee_uid="",
  recycler_location_uid="",
  business_uid=""
) {

  const retval: Result = {
    debug_data: [],
    return_msg: "fs30_writer:fs30Writer ",
    success: true,
    error_title: "",
    error_summary: "",
    error_next_steps: "",
  };

  /// Validate arguments according to write type
  if (!firebase_uid) {
    retval.success = false;
    retval.return_msg += "Insufficient arguments.";
    retval.error_title = "Insufficient arguments.";
    retval.error_summary = "firebase_uid is not set.";
    retval.error_next_steps = "Please provide correct firebase_uid.";
    return retval;
  }

  if ((w_type == "n" || w_type == "u") && (!employee_uid || !recycler_location_uid || !business_uid)) {
    retval.success = false;
    retval.return_msg += "Insufficient arguments.";
    retval.error_title = "Insufficient arguments.";
    retval.error_summary = "business_uid or recycler_location_uid is not set.";
    retval.error_next_steps = "Please provide correct recycler_location_uid and business_uid.";
    return retval;
  }

  if ((w_type == "e" || w_type == "d") && !employee_uid) {
    retval.success = false;
    retval.return_msg += "Insufficient arguments.";
    retval.error_title = "Insufficient arguments.";
    retval.error_summary = "employee_uid is not set.";
    retval.error_next_steps = "Please provide correct employee_uid.";
    return retval;
  }
  /// <END> Validate arguments according to write type

  // Delete record
  if (w_type == "d") {
    await removeDocFrom_fs30EmployeeAccountRecyclerJoins(firebase_uid, employee_uid)
    return retval
  }

  // Create new record
  if (w_type == "n") {
    const fs30Data = { 
      employee_uid: employee_uid,
      recycler_location_uid: recycler_location_uid,
      business_uid: business_uid
    } as fs30EmployeeAccountRecyclerJoins;

    await setDocIn_fs30EmployeeAccountRecyclerJoins(
      firebase_uid,
      employee_uid,
      fs30Data
    )
    
    return retval
  }

  // TODO - check if we need edit/update?
}

function firestoreWriteError(error:any) {
  // TODO - complete this
}
