<template>
  <div id="p1s1c2c7">

    <!-- Heading -->
    <div class="flex flex-row items-center justify-between my-3 mx-2">
      <svg @click="$emit('finish_conversation')" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"/>
      </svg>
      <h1 class="font-bold text-sm">Rate The Conversation</h1>
      <svg @click="$emit('finish_conversation')" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer">
        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"/>
      </svg>
    </div>

    <!-- Conversation info -->
    <div class="grid grid-cols-7 gap-2 px-2 items-center mb-3">
      <div class="col-span-1 h-full items-start">
        <h1 class="font-bold text-lg text-left">B Q</h1>
      </div>
      <div class="col-span-6">
        <p class="text-left text-sm my-1">
          John Miller | Downtown Repair | Mechanic
          <a class="underline text-blue-500" href="#">972-221-9715</a>
        </p>
      </div>
    </div>

    <!-- Back button -->
    <button @click="$emit('finish_conversation')" class="btn btn-lime my-3">
      Go Back
    </button>

    <p class="text-center my-3">
      Why was this conversation <br />
      below average?
    </p>

    <!-- Reasons list -->
    <div class="border border-gray-400 rounded-xl p-2">
      <div class="border border-gray-400 rounded-xl overflow-y-scroll">
        <div
          v-for="index in 7"
          :key="index + Math.random()"
          @click="toggleMessages(index)"
          class="border-b border-gray-400 p-2"
        >
          <p>Common Reason {{ index }}</p>
        </div>
      </div>
    </div>

    <!-- End button -->
    <button @click="$emit('show-live-chat')" class="btn btn-lime my-4">
      End Conversation
    </button>
  </div>
</template>

<script>
export default {
  name: "p1s1c2c7RateTheConversation",

  data() {
    return {
      DV_userClick: {
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
        7: false,
      },
    };
  },

  methods: {
    toggleMessages(index) {
      DV_this.userClick[index] = !this.userClick[index];
    },
  },
};
</script>
