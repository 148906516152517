<template>
    <div>
      <h2 class="text-2xl font-bold">Frequently Asked Questions</h2>

        <!-- Q&A -->
        <ol class="list-number text-left">
          <!-- Q1 -->
          <li>
            <span class="font-bold">How do I start a new conversation from the Sales Dashboard?</span>
            <p class="text-base">
              There are two primary ways to initiate a new conversation from the Sales Dashboard:
              <ol class="!mt-0 list-disc list-inside list-bullets" type="a">
                <li class="list-item">Via the New Conversation Button:
                  <ol class="list-[numeric] list-inside list-bullets pl-5">
                    <li class="!mt-0 list-item">Click on the green "New Conversation" button located at the top right of the Sales Dashboard.</li>
                    <li class="!mt-0 list-item">For existing customers: Search for their name and select "Start New Chat" from the Customer Engagement & Insights column.</li>
                    <li class="!mt-0 list-item">For new customers: Input their phone number in the "New Customer" box. You can also optionally add their first and last name. Once done, click on "Start New Chat".</li>
                    <li class="!mt-0 list-item">A brief notification will appear above the "My Conversations" list, and the new conversation will be visible, allowing immediate communication. Your customer will instantly receive a text containing a chat link.</li>
                  </ol>
                </li>

                <li class="list-item">Via the Contacts Menu:
                  <ol class="list-[numeric] list-inside list-bullets pl-5">
                    <li class="!mt-0 list-item">Begin by selecting the top left menu and choosing "Contacts".</li>
                    <li class="!mt-0 list-item">Here, you can either search through the Customer Contacts or utilize filters to locate the customer you wish to engage in a conversation with.</li>
                    <li class="!mt-0 list-item">Once found, under the Customer Engagement & Insights column, click on "Chat Now". This will redirect you back to the sales dashboard, and a short notification of the new conversation will appear before it enters your active conversations list.</li>
                    <li class="!mt-0 list-item">Notably, from the Contacts page, you have the added capability to deactivate customers. Conversely, the New Conversation page provides the added advantage of enabling you to initiate conversations with completely new customers.</li>
                  </ol>
                </li>
              </ol>
            </p>
            <span class="-mt-1 text-base pl-4">For user convenience, some functionalities overlap across different pages, allowing you to choose the method that best suits your comfort and situation.</span>
          </li>

          <!-- Q2 -->
          <li>
            <span class="font-bold">How can I monitor my customer's activity during a conversation?</span>
            <p class="text-base">
              <i>Activity indicators:</i>
              <ol class="!mt-0 list-disc list-inside list-bullets">
                <li class="!mt-0 list-item">Green Dot: Customer is active in the chat.</li>
                <li class="!mt-0 list-item">Typing Indicator: Appears above the send bar when the customer is typing.</li>
                <li class="!mt-0 list-item">Yellow Dot: Inactivity for 15 seconds.</li>
                <li class="!mt-0 list-item">Clear Dot: Inactivity for 45 seconds. It turns green when they're active again.</li>
                <li class="!mt-0 list-item">New Message Indicator: Appears in "My Conversations" if you receive a message from another customer.</li>
              </ol>
            </p>
          </li>

          <!-- Q3 -->
          <li>
            <span class="font-bold"> What's the purpose of the left panel on the Sales Dashboard?</span>
            <p class="text-base">
              <i>The left panel is the customer queue.</i> Customers initiate requests from your website or Facebook via widgets. The queue displays their name, phone number, and part details. To engage, select "Chat Now". Address these requests promptly as customers await your response.
            </p>
          </li>

          <!-- Q4 -->
          <li>
            <span class="font-bold">How do I add a new customer contact?</span>
            <p class="text-base">
              <i>Creating a new customer contact:</i>
              <ol class="!mt-0 list-disc list-inside list-bullets">
                <li class="!mt-0 list-item">Automatically created with each new conversation.</li>
                <li class="!mt-0 list-item">Alternatively, navigate to Menu > Contacts > "Create New Customer Contact", input at least the customer's texting phone number, then click "Create New Customer".</li>
              </ol>
            </p>
          </li>

          <!-- Q5 -->
          <li>
            <span class="font-bold">How can I add a new business?</span>
            <p class="text-base">
              <i>To create a new business:</i>
              <ol class="!mt-0 list-disc list-inside list-bullets">
                <li class="!mt-0 list-item">From a Customer Profile: Navigate to "Profile" during a conversation and choose "Manage Customer's Business Associations". Fill in the required business details, mainly the "Business Name", and click "Associate Customer With New Business".</li>
                <li class="!mt-0 list-item">Without a customer: Go to Menu > Contacts > "Business" tab > "Create New Business". Input the business name (and any additional info if desired), then click "Create New Business".</li>
              </ol>
            </p>
          </li>

          <!-- Q6 -->
          <li>
            <span class="font-bold">How can I associate a business with a customer contact?</span>
            <p class="text-base">
              <i>For associating a business with a customer contact:</i>
              <ol class="!mt-0 list-disc list-inside list-bullets">
                <li class="!mt-0 list-item">Navigate to the "Profile" tab in a conversation.</li>
                <li class="!mt-0 list-item">Choose "Manage Customer's Business Associations".</li>
                <li class="!mt-0 list-item">Fill in at least the "Business Name" (additional info optional) and click "Associate Customer With New Business". A confirmation will appear.</li>
                <li class="!mt-0 list-item">If the business exists already, simply select it from the existing list on the same page.</li>
              </ol>
            </p>
          </li>

          <!-- Q7 -->
          <li>
            <span class="font-bold">How can I invite a new employee to the platform?</span>
            <p class="text-base">
              <i>Only users with sales manager or admin permissions can do this.</i>
              <ol class="!mt-0 list-disc list-inside list-bullets">
                <li class="!mt-0 list-item">Go to "Contacts" > "Employees" tab.</li>
                <li class="!mt-0 list-item">Click "Invite An Employee" at the top right.</li>
                <li class="!mt-0 list-item">Enter the employee's details (email, name, roles) and click "Invite New Employee".</li>
                <li class="!mt-0 list-item">The invitee should receive an email (check spam/junk if not in inbox) and click the provided link to finalize their account.</li>
              </ol>
            </p>
          </li>

          <!-- Q8 -->
          <li>
            <span class="font-bold">How do I deactivate a customer contact, business, or employee?</span>
            <p class="text-base">
              <i>To deactivate:</i>
              <ol class="!mt-0 list-disc list-inside list-bullets">
                <li class="!mt-0 list-item">Navigate to the "Contact" page.</li>
                <li class="!mt-0 list-item">Locate the desired contact or business. For employees, ensure you have Manager or Admin access.</li>
                <li class="!mt-0 list-item">Click the "Deactivate" button on the right and confirm your choice.</li>
              </ol>
            </p>
          </li>

          <!-- Q9 -->
          <li>
            <span class="font-bold">How can I reactivate a deactivated contact, business, or employee?</span>
            <p class="text-base">
              <i>For reactivation (requires Manager or Admin access):</i>
              <ol class="!mt-0 list-disc list-inside list-bullets">
                <li class="!mt-0 list-item">Go to the "Contacts" page and access the "Deactivated" list.</li>
                <li class="!mt-0 list-item">Sales persons cannot easily reactivate. Manager or Admin accounts can, however.</li>
                <li class="!mt-0 list-item">Reactivate as needed, and the contact/business/employee will reappear for everyone on the "Contacts" page.</li>
              </ol>
            </p>
          </li>


          <!-- Q10 -->
          <li>
            <span class="font-bold">How do I edit the profile information of a customer contact, business, or employee?</span>
            <p class="text-base">
              <i>For updating profiles:</i>
              <ol class="!mt-0 list-disc list-inside list-bullets">
                <li class="!mt-0 list-item">Customer Contact: Update within a conversation or find them under "Contacts" > "Customer Contacts" and edit.</li>
                <li class="!mt-0 list-item">Business: Update in a conversation under "Business Associations" or navigate to "Contacts" > "Businesses".</li>
                <li class="!mt-0 list-item">Employee: Accessible only via "Contacts" > "Employees" for those with Manager or Admin permissions.</li>
              </ol>
            </p>
          </li>

          <!-- Q11 -->
          <li>
            <span class="font-bold">What media files can I send, and what are their limitations?</span>
            <p class="text-base">
              <i>You can send:</i>
              <ol class="!mt-0 list-disc list-inside list-bullets">
                <li class="!mt-0 list-item">Pictures, videos, and PDFs.</li>
                <li class="!mt-0 list-item">The maximum file size allowed is 30MB. If uploading high-definition videos, ensure they're no more than 30MB to avoid an error.</li>
              </ol>
            </p>
          </li>

          <!-- Q12 -->
          <li>
            <span class="font-bold">How can I integrate with my preferred payment processor?</span>
            <p class="text-base">
              <i>To integrate with a payment processor:</i>
              <ol class="!mt-0 list-disc list-inside list-bullets">
                <li class="!mt-0 list-item">We currently support two processors prevalent in the automotive recycling industry.</li>
                <li class="!mt-0 list-item">Create or log into your account with your chosen processor.</li>
                <li class="!mt-0 list-item">Refer to the "Mobile Payments Integration" documentation on the "Help & Support" page.</li>
                <li class="!mt-0 list-item">Generate an API key in your processor's software and input this, following the instructions, into "Mobile Payments" under "Integrate With Synergy" on the "Settings" page.</li>
                <li class="!mt-0 list-item">Ensure you have admin or manager permissions to access and save these settings.</li>
              </ol>
            </p>
          </li>

          <!-- Q13 -->
          <li>
            <span class="font-bold">What is identity verification, and when is it activated?</span>
            <p class="text-base">
              <i>Identity verification serves to:</i>
              <ol class="!mt-0 list-disc list-inside list-bullets">
                <li class="!mt-0 list-item">Confirm that a customer is genuine, reducing fraud chances.</li>
                <li class="!mt-0 list-item">Salespeople might encounter a prompt when generating a new payment link, especially if the amount exceeds the admin-set threshold.</li>
                <li class="!mt-0 list-item">If activated, a $1.75 charge applies, and customers receive a link to upload their ID and face photos. Biometric data and facial recognition techniques are then used for verification.</li>
              </ol>
            </p>
          </li>

          <!-- Q14 -->
          <li>
            <span class="font-bold">How can I enable Identity Theft Verification?</span>
            <p class="text-base">
              <i>To activate this feature:</i>
              <ol class="!mt-0 list-disc list-inside list-bullets">
                <li class="!mt-0 list-item">Admins or managers should navigate to "Settings" > "Integrate With Synergy" > "Mobile Payments".</li>
                <li class="!mt-0 list-item">Toggle on "Stripe Identity Verification". Remember, it's off by default due to its $1.75 cost per verification.</li>
                <li class="!mt-0 list-item">After consenting to the cost, specify the minimum payment amount that warrants identity verification. By default, it's set to $1000, but this can be adjusted.</li>
                <li class="!mt-0 list-item">Salespeople will be prompted about the fee, but they can opt-out of repeated notifications</li>
              </ol>
            </p>
          </li>

          <!-- Q15 -->
          <li>
            <span class="font-bold">How do I generate a payment link?</span>
            <p class="text-base">
              <i>Creating a payment link involves:</i>
              <ol class="!mt-0 list-disc list-inside list-bullets">
                <li class="!mt-0 list-item">Within a conversation, clicking "Payment Links".</li>
                <li class="!mt-0 list-item">Entering the required details: description, amount, and order number.</li>
                <li class="!mt-0 list-item">Click "Create New Pay Link". Depending on settings and transaction amounts, a verification popup might appear.</li>
                <li class="!mt-0 list-item">Once generated, the payment link is sent via live chat, and customers receive a text message containing the same link.</li>
              </ol>
            </p>
          </li>

          <!-- Q16 -->
          <li>
            <span class="font-bold">How can I modify the amount on a payment link?</span>
            <p class="text-base">
              <i>Steps to change the amount:</i>
              <ol class="!mt-0 list-disc list-inside list-bullets">
                <li class="!mt-0 list-item">Go to the desired conversation and click on "Payment Links."</li>
                <li class="!mt-0 list-item">Choose the payment link you wish to adjust from "Open Payment Links."</li>
                <li class="!mt-0 list-item">Adjust the details under "Payment Link Details" and click "Update Payment Link."</li>
                <li class="!mt-0 list-item">A revised text message with the updated amount will be sent to the customer.</li>
              </ol>
            </p>
          </li>

          <!-- Q17 -->
          <li>
            <span class="font-bold">How can I monitor the status of a payment link?</span>
            <p class="text-base">
              <i>Tracking payment link status:</i>
              <ol class="!mt-0 list-disc list-inside list-bullets">
                <li class="!mt-0 list-item">The "My Conversations" list features an icon next to each conversation indicating the payment link status. Icons represent:
                  <ol class="!mt-0 list-disc list-inside list-bullets pl-5">
                    <li class="!mt-0 list-item">An active payment link.</li>
                    <li class="!mt-0 list-item">A paid payment link.</li>
                    <li class="!mt-0 list-item">A payment issue with a link.</li>
                  </ol>
                </li>
              </ol>
            </p>
          </li>

          <!-- Q18 -->
          <li>
            <span class="font-bold">What if a payment link remains unpaid by the customer?</span>
            <p class="text-base">
              <ol class="!mt-0 list-disc list-inside list-bullets">
                <li class="!mt-0 list-item">An automated reminder text is sent to the customer.</li>
                <li class="!mt-0 list-item">The status of the payment link will not update to "paid," signaling the need for a direct follow-up call.</li>
              </ol>
            </p>
          </li>

          <!-- Q19 -->
          <li>
            <span class="font-bold">What steps should be taken if there's a payment processing problem?</span>
            <p class="text-base">
              <i>Resolving payment issues:</i>
              <ol class="!mt-0 list-disc list-inside list-bullets">
                <li class="!mt-0 list-item">Contact the customer to address potential credit card issues.</li>
                <li class="!mt-0 list-item">If unresolved, reach out to your integrated payment processor for further troubleshooting. A status indicator will show if there's a payment issue.</li>
              </ol>
            </p>
          </li>

          <!-- Q20 -->
          <li>
            <span class="font-bold">How are chargebacks managed?</span>
            <p class="text-base">
              <i>Handling chargebacks:</i>
              <ol class="!mt-0 list-disc list-inside list-bullets">
                <li class="!mt-0 list-item">Our "Identity Theft Verification" feature aims to minimize chargebacks.</li>
                <li class="!mt-0 list-item">If one occurs, resolution is between the customer's bank and your chosen payment processor integrated with Synergy Auto Solutions.</li>
                <li class="!mt-0 list-item">Synergy Auto Solutions is not involved directly as we aren't a payment processor.</li>
                <li class="!mt-0 list-item">For proactive measures, many processors offer training to reduce the likelihood of chargebacks.</li>
              </ol>
            </p>
          </li>

          <!-- Q21 -->
          <li>
            <span class="font-bold">How does a customer receive a receipt after a successful payment?</span>
            <p class="text-base">
              <i>Receiving payment receipts:</i>
              <ol class="!mt-0 list-disc list-inside list-bullets">
                <li class="!mt-0 list-item">Once the payment is processed successfully, customers can input their email to receive an e-receipt.</li>
                <li class="!mt-0 list-item">The receipt features your Business Name, Logo, Address, total amount, description, and order number.</li>
              </ol>
            </p>
          </li>

          <!-- Q22 -->
          <li>
            <span class="font-bold">How can we prompt a customer to rate our business on Facebook or Google post-payment?</span>
            <p class="text-base">
              <i>Sending review requests:</i>
              <ol class="!mt-0 list-disc list-inside list-bullets">
                <li class="!mt-0 list-item">Post successful payment, an automated review request text is sent, given that your Business Logo, Facebook URL, and Google Maps URL have been set up in the Business Information tab.</li>
                <li class="!mt-0 list-item">Feedback mechanism:
                  <ol class="!mt-0 list-disc list-inside list-bullets pl-5">
                    <li class="!mt-0 list-item">Ratings of 3 stars or below notify the business via email, allowing for offline issue resolution.</li>
                    <li class="!mt-0 list-item">Ratings of 4 or 5 stars redirect customers to either the Google or Facebook page to submit their review.</li>
                  </ol>
                </li>
              </ol>
            </p>
          </li>

          <!-- Q23 -->
          <li>
            <span class="font-bold">What's the procedure for concluding a conversation and rating a customer?</span>
            <p class="text-base">
              <i>Wrapping up and rating a conversation:</i>
              <ol class="!mt-0 list-disc list-inside list-bullets">
                <li class="!mt-0 list-item">Within the chat, click on "Move To Finished."</li>
                <li class="!mt-0 list-item">A popup will appear for rating the customer's interaction. This is optional.</li>
                <li class="!mt-0 list-item">Ratings can be stored in "Conversation History" and influence the customer's overall average score on "Contacts" and "New Conversation" pages.</li>
              </ol>
            </p>
          </li>

          <!-- Q24 -->
          <li>
            <span class="font-bold">How is a customer's average rating determined on the Contacts and New Conversation pages?</span>
            <p class="text-base">
              <i>Understanding rating metrics:</i>
              <ol class="!mt-0 list-disc list-inside list-bullets">
                <li class="!mt-0 list-item">Ratings are categorized as: "Amazing", "Good", "Average", "Below Average", and "Bad."</li>
                <li class="!mt-0 list-item">Scores breakdown:
                  <ol class="!mt-0 list-disc list-inside list-bullets pl-5">
                    <li class="!mt-0 list-item">Amazing = 100%</li>
                    <li class="!mt-0 list-item">Good = 85%</li>
                    <li class="!mt-0 list-item">Average = 70%</li>
                    <li class="!mt-0 list-item">Below Average = 60%</li>
                    <li class="!mt-0 list-item">Bad = 50%</li>
                  </ol>
                </li>
                <li class="!mt-0 list-item">Average Score Interpretation:
                  <ol class="!mt-0 list-disc list-inside list-bullets pl-5">
                    <li class="!mt-0 list-item">90% and above = Amazing</li>
                    <li class="!mt-0 list-item">80-89% = Good</li>
                    <li class="!mt-0 list-item">70-79% = Average</li>
                    <li class="!mt-0 list-item">60-69% = Below Average</li>
                    <li class="!mt-0 list-item">50-59% = Bad</li>
                  </ol>
                </li>
                <li class="!mt-0 list-item">If no ratings exist, the field remains blank.</li>
              </ol>
            </p>
          </li>

          <!-- Q25 -->
          <li>
            <span class="font-bold">How do I use the filters on the Contacts page effectively?</span>
            <p class="text-base">
              <i>Making use of Contacts page filters:</i>
              <ol class="!mt-0 list-disc list-inside list-bullets">
                <li class="!mt-0 list-item">Initially, the Contacts page displays all Customer Contacts, Businesses, or Employees based on the chosen tab.</li>
                <li class="!mt-0 list-item">Filters include options like City, State, customer types, and ratings.</li>
                <li class="!mt-0 list-item">A keyword search bar at the top left can also be used for specific searches.</li>
              </ol>
            </p>
          </li>

          <!-- Q26 -->
          <li>
            <span class="font-bold">What purpose does the "Business Name" serve in the Business Information tab of the Settings page?</span>
            <p class="text-base">
              <i>Usage of Business Name:</i>
              <ol class="!mt-0 list-disc list-inside list-bullets">
                <li class="!mt-0 list-item">The Business Name is displayed in text messages sent to customers.</li>
                <li class="!mt-0 list-item">It's also featured on the receipt customers receive post successful transaction.</li>
              </ol>
            </p>
          </li>

          <!-- Q27 -->
          <li>
            <span class="font-bold">How is the "Business Logo" utilized in the Business Information tab of the Settings page?</span>
            <p class="text-base">
              <i>Significance of Business Logo:</i>
              <ol class="!mt-0 list-disc list-inside list-bullets">
                <li class="!mt-0 list-item">Employees observe the logo on each page when they access the Synergy Auto Solutions, enhancing brand visibility.</li>
                <li class="!mt-0 list-item">Customers see this logo on the payment page, receipt, and during the review request phase.</li>
              </ol>
            </p>
          </li>

          <!-- Q28 -->
          <li>
            <span class="font-bold">What's the role of the "Business Phone Number" in the Business Information tab of the Settings page?</span>
            <p class="text-base">
              <i>Function of Business Phone Number:</i>
              <ol class="!mt-0 list-disc list-inside list-bullets">
                <li class="!mt-0 list-item">This number is the primary contact for reaching out to the business if required.</li>
                <li class="!mt-0 list-item">The local area code from this number is employed to procure a business texting number for customer communication via Synergy.</li>
                <li class="!mt-0 list-item">A distinct phone number, matching the local area code, is reserved to make it appear as a local contact when messaging local clientele.</li>
              </ol>
            </p>
          </li>

          <!-- Q29 -->
          <li>
            <span class="font-bold">How is the "Street Address, City, State, and Zip Code" information applied in the Business Information tab of the Settings page?</span>
            <p class="text-base">
              <i>Use of Address Details:</i>
              <ol class="!mt-0 list-disc list-inside list-bullets">
                <li class="!mt-0 list-item">This data aids in activating the business texting phone number.</li>
                <li class="!mt-0 list-item">Customers can view this address on the receipt they receive upon a successful transaction.</li>
              </ol>
            </p>
          </li>

          <!-- Q30 -->
          <li>
            <span class="font-bold">Why are the "Google Maps URL" and "Facebook URL" fields important in the Business Information tab of the Settings page?</span>
            <p class="text-base">
              <i>Purpose of URLs:</i>
              <ol class="!mt-0 list-disc list-inside list-bullets">
                <li class="!mt-0 list-item">Following a successful transaction, these URLs are vital when sending out review requests.</li>
                <li class="!mt-0 list-item">If a customer rates either 4 or 5 stars, buttons for Google and Facebook appear.</li>
                <li class="!mt-0 list-item">Clicking these buttons redirects the customer to the URLs provided, given that they've been correctly set up as per the Review Request Integration guide on the Help & Support page. Proper setup ensures customers are directed to the right page for leaving reviews.</li>
              </ol>
            </p>
          </li>

          <!-- Q31 -->
          <li>
            <span class="font-bold">What is the purpose of the Payment Processor data on the "Integrate With Synergy" tab under "Mobile Payments"?</span>
            <p class="text-base">
              <i>Use of Payment Processor Data:</i>
              <ol class="!mt-0 list-disc list-inside list-bullets">
                <li class="!mt-0 list-item">This information links your payment links to your dedicated payment processor.</li>
                <li class="!mt-0 list-item">Properly following the instructions on the Help & Support page ensures the correct generation of payment links for customer transactions via mobile.</li>
              </ol>
            </p>
          </li>

          <!-- Q32 -->
          <li>
            <span class="font-bold">How do I incorporate the HTML code from the "Integrate With Synergy" tab for online widgets on my site?</span>
            <p class="text-base">
              <i>Steps to Integrate:</i>
              <ol class="!mt-0 list-disc list-inside list-bullets">
                <li class="!mt-0 list-item">Copy the provided HTML code by clicking the associated icon.</li>
                <li class="!mt-0 list-item">Share this code with your website manager to embed in the footer of every desired page on your site.</li>
                <li class="!mt-0 list-item">For comprehensive instructions, refer to the "Website Widget Integration" guide on the Help & Support page.</li>
                <li class="!mt-0 list-item">Upon successful integration, customer-generated queue requests will include details like name, contact, vehicle specifications, or other descriptive information.</li>
              </ol>
            </p>
          </li>

          <!-- Q33 -->
          <li>
            <span class="font-bold">How can I incorporate the Synergy app into my Facebook Business page?</span>
            <p class="text-base">
              <i>Facebook Integration:</i>
              <ol class="!mt-0 list-disc list-inside list-bullets">
                <li class="!mt-0 list-item">Start by downloading and adding the Synergy app from your Facebook Business page.</li>
                <li class="!mt-0 list-item">Consult the instructions on the "Facebook Widget Integration" section of the Help & Support page.</li>
                <li class="!mt-0 list-item">Once set up, customer-generated queue requests from your Facebook page will entail details such as name, phone number, vehicle info, and other pertinent data.</li>
              </ol>
            </p>
          </li>

          <!-- Q34 -->
          <li>
            <span class="font-bold">Where can I locate all legal documents associated with Synergy Auto Solutions?</span>
            <p class="text-base">
              <i>Accessing Legal Documents:</i>
              <ol class="!mt-0 list-disc list-inside list-bullets">
                <li class="!mt-0 list-item">Navigate to the "Legal Documents" section within the "Help & Support" page, accessible from the top-left menu.</li>
              </ol>
            </p>
          </li>

          <!-- Q35 -->
          <li>
            <span class="font-bold">I'm having trouble accessing the Sales Dashboard. What should I do?</span>
            <p class="text-base">
              <i>Steps to Access Sales Dashboard:</i>
              <ol class="!mt-0 list-disc list-inside list-bullets">
                <li class="!mt-0 list-item">Navigate to our Login Page and enter the credentials you established during account setup.</li>
                <li class="!mt-0 list-item">Alternatively, you can choose "Login With Email Link" to get an email link. Clicking on this link will auto-log you into the Sales Dashboard.</li>
                <li class="!mt-0 list-item">If accessing the link directs you to the dashboard without asking for credentials, it implies you were previously logged in.</li>
                <li class="!mt-0 list-item">Forgot your password? Click "Forgot Password?" and follow the reset instructions.</li>
              </ol>
            </p>
          </li>

          <!-- Q36 -->
          <li>
            <span class="font-bold">After initial sign-up with Synergy Auto Solutions, when is the subsequent billing, and what's the billing procedure moving forward?</span>
            <p class="text-base">
              <i>Billing Details:</i>
              <ol class="!mt-0 list-disc list-inside list-bullets">
                <li class="!mt-0 list-item">The initial billing upon sign-up is $170, covering the first month.</li>
                <li class="!mt-0 list-item">30 days post sign-up, you're billed for the second month plus usage costs from the first month.</li>
                <li class="!mt-0 list-item">Usage costs predominantly arise from text messages at a rate of $0.02/text.</li>
                <li class="!mt-0 list-item">Synergy Auto Solutions optimally sends texts only when necessary, predominantly using live chat messages to keep costs minimal.</li>
                <li class="!mt-0 list-item">Another significant usage cost comes from Identity Theft Verification (if activated) at $1.75 per verification. It's a valuable feature, though expensive, so consider usage judiciously.</li>
                <li class="!mt-0 list-item">Other minor usage costs, amounting to roughly $3-$7 per user monthly, derive from activities that cost fractions of a penny. These aren't itemized to prevent clutter but are included to guard against high usage by extensive teams.</li>
              </ol>
            </p>
          </li>

          <!-- Q37 -->
          <li>
            <span class="font-bold">If we have inquiries or concerns regarding Synergy Auto Solutions, whom should we reach out to?</span>
            <p class="text-base">
              <i>Contacting Support:</i>
              <ol class="!mt-0 list-disc list-inside list-bullets">
                <li class="!mt-0 list-item">For support, navigate to the Help & Support page and create a Support Ticket. The team will be notified and will contact you either through email or phone to address the issue.</li>
                <li class="!mt-0 list-item">Alternatively, you can send an email to info@texnrewards.com for more assistance.</li>
              </ol>
            </p>
          </li>

          <!-- Q38 -->
          <li>
            <span class="font-bold">Which screen sizes can run Synergy Auto Solutions effectively?</span>
            <p class="text-base">
              <i>Platform Compatibility:</i>
              <ol class="!mt-0 list-disc list-inside list-bullets">
                <li class="!mt-0 list-item">Sign-ups can be done via mobile devices or desktop.</li>
                <li class="!mt-0 list-item">For day-to-day platform use, a screen size of at least 15.6" (a common laptop screen dimension) is essential.</li>
                <li class="!mt-0 list-item">The sales dashboard isn't optimized for mobile since sales personnel typically work on computers and find desktop interfaces more efficient.</li>
              </ol>
            </p>
          </li>

          <!-- Q39 -->
          <li>
            <span class="font-bold">How can I involve a colleague in a chat?</span>
            <p class="text-base">
              <i>Inviting Team Members to Conversations:</i>
              <ol class="!mt-0 list-disc list-inside list-bullets">
                <li class="!mt-0 list-item">To include another employee in a chat, click on "Invite Team Member" within the conversation.</li>
                <li class="!mt-0 list-item">Choose a colleague from the dropdown and click "Send Invite."</li>
                <li class="!mt-0 list-item">The invited member will get a notification. Upon acceptance, "My Conversations" will indicate their participation.</li>
                <li class="!mt-0 list-item">This colleague can now communicate with the customer and access related profiles, conversation logs, and notes.</li>
                <li class="!mt-0 list-item">For internal chats unseen by the customer, use the "Yard Only Chat" tab.</li>
              </ol>
            </p>
          </li>

          <!-- Q40 -->
          <li>
            <span class="font-bold">When and how might I shift a conversation to the queue?</span>
            <p class="text-base">
              <i>Moving Conversations to Queue:</i>
              <ol class="!mt-0 list-disc list-inside list-bullets">
                <li class="!mt-0 list-item">Relocate a chat to the queue when you're overwhelmed with ongoing conversations or need a break, allowing colleagues to step in.</li>
                <li class="!mt-0 list-item">Alternatively, you can directly invite another team member into the conversation.</li>
              </ol>
            </p>
          </li>

          <!-- Q41 -->
          <li>
            <span class="font-bold">Can you explain the 'Call Customer' function and its setup?</span>
            <p class="text-base">
              <i>Using the Call Customer Feature:</i>
              <ol class="!mt-0 list-disc list-inside list-bullets">
                <li class="!mt-0 list-item">The "Call Customer" button identifies installed desktop apps offering a phone feature.</li>
                <li class="!mt-0 list-item">On the first click, available options will appear. If none do, you lack a suitable app.</li>
                <li class="!mt-0 list-item">After choosing an app (like Skype), it becomes the default for subsequent calls.</li>
                <li class="!mt-0 list-item">For a single saved number in a customer profile, the chosen app (e.g., Skype) will prefill and dial this number.</li>
                <li class="!mt-0 list-item">Multiple saved numbers necessitate a choice before redirecting to the selected phone app.</li>
              </ol>
            </p>
          </li>

          <!-- Q42 -->
          <li>
            <span class="font-bold">How can I modify the primary phone software on my computer, and why might this be beneficial?</span>
            <p class="text-base">
              <i>Changing the Primary Software Phone:</i>
              <ol class="!mt-0 list-disc list-inside list-bullets">
                <li class="!mt-0 list-item">After your initial "Call Customer" action, Synergy Auto Solutions remembers your chosen phone app, directing you to it automatically for future uses.</li>
                <li class="!mt-0 list-item">Should you need a different default phone software, this necessitates adjusting settings outside of Synergy, by editing your computer's default phone application.</li>
                <li class="!mt-0 list-item">Switching might be prompted by an initial wrong choice or a newfound preference for another software phone app.</li>
              </ol>
            </p>

            <p class="text-base">
              <i>Steps for Changing the Default Phone Program:</i>
              <br>
              <b>For Windows 11:</b>
              <ol class="!mt-0 list-inside list-bullets list-decimal">
                <li class="!mt-0 list-item">Open the <b>Start Menu</b> and click on <b>Settings</b>.</li>
                <li class="!mt-0 list-item">Navigate to <b>Apps > Default apps.</b></li>
                <li class="!mt-0 list-item">Scroll down and find the category for <b>Phone call</b> or similar.</li>
                <li class="!mt-0 list-item">Click on it, and you will see a list of apps that can be set as the default for phone calls.</li>
                <li class="!mt-0 list-item">Choose the desired app from the list.</li>
              </ol>
            </p>

            <p class="text-base">
              <b>For Windows 10:</b>
              <ol class="!mt-0 list-inside list-bullets list-decimal">
                <li class="!mt-0 list-item">Go to Settings from the Start Menu.</li>
                <li class="!mt-0 list-item">Click on Apps.</li>
                <li class="!mt-0 list-item">On the left sidebar, select Default apps.</li>
                <li class="!mt-0 list-item">Scroll down and click on the Choose default apps by protocol link.</li>
                <li class="!mt-0 list-item">Look for a protocol related to phone calls (e.g., TEL or similar).</li>
                <li class="!mt-0 list-item">Click on the current default app beside it, and a list will appear.</li>
                <li class="!mt-0 list-item">From the list, select the preferred app for phone calls.</li>
              </ol>
            </p>
          </li>

          <!-- Q43 -->
          <li>
            <span class="font-bold">What is the maximum number of phone numbers I can save to a customer's profile in Synergy Auto Solutions?</span>
            <p class="text-base">
              In Synergy Auto Solutions, you can save up to three phone numbers for a single customer. While one number is mandatory for texting purposes, you have the option to add two additional numbers for each customer.
            </p>
          </li>

          <!-- Q44 -->
          <li>
            <span class="font-bold">How many simultaneous conversations can my business have with a single phone number?</span>
            <p class="text-base">
              A single phone number can be engaged in up to three simultaneous conversations. This design allows multiple representatives to interact with the same customer concurrently, if needed. Additionally, it provides a safeguard against a customer repeatedly submitting online requests using the same phone number.
            </p>
          </li>

          <!-- Q45 -->
          <li>
            <span class="font-bold">What should I do if I encounter issues with Saved Data auto-populating when inputting information into Synergy Auto Solutions?</span>
            <p class="text-base">
              If you're experiencing issues with autofill data, we recommend using Google Chrome as your browser, which tends to handle these scenarios smoothly. If you wish to disable the autofill feature across all browsers, consult the following guide: <a class="underline text-blue-500" href="https://www.beaufortcountysc.gov/apps/disable-autofill.html">Disable Autofill</a>.
            </p>
          </li>

        </ol>
      </div>
</template>

<script>
export default {
    name: "p5s1frequentlyAskedQuestions"
}
</script>