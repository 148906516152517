import { fs128TrialAccountTokens } from "../firestore_schemas/fs128TrialAccountTokens"
import { setDocIn_fs128TrialAccountTokens } from "../firestore_schemas/fs128TrialAccountTokens"
import { getDocFrom_fs128TrialAccountTokens } from "../firestore_schemas/fs128TrialAccountTokens"
import { setValueOfOptionalFields } from "../firestore_writers/include/general"

type Result = {
  debug_data: Array<string>;
  return_msg: string;
  success: boolean;
  error_title: string;
  error_summary: string;
  error_next_steps: string;
  document_id?: string;
};

export async function fs128Writer(
  w_type: string,
  fs128_id: string,
  data={
    trial_account_token: "",
    token_used: false,
    business_uid: "",
    email_address: ""
  }) {

  const retval: Result = {
    debug_data: [],
    return_msg: "fs128_writer:fs128Writer ",
    success: true,
    error_title: "",
    error_summary: "",
    error_next_steps: "",
  };

  /// Validate arguments according to write type
  if((w_type === "d" || w_type === "e") && (!fs128_id || !data.trial_account_token)) {
    retval.success = false;
    retval.return_msg += "fs128_id argument missing.";
    retval.error_title = "fs128_id argument missing.";
    retval.error_summary = "Trial account token missing.";
    retval.error_next_steps = "Please use a correct trial account token.";
    return retval;
  }
  /// <END> Validate arguments according to write w_type

  if (w_type === "e") {

    let response_data = await getDocFrom_fs128TrialAccountTokens(fs128_id)

    if (response_data.success === true && response_data['doc_data'].token_used === true) {
      retval.success = false;
      retval.return_msg += "Record found.";
      retval.error_title = "Record found.";
      retval.error_summary = "FS128 record found in firestore.";
      retval.error_next_steps = "Please sign up using a valid trial token.";
      return retval;
    }

    const fs128Data: fs128TrialAccountTokens = {
      trial_account_token: data.trial_account_token,
      token_used: data.token_used,
      business_uid: data.business_uid,
      email_address: data.email_address
    } as fs128TrialAccountTokens;

    let response = await setDocIn_fs128TrialAccountTokens(
      fs128_id,
      fs128Data
    )

    if (!response.id || response.id == "" ) {
      retval.success = false;
      retval.return_msg += "Firestore write error.";
      retval.error_title = "Firestore write error.";
      retval.error_summary = "FS128 record not updated in firestore.";
      retval.error_next_steps = "Please reload page and try again.";
      return retval;
    }
    retval.document_id = response.id;
    return retval
  }
}

function firestoreWriteError(error:any) {
  // TODO - complete this
}
