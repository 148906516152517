<template>
  <div id="mod1">
    <PopupModal ref="mod1CustomerChatLog" :P_fullModal="true" :P_showClose="true" P_modalClasses="absolute left-[12.5%] !h-[95%] -top-[15px]" @close-modal="closeSelf">

      <!-- Title -->
      <div slot="title" class="relative"> 
        <h3 v-if="DV_conversationInfo" class="font-bold">
          {{ DV_conversationInfo.consumer_name }} | 
          {{ formatPhoneNumber(DV_conversationInfo.phone_number) }}
        </h3>
        <span v-if="P_conversationStartTime"> Started on {{moment(P_conversationStartTime.toDate()).format('MM-DD-YYYY')}} at {{moment(P_conversationStartTime.toDate()).format('h:mm A')}}</span>
        <span v-if="P_conversationEndTime"> Finished on {{moment(P_conversationEndTime.toDate()).format('MM-DD-YYYY')}} at {{moment(P_conversationEndTime.toDate()).format('h:mm A')}}</span>
        <div v-show="availableDownloadReceipts(P_consumerUid, P_conversationUid).length > 0" class="btn btn-lime absolute top-0 right-32" :class="{'cursor-not-allowed': DV_downloadingReceipts['loading']}" @click="startReceiptDownloads(P_consumerUid, P_conversationUid, moment(P_conversationStartTime.toDate()).format('MM-DD-YYYY'), moment(P_conversationEndTime.toDate()).format('MM-DD-YYYY'), $event)">
          {{ (DV_downloadingReceipts['loading'] && DV_downloadingReceipts['conversationUid'] == P_conversationUid) ? "Downloading..." : "Download Receipt(s)" }}
        </div>
      </div>
      
      <!-- Body -->
      <template slot="body">
        <div class="pb-10">
          <c7CustomerChat
            v-if="DV_showModal"
            :P_consumerUid="P_consumerUid"
            :P_conversationUid="P_conversationUid"
            :P_readOnly="true"
            :is-modal="true"
            :P_isChatModuleParent="P_isChatModuleParent">
          </c7CustomerChat>
        </div>
      </template>
    </PopupModal>
  </div>
</template>

<script>
import moment from 'moment';
import SearchBar from './SearchBar.vue';
import c7CustomerChat from "../c1/c7CustomerChat.vue";
import PopupModal from './PopupModal.vue';
import m2MiscDataFetchers from "../../mixins/m2MiscDataFetchers"
import generalFunctions from '../../mixins/generalFunctions';

export default {
  name: "mod1CustomerChatLog",

  mixins: [ m2MiscDataFetchers, generalFunctions ],

  props: ['P_consumerUid', 'P_conversationUid', 'P_conversationStartTime', 'P_conversationEndTime', 'P_isChatModuleParent'],

  components: { SearchBar, c7CustomerChat, PopupModal },

  data() {
    return {
      DV_ccIndexes: window.G_bi2_data.IV_al_consumer_contact_index,
      DV_consumerContact: null,
      DV_conversationInfo: null,
      DV_consumerConversationUuid: "",
      DV_showModal: false,
      moment: moment,
      DV_consumerConversationPaymentLinks: window.G_bi2_data.IV_consumer_conversation_payment_links,
    };
  },

  mounted() {
    this.UpdateLoadedConversation();
  },

  watch:{
    P_consumerUid() {
      this.UpdateLoadedConversation();
    },
    
    P_conversationUid() {
      this.UpdateLoadedConversation();
    },
  },

  methods: {
    UpdateLoadedConversation() {
      if (!this.P_consumerUid || !this.P_conversationUid ) { return;}
      this.DV_consumerConversationUuid = this.P_consumerUid + "-" + this.P_conversationUid;

      window.G_bi2_data.startConsumerConversationMessagesListener(
        window.G_bi2_data,
        window.G_bi2_data.IV_active_employee_info.business_uid,
        window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
        this.P_consumerUid,
        this.P_conversationUid
      );

      this.DV_consumerContact = window.G_bi2_data.IV_consumer_contacts.value[this.P_consumerUid];
      this.DV_conversationInfo = window.G_bi2_data.IV_consumer_conversations.value[this.DV_consumerConversationUuid];
    },

    showSelf() {
      this.UpdateLoadedConversation();
      this.DV_showModal = true;
      this.$refs.mod1CustomerChatLog.show();
    },

    closeSelf() {
      this.$refs.mod1CustomerChatLog.hide();
      this.DV_showModal = false;
    }
  }
};
</script>

<style>
.dropdown-menu {
    max-height: 280px;
    overflow-y: auto;
}
</style>
