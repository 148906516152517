<template>
  <div id="mod3">
    <PopupModal ref="mod3CustomerFeedback" :P_fullModal="true" :P_showClose="true" @close-modal="closeSelf">
      
      <!-- Title -->
      <div slot="title"> 
        <h3  class="font-bold"></h3>
      </div>
      
      <!-- Body -->
      <template slot="body" style="height: 60vh">
        <div class="pb-10">
          <c3CustomerFeedback :P_consumerUid="P_consumerUid" :P_showingWithinModal="true"></c3CustomerFeedback>
        </div>
      </template>
    </PopupModal>
  </div>
</template>

<script>
import moment from 'moment';
import PopupModal from './PopupModal.vue';
import c3CustomerFeedback from '../c1/c3CustomerFeedback.vue'

export default {
  name: "mod3CustomerFeedback",

  props: ['P_consumerUid'],

  components: {PopupModal, c3CustomerFeedback },

  data() {
    return {
      DV_ccIndexes: window.G_bi2_data.IV_al_consumer_contact_index,
      DV_consumerContact: null,
      moment: moment
    };
  },

  mounted() {
    this.loadConsumerData()
  },

  watch: {
    P_consumerUid() { 
      this.loadConsumerData();
    }
  },

  methods: {
    loadConsumerData() {
      if (this.P_consumerUid in window.G_bi2_data.IV_consumer_contacts === false)  {
        return;
      }
    },

    showSelf() {
      this.$refs.mod3CustomerFeedback.show();
    },

    closeSelf() {
      this.$refs.mod3CustomerFeedback.hide();
    },
  },
};
</script>
