import { fs46EmployeeActiveConsumerConversationJoins } from "../firestore_schemas/fs46EmployeeActiveConsumerConversationJoins"
import { getDocFrom_fs46EmployeeActiveConsumerConversationJoins } from "../firestore_schemas/fs46EmployeeActiveConsumerConversationJoins"
import { addDocIn_fs46EmployeeActiveConsumerConversationJoins } from "../firestore_schemas/fs46EmployeeActiveConsumerConversationJoins"
import { removeDocFrom_fs46EmployeeActiveConsumerConversationJoins } from "../firestore_schemas/fs46EmployeeActiveConsumerConversationJoins"

type Result = {
  debug_data: Array<string>;
  return_msg: string;
  success: boolean;
  error_title: string;
  error_summary: string;
  error_next_steps: string;
};

type MetaMatch = `ac_${string}`;

export async function fs46Writer(
  w_type: string,
  fs1_id: string,
  fs2_id: string,
  fs3_employee_uid="",
  fs4_consumer_contact_uid="",
  fs6_conversation_uid=""
) {

  const retval: Result = {
    debug_data: [],
    return_msg: "fs46_writer:fs46Writer ",
    success: true,
    error_title: "",
    error_summary: "",
    error_next_steps: "",
  };

  /// Validate arguments according to write type
  if (!fs1_id || !fs2_id) {
    retval.success = false;
    retval.return_msg += "Insufficient arguments.";
    retval.error_title = "Insufficient arguments.";
    retval.error_summary = "business_uid or recycler_uid not set.";
    retval.error_next_steps = "Please provide correct business_uid snd recycler_uid.";
    return retval;
  }

  if (w_type == "e" || w_type == "d" && (!fs3_employee_uid || !fs6_conversation_uid || !fs4_consumer_contact_uid)) {
    retval.success = false;
    retval.return_msg += "Insufficient arguments.";
    retval.error_title = "Insufficient arguments.";
    retval.error_summary = "fs3_employee_uid or fs6_conversation_uid is not set.";
    retval.error_next_steps = "Please provide correct fs3_employee_uid and fs6_conversation_uid.";
    return retval;
  }
  /// <END> Validate arguments according to write type

  // Delete record
  if (w_type == "d") {
    await removeDocFrom_fs46EmployeeActiveConsumerConversationJoins(fs1_id, fs2_id, fs3_employee_uid, fs4_consumer_contact_uid, fs6_conversation_uid)
    return retval
  }

  // Create new record
  if (w_type == "n") {
    const dynamicKey: MetaMatch = `ac_${fs4_consumer_contact_uid}_${fs6_conversation_uid}`;

    const fs46Data: { [key: string]: true | Date } & fs46EmployeeActiveConsumerConversationJoins = {
      not_empty_document: true,
      [dynamicKey]: new Date()
    } as { [key: string]: true | Date } & fs46EmployeeActiveConsumerConversationJoins;;

    await addDocIn_fs46EmployeeActiveConsumerConversationJoins(
      fs1_id,
      fs2_id,
      fs3_employee_uid,
      fs46Data
    )
    
    return retval
  }

  // TODO - check if we need edit/update?
  // TODO - handle edit/upsert operations?
}

function firestoreWriteError(error:any) {
  // TODO - complete this
}
