<template>
  <div>
    <div id="c1m1" v-show="customerInfo == 'moreAction'">

      <!-- Heading -->
      <div class="flex flex-row items-center justify-between mx-2 mt-2">
        <svg @click="$emit('show-live-chat-action')" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"/>
        </svg>

        <h1 class="font-bold text-sm">More Actions</h1>
        <svg @click="$emit('show-live-chat-action')" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"/>
        </svg>
      </div>

      <!-- Conversation detail -->
      <div class="grid grid-cols-7 gap-2 px-2 items-center cursor-pointer my-5 mx-2">
        <div class="col-span-1 h-full items-start">
          <h1 class="font-bold text-lg text-left">B Q</h1>
        </div>
        <div class="col-span-6">
          <p class="text-left text-sm my-1">
            John Miller | Downtown Repair | Body Shop | Quotes: 12435653($1000)
            132456($100)
          </p>
        </div>
      </div>

      <!-- Buttons -->
      <div class="grid grid-cols-2 items-center gap-2 mb-3 mx-2">
        <button @click="customerInfo = 'profile'" class="view-btn">
          Customer Profile
        </button>

        <button @click="customerInfo = 'paymentLinkHistory'" class="view-btn">
          Payment Link History
        </button>
      </div>

      <!-- Buttons -->
      <div class="grid grid-cols-2 items-center gap-2 mb-3 mx-2">
        <button @click="customerInfo = 'conversationHistory'" class="view-btn">
          Conversation History
        </button>

        <button @click="customerInfo = 'customerFeedbackLog'" class="view-btn">
          Customer Notes
        </button>
      </div>

      <!-- Buttons -->
      <div class="grid grid-cols-2 items-center gap-2 mb-3 mx-2">
        <button class="view-btn">
          Customer Vehicle History
        </button>

        <button class="view-btn">
          Yard Only Chat
        </button>
      </div>

      <!-- Button -->
      <div class="grid grid-cols-2 items-center gap-2 mb-3 mx-2">
        <button class="view-btn">
          Invite Team Member to Join Conversation
        </button>
      </div>

      <!-- Button -->
      <div class="grid grid-cols-1 items-center gap-2 mb-3 mx-2">
        <button @click="$emit('show-live-chat-action')" class="view-btn">
          Return To Live Chat
        </button>
      </div>
    </div>

    <c1c6CustomerProfile
      v-show="customerInfo == 'profile'"
      :consumer_uid = this.consumer_uid
      @show-more-actions="customerInfo = 'moreAction'"
    />

    <c1c4ConversationHistory
      v-show="customerInfo == 'conversationHistory'"
      :consumer_uid = this.consumer_uid
      @show-more-actions="customerInfo = 'moreAction'"
    />

    <p1s1c2c4PaymentLinksHistory
      v-show="customerInfo == 'paymentLinkHistory'"
      :conversation_uid = this.conversation_uid
      @show-more-actions="customerInfo = 'moreAction'"
    />

    <c1c5CustomerFeedbackLog
      v-show="customerInfo == 'customerFeedbackLog'"
      :consumer_uid = this.consumer_uid
      @show-more-actions="customerInfo = 'moreAction'"
    />
  </div>
</template>

<script>
import p1s1c2c4PaymentLinksHistory from "../p1/p1s1c2c4PaymentLinksHistory.vue";
import c1c5CustomerFeedbackLog from "./c1c5CustomerFeedbackLog.vue";
import c1c4ConversationHistory from "./c1c4ConversationHistory.vue";
import c1c6CustomerProfile from "../c1/c1c6CustomerProfile.vue";

export default {
  name: "c1m1MoreActions",

  props: ["P_conversationUuid","P_consumerUid"],
  data() {
    return {
      customerInfo: "moreAction",
    };
  },

  components: { c1c6CustomerProfile, c1c4ConversationHistory, p1s1c2c4PaymentLinksHistory, c1c5CustomerFeedbackLog},
};
</script>
