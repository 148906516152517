<template>
  <div>
    <div class="flex w-full grow mt-2 text-center border-2 border-slate-300 rounded-xl p-2">
      <span class="w-full">First Name: </span>
      <div class="w-full">
        <input autocomplete="off" v-model="DV_firstName" @input="DV_firstNameError = ''" type="text">
        <div class="text-left w-full">
          <span v-if="this.DV_firstNameError !== ''" class="text-sm text-red-400">{{ this.DV_firstNameError }}</span>
        </div>
      </div>
    </div>

    <div class="flex w-full grow mt-2 text-center border-2 border-slate-300 rounded-xl p-2">
      <span class="w-full">Last Name:</span>
      <div class="w-full">
        <input autocomplete="off" v-model="DV_lastName" @input="DV_lastNameError = ''" type="text">
        <div class="text-left w-full">
          <span v-if="this.DV_lastNameError !== ''" class="text-sm text-red-400">{{ this.DV_lastNameError }}</span>
        </div>
      </div>
    </div>

    <div class="flex w-full grow mt-2 text-center border-2 border-slate-300 rounded-xl p-2">
      <span class="w-full">Email:</span>
      <div class="w-full">
        <input autocomplete="off" v-model="DV_email" @input="DV_emailError = ''" type="text">
        <div class="text-left w-full">
          <span v-if="this.DV_emailError !== ''" class="text-sm text-red-400">{{ this.DV_emailError }}</span>
        </div>
      </div>
    </div>

    <div class="flex w-full grow mt-2 text-center border-2 border-slate-300 rounded-xl p-2">
      <span class="w-full">Password:</span>
      <div class="w-full text-left">
        <a href="Javascript:;" @click.stop="confirmAndResetPassword" class="text-blue-500">(Change Password)</a>
      </div>
    </div>

    <div class="flex w-full grow mt-2 text-center border-2 border-slate-300 rounded-xl p-2">
      <span class="inline-flex items-center justify-center text-center w-full">Manage Payment Method:</span>
      <div class="w-full text-left">
        <div class="flex mb-2">
          <input v-if="C_notTrialAccount" disable="true" :value="C_cardDetails" type="text" class="!w-52 mr-4">
          <a href="Javascript:;" @click.stop="updatePaymentMethod" class="text-blue-500">(Update Payment Method)</a>
        </div>
        <span v-if="C_notTrialAccount" class="">Next billing Date: {{ C_nextBillingDate }}</span>
      </div>
    </div>

    <div v-if="!DV_savingInformation" class="flex w-full grow mt-2 text-center border-2 border-slate-300 rounded-xl p-2">
      <div class="w-full flex justify-end">
        <button :disabled="DV_savingInformation" class="btn btn-red w-36 text-sm mr-1" @click.stop="confirmAndInitiateDeleteAccount">Delete Account</button>
      </div>
    </div>

    <div class="w-full mt-12 flex items-center justify-between">
      <button :disabled="DV_savingInformation" @click.stop="resetChanges" class="btn btn-white text-sm mr-3 w-36">Discard Changes</button>
      <button :disabled="DV_savingInformation" @click.stop="saveChanges" class="btn btn-blue text-sm mr-3 w-36">{{DV_savingInformation ? "Saving Changes..." : "Save Changes"}}</button>
    </div>

    <PopupModal ref="updatePaymentModal" :P_showClose="true" @close-modal="closeUpdatePaymentModal">
      <div slot="title">Enter Card Details</div> 
      <div slot="body">
        <div class="flex flex-col items-start mt-4 w-full">
          <div class="flex flex-col w-full mt-2">
            <span class="text-left">Full Name: </span>
            <input autocomplete="off" type="text">
          </div>
  
          <div class="flex flex-col w-full mt-8">
              <span class="text-left">Card Details:</span> 
              <div class="w-full block w-full rounded py-1 border border-gray-300 px-3 sm:text-sm" id="card-element"></div>
              <div id="card-errors" role="alert"></div>
          </div>
  
          <div 
            class="w-full mt-12 gap-10 flex flex-row justify-center items-center">
            <button :disabled="DV_cardVerificationInProgress" @click.stop="verifyCard()" class="btn btn-lime text-base">{{DV_cardVerificationInProgress ? 'Verifying Details...' : 'Update Details'}}</button>
          </div>
        </div>
      </div>

    </PopupModal>
  </div>
</template>

<script>
import AWN from "awesome-notifications";
import moment from 'moment';
import { fs3Writer } from '../../includes/firestore_writers/fs3_writer.ts'
import dataValidator from "../../includes/bi1Datavalidation"
import { CR, RC } from "../../includes/bi2SuccessCodes"
import PopupModal from "../shared/PopupModal.vue";
import verifyCardDetails from "../../includes/server_calls/p1s7/p1s7e3"

export default {
  name: "p4s1AccountSetting",

  props: [],

  components: { PopupModal },

  data() {
    return {
      DV_firstName: "",
      DV_firstNameError: "",
      DV_lastName: "",
      DV_lastNameError: "",
      DV_email: "",
      DV_emailError: "",
      DV_savingInformation: false,
      DV_cardVerificationInProgress: false,
      cardStyle: {
        iconStyle: 'solid',
        style: {
          base: {
            fontSmoothing: 'antialiased',
            '::placeholder': {
              color: "#bbb"
            },
          },
        },
      }
    }
  },

  mounted() {
    const CI = this;
    CI.resetChanges();
    CI.includeStripe('js.stripe.com/v3/', function() {
      CI.populateStripeElement();
    }.bind(CI) );
  },

  computed: {
    //
    C_paymentInfo() {
      return window.G_bi2_data.IV_payment_method.value[1]
    },

    C_cardDetails() {
      return `**** **** **** ${this.C_paymentInfo.last_4_digits}     ${this.C_paymentInfo.card_type}`
    },

    C_nextBillingDate() {
      return `${moment(this.C_paymentInfo.creation_day.seconds * 1000).format('MM/DD/YYYY')}`
    },

    C_notTrialAccount() {
      return (this.C_paymentInfo.customer_id != null && this.C_paymentInfo.customer_id != 'undefined' && !this.C_paymentInfo.customer_id.includes('trial_account'))
    }
  },

  methods: {
    confirmAndResetPassword() {
      const CI = this;

      let notifier = new AWN();
      
      notifier.confirm(
        
        // Label
        "System will send you an email with instructions to set new password. Are you sure you want to change your password?",
        
        // On OK
        function() {
          window.G_firebase_auth.resetAccountPassword(window.G_bi2_data.IV_loggedin_user_info.email);
          CI.$awn.success("You will recieve the email shortly.");
        },

        // On cancel
        function() {},

        // Other options
        {
          labels: {
            confirm: 'Send Password Reset Email',
            confirmOk: "Confirm",
            confirmCancel: "Cancel"
          }
        }
      );
    },

    confirmAndInitiateDeleteAccount() {
      this.$awn.info("To delete your account, please contact support at info@texnrewards.com");
    },

    resetChanges() {
      let activeEmployeeUid = window.G_bi2_data.IV_active_employee_info.employee_uid;
    
      if (activeEmployeeUid in window.G_bi2_data.IV_recycler_employees.value === true) {
        let employeeInfo = window.G_bi2_data.IV_recycler_employees.value[activeEmployeeUid];

        this.DV_firstName = employeeInfo.first_name;
        this.DV_lastName = employeeInfo.last_name;
        this.DV_email = employeeInfo.email_address;
      }
    },

    async saveChanges() {
      const CI = this;

      CI.DV_savingInformation = true;

      let fail_flag = false;

      let call_result = dataValidator.is_string(CI.DV_firstName, 3, 200);
      if (call_result[CR.success] !== RC.success) {
        CI.DV_firstNameError = "First name cannot be empty or less than 3 letters.";
        fail_flag = true;
      }

      call_result = dataValidator.is_string(CI.DV_lastName, 3, 200);
      if (call_result[CR.success] !== RC.success) {
        CI.DV_lastNameError = "Last name cannot be empty or less than 3 letters.";
        fail_flag = true;
      }

      call_result = dataValidator.is_email_address(CI.DV_email);
      if (call_result[CR.success] !== RC.success) {
        CI.DV_emailError = "Email address is invalid";
        fail_flag = true;
      }

      if (fail_flag === true) {
        this.$awn.alert("Please fill in the required information.");
        CI.DV_savingInformation = false;
        return;
      }

      let data = {
        email_address: CI.DV_emailError,
        employee_uid: window.G_bi2_data.IV_active_employee_info.employee_uid,
        first_name: CI.DV_firstName,
        last_name: CI.DV_lastName,
        phone_number: null, // not changing
        extension: null, // not changing
        permissions: null // not changing
      }

      let result = await fs3Writer(
        "e",
        window.G_bi2_data.IV_active_employee_info.business_uid,
        window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
        window.G_bi2_data.IV_active_employee_info.employee_uid,
        data,
        null // permissions not changing
      )

      if (result.success == true) {
        this.$awn.success("Account settings updated successfully.");
        CI.DV_savingInformation = false;
      } else {
        this.$awn.alert("There was an issue while updating account settings. "+result.error_next_steps);
        CI.DV_savingInformation = false;
      }
    },

    updatePaymentMethod() {
      this.$refs.updatePaymentModal.show();
    },

    closeUpdatePaymentModal() {
      this.$refs.updatePaymentModal.hide();
    },

    includeStripe( URL, callback ){
      let documentTag = document, tag = 'script',
      object = documentTag.createElement(tag),
      scriptTag = documentTag.getElementsByTagName(tag)[0];
      object.src = '//' + URL;
      
      if (callback) { object.addEventListener('load', function (e) { callback(null, e); }, false); }
      scriptTag.parentNode.insertBefore(object, scriptTag);
    },

    populateStripeElement() {
      // We only get stripe key if user has permissions to get payments

      this.stripe = Stripe("pk_live_51MAej2LhOYag7rNDyo0sUg6zBxUMbE0JxuHvFL36VNOGdcvA84UOaFJrYHZmqZpwHyv2tCjaynsSV7tWIXevNBsx00Dlx2q5T8");
      var elements = this.stripe.elements();
      this.cardElement = elements.create('card', this.cardStyle);
      this.cardElement.mount('#card-element',);
    },

    verifyCard() {
      const CI = this;

      CI.DV_cardVerificationInProgress = true;

      CI.stripe.createToken(CI.cardElement).then(function(result) {
        if (result.error) {
          CI.$awn.alert("There was an issue verifying the card details. Please make sure you are typing in the correct details.");
          CI.DV_cardVerificationInProgress = false;
        } else {
          // Send the token to your server
          CI.stripeTokenHandler(result.token);
        }
      });
    },

    stripeTokenHandler(token) {
      const CI = this;

      let error_text = "There was an issue verifying the card details. Please make sure you are typing in the correct details.";

      verifyCardDetails(
        token.id,
        2,
        window.G_bi2_data.IV_active_employee_info.business_uid
      ).then((resp) => {
        // Success
        if (resp.success === true) {
          CI.DV_cardVerificationInProgress = false;
          CI.$awn.success("Successfully updated card details.");
          CI.closeUpdatePaymentModal();
          return;
        }

        // Fail
        CI.DV_cardVerificationInProgress = false;
        if (resp.msg) { error_text = resp.msg };
        CI.$awn.alert(error_text);
      }).catch((error) => {

        // Fail
        CI.DV_cardVerificationInProgress = false;
        if (error.error_msg) { error_text = error.error_msg };
        CI.$awn.alert(error_text);
        CI.closeUpdatePaymentModal();
      });
    }
  },

  watch: {
    // 
  }
}
</script>