import { FirestoreDB } from "./include/creds";
import {
  DocPath,
  DataProblem,
  // isUrlValid,
  // isStringTooLong,
  // OurLogger,
  RemoveDocResult,
  GetDocResult,
} from "./include/misc";
// const logger: OurLogger = OurLogger.getInstance();

import {
  getFirestore,
  CollectionReference,
  collection,
  DocumentData,
  doc,
  setDoc,
  getDocs,
  getDoc,
  deleteDoc,
} from "firebase/firestore";
import { watch } from "vue";

export type fs13EmployeeFeedbackMessages = {
  message_uid?: string;
  conversation_uid: string;
  message_type: 1 | 2;
  uploaded_file_link?: string;
  employee_uid: string;
  message_content: string;
  timestamp: Date;
  rating?: number;
};

export function getDataProblems_fs13EmployeeFeedbackMessages(
  data: fs13EmployeeFeedbackMessages
): DataProblem[] {
  const dataproblems: DataProblem[] = [];
  return dataproblems;
}

export function isValidData(data: fs13EmployeeFeedbackMessages): boolean {
  // switch (data.message_type) {
  //   case 1:
  //     if (data.message_content === undefined) {
  //       return false;
  //     } else if (data.uploaded_file_link !== undefined) {
  //       // logger.error("Data validation error");
  //       return false;
  //     }
  //     break;
  //   case 2:
  //     if (data.uploaded_file_link === undefined) {
  //       // logger.error("Data validation error");
  //       return false;
  //     }
  //     break;
  // }

  // if (
  //   data.uploaded_file_link !== undefined &&
  //   !isUrlValid(data.uploaded_file_link)
  // ) {
  //   // logger.error("Data validation error");
  //   return false;
  // }

  // if (
  //   data.message_content !== undefined &&
  //   isStringTooLong(data.message_content, 4000)
  // ) {
  //   // logger.error("Data validation error");
  //   return false;
  // }

  return true;
}

export async function getDocFrom_fs13EmployeeFeedbackMessages(
  fs1_id: string,
  fs2_id: string,
  fs4_id: string,
  fs13_id: string
): Promise<GetDocResult> {
  const result: GetDocResult = {
    debug_data: [],
    return_msg:
      "fs13EmployeeFeedbackMessages:getDocFrom_fs13EmployeeFeedbackMessages",
    success: true,
    doc_data: {},
  };

  if (!fs1_id || !fs2_id || !fs4_id || !fs13_id) {
    result.success = false;
    result.return_msg += "Invalid function arguments.";
    return await Promise.resolve(result);
  }

  const doc_ref = doc(
    collection(
      FirestoreDB,
      "fs1RecyclerBusiness",
      fs1_id,
      "fs2RecyclerLocation",
      fs2_id,
      "fs4ConsumerContacts",
      fs4_id,
      "fs13EmployeeFeedbackMessages",
      fs13_id
    )
  );

  const doc_data = (await getDoc(doc_ref)).data();
  if (doc_data === undefined) {
    result.success = false;
    result.return_msg +=
      "Could not find a document with the passed-in path arguments.";
    return await Promise.resolve(result);
  }

  result.doc_data = doc_data;
  return await Promise.resolve(result);
}

export async function addDocIn_fs13EmployeeFeedbackMessages(
  fs1_id: string,
  fs2_id: string,
  fs4_id: string,
  data: fs13EmployeeFeedbackMessages
): Promise<DocPath> {
  const result: DocPath = {
    id: "",
    path: "",
  };
  if (!isValidData(data)) {
    return await Promise.resolve(result);
  }

  const doc_ref = doc(
    collection(
      FirestoreDB,
      "fs1RecyclerBusiness",
      fs1_id,
      "fs2RecyclerLocation",
      fs2_id,
      "fs4ConsumerContacts",
      fs4_id,
      "fs13EmployeeFeedbackMessages"
    )
  );

  await setDoc(doc_ref, data);

  result.id = doc_ref.id;
  result.path = doc_ref.path;

  const written_doc_ref = doc(
      FirestoreDB,
      "fs1RecyclerBusiness",
      fs1_id,
      "fs2RecyclerLocation",
      fs2_id,
      "fs4ConsumerContacts",
      fs4_id,
      "fs13EmployeeFeedbackMessages",
      doc_ref.id
      );

  data.message_uid = doc_ref.id;
  
  await setDoc(written_doc_ref, data);

  return await Promise.resolve(result);
}

export async function setDocIn_fs13EmployeeFeedbackMessages(
  fs1_id: string,
  fs2_id: string,
  fs4_id: string,
  fs13_id: string,
  data: fs13EmployeeFeedbackMessages
): Promise<DocPath> {
  const result: DocPath = {
    id: "",
    path: "",
  };
  if (!isValidData(data)) {
    return await Promise.resolve(result);
  }

  const doc_ref = doc(
    collection(
      FirestoreDB,
      "fs1RecyclerBusiness",
      fs1_id,
      "fs2RecyclerLocation",
      fs2_id,
      "fs4ConsumerContacts",
      fs4_id,
      "fs13EmployeeFeedbackMessages",
      fs13_id
    )
  );

  await setDoc(doc_ref, data);

  result.id = doc_ref.id;
  result.path = doc_ref.path;
  return await Promise.resolve(result);
}

export async function removeDocFrom_fs13EmployeeFeedbackMessages(
  fs1_id: string,
  fs2_id: string,
  fs4_id: string,
  fs13_id: string
): Promise<RemoveDocResult> {
  const result: RemoveDocResult = {
    debug_data: [],
    return_msg:
      "fs13EmployeeFeedbackMessages:removeDocFrom_fs13EmployeeFeedbackMessages ",
    success: true,
  };

  if (!fs1_id || !fs2_id || !fs4_id || !fs13_id) {
    result.success = false;
    result.return_msg += "Invalid function arguments.";
    return await Promise.resolve(result);
  }

  const doc_ref = doc(
    collection(
      FirestoreDB,
      "fs1RecyclerBusiness",
      fs1_id,
      "fs2RecyclerLocation",
      fs2_id,
      "fs4ConsumerContacts",
      fs4_id,
      "fs13EmployeeFeedbackMessages",
      fs13_id
    )
  );

  await deleteDoc(doc_ref);
  return await Promise.resolve(result);
}
