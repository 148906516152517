<template>
  <div id="p1s3c7" class="hide-scrollbar">
    <h1 class="text-2xl pl-5 pt-5 font-bold text-left">Filters</h1>
    <div class="flex flex-col items-center pt-5 gap-5 overflow-y-scroll hide-scrollbar">

      <!-- Business type filter -->
      <h1 class="font-bold text-left">Business Type</h1>
      <span class=" lg:whitespace-nowrap flex flex-wrap gap-4 justify-between w-full px-5">
        <span class="flex items-center">
          <input type="checkbox" id="customer-type-none-specified" value="none-specified" v-model="DV_BusinesssTypeFilter" class="cursor-pointer">
          <label for="customer-type-none-specified" class="ml-2 cursor-pointer">None Specified</label>
        </span>

        <span class="flex items-center">
          <input type="checkbox" id="body-shop" value="body-shop" v-model="DV_BusinesssTypeFilter" class="cursor-pointer">
          <label for="body-shop" class="ml-2 cursor-pointer">Body Shop</label>
        </span>

        <span class="flex items-center">
          <input type="checkbox" id="exporter" value="exporter" v-model="DV_BusinesssTypeFilter" class="cursor-pointer">
          <label for="exporter" class="ml-2 cursor-pointer">Exporter</label>
        </span>

        <span class="flex items-center">
          <input type="checkbox" id="insurance-adjuster" value="insurance-adjuster" name="insurance-adjuster" v-model="DV_BusinesssTypeFilter" class="cursor-pointer">
          <label for="insurance-adjuster" class="ml-2 cursor-pointer">Insurance Adjuster</label>
        </span>

        <span class="flex items-center">
          <input type="checkbox" id="mechanic" value="mechanic" v-model="DV_BusinesssTypeFilter" class="cursor-pointer">
          <label for="mechanic" class="ml-2 cursor-pointer">Mechanic</label>
        </span>

        <span class="flex items-center ml-2">
          <input type="checkbox" id="reseller" value="reseller" v-model="DV_BusinesssTypeFilter" class="cursor-pointer">
          <label for="reseller" class="ml-2 cursor-pointer">Reseller</label>
        </span>

        <span class="flex items-center">
          <input type="checkbox" id="retail" value="retail" v-model="DV_BusinesssTypeFilter" class="cursor-pointer">
          <label for="retail" class="ml-2 cursor-pointer">Retail</label>
        </span>
      </span>


      <!-- City filter -->
      <div class="flex items-center justify-between w-[98%]">
        <div class="text-center w-1/2">
          <label class="font-bold" for="city">City</label>
          <select
            id="city"
            name="city"
            class="w-full rounded-md border border-gray-400 py-2 pl-2 pr-5 mt-1 text-gray-500 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            @change="selectBusinessCityFilter($event.target.value)"
          >
            <option v-for="city in P_citiesList">
              {{city}}
            </option>
          </select>
        </div>

        <!-- State filter -->
        <div class="text-center w-1/2 ml-2">
          <label class="font-bold" for="state">State</label>
          <select
            id="state"
            name="state"
            class="w-full rounded-md border border-gray-400 py-2 pl-2 pr-5 mt-1 text-gray-500 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            @change="selectBusinessStateFilter($event.target.value)"
          >
            <option v-for="state in P_statesList">
              {{ state }}
            </option>
          </select>
        </div>
      </div>
    
      <!-- Interaction filter -->
      <div v-if="DV_showUnfinishedFeatures" class="my-5">
        <h1 class="text-sm font-medium text-left mb-2">
          Last Completed Interaction
        </h1>
        <span class="isolate inline-flex rounded-md shadow-sm w-full mb-2">
          <button type="button" class="filter-button left">
            All Time
          </button>
          <button type="button" class="filter-button">
            8 Hours
          </button>
          <button type="button" class="filter-button">
            3 Days
          </button>
          <button type="button" class="filter-button right">
            1 Week
          </button>
        </span>

        <span  class="isolate inline-flex rounded-md shadow-sm w-full mb-2">
          <button type="button" class="filter-button left">
            1 Month
          </button>
          <button type="button" class="filter-button">
            3 Month
          </button>
          <button type="button" class="filter-button">
            1 Year
          </button>
          <button type="button" class="filter-button right">
            Custom Range
          </button>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "p1s3c7BusinessFilters",

  props: ["P_citiesList", "P_statesList"],

  data() {
    return {
      DV_BusinesssTypeFilter: ["none-specified", "exporter", "insurance-adjuster", "retail", "body-shop", "mechanic", "reseller"],
      DV_showUnfinishedFeatures: window.G_show_unfinished_features,

    };
  },

  methods: {
    selectBusinessCityFilter(filter) {
      this.$emit("event-business-city-filter-selected", filter)
    },

    selectBusinessStateFilter(filter) {
      this.$emit("event-business-state-filter-selected", filter)
    }
  },

  watch: {
    DV_BusinesssTypeFilter() {
      this.$emit("event-business-customer-type-filter-selected", this.DV_BusinesssTypeFilter);
    },

  }
};
</script>
