<template>
  <div id="p1s1c1c5">

    <!-- Search section  -->
    <SearchBar containerClasses="mt-2" inputClasses="w-9/12" />

    <!-- Filter buttons  -->
    <div class="grid grid-cols-5 gap-2 items-center my-3">
      <h1 class="font-medium">Sort By:</h1>
      <div class="col-span-4">
        <span class="isolate inline-flex w-full">
          <button v-if="window.G_show_unfinished_features" type="button" class="filter-button left">
            Vehicle
          </button>
          <button type="button" class="filter-button">
            Date Started
          </button>
          <button type="button" class="filter-button right">
            Date Finished
          </button>
        </span>
      </div>
    </div>

    <!-- Previous conversations list -->
    <div v-if="DV_conversationHistory.length > 0" class="border border-gray-400 rounded-xl shadow-sm">
      <div v-for="item in DV_conversationHistory" :key="index" class="flex items-center justify-between border-b p-2 text-left">
        <div>
          <p class="text-sm font-medium">
            {{item.consumer_name}} <span>{{item.vehicle_name}}</span>
          </p>
          <p class="font-medium text-sm">
            Started: <span>item.start_time</span>
          </p>
          <p class="font-medium text-sm">
            Finished: <span>item.end_time</span>
          </p>
        </div>
        <div>
          <button @click.stop="openConversation(index)" class="btn btn-lime px-1">
            Open Conversation
          </button>
        </div>
      </div>
    </div>
    <div v-else>
      No conversation history record found for this consumer contact!
    </div>
  </div>
</template>

<script>
import SearchBar from '../shared/SearchBar.vue';

export default {
  name: "p1s1c1c5CustomerHistory",

  components: { SearchBar },

  data() {
    return {
      DV_conversationHistory: []
    }
  },

  methods: {
    openConversation(index) {
      this.$emit('event-open-conversation', index);
    }
  }
};
</script>
