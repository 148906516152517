import { FirestoreDB } from "./include/creds";
import {
  DocPath,
  DataProblem,
  GetDocResult,
  RemoveDocResult,
} from "./include/misc";

import {
  collection,
  doc,
  deleteDoc,
  setDoc,
  getDoc,
} from "firebase/firestore";

export type fs43RecentQueueEntries = {
  conversation_uid: string;
  consumer_contact_uid: string;
};

export function getDataProblems_fs43RecentQueueEntries(
  data: fs43RecentQueueEntries
): DataProblem[] {
  const dataproblems: DataProblem[] = [];
  return dataproblems;
}

export function isValidData(data: fs43RecentQueueEntries): boolean {
  return true;
}

export async function getDocFrom_fs43RecentQueueEntries(
  fs1_id: string,
  fs2_id: string,
  fs4_consumer_contact_uid: string,
  fs27_conversation_uid: string
): Promise<GetDocResult> {
  const result: GetDocResult = {
    debug_data: [],
    return_msg: "fs43RecentQueueEntries:getDocFrom_fs43RecentQueueEntries ",
    success: true,
    doc_data: {},
  };

  if (!fs1_id || !fs2_id || !fs4_consumer_contact_uid || !fs27_conversation_uid) {
    result.success = false;
    result.return_msg += "Invalid function arguments.";
    return await Promise.resolve(result);
  }

  const doc_ref = doc(
    collection(
      FirestoreDB,
      "fs1RecyclerBusiness",
      fs1_id,
      "fs2RecyclerLocation",
      fs2_id,
      "fs43RecentQueueEntries"
    ),
    `${fs4_consumer_contact_uid}_${fs27_conversation_uid}`
  );

  const doc_data = (await getDoc(doc_ref)).data();
  if (doc_data === undefined) {
    result.success = false;
    result.return_msg +=
      "Could not find a document with the passed-in path arguments.";
    return await Promise.resolve(result);
  }

  result.doc_data = doc_data;
  return await Promise.resolve(result);
}

export async function removeDocFrom_fs43RecentQueueEntries(
  fs1_id: string,
  fs2_id: string,
  fs27_consumer_uid: string,
  fs27_conversation_uid: string
): Promise<RemoveDocResult> {
  const result: RemoveDocResult = {
    debug_data: [],
    return_msg: "fs43RecentQueueEntries:getDocFrom_fs43RecentQueueEntries ",
    success: true,
  };

  if (!fs1_id || !fs2_id || !fs27_consumer_uid || !fs27_conversation_uid) {
    result.success = false;
    result.return_msg += "Invalid function arguments.";
    return await Promise.resolve(result);
  }

  const doc_ref = doc(
    collection(
      FirestoreDB,
      "fs1RecyclerBusiness",
      fs1_id,
      "fs2RecyclerLocation",
      fs2_id,
      "fs43RecentQueueEntries"
    ),
    `${fs27_consumer_uid}_${fs27_conversation_uid}`
  );

  await deleteDoc(doc_ref);
  return await Promise.resolve(result);
}

export async function setDocIn_fs43RecentQueueEntries(
  fs1_id: string,
  fs2_id: string,
  fs27_consumer_uid: string,
  fs27_conversation_uid: string,
  data: fs43RecentQueueEntries
): Promise<DocPath> {
  const result: DocPath = {
    id: "",
    path: "",
  };
  if (!isValidData(data)) {
    return await Promise.resolve(result);
  }

  const doc_ref = doc(
      FirestoreDB,
      "fs1RecyclerBusiness",
      fs1_id,
      "fs2RecyclerLocation",
      fs2_id,
      "fs43RecentQueueEntries",
      `${fs27_consumer_uid}_${fs27_conversation_uid}`
  );

  await setDoc(doc_ref, data);

  result.id = doc_ref.id;
  result.path = doc_ref.path;
  return await Promise.resolve(result);
}
