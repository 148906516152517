<template>
  <div id="p1s1c1m1" class="sm:h-[11%]">
    <!-- Mobile view -->
    <div class="md:hidden block">
      <div>
        <div class="flex flex-row items-center justify-between mb-3">
          <div class="">
            <button @click="$emit('show-queue')" class="flex items-center flex-row">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 pr-1">
                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"/>
              </svg>
            </button>
          </div>
          <h1 class="font-bold text-sm">Set Queue Filters</h1>
          <div class="flex flex-row items-center justify-end">
            <svg @click="$emit('show-queue')" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"/>
            </svg>
          </div>
        </div>
      </div>

      <!-- Customers list -->
      <div>
        <span class="isolate inline-flex rounded-md shadow-sm w-full">
          <button
            type="button"
            class="filter-button left"
            :class="{'active': P_customersTypeFilter == 'my'}"
            @click="$emit('event-set-customers-type-filter', 'my')"
          >
            My Customers
          </button>
          <button
            type="button"
            class="relative -ml-px filter-button right"
            :class="{'active': P_customersTypeFilter == 'all'}"
            @click="$emit('event-set-customers-type-filter', 'all')"
          >
            All Customers
          </button>
        </span>
      </div>
    </div>
    <div class="md:hidden flex justify-center mt-10">
      <button class="btn btn-lime btn-p w-7/12">Default Filters</button>
    </div>

    <!-- Filter buttons -->
    <div class="md:grid hidden grid-cols-4 gap-4 items-center mb-5">
      <h2 class="text-center text-md font-bold">Sort By:</h2>
      <div class="col-span-3">
        <span class="isolate inline-flex rounded-md shadow-sm w-full">
          <button
            type="button"
            class="filter-button left"
            :class="{'active': P_customersSortFilter == 'time'}"
            @click="$emit('event-set-customers-sort-filter', 'time')"
          >
            Wait Time
          </button>
          <button
            type="button"
            class="relative w-full -ml-px filter-button right"
            :class="{'active': P_customersSortFilter == 'rating'}"
            @click="$emit('event-set-customers-sort-filter', 'rating')"
          >
            Customer Rating
          </button>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "p1s1c1m1SetActiveFilters",
 
  props: ['P_customersTypeFilter', 'P_customersSortFilter'],
};
</script>

<style scoped>
button {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}
.btn-p {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
</style>
