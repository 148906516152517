<template>
  <div id="c1c7">

    <!-- Conversation detail -->
    <div class="grid grid-cols-7 gap-2 px-2 items-center cursor-pointer border-b">
      <div class="col-span-1 h-full items-start">
        <h1 class="font-bold text-lg text-left">B Q</h1>
      </div>
      <div class="col-span-6">
        <p class="text-left text-sm my-1">
          John Miller | Downtown Repair | Body Shop | Quotes: 12435653($1000)
          132456($100)
        </p>
      </div>
    </div>

    <!-- Heading -->
    <div class="grid grid-cols-3 items-center my-3 gap-2 mx-2">
      <h1 class="col-start-2 col-end-3 font-bold text-sm">
        Conversation History Entry
      </h1>
      <div class="col-span-1 flex h-full justify-end items-center">
        <svg @click="$emit('returnToParent')" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </div>
    </div>

    <!-- Payment Details -->
    <div class="grid grid-cols-2 gap-2 items-center mx-2">
      <button @click="$emit('paymentLinkHistory')" class="h-full btn btn-lime px-2 w-full text-sm">
        View <span>$150</span> in Payment Links
      </button>
      <p class="text-left text-sm fon-medium">
        Started on <span>01/09/2022</span> <br />
        Finished on <span>02/09/2022</span>
      </p>
    </div>

    <!-- Search bar  -->
    <SearchBar containerClasses="my-2" inputClasses="" />

    <!-- Chat messages -->
    <div class="border border-gray-400 rounded-xl p-3 text-left mt-3">
      <p>
        <span class="sender font-medium">Fred</span>
        <span class="time font-medium">2:50 pm:</span>
        <span class="sender-message">
          what parts do you need for the civic 2004?</span
        >
      </p>
      <p>
        <span class="receiver-customer font-medium">John</span>
        <span class="time font-medium">2:54 pm:</span>
        <span class="message">an engine</span>
      </p>
      <p>
        <span class="sender font-medium">Fred</span>
        <span class="time font-medium">2:55 pm:</span>
        <span class="sender-message">
          which version can you send me the picture of cars VIN ?</span
        >
      </p>
      <div class="border border-gray-400 rounded w-20 h-20 cursor-pointer text-center items-center flex">
        image of VIN
      </div>
      <p>
        <span class="receiver-customer font-medium">John</span>
        <span class="time font-medium">3:04 pm:</span>
        <span class="message"> The engine needs to have some special factory options which webought last week</span>
      </p>
    </div>
  </div>
</template>

<script>
import SearchBar from '../shared/SearchBar.vue';

export default {
  name: "c1c7ConversationHistoryEntry",

  components: { SearchBar },

  data() {
    return {
      //
    };
  },
};
</script>
