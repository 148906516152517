/* eslint-disable */
import { ajax } from "noquery-ajax";
import { httpsLogger } from "../p1s7/p1s7e1";

function createStaffAccount(
  first_name: string,
  last_name: string,
  email: string,
  firebase_uid: string,
  firebase_token: string
) {
  return new Promise(function (resolve, reject) {
    let params = {
      p1s3e10_first_name: first_name,
      p1s3e10_last_name: last_name,
      p1s3e10_email: window.G_firebase_auth.IV_email_address,
      p1s3e10_firebase_account_uid: firebase_uid,
      firebase_uid: firebase_uid,
      firebase_token: firebase_token,
      firebase_email: window.G_firebase_auth.IV_email_address,
      p1s3e10_trial_token: window.G_trial_token
    };

    ajax({
      url: window.G_cloud_p1s3_base_url + "p1s3e10CreateStaffAccount",
      headers: {
        Accept: "application/json",
      },
      method: "POST",
      dataType: "json",
      data: params,

      success: function (result) {
        resolve(result);
      },

      error: function (error) {
        reject(error);
        console.log(error);
        let error_data = {
          request_object: error,
          request_params: params,
          file_name: "p1s3e10.ts",
          class_name: "p1s3e10",
          method_name: "createStaffAccount",
          error_title: "Failed to create verify Syngery account exists",
          error_description:
            "The server responded with an error when trying verify your Syngery account exists",
        };

        httpsLogger(error_data);
      },
    });
  });
}

export default createStaffAccount;
