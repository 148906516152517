import { fs109StripeIdentityVerificationPlans } from "../firestore_schemas/fs109StripeIdentityVerificationPlans"
import { setDocIn_fs109StripeIdentityVerificationPlans } from "../firestore_schemas/fs109StripeIdentityVerificationPlans"
import { setValueOfOptionalFields } from "../firestore_writers/include/general"

type Result = {
  debug_data: Array<string>;
  return_msg: string;
  success: boolean;
  error_title: string;
  error_summary: string;
  error_next_steps: string;
  document_id?: string;
};

export async function fs109Writer(
  w_type: string,
  business_uid: string,
  recycler_location_uid: string,
  data: any
 ) {

  const retval: Result = {
    debug_data: [],
    return_msg: "fs109_writer:fs109Writer ",
    success: true,
    error_title: "",
    error_summary: "",
    error_next_steps: "",
  };

  if (w_type === "e") {
    const fs109Data: fs109StripeIdentityVerificationPlans = {
      stripe_identity_verification_enabled: data.stripe_identity_verification_enabled,
      minimum_transaction_amount: data.minimum_transaction_amount
    } as fs109StripeIdentityVerificationPlans;

    let response = await setDocIn_fs109StripeIdentityVerificationPlans(
      business_uid,
      recycler_location_uid, 
      fs109Data
    )

    if (!response.id || response.id == "" ) {
      retval.success = false;
      retval.return_msg += "Firestore write error.";
      retval.error_title = "Firestore write error.";
      retval.error_summary = "FS109 record not created in firestore.";
      retval.error_next_steps = "Please reload page and try again.";
      return retval;
    }

    retval.document_id = response.id;
    return retval
  }
}

function firestoreWriteError(error:any) {
  // TODO - complete this
}
