<template>
    <div id="p5s1" class="h-full">
  
      <div v-if="!C_showSettingsForm">
        <!-- Header - logo + menu button -->
        <div class="p-8">
          <c9MainMenu P_customClass="p5s1-c9-container" P_activePage="help_&_support"></c9MainMenu>
        </div>
  
        <!-- Authenticating  -->
        <div v-if="DV_isCheckingAuthentication" class="bg-white w-6/12 mx-auto mt-40 border border-gray-400 rounded p-12">
          Loading account information...
        </div>
      </div>   
  
      <!-- Main content -->
      <div v-if="C_showSettingsForm" class="m-5 grid grid-cols-10 gap-4">
        
        <!-- Side Menu -->
        <div class="col-span-3">
          <!-- Header - logo + menu button -->
          <c9MainMenu P_activePage="help_&_support"></c9MainMenu>
  
          <h1 class="font-bold text-2xl text-left mt-5">Help & Support</h1>
          <p class="text-left mb-1">{{C_employeeEmail || ""}}</p>
  
          <ul class="flex flex-col">
            <li class="mt-0">
              <a
                @click.stop="DV_activeTab = 'frequently asked questions'; DV_activeSubPage = 'p5s1frequentlyAskedQuestions'"
                :class="{ active: DV_activeTab == 'frequently asked questions' }"
                class="cursor-pointer relative inline-flex items-center w-full justify-start rounded-tl-xl rounded-tr-xl border border-gray-300 px-4 py-2 text-sm font-bold text-gray-700 hover:bg-gray-50 focus:text-white focus:z-10 focus:bg-blue-900 focus:outline-none focus:bg-blue-900"
                >Frequently Asked Questions</a
              >
            </li>
            <li class="mt-0">
              <a
                @click.stop="DV_activeTab = 'sales dashboard'; DV_activeSubPage = 'p5s2salesDashboard'"
                :class="{ active: DV_activeTab == 'sales dashboard' }"
                class="cursor-pointer relative inline-flex items-center w-full justify-start border border-gray-300 px-4 py-2 text-sm font-bold text-gray-700 hover:bg-gray-50 focus:text-white focus:z-10 focus:bg-blue-900 focus:outline-none focus:bg-blue-900"
                >Sales Dashboard</a
              >
            </li>
            <li class="mt-0">
              <a
                @click.stop="DV_activeTab = 'new conversations'; DV_activeSubPage = 'p5s3newConversations'"
                :class="{ active: DV_activeTab == 'new conversations' }"
                class="cursor-pointer relative inline-flex items-center w-full justify-start border border-gray-300 px-4 py-2 text-sm font-bold text-gray-700 hover:bg-gray-50 focus:text-white focus:z-10 focus:bg-blue-900 focus:outline-none focus:bg-blue-900"
                >New Conversation</a
              >
            </li>
            <li class="mt-0">
              <a
                @click.stop="DV_activeTab = 'contacts'; DV_activeSubPage = 'p5s4contacts'"
                :class="{ active: DV_activeTab == 'contacts' }"
                class="cursor-pointer relative inline-flex items-center w-full justify-start border border-gray-300 px-4 py-2 text-sm font-bold text-gray-700 hover:bg-gray-50 focus:text-white focus:z-10 focus:bg-blue-900 focus:outline-none focus:bg-blue-900"
                >Contacts</a
              >
            </li>
            <li class="mt-0">
              <a
                @click.stop="DV_activeTab = 'integrate with synergy'; DV_activeSubPage = 'p5s5integrateWithSynergy'"
                :class="{ active: DV_activeTab == 'integrate with synergy' }"
                class="cursor-pointer relative inline-flex items-center w-full justify-start border border-gray-300 px-4 py-2 text-sm font-bold text-gray-700 hover:bg-gray-50 focus:text-white focus:z-10 focus:bg-blue-900 focus:outline-none focus:bg-blue-900"
                >Integrate With Synergy</a
              >
            </li>
            <li class="mt-0">
              <a
                @click.stop="DV_activeTab = 'legal documents'; DV_activeSubPage = 'p5s6legalDocuments'"
                :class="{ active: DV_activeTab == 'legal documents' }"
                class="cursor-pointer relative inline-flex items-center w-full justify-start border border-gray-300 px-4 py-2 text-sm font-bold text-gray-700 hover:bg-gray-50 focus:text-white focus:z-10 focus:bg-blue-900 focus:outline-none focus:bg-blue-900"
                >Legal Documents</a
              >
            </li>
            <li class="mt-0">
              <a
                @click.stop="DV_activeTab = 'submit support request'; DV_activeSubPage = 'p5s7submitSupportRequest'"
                :class="{active: DV_activeTab == 'submit support request'}"
                class="cursor-pointer relative inline-flex items-center w-full justify-start rounded-bl-xl rounded-br-xl border border-gray-300 px-4 py-2 text-sm font-bold text-gray-700 hover:bg-gray-50 focus:text-white focus:z-10 focus:bg-blue-900 focus:outline-none focus:bg-blue-900"
                >Submit Support Request</a
              >
            </li>
          </ul>        
        </div>
        
        <!-- Main container -->
        <div v-if="C_showSettingsForm" style="height: 95vh; overflow: scroll" class="col-span-7 p-12 border border-gray-400 rounded-xl !overflow-x-hidden hide-scrollbar">
          <component
            :is="DV_activeSubPage"
          ></component>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import p5s1frequentlyAskedQuestions from "../components/p5/p5s1frequentlyAskedQuestions"
  import p5s2salesDashboard from "../components/p5/p5s2salesDashboard"
  import p5s3newConversations from "../components/p5/p5s3newConversations"
  import p5s4contacts from "../components/p5/p5s4contacts"
  import p5s5integrateWithSynergy from "../components/p5/p5s5integrateWithSynergy"
  import p5s6legalDocuments from "../components/p5/p5s6legalDocuments"
  import p5s7submitSupportRequest from "../components/p5/p5s7submitSupportRequest"
  import c9MainMenu from "../components/shared/c9MainMenu"
  
  export default {
    name: "p5HelpAndSupport",
  
    props: [],
  
    components: {
      p5s1frequentlyAskedQuestions,
      p5s2salesDashboard,
      p5s3newConversations,
      p5s4contacts,
      p5s5integrateWithSynergy,
      p5s6legalDocuments,
      p5s7submitSupportRequest,
      c9MainMenu
    },
  
    data() {
      return {
        DV_activeTab: "frequently asked questions",
        DV_activeSubPage: "p5s1frequentlyAskedQuestions",
        DV_isCheckingAuthentication: true,
      }
    },
  
    mounted() {
      const CI = this;
  
      CI.authenticationCheck(0);
    },
  
    computed: {
      C_showSettingsForm() {
        return !(this.DV_isCheckingAuthentication)
      },
  
      C_employeeEmail() {
        let activeEmployeeUid = window.G_bi2_data.IV_active_employee_info.employee_uid;
      
        if (activeEmployeeUid in window.G_bi2_data.IV_recycler_employees.value === true) {
          let employeeInfo = window.G_bi2_data.IV_recycler_employees.value[activeEmployeeUid];
  
          return employeeInfo.email_address;
        }
  
        return "";
      }
    },
  
    methods: {
      authenticationCheck(retry_count=0) {
        const CI = this;
  
        if (retry_count >= 40 && window.location.pathname !== "/login" && window.location.pathname !== "/") {
            location.assign(window.location.origin + `/login`);
          return;
        }
  
        if (window.G_firebase_auth.IV_is_user_logged_in !== true) {
          setTimeout( function() {CI.authenticationCheck(++retry_count)}, 100);
          return
        };
  
        CI.DV_isCheckingAuthentication = false;
      },

    },
  
  }
  </script>
  
  <style>
  .p5s1-c9-container img {
    width: 10%;
  }
  </style>