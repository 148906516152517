import { bi2DataInteractions } from "./includes/bi2DataInteractions";
import FirebaseAuth from "./includes/firebase_auth";
import Vue, { ref } from "vue";
import  { Ref } from "vue";


// All global variables we'll use as window.VARIABLE
declare global {
  interface Window {
    G_dev_live_flag_force: any;
    G_firebase_auth: any;
    G_dev_flag: any;
    G_vue_instance: any;
    G_bi2_data: any;
    G_web_widget_base_url: string;
    G_base_url: any;
    G_cloud_p1s3_base_url: string;
    G_cloud_p1s5_base_url: string;
    G_cloud_functions_base_url: string;
    G_loaded_includes: any;
    G_last_created_recycler_business_uid: string;
    G_last_created_recycler_location_uid: string;
    G_show_unfinished_features: boolean;
    G_active_render_mode: Ref<number>;
    G_render_mode_desktop_wide: number;
    G_render_mode_desktop: number;
    G_render_mode_desktop_narrow: number;
    G_render_mode_tablet: number;
    G_render_mode_phone: number;
    G_trial_token: string;
    G_run_first_complete: boolean;
  }
}

const detectDeviceType = () => {

  let new_mode = window.G_render_mode_desktop;
  let screen_ratio = window.innerWidth / window.innerHeight;
  
  if (window.innerWidth > 1000 && window.innerWidth < 1417 && window.innerHeight > 500) {
    new_mode = window.G_render_mode_desktop_narrow;
  }
  else if (window.innerWidth > 1400 && window.innerHeight > 700) {
    new_mode = window.G_render_mode_desktop;
  }
  else if (window.innerWidth <= 600) {
    new_mode = window.G_render_mode_phone;
  }
  else if (window.innerHeight < 600) {
    new_mode = window.G_render_mode_phone;
  }
  else if (window.innerWidth > 600 && window.innerWidth < 1200) {
    new_mode = window.G_render_mode_tablet;
  }
  else if (screen_ratio < 0.5) {
    new_mode = window.G_render_mode_phone;
  }
  else if (screen_ratio < 1.6) {
    new_mode = window.G_render_mode_tablet;
  }
  else if (screen_ratio > 2) {
    new_mode = window.G_render_mode_desktop_wide;
  }
  Vue.set(window.G_active_render_mode, "value", new_mode);
  console.log(window.G_active_render_mode.value);
}

const runFirst = () => {
  if(window.G_run_first_complete === true) {return;}
  window.G_run_first_complete = true;

  window.G_render_mode_desktop_wide = 5;
  window.G_render_mode_desktop = 4;
  window.G_render_mode_desktop_narrow = 3;
  window.G_render_mode_tablet = 2;
  window.G_render_mode_phone = 1;
  window.G_active_render_mode = ref(3);
  
  detectDeviceType();

  // produtction versus dev stuff
  console.log(window.location.hostname);
  if(window.location.hostname === "synergy-auto-solutions.web.app") {
    window.G_dev_live_flag_force = true;
  }
  if(window.location.hostname === "localhost") {
    window.G_dev_live_flag_force = false;
  }

  window.G_dev_live_flag_force = false;
  window.G_dev_flag = false;
  window.G_show_unfinished_features = false;

  let current_version = "2023-10-12:10:19 MST"
  let browser_version = localStorage.getItem('synergy_cache_version');
  
  if (browser_version !== current_version || browser_version === null) {
    localStorage.clear();
    localStorage.setItem('synergy_cache_version',current_version)
    console.log('clearing local storage');
  }

  if (window.location.hostname === "dashboard.synergy-auto-solutions.com"
  || window.G_dev_live_flag_force === true)
    {
    console.log("production version: " + current_version);
    window.G_cloud_p1s3_base_url = "https://p1s3-dot-synergy-auto-solutions.appspot.com/"
    window.G_cloud_p1s5_base_url = "https://p1s5-dot-synergy-auto-solutions.appspot.com/"
    window.G_cloud_functions_base_url = "https://us-east4-synergy-auto-solutions.cloudfunctions.net/"
    window.G_base_url = "https://dashboard.synergy-auto-solutions.com"
    window.G_web_widget_base_url = "https://c.synergy-auto-solutions.com/"
    window.G_dev_flag = false;
    window.G_dev_live_flag_force = false;
  }
  else if (
    window.location.hostname.toString().includes("dev") ||
    window.location.hostname.toString().includes("localhost") ||
    window.location.hostname.toString().includes("127.0.0.1")
  ) {
    window.G_dev_flag = true;
    console.log("development version: " + current_version);
    window.G_cloud_p1s3_base_url = "https://p1s3-dot-dev-synergy-auto-solutions.appspot.com/"
    window.G_cloud_p1s5_base_url = "https://p1s5-dot-dev-synergy-auto-solutions.appspot.com/"
    window.G_cloud_functions_base_url = "https://us-central1-dev-synergy-auto-solutions.cloudfunctions.net/"

    window.G_base_url = "http://localhost:8080"
    window.G_web_widget_base_url = "https://devc.synergy-auto-solutions.com/"
  }
  //forcing live flag should only work on localhost

  else {
    window.G_dev_flag = true;
    console.log("development version: " + current_version);
    window.G_cloud_p1s3_base_url = "https://p1s3-dot-dev-synergy-auto-solutions.uc.r.appspot.com/"
    window.G_cloud_p1s5_base_url = "https://p1s5-dot-dev-synergy-auto-solutions.uc.r.appspot.com/"
    window.G_cloud_functions_base_url = "https://us-central1-dev-synergy-auto-solutions.cloudfunctions.net/"
    window.G_base_url = "https://dev-dashboard.synergy-auto-solutions.com"
    window.G_web_widget_base_url = "https://devc.synergy-auto-solutions.com/"
  }

  window.G_firebase_auth = new FirebaseAuth();
  window.G_bi2_data = new bi2DataInteractions(window.G_firebase_auth.IV_firestore_object);
  
  // set the trial_token if present in url
  const urlParams = new URLSearchParams(window.location.search);
  window.G_trial_token = urlParams.get("trial_token") || '';

  window.addEventListener('resize', () => {
    detectDeviceType();
  });

};

runFirst();

export default runFirst;
