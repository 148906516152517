<template>
  <div id="p1s1c2" class="border border-gray-400 md:rounded-xl rounded-b-xl rounded-r-xl shadow-sm p-2 h-full">
    
    <!-- Descktop view -->
    <div v-show="DV_activeView == 'liveChat'" class="md:block hidden h-full">
      
      <!-- Filters section -->
      <div class="flex flex-col">
        
        <!-- Label section -->
        <div class="flex items-center flex-col justify-between relative">
          <h1 class="font-bold text-lg text-left mb-1"> My Conversations</h1>

          
          <!-- New conversation button -->
          <button  @click="newConversation" class="btn btn-lime drop-shadow-md flex flex-row items-center">
            <svg class="w-6 h-6 font-bold" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v12m6-6H6"/>
            </svg>
            New Conversation
          </button>

          <div id="notifications" @click.stop="DV_isNotificationsOpened = !DV_isNotificationsOpened" @blur="closeNotifocations" class="absolute right-5 top-5 cursor-pointer">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-bell">
              <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
              <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
            </svg>

            <span v-if="DV_unreadNotificationsCount > 0" class="badge">{{DV_unreadNotificationsCount}}</span>

            <transition
              enter-active-class="transform transition duration-500 ease-custom"
              enter-class="-translate-y-1/2 scale-y-0 opacity-0"
              enter-to-class="translate-y-0 scale-y-100 opacity-100"
              leave-active-class="transform transition duration-300 ease-custom"
              leave-class="translate-y-0 scale-y-100 opacity-100"
              leave-to-class="-translate-y-1/2 scale-y-0 opacity-0"
            >
              <div class="absolute right-0 top-8 mb-4 bg-white divide-y rounded-b-lg drop-shadow-lg overflow-hidden w-[30rem] z-10" v-show="DV_isNotificationsOpened">
                
                <!-- First row (Actions) -->
                <div class="flex flex-row items-center justify-between p-2">
                  <span v-if="DV_selectNotifications === false">You have {{DV_unreadNotificationsCount}} new Notifications</span>
                  <div v-if="DV_selectNotifications === true">
                    <span @click.stop="selectAllNotifications" class="chip mr-2">Select All</span>
                    <span @click.stop="unSelectAllNotifications" class="chip mr-2">Unselect All</span>
                    <span @click.stop="readAllSelectedNotifications" class="chip mr-2">Mark as Read</span>
                    <span @click.stop="deleteAllSelectedNotifications" class="chip red mr-2">Delete Selected</span>
                  </div>
                  <span v-if="DV_selectNotifications === false" @click.stop="DV_selectNotifications = true" class="chip ml-auto mr-2">Select</span>
                  <span v-if="DV_selectNotifications === true" @click.stop="DV_selectNotifications = false; DV_selectedNotificationIds = []" class="chip ml-auto mr-2">Cancel</span>
                  <svg @click.stop="closeNotifocations" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </div>

                <!-- List of notifications -->
                <ul class="notifications-list">
                  <!-- Sales Dashboard -->
                  <li 
                    v-for="notification in DV_notificationsList" 
                    :class="[
                        'flex', 'items-start', 'justify-between', 'font-normal', 'border-t', 'menu-list-item', 'text-left', 'font-medium', 'cursor-pointer', 
                        'transition-colors', 'duration-300', 'hover:bg-gray-300', 'z-10', 'w-full', '!mt-0',
                        {'bg-gray-200': notification.isNew}
                    ]"
                    @click.stop="openOrSelectNotification(notification)"
                  >

                    <div v-if="DV_selectNotifications === true">
                      <input type="checkbox" :checked="DV_selectedNotificationIds.includes(notification.uid)">
                    </div>
                    <div :class="[DV_selectNotifications === true ? ' w-[26rem]' : ' w-[28rem]']">
                      <!-- Notification title -->
                      <div class="font-bold truncate flex justify-between items-center">
                        <span>{{notification.title}}</span>
                        <!-- Unread / Delete Notification icons -->
                        <div v-if="DV_selectNotifications === false" class="flex items-center">
                          <!-- Unread -->
                          <svg v-if="notification.isNew === true" @click.stop="markTheNotificationAsRead(notification)" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6 text-red-900">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                          </svg>
                          <!-- Read -->
                          <svg v-else @click.stop="confirmAndMarkTheNotificationAsUnRead(notification)" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 9v.906a2.25 2.25 0 01-1.183 1.981l-6.478 3.488M2.25 9v.906a2.25 2.25 0 001.183 1.981l6.478 3.488m8.839 2.51l-4.66-2.51m0 0l-1.023-.55a2.25 2.25 0 00-2.134 0l-1.022.55m0 0l-4.661 2.51m16.5 1.615a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V8.844a2.25 2.25 0 011.183-1.98l7.5-4.04a2.25 2.25 0 012.134 0l7.5 4.04a2.25 2.25 0 011.183 1.98V19.5z" />
                          </svg>
                          <!-- Delete -->
                          <svg @click.stop="confirmAndDeleteTheNotification(notification)" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6 text-red-900">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                          </svg>
                        </div>
                      </div>
                      
                      <div class="truncate">{{notification.description}}</div>
                      <div class="truncate" v-if="notification.type.includes('payment') && notification.payment_description">
                        {{notification.payment_description}}
                      </div>
                      <div class="truncate" v-if="notification.type.includes('message') && notification.message_description">
                        {{notification.message_description}} <br> <span class="text-gray-500">{{notification.summary}}</span>
                      </div>
                      
                      <!-- Notification date -->
                      <div class="text-sm font-bold">{{notification.formatted_time}}</div>
                    </div>
                  </li>
                </ul>
              </div>
            </transition>
          </div>
        </div>

        <!-- Status and last active filters -->
        <p1s1c1m1SetListFilters :P_showFilterDropDown="P_activeConversationUid"/>

        <div class="my-2 w-3/5">
            <SearchBar v-model="DV_searchText" containerClasses="w-full mt-1" inputClasses="" /> 
          </div>
      </div>
      <span class="font-bold text-lime-500" v-if="!DV_newConversationSuccessMsg !== ''">{{ DV_newConversationSuccessMsg }}</span>
      <!-- Conversations list -->
      <p1s1c2c2ConversationsList
        v-show="DV_activeView == 'liveChat'"
        @event-open-conversation="passOpenConversationEvent"
        @close-new-conversation="closeNewConversation"
        :isModal="true"
        :P_activeConversationUid="P_activeConversationUid"
        :P_searchText="DV_searchText"
      />
    </div>

    <p1s1c2c1NewConversation 
      v-show="DV_activeView == 'newConversation'" 
      @close-new-conversation="closeNewConversation"
      @event-open-profile-modal="passEventOpenProfileModal"
      @event-open-chat-log-modal="passEventOpenChatLogModal"
      @event-open-consumer-feedback-log-modal="passOpenConsumerFeedbackLogModal"
      @event-open-consumer-business-modal="passEventOpenEditNewConsumerBusinessModal"
    />
    
    <!-- Mobile view -->
    <div class="md:hidden block w-full">
      <div>
        <p1s1c2c1NewConversation v-show="DV_activeView == 'newConversation'" @close-new-conversation="closeNewConversation"/>
        <div v-show="DV_activeView == 'liveChat' || DV_activeView == 'liveChatAction'">
          <div>
            <div v-if="DV_activeView == 'liveChat'">

              <!-- Top bar -->
              <div class="flex flex-row justify-between items-center">
                <button @click="DV_activeView = 'newConversation'" class="btn btn-lime">
                  New Conversation
                </button>
                <button @click="DV_activeView = 'listFilters'" class="btn btn-lime">
                  Filters <span>(2)</span>
                </button>
              </div>

              <div class="grid grid-cols-4 mt-3 items-end">

                <!-- Hint -->
                <div class="col-span-1 text-left">
                  <p class="text-sm">R = Retail</p>
                </div>

                <!-- Filters section -->
                <div class="col-span-3">
                  <div class="flex items-center justify-end border border-gray-400 rounded-md p-1">
                    <p class="font-medium pr-3">Sort By</p>
                    <div class="flex items-center">
                      <select
                        id="conversation-filters"
                        name="conversation-filters"
                        class="w-full rounded-md border border-gray-400 py-1 pl-2 pr-5 text-gray-500 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      >
                        <option>Customer Rating</option>
                        <option>Status</option>
                        <option>Last Activity</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <p class="text-sm text-left">
                I = Invoice Created | P = Invoice Paid
              </p>
            </div>

            <p1s1c2c2ConversationsList
              @chat-action="chatAction"
              @show-image="showImage"
              @show-live-chat="DV_activeView = 'liveChat'"
              :chatAction="DV_returnTo"
              :is-modal="false"
              :P_activeConversationUid="P_activeConversationUid"
            />
          </div>
        </div>

        <c1m2ImageViewer
          v-if="DV_activeView == 'imageView'"
          @returnTo="returnToParent"
        />

        <p1s1c1m1SetListFilters
          v-if="DV_activeView == 'listFilters'"
          @show-live-chat="DV_activeView = 'liveChat'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import c1m2ImageViewer from "../c1/c1m2ImageViewer.vue";
import p1s1c2c2ConversationsList from "./p1s1c2c2ConversationsList.vue";
import p1s1c1m1SetListFilters from "./p1s1c1m1SetListFilters.vue";
import p1s1c2c1NewConversation from "./p1s1c2c1NewConversation.vue";
import SearchBar from "../shared/SearchBar.vue";
import m2MiscDataFetchers from "../../mixins/m2MiscDataFetchers"
import { fs125Writer } from '../../includes/firestore_writers/fs125_writer.ts'
import { fs112Writer } from '../../includes/firestore_writers/fs112_writer.ts'
import _ from "lodash"
import moment from "moment"
import AWN from "awesome-notifications";

export default {
  name: "p1s1c2Conversations",

  props: ['P_activeConversationUid'],

  mixins: [ m2MiscDataFetchers ],

  components: { p1s1c2c2ConversationsList, p1s1c1m1SetListFilters, p1s1c2c1NewConversation, c1m2ImageViewer, SearchBar},

  data() {
    return {
      DV_activeView: "liveChat",
      DV_returnTo: "",
      DV_leadsFilter: "leads",
      DV_searchText: "",
      DV_newConversationSuccessMsg: "",
      DV_unreadNotificationsCount: 0,
      DV_notificationsList: [],
      DV_isNotificationsOpened: false,
      DV_allNotificationsRef: {},
      DV_allNotificationsReadTimeRef: {},
      DV_selectNotifications: false,
      DV_selectedNotificationIds: [],
      DV_notificationTypeMapping: {
        1: "new-message",
        2: "payment-success",
        3: "payment-failed",
        4: "payment-in-progress",
        5: "payment-refunded",
      },
      DV_inactiveTimer: null,
      DV_recyclerLogos: [],
      DV_recyclerLogo: null,
    };
  },

  mounted() {
    const CI = this;

    if (Notification.permission !== "granted") {
      Notification.requestPermission();
    }

    CI.DV_allNotificationsRef = window.G_bi2_data.IV_user_notifications.value;
    CI.DV_allNotificationsReadTimeRef = window.G_bi2_data.IV_user_notifications_read_time.value;
    CI.DV_recyclerLogos = window.G_bi2_data.IV_recyclers_logo_urls;

    document.addEventListener('click', (e) => {
      if (e.target.classList.contains("notification")) { return; };
      CI.DV_isNotificationsOpened = false;
      CI.DV_selectNotifications = false;
      CI.DV_selectedNotificationIds = [];

    }, false);
  },

  beforeDestroy() {
    const CI = this;

    document.addEventListener('click', (e) => {
      if (e.target.classList.contains("notification")) { return; };
      CI.DV_isNotificationsOpened = false;
      CI.DV_selectNotifications = false;
      CI.DV_selectedNotificationIds = [];

    }, false);
  },

  methods: {
    setLogoUrl() {
      const CI = this;

      const activeBusiness = window.G_bi2_data.IV_active_employee_info.business_uid
      const activeLocation = window.G_bi2_data.IV_active_employee_info.recycler_location_uid

      let recycler_business_location_uid = `${activeBusiness}-${activeLocation}`

      if (recycler_business_location_uid in CI.DV_recyclerLogos === true) {
        CI.DV_recyclerLogo = CI.DV_recyclerLogos[recycler_business_location_uid];
      }
    },

    chatAction(val) {
      if (val != undefined) {
        this.DV_returnTo = val;
        this.DV_activeView = "liveChatAction";
      }
    },

    showImage(value) {
      if (value != undefined && value == "liveChat") {
        this.DV_returnTo = value;
        this.DV_activeView = "imageView";
      }
      if (value == undefined && this.DV_returnTo == "liveChatAction") {
        this.DV_activeView = "imageView";
      }
    },

    returnToParent() {
      if (this.DV_returnTo == "liveChat") {
        this.DV_activeView = "liveChat";
      }
      if (this.DV_returnTo == "liveChatAction") {
        this.DV_activeView = "liveChatAction";
      }
    },

    passOpenConversationEvent(uid, notification=null) {
      const CI = this;

      CI.closeNewConversation();
      CI.$emit('event-open-conversation', uid)
      CI.DV_newConversationSuccessMsg = "";

      CI.markConversationNotificationsAsRead(uid, notification);
    },

    passEventOpenEditNewConsumerBusinessModal(business_uid) {
      this.$emit('event-open-consumer-business-modal',business_uid);
    },
    passEventOpenProfileModal(consumer_uid) {
      this.$emit('event-open-profile-modal',consumer_uid);
    },

    passEventOpenChatLogModal(consumer_uid) {
      this.$emit('event-open-chat-log-modal',consumer_uid);
    },

    passOpenConsumerFeedbackLogModal(consumer_uid) {
      this.$emit('event-open-consumer-feedback-log-modal',consumer_uid);
    },

    newConversation() {
      this.$emit('event-open-conversation', null)
      this.$emit("show-new-conversation", true);
      this.DV_activeView = 'newConversation';
    },

    closeNewConversation() {
      this.$emit("show-new-conversation", false);
      this.DV_activeView = 'liveChat'
    },

    getRelatedConversationNotification(conversation_uid) {
      const CI = this;

      let formatted_conv_uid = `${conversation_uid}_1`;

      // See if we get related notification to this conversation.
      if (formatted_conv_uid in CI.DV_allNotificationsRef) {
        return CI.DV_allNotificationsRef[formatted_conv_uid];
      }

      return null;
    },

    markConversationNotificationsAsRead(conv_uid, notification=null) {
      const CI = this;

      if (conv_uid === null) { return; }

      let [consumer_uid, conversation_uid] = conv_uid.split("-");

      if (!notification) { notification = CI.getRelatedConversationNotification(); }

      // Write to fs125 using fs125_writer
      fs125Writer(
        "n",
        window.G_bi2_data.IV_active_employee_info.business_uid,
        window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
        window.G_bi2_data.IV_active_employee_info.employee_uid,
        conv_uid,
        {
          consumer_contact_uid: consumer_uid,
          conversation_uid: conversation_uid,
          read_message_time: new Date(),
          read_notification_time: (notification ? notification.timestamp : new Date())
        }
      )

      // Mark the force unread to false
      if (notification) {
        fs112Writer(
          "e", 
          window.G_bi2_data.IV_active_employee_info.business_uid, 
          window.G_bi2_data.IV_active_employee_info.recycler_location_uid, 
          notification.uid, 
          {force_mark_unread: false}
        );
      }

      // Mark any payment notification force unread to false
      let conversation_payment_link_uids = [];

      if (conv_uid in window.G_bi2_data.IV_consumer_conversation_payment_links.value === true) {
        for (let payment_link_uid in window.G_bi2_data.IV_consumer_conversation_payment_links.value[conv_uid]) {
          conversation_payment_link_uids.push(payment_link_uid);
        }
      }

      // See if any notification related to this payment is available and marked as force unread
      for (let payment_link_uid of conversation_payment_link_uids) {
        let payment_notification = _.find(CI.DV_notificationsList, (notif) => {
          return notif.payment_link_uid === payment_link_uid;
        });

        if (payment_notification && payment_notification.force_mark_unread === true) {
          fs112Writer(
            "e", 
            window.G_bi2_data.IV_active_employee_info.business_uid, 
            window.G_bi2_data.IV_active_employee_info.recycler_location_uid, 
            payment_notification.uid, 
            {force_mark_unread: false}
          );
        }
      }
    },

    lazyGenerateListOfNotifications: _.debounce(function(event) {
      const CI = this
      let new_notifications_count = 0;

      let notifications = []
      let employeesActiveConversations = window.G_bi2_data.IV_employees_active_conversations.value;

      for (let notification_uid in CI.DV_allNotificationsRef) {
        const notification = CI.DV_allNotificationsRef[notification_uid]
        let notificationListItem = {}

        if (window.G_bi2_data.IV_active_employee_info.permissions.sales_person) {
          if (notification.summary.includes("New Queue Request") || notification.description.includes("New Queue Request")) {} else {
            if (notification.consumer_conversation_uid in employeesActiveConversations === false) { continue; }
          }
        }

        notificationListItem.type = CI.DV_notificationTypeMapping[notification.type];
        notificationListItem.consumer_conversation_uid = notification.consumer_conversation_uid
        notificationListItem.consumer_uid = notification.consumer_uid
        notificationListItem.conversation_uid = notification.conversation_uid
        notificationListItem.title = ""
        notificationListItem.payment_description = ""
        notificationListItem.message_description = ""
        notificationListItem.description = notification.description
        notificationListItem.summary = notification.summary;
        notificationListItem.payment_link_uid = notification.payment_link_uid;
        notificationListItem.formatted_time = moment.unix(notification.timestamp.seconds).fromNow();
        notificationListItem.timestamp = notification.timestamp;
        notificationListItem.isNew = false;
        notificationListItem.uid = notification_uid;
        notificationListItem.force_mark_unread = notification.force_mark_unread;

        // skip notification if conversation not found
        const conv = window.G_bi2_data.IV_consumer_conversations.value[notification.consumer_conversation_uid];
        if (!conv) {continue;}
        
        // Format notification title
        notificationListItem.title += `${this.getPhoneNumber(conv) || ""} ${conv.consumer_name || ""}`;

        // Format payment description
        if (notificationListItem.type.includes("payment") && notification.payment_link_uid) {
          if (notification.consumer_conversation_uid in window.G_bi2_data.IV_consumer_conversation_payment_links.value === true &&
              notification.payment_link_uid in window.G_bi2_data.IV_consumer_conversation_payment_links.value[notification.consumer_conversation_uid] === true
          ) {
            const payment_link = window.G_bi2_data.IV_consumer_conversation_payment_links.value[notification.consumer_conversation_uid][notification.payment_link_uid];
            notificationListItem.payment_description = payment_link.description;
            
            if (notificationListItem.type == "payment-success") {
              notificationListItem.payment_description = `A $${payment_link.amount} payment is paid for ${payment_link.description}`;
            } else if (notificationListItem.type == "payment-failed") {
              notificationListItem.payment_description = `A $${payment_link.amount} payment has failed for ${payment_link.description}`;
            } else if (notificationListItem.type == "payment-in-progress") {
              notificationListItem.payment_description = `A $${payment_link.amount} payment has initiated for ${payment_link.description}`;
            } else if (notificationListItem.type == "payment-refunded") {
              notificationListItem.payment_description = `A $${payment_link.amount} payment has been refunded`;
            }
          }
        }

        // Format message notification description
        if (notificationListItem.type.includes("message")) {
          notificationListItem.message_description = `New message(s) received from ${conv.consumer_name || "customer"}`;
        }

        // Count if new notification
        if (notification.consumer_conversation_uid in CI.DV_allNotificationsReadTimeRef === false) {
          new_notifications_count += 1;
          notificationListItem.isNew = true;
        } else {
          let last_read_time = CI.DV_allNotificationsReadTimeRef[notification.consumer_conversation_uid]
          if (last_read_time && (last_read_time.read_message_time.seconds < notification.timestamp.seconds)) {
            new_notifications_count += 1;
            notificationListItem.isNew = true;
          }
        }

        // Mark and count force unread only if not already count
        if (notification.force_mark_unread === true && notificationListItem.isNew === false) {
          new_notifications_count += 1;
          notificationListItem.isNew = true;
        }

        // Set message count in description if new
        if (notificationListItem.isNew) {
          let localDate = moment().local();
          let secondsThreshold = localDate - (20 * 1000);

          if (new Date(notificationListItem.timestamp.toDate()).getTime() > secondsThreshold) {
            if (document.visibilityState === 'visible') {} // Skip if the tab is visible 
            else {
              // Check if the browser supports notifications
              if (!('Notification' in window)) {
                console.log('This browser does not support desktop notifications');
                return;
              }
            
              let notificationBody = notificationListItem.summary;

              // Check if permission is granted
              if (Notification.permission === 'granted') {
                // If it's okay let's create a notification
                new Notification(notificationListItem.title, {
                  body: notificationBody,
                  icon: CI.DV_recyclerLogo,
                });
              } else if (Notification.permission !== 'denied') {
                // Otherwise, we need to ask the user for permission
                Notification.requestPermission().then(function (permission) {
                  // If the user accepts, let's create a notification
                  if (permission === 'granted') {
                    new Notification(notificationListItem.title, {
                      body: notificationBody,
                      icon: CI.DV_recyclerLogo,
                    });
                  }
                });
              }
            }
          }
          
          if (notification.consumer_conversation_uid in window.G_bi2_data.IV_conversations_unread_messages_count.value === true) {
            let message_count = window.G_bi2_data.IV_conversations_unread_messages_count.value[notification.consumer_conversation_uid]
            if (message_count > 0) {
              notificationListItem.message_description = `${message_count} new message(s) received from ${conv.consumer_name || "customer"}`;
            }
          }
        }

        notifications.push(notificationListItem);
      }

      CI.DV_unreadNotificationsCount = new_notifications_count;

      if (new_notifications_count !== 0) {
        window.document.title = `(${new_notifications_count}) - Synergy Auto Solutions - Sales Dashboard`;
      } else {
        window.document.title = "Synergy Auto Solutions - Sales Dashboard";
      }

      CI.DV_notificationsList = _.orderBy(notifications, ['timestamp'], ['desc']);
    }, 2000),

    getPhoneNumber(conv) {
      let phone_number = ""

      if (conv.phone_number) { 
        phone_number = `${this.formatPhoneNumber(conv.phone_number)}`;
      }

      return phone_number;
    },

    openOrSelectNotification(notification) {
      const CI = this;

      if (CI.DV_selectNotifications === true) {
        CI.toggleNotificationSelection(notification);
        return;
      }

      // Open relevant conversation
      CI.passOpenConversationEvent(notification.consumer_conversation_uid, notification)
      CI.closeNotifocations;
    },

    closeNotifocations() {
      const CI = this;

      CI.DV_isNotificationsOpened = false;
      CI.DV_selectNotifications = false;
      CI.DV_selectedNotificationIds = [];
    },

    toggleNotificationSelection(notification) {
      const CI = this;

      if (CI.DV_selectedNotificationIds.includes(notification.uid)) {
        let index = CI.DV_selectedNotificationIds.indexOf(notification.uid);
        CI.DV_selectedNotificationIds.splice(index, 1);
        return;
      }

      CI.DV_selectedNotificationIds.push(notification.uid);
    },

    selectAllNotifications() {
      const CI = this;
      
      let selected_notifications = [];
      for (let notification of CI.DV_notificationsList) {
        selected_notifications.push(notification.uid);
      }

      CI.DV_selectedNotificationIds = selected_notifications;
    },

    unSelectAllNotifications() {
      const CI = this;
      CI.DV_selectedNotificationIds = [];
    },

    deleteAllSelectedNotifications() {
      const CI = this;

      if (CI.DV_selectedNotificationIds.length === 0) {return;}

      let notifier = new AWN();
      notifier.confirm(
        // Label
        "Are you sure, you want to delete "+ CI.DV_selectedNotificationIds.length +" notification(s)?",

        // On OK
        function() {
          for (let notification_id of CI.DV_selectedNotificationIds) {
            let notification = _.find(CI.DV_notificationsList, (notif) => {
              return notif.uid === notification_id;
            });
            if (!notification) {continue;}
            CI.deleteTheNotification(notification)
          }

          CI.DV_selectedNotificationIds = [];
          CI.DV_selectNotifications = false;
        },

        // On Cancel
        function() {},

        // Other options
        {
          labels: {
            confirm: 'Delete Notification(s)',
            confirmOk: "Confirm",
            confirmCancel: "Cancel"
          }
        }
      );
    },

    readAllSelectedNotifications() {
      const CI = this;

      if (CI.DV_selectedNotificationIds.length === 0) {return;}

      let notifier = new AWN();
      notifier.confirm(
        // Label
        "Are you sure, you want to mark "+ CI.DV_selectedNotificationIds.length +" notification(s) as read?",

        // On OK
        function() {
          for (let notification_id of CI.DV_selectedNotificationIds) {
            let notification = _.find(CI.DV_notificationsList, (notif) => {
              return notif.uid === notification_id;
            });
            if (!notification) {continue;}
            CI.markTheNotificationAsRead(notification)
          }

          CI.DV_selectedNotificationIds = [];
          CI.DV_selectNotifications = false;
        },

        // On Cancel
        function() {},

        // Other options
        {
          labels: {
            confirm: 'Mark Notification(s) Read',
            confirmOk: "Confirm",
            confirmCancel: "Cancel"
          }
        }
      );
    },

    confirmAndMarkTheNotificationAsRead(notification) {
      const CI = this;

      let notifier = new AWN();

      notifier.confirm(
        // Label
        "Are you sure, you want to mark this notification as read?",
        // On OK
        function() {
          CI.markTheNotificationAsRead(notification);
        },

        // On Cancel
        function() {},

        // Other options
        {
          labels: {
            confirm: 'Mark as Read',
            confirmOk: "Confirm",
            confirmCancel: "Cancel"
          }
        }
      );
    },

    markTheNotificationAsRead(notification) {
      const CI = this;

      let [consumer_uid, conversation_uid] = notification.consumer_conversation_uid.split("-");
      let currentBusinessUid = window.G_bi2_data.IV_active_employee_info.business_uid;
      let currentRecyclerLocationUid = window.G_bi2_data.IV_active_employee_info.recycler_location_uid;
      let currentEmployeeUid = window.G_bi2_data.IV_active_employee_info.employee_uid;

      // Write to fs125 using fs125_writer
      fs125Writer(
        "n",
        currentBusinessUid,
        currentRecyclerLocationUid,
        currentEmployeeUid,
        notification.consumer_conversation_uid,
        {
          consumer_contact_uid: consumer_uid,
          conversation_uid: conversation_uid,
          read_message_time: new Date(),
          read_notification_time: notification.timestamp
        }
      )

      if (notification.force_mark_unread === true) {
        fs112Writer(
          "e", 
          currentBusinessUid, 
          currentRecyclerLocationUid, 
          notification.uid, 
          {force_mark_unread: false}
        );
      }
    },

    confirmAndMarkTheNotificationAsUnRead(notification) {
      const CI = this;

      //let notifier = new AWN();

      //notifier.confirm(
        // Label
      //  "Are you sure, you want to mark this notification as unread?",
        // On OK
      //  function() {
      let currentBusinessUid = window.G_bi2_data.IV_active_employee_info.business_uid;
      let currentRecyclerLocationUid = window.G_bi2_data.IV_active_employee_info.recycler_location_uid;

      fs112Writer("e", currentBusinessUid, currentRecyclerLocationUid, notification.uid, {force_mark_unread: true});
      //  },

        // On Cancel
        //function() {},

        // Other options
      //  {
      //    labels: {
      //      confirm: 'Mark as Unread',
      //      confirmOk: "Confirm",
      //      confirmCancel: "Cancel"
      //    }
      //  }
      //);
    },

    confirmAndDeleteTheNotification(notification) {
      const CI = this;

      let notifier = new AWN();
      notifier.confirm(
        // Label
        "Are you sure, you want to delete this notification?",
        // On OK
        function() {
          CI.deleteTheNotification(notification);
        },

        // On Cancel
        function() {},

        // Other options
        {
          labels: {
            confirm: 'Delete Notification',
            confirmOk: "Confirm",
            confirmCancel: "Cancel"
          }
        }
      );      
      // 
    },

    deleteTheNotification(notification) {
      const CI = this;

      let currentBusinessUid = window.G_bi2_data.IV_active_employee_info.business_uid;
      let currentRecyclerLocationUid = window.G_bi2_data.IV_active_employee_info.recycler_location_uid;

      fs112Writer("d", currentBusinessUid, currentRecyclerLocationUid, notification.uid);
    }
  },

  watch: {
    DV_allNotificationsRef: {
      handler() {
        const CI = this;
        CI.lazyGenerateListOfNotifications()
      },
      deep: true
    },

    DV_allNotificationsReadTimeRef: {
      handler() {
        const CI = this;
        CI.lazyGenerateListOfNotifications()
      },
      deep: true
    },
    
    DV_recyclerLogos: {
      handler() {
        const CI = this;

        CI.setLogoUrl();
      },

      deep: true
    },
  }
};
</script>

<style>
.notifications-list {
  height: auto;
  max-height: 50vh;
  overflow-y: scroll;
}

#notifications .badge {
  position: absolute;
  top: -15px;
  left: 12px;
  font-size: 12px;
  border-radius: 50%;
  background: red;
  color: white;
  font-weight: 600;
  width: 23px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
