<template>
  <div id="p1s1c2c1">

    <!-- Mobile view -->
    <div class="md:hidden block">
      <div class="flex flex-row items-center justify-between mb-3">
        
        <!-- Close button icon -->
        <div class="">
          <button @click="$emit('close-new-conversation')" class="flex items-center flex-row">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 pr-1">
              <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"/>
            </svg>
          </button>
        </div>

        <!-- Page Title -->
        <h1 class="font-bold text-sm">New Conversation</h1>
        <div class="flex flex-row items-center justify-end">
          <svg @click="$emit('close-new-conversation')" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"/>
          </svg>
        </div>
      </div>

      <!-- Start new live chat section -->
      <div class="grid grid-cols-6 gap-2 items-center pt-3">
        <textarea id="chat" rows="1" class="border focus:outline-none p-2 border-gray-400 rounded shadow col-span-4"></textarea>
        <div class="col-span-2 flex flex-row items-center justify-end">
          <button @click="$emit('close-new-conversation')" class="btn btn-lime text-sm">
            Start New Live Chat
          </button>
        </div>
      </div>
      <div>
        <p class="font-bold text-center my-3">
          Enter a phone number to start a new chat <br /> Or <br /> Search for an existing Contact
        </p>
      </div>

      <!-- Search from the list section -->
      <SearchBar containerClasses="mt-1" inputClasses="" />
    </div>

    <!-- Desktop view -->
    <div class="md:block hidden h-[100vh]">
      <div class="flex items-center justify-between p-3 h-[5vh]">

        <!-- Heading -->
        <nav class="flex" aria-label="Breadcrumb">
          <ol class="inline-flex items-center space-x-1 md:space-x-3">
            <li class="inline-flex items-center !mt-0">
              <a @click="$emit('close-new-conversation')" class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 cursor-pointer">
                My Conversations
              </a>
            </li>
            <li aria-current="page" class="!mt-0">
              <div class="flex items-center">
                <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path>
                </svg>
                <span class="ml-1 text-sm font-medium text-gray-500 md:ml-2">
                  New Conversation
                </span>
              </div>
            </li>
          </ol>
        </nav>

        <!-- Close button icon -->
        <svg @click="$emit('close-new-conversation')" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer">
          <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
        </svg>
      </div>

      <!-- Start new chat section -->
      <div class="flex justify-between self-center w-fit m-auto rounded-lg border-solid border-2 border-slate-500">
        <div class="flex flex-col gap-2 p-3">
          <span class="self-center font-bold text-xl h-1/4">New Customer</span>
          <div class="flex self-center flex-row text-lg">

            <div class="flex flex-col items-center">
            <span><font color="red">*</font> Phone Number: </span><input autocomplete="off" class="mt-2" id="p1s1c2c1_phone_number" type="number" v-model="DV_startNewChatMobile" @input="DV_startNewChatMobileError = ''" style="width: 12rem" placeholder="Phone Number"/>
            <div v-if="DV_startNewChatMobileError !== ''" class="text-left text-sm text-red-400">{{ DV_startNewChatMobileError }}</div>
          </div>

          <div class="flex flex-col ml-4 items-center">
            First Name: <input autocomplete="off" class="mt-2" id="p1s1c2c1_first_name" type="text" v-model="DV_startNewChatFirstName" @input="DV_startNewChatFirstNameError = ''" style="width: 12rem" placeholder="First Name"/>
            <div v-if="DV_startNewChatFirstNameError !== ''" class="text-left text-sm text-red-400">{{ DV_startNewChatFirstNameError }}</div>
          </div>

          <div class="flex flex-col ml-4 items-center">
            Last Name:<input autocomplete="off" class="mt-2" id="p1s1c2c1_last_name" type="text" v-model="DV_startNewChatLastName" @input="DV_startNewChatLastNameError = ''" style="width: 12rem" placeholder="Last Name"/>
            <div v-if="DV_startNewChatLastNameError !== ''" class="text-left text-sm text-red-400">{{ DV_startNewChatLastNameError }}</div>
          </div>

          <div v-show="DV_conversationStartersEnabled == true" class="flex flex-col ml-4 items-center">
            Conversation Starter:
            
            <select 
              class="mt-2"
              v-model="DV_selectedConversationStarter.id"
              @change="conversationStarterChanged"
            >
              <option v-for="[key, value] in Object.entries(C_filteredConversationStarters)" :value="value.id">{{ value.subject }}</option>
              <option value="Custom Message">Custom Message</option>
            </select>
            <div v-if="DV_startNewChatMessageError !== ''" class="text-left text-sm text-red-400">{{ DV_startNewChatMessageError }}</div>
          </div>
           
            <button
              v-show="!DV_startingNewChat"
              :disabled="DV_startingNewChat" 
              @click="startNewChat(null)"
              class="btn btn-lime self-end ml-6 w-fit h-fit"
            >
              Start New Chat
            </button>

            <button
              v-show="DV_startingNewChat"
              :disabled="DV_startingNewChat"
              class="btn btn-lime self-end ml-6 w-48 h-fit flex justify-center"
            >
              <svg 
                class="w-6 h-6 spin-clockwise"
                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
              >
                <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
              </svg>
            </button>

          </div>
          
          <div class="relative">
            <textarea 
              rows="4" 
              class="w-full mt-2 rounded-md border-gray-300" 
              placeholder="Custom Message" 
              v-model="C_computedBody"
              maxlength="320"
            ></textarea>
            <div class="absolute bottom-1 right-1 text-sm text-gray-500">
              {{ C_characterCount }}/320
            </div>
          </div>

          <span 
            v-if="DV_newlyStartingConversation['message'] != ''"
            class="text-sm font-bold block my-auto flex items-center justify-center"
            :class="[DV_newlyStartingConversation['status'] == 200 ? 'text-lime-500' : 'text-red-700']"
          >
            {{DV_newlyStartingConversation['message']}}
          </span>
         
        </div>
      </div>
      <!-- </end> Start new chat section -->

      <div class="flex pt-1 pl-4 pr-4 pb-1 mt-3 flex-col items-start rounded-lg border-solid border-2 border-slate-500 h-[70vh]">        
        <span class="self-center">
          <span class=" text-xl font-bold">Existing Customers</span>
          <span class="text-lg"> - Click on a customer to start a conversation</span>
        </span>

        <!-- Search area  -->
        <SearchBar containerClasses="self-center mt-1 w-1/2" inputClasses="py-2"
        @input="setConsumerSearchValue" />


        <!-- Filters section -->
        <div v-if="DV_showUnfinishedFeatures" class="flex items-center justify-between">

        <!-- Rating filters section -->
        <div class="grid grid-cols-12 items-center mb-2">
          <div class="col-span-3">
            <p class="text-left font-bold text-xs">Customer Rating:</p>
          </div>
          <select v-model="DV_activeRatingFilter" class="ml-2 h-9 w-48">
            <option v-for="option in DV_ratingFilters" :key="option">{{option}}</option>
          </select>
        </div>

        <!-- Type filters section -->
        <div class="grid grid-cols-12 items-center mb-2">
          <div class="col-span-3">
            <p class="text-left font-bold text-xs">Customer Type:</p>
          </div>
          <select v-model="DV_activeCustomerTypeFilter" class="ml-2 h-9 w-48">
            <option v-for="option in DV_customerTypeFilters" :key="option">{{option}}</option>
          </select>
        </div>

        <!-- Interaction filter section -->
        <div class="grid grid-cols-12 items-center mb-2">
          <div class="col-span-3">
            <p class="text-left font-bold text-xs">Last Interaction:</p>
          </div>
          <select v-model="DV_activeTimeFilter" name="team-member" class="ml-2 h-9 w-48">
            <option v-for="option in DV_timeFilters" :key="option">{{option}}</option>
          </select>
        </div>
        </div>

        <!-- Users table -->
        <div class="w-full flex flex-col my-2">
        <div class="overflow-hidden border border-gray-400 rounded-xl h-[59vh] overflow-y-scroll hide-scrollbar">
          <table class="w-full rounded-xl text-center">
            <thead class="border-b">
              <tr class="font-medium bg-gray-100 h-9">
                <th class="text-center" scope="col">Customer Name</th>
                <th class="text-center" scope="col">Texting Phone Number</th>
                <th class="text-center" scope="col">Business Associations</th>
                <th class="text-center" scope="col">Customer Type</th>
                <th class="text-center" scope="col">Rating</th>
                <th class="text-center" scope="col">City</th>
                <th class="text-center" scope="col">State</th>
                <th class="text-center" scope="col">Customer Engagement & Insights</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="user in C_filteredConsumerList" :key="user[DV_ccIndex.consumer_uid]"
              @click.stop="setSelectedConsumer(user[DV_ccIndex.consumer_uid])"
              :style="{'background-color':(user[DV_ccIndex.consumer_uid] === DV_selectedConsumerUid) ? 'azure' : ''}">
                <td>{{user[DV_ccIndex.first_name] + " " + user[DV_ccIndex.last_name] }}</td>
                <td>{{formatPhoneNumber(user[DV_ccIndex.primary_phone_number].phone_number)  }}</td>
                <td v-if="user[DV_ccIndex.consumer_uid] in DV_consumerBusinessJoins">
                  <div class="flex flex-col gap-2">
                    <span 
                    class="pl-1 pr-1 btn btn-lime"
                    v-for="business in getConsumerBusinessAssociations(user[DV_ccIndex.consumer_uid])"
                    @click.stop="passEventOpenEditNewConsumerBusinessModal(business.uid)"
                    >
                    {{ business.name }}<br>
                    </span>
                  </div>
                </td>
                <td v-else></td>
                <td>{{user[DV_ccIndex.customer_types].join(" , ")}}</td>
                <td>{{classifyCustomerRating(user[DV_ccIndex.average_consumer_rating])}}</td>
                <td>{{user[DV_ccIndex.address_info_city_2d[0]][DV_ccIndex.address_info_city_2d[1]]  }}</td>
                <td>{{user[DV_ccIndex.address_info_state_2d[0]][DV_ccIndex.address_info_state_2d[1]]  }}</td>
                <td>
                  <span 
                  v-if="DV_consumerConversationCounts[user[DV_ccIndex.consumer_uid]] > 0"
                  class="font-bold whitespace-normal"
                  >{{ getConsumerConversationStatusSummary(user[DV_ccIndex.consumer_uid]) }}</span>
                  <span class="flex flex-row justify-center mt-3 flex-wrap gap-2" 
                  v-if="user[DV_ccIndex.consumer_uid] === DV_selectedConsumerUid" >
                    <div v-if="customerConversationsInLimit(user[DV_ccIndex.consumer_uid])" class="w-full flex items-center justify-center">
                      <button
                        v-show="!DV_startingNewChat"
                        @click="startNewChat(user[DV_ccIndex.consumer_uid])"
                        class="pl-1 pr-1 btn btn-lime text-sm w-40 mr-2"
                        :class="[DV_startingNewChat ? 'cursor-not-allowed' : '']" 
                        :disabled="DV_startingNewChat"
                      >
                        Start New Chat
                      </button>

                      <button
                        v-show="DV_startingNewChat"
                        :disabled="DV_startingNewChat"
                        class="pl-1 pr-1 btn btn-lime text-sm w-40 mr-2"
                      >
                        <svg 
                          class="w-6 h-5 spin-clockwise mx-auto"
                          xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                        >
                          <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                        </svg>
                      </button>
                      <button @click="passEventOpenProfileModal(user[DV_ccIndex.consumer_uid])" class="pl-1 pr-1 btn btn-lime text-sm w-40">
                        Profile
                      </button>
                    </div>

                    <button v-if="!customerConversationsInLimit(user[DV_ccIndex.consumer_uid])"
                     @click="passEventOpenProfileModal(user[DV_ccIndex.consumer_uid])" class="pl-1 pr-1 btn btn-lime text-sm" :class="[customerConversationsInLimit(user[DV_ccIndex.consumer_uid]) ? 'w-40' : 'w-16']">
                        Profile
                    </button>

                    <button @click="passOpenConsumerFeedbackLogModal(user[DV_ccIndex.consumer_uid])" class="pl-1 pr-1 btn btn-lime text-sm" :class="[customerConversationsInLimit(user[DV_ccIndex.consumer_uid]) ? 'w-40' : '']">
                      Customer Notes
                    </button>
                    <button @click="passEventOpenChatLogModal(user[DV_ccIndex.consumer_uid])" class=" pl-1 pr-1 btn btn-lime text-sm" :class="[customerConversationsInLimit(user[DV_ccIndex.consumer_uid]) ? 'w-40' : '']">
                      Chat History
                    </button>

                    <span 
                      v-if="DV_newlyStartingConversation['message'] != '' && DV_newlyStartingConversation['phoneNumber'] == user[DV_ccIndex.primary_phone_number].phone_number"
                      :class="[DV_newlyStartingConversation['status'] == 200 ? 'font-bold text-lime-500 text-sm' : 'font-bold text-red-700 text-sm']"
                    >
                      {{DV_newlyStartingConversation['message']}}
                    </span>
                </span>
                <span class="grid-cols-1 pr-2">
                  <span v-if="user[DV_ccIndex.consumer_uid] === DV_selectedConsumerUid"
                  @click.stop="DV_selectedConsumerUid=''"
                  >
                  <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 float-right">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5"/>
                  </svg>
                </span>
                  <span v-else>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 float-right">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5"/>
                  </svg>
                </span>
                </span>
                  
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        </div>
      </div>

      <!-- Texting number error -->
      <PopupModal ref="textingNumberErrorModal" :P_showClose="true" :P_mediumModal="true" @close-modal="closeTextingNumberErrorModal">
        <div slot="icon">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-14 h-14 mx-auto text-red-500">
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
          </svg>
        </div>

        <div slot="title">
          <h3 class="font-bold text-xl">{{ DV_textingNumberErrorHeader }}</h3>
        </div>
        <div slot="body">
          <div class="text-base" v-html="DV_textingNumberErrorBody"></div>
        </div>
      </PopupModal>

    </div>
  </div>
</template>

<script>
import SearchBar from '../shared/SearchBar.vue';
import PopupModal from "../shared/PopupModal.vue"
import sendConversationInvite from "../../includes/server_calls/p1s3/p1s3e16";
import dataValidator from "../../includes/bi1Datavalidation"
import {CR,RC} from "../../includes/bi2SuccessCodes"
import m2MiscDataFetchers from "../../mixins/m2MiscDataFetchers"
import generalFunctions from "../../mixins/generalFunctions"
import _ from "lodash"

export default {
  name: "p1s1c2c1NewConversation",

  components: { SearchBar, PopupModal },

  mixins: [ m2MiscDataFetchers, generalFunctions ],

  data() {
    return {
      DV_showUnfinishedFeatures: window.G_show_unfinished_features,
      DV_consumerContacts: window.G_bi2_data.IV_consumer_contacts.value,
      DV_consumerContactsFiltered: {},
      DV_consumerContactsChanged: null,
      DV_consumerConversationCounts: {},
      DV_consumerBusinesses: {},
      DV_consumerBusinessJoins: {},
      DV_ccIndex: window.G_bi2_data.IV_al_consumer_contacts,
      DV_activeCustomersFilter: "my",
      DV_activeRatingFilter: "all",
      DV_activeCustomerTypeFilter: "all",
      DV_activeTimeFilter: "8-hours",
      DV_startNewChatMobile: "",
      DV_startNewChatFirstName: "",
      DV_startNewChatFirstNameError: "",
      DV_startNewChatLastName: "",
      DV_startNewChatLastNameError: "",
      DV_startNewChatMessage: "",
      DV_startNewChatMessageError: "",
      DV_startNewChatMobileError: "",
      DV_ratingFilters: ['all', 'amazing', 'good', 'average', 'below', 'bad', 'none'],
      DV_customerTypeFilters: ['all', 'mechanic', 'body-shop', 'reseller', 'retail', 'insurance'],
      DV_timeFilters: ['8-hours', '3-days', '1-week', '1-month', '3-month', '1-year', 'all'],
      DV_startingNewChat: false,
      DV_selectedConsumerUid: "",
      DV_ConsumerSearchValue: "",
      DV_lastStartNewChatMobileValue: "",
      DV_lastMobileAutoCompleteValue: "",
      DV_newlyStartingConversation: {
        status: 200,
        message: "",
        phoneNumber: ""
      },
      DV_activeUserInfo: window.G_bi2_data.IV_active_employee_info,
      DV_textingNumberErrorHeader: "",
      DV_textingNumberErrorBody: "",
      DV_conversationStarters: {},
      DV_selectedConversationStarter: {
        id: "",
        body: "",
      },
      DV_conversationStartersEnabled: true,
    };
  },

  mounted() {
    //FIXME only call this after a user clicks on the new conversation button        
    this.StartDataListeners();
    this.startConversationStartersListener();
  },

  watch: {
    DV_startNewChatMobile() {
      this.checkInputPhoneNumber()
    },
    DV_conversationStarters: {
      handler() {
        const CI = this;

        if (Object.keys(window.G_bi2_data.IV_recycler_conversation_starters.value).length === 0) { return; } 

        let firstItem = Object.values(CI.C_filteredConversationStarters)[0]
        CI.DV_selectedConversationStarter = {
          id: firstItem.id,
          body: firstItem.body,
        }
      },
      deep: true
    }
  },
  computed: {
    C_filteredConversationStarters() {
        const CI = this;

        let newConversationStarters = {};

        for (let entry in CI.DV_conversationStarters) {
          let conversationStarter = CI.DV_conversationStarters[entry];
          if (!conversationStarter.body.includes("[Payment Link]")) {
            newConversationStarters[entry] = conversationStarter;
          }
        }

        return newConversationStarters;
    },

    C_computedBody: {
      get() {
        return this.DV_selectedConversationStarter.body;
      },
      set(value) {
        this.DV_selectedConversationStarter.body = value.length > 320 ? value.substring(0, 320) : value;
      }
    },
    
    C_characterCount() {
      return this.DV_selectedConversationStarter.body.length;
    },

    C_filteredConsumerList() {
      let activatedConsumers = [];

      for (let consumer_uid in this.DV_consumerContacts) {
        let consumer = this.DV_consumerContacts[consumer_uid]
        
        if (consumer[this.DV_ccIndex.is_deactivated] === true) {
          continue;
        }

        activatedConsumers.push(consumer)
      }

      if (this.DV_ConsumerSearchValue === "") {
        let consumerWithNames = Object.values(activatedConsumers).filter(consumer => consumer[this.DV_ccIndex.first_name].trim() != "");
        let ConsumerWithNoNames = Object.values(activatedConsumers).filter(consumer => consumer[this.DV_ccIndex.first_name].trim() == "");
        consumerWithNames = _.sortBy(consumerWithNames, consumer => consumer[this.DV_ccIndex.first_name])

        return [...consumerWithNames, ...ConsumerWithNoNames];
      }
      let search_value = this.DV_ConsumerSearchValue.toLowerCase();

      let consumer_list = [];

      for (let consumer_uid in activatedConsumers) {
        let consumer = activatedConsumers[consumer_uid]
        let match_flag = false;

        if(consumer[this.DV_ccIndex.first_name].toLowerCase().indexOf(search_value) !== -1) {
          match_flag = true;
        } 
        else if(consumer[this.DV_ccIndex.last_name].toLowerCase().indexOf(search_value) !== -1) {
          match_flag = true;
        } 
        else if(consumer[this.DV_ccIndex.primary_phone_number].phone_number.toLowerCase().indexOf(search_value) !== -1) {
          match_flag = true;
        }  
        else if(consumer[this.DV_ccIndex.address_info_city_2d[0]][this.DV_ccIndex.address_info_city_2d[1]] !== undefined && 
          consumer[this.DV_ccIndex.address_info_city_2d[0]][this.DV_ccIndex.address_info_city_2d[1]].toLowerCase().indexOf(search_value) !== -1) {
          match_flag = true; 
        } 
        else if(consumer[this.DV_ccIndex.address_info_postal_code_2d[0]][this.DV_ccIndex.address_info_postal_code_2d[1]] !== undefined &&
          consumer[this.DV_ccIndex.address_info_postal_code_2d[0]][this.DV_ccIndex.address_info_postal_code_2d[1]].toLowerCase().indexOf(search_value) !== -1) {
          match_flag = true; 
        } 
        else if(consumer[this.DV_ccIndex.address_info_state_2d[0]][this.DV_ccIndex.address_info_state_2d[1]] !== undefined &&
          consumer[this.DV_ccIndex.address_info_state_2d[0]][this.DV_ccIndex.address_info_state_2d[1]].toLowerCase().indexOf(search_value) !== -1) {
          match_flag = true;
        } else if( this.getConsumerBusinessAssociations(consumer_uid,search_value).length > 0) {
          match_flag = true; 
        }

       if(match_flag=== true) {
        consumer_list.push(consumer)
       }
      }

      let consumerWithNames = consumer_list.filter(consumer => consumer[this.DV_ccIndex.first_name].trim() != "");
      let ConsumerWithNoNames = consumer_list.filter(consumer => consumer[this.DV_ccIndex.first_name].trim() == "");
      consumerWithNames = _.sortBy(consumerWithNames, consumer => consumer[this.DV_ccIndex.first_name])

      return [...consumerWithNames, ...ConsumerWithNoNames];
    }
  },

  methods: { 
    conversationStarterChanged() {
      const CI = this;

      if (CI.DV_conversationStarters[CI.DV_selectedConversationStarter.id]) {
        CI.DV_selectedConversationStarter.body = CI.DV_conversationStarters[CI.DV_selectedConversationStarter.id].body
      }

      if (CI.DV_selectedConversationStarter.id === 'Custom Message') {
        CI.DV_selectedConversationStarter.body = "";
      }
    },

    startConversationStartersListener(retry_count=0) {
      const CI = this;
      
      const recyclerLocationUid = window.G_bi2_data.IV_active_employee_info.recycler_location_uid;

      if (recyclerLocationUid in window.G_bi2_data.IV_recyclers.value == false) {
        if (retry_count >= 20) { return; }

        setTimeout(function() {
          CI.startConversationStartersListener(++retry_count)
        }, 500);
        return;
      }
      
      let recyclerInfo = window.G_bi2_data.IV_recyclers.value[recyclerLocationUid];
      CI.DV_conversationStartersEnabled = recyclerInfo.conversation_starters_enabled;      

      // populate conversation starters if it is enabled for this recycler location
      if (recyclerInfo.conversation_starters_enabled === false) {
        CI.DV_selectedConversationStarter.id = "Custom Message";
        return;
      }

      window.G_bi2_data.startRecyclerConversationStartersListener(
        window.G_bi2_data,
        window.G_bi2_data.IV_active_employee_info.business_uid,
        window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
      );
    
      CI.DV_conversationStarters = window.G_bi2_data.IV_recycler_conversation_starters.value;
    },

    StartDataListeners() {
      const CI = this;

      if(this.DV_activeUserInfo.recycler_location_uid === "") { 
        setTimeout(function() { CI.StartDataListeners(); }, 1000);
        return;
      }

      window.G_bi2_data.startAllConsumerContactsListener(
        window.G_bi2_data,
        this.DV_activeUserInfo.business_uid,
        this.DV_activeUserInfo.recycler_location_uid,
      );

      CI.DV_consumerConversationCounts = window.G_bi2_data.IV_consumer_conversation_counts.value;
      CI.DV_consumerBusinesses = window.G_bi2_data.IV_consumer_businesses.value;
      CI.DV_consumerContactsChanged = window.G_bi2_data.IV_consumer_contacts_data_last_changed.value;
      CI.DV_consumerBusinessJoins = window.G_bi2_data.IV_consumer_business_joins.value;

    },

    passEventOpenProfileModal(consumer_uid) {
      this.$emit('event-open-profile-modal',consumer_uid);
    },

    passEventOpenChatLogModal(consumer_uid) {
      this.$emit('event-open-chat-log-modal',consumer_uid);
    },

    passOpenConsumerFeedbackLogModal(consumer_uid) {
      this.$emit('event-open-consumer-feedback-log-modal',consumer_uid);
    },

    passEventOpenEditNewConsumerBusinessModal(business_uid) {
      this.$emit('event-open-consumer-business-modal',business_uid);
    },

    getConsumerBusinessAssociations(consumer_uid,search_term) {

      const CI = this;
      if(consumer_uid in CI.DV_consumerBusinessJoins === false) { 
        return [];
      }
      let associatedBusinesses = [];
      for (let array_index in CI.DV_consumerBusinessJoins[consumer_uid]) {
        let business_uid = CI.DV_consumerBusinessJoins[consumer_uid][array_index];
        const businessInfo = CI.DV_consumerBusinesses[business_uid];
        if (!businessInfo.name) { continue; };
          associatedBusinesses.push({uid: business_uid, name: businessInfo.name});          
      }

      //return the whole list if no search term was specified
      if(search_term === undefined) {return associatedBusinesses; }

      //if any thing matches return the whole list
      for(let array_index in associatedBusinesses) {
        if(associatedBusinesses[array_index].name.toLowerCase().indexOf(search_term) !== -1) {return associatedBusinesses;}
      }
      return [];  //this is only reached when using a search term which had no matches

    },

    setConsumerSearchValue(new_search_value)
    {
      this.DV_ConsumerSearchValue =new_search_value;

    },

    getConsumerConversationStatusSummary(consumer_uid) {
      let return_string = "";

      if (consumer_uid in window.G_bi2_data.IV_all_active_conversations.value === false) {return return_string;}
      let active_conversation_strings = [];
      let employee_names = {};
      let queued_conversation_count = 0;
      let active_conversation_count = 0;

      for(let conversation_uid in window.G_bi2_data.IV_all_active_conversations.value[consumer_uid]) {

        let consumer_conversation_uid = consumer_uid + "-" + conversation_uid;
        
        
        if(consumer_conversation_uid in window.G_bi2_data.IV_consumer_conversations.value === false) {continue;}   
        
        // conversation finished status over-rules all other statuses
        if(window.G_bi2_data.IV_consumer_conversations.value[consumer_conversation_uid].status_codes.indexOf("z") !== -1 ) { continue;}

        if ((consumer_conversation_uid in window.G_bi2_data.IV_conversation_queue_entries.value === true) ||
          (window.G_bi2_data.IV_consumer_conversations.value[consumer_conversation_uid].status_codes.indexOf("a") !== -1 )
        ) { 
          queued_conversation_count += 1;
        }

        let employee_uid = window.G_bi2_data.IV_consumer_conversations.value[consumer_conversation_uid].assigned_employee
        
        if (employee_uid === null) {continue;}

        let employee_name = "Employee";
        if (employee_uid in window.G_bi2_data.IV_recycler_employees.value == true) {
          employee_name = window.G_bi2_data.IV_recycler_employees.value[employee_uid].first_name + " " + window.G_bi2_data.IV_recycler_employees.value[employee_uid].last_name;
        }

        // counting the employee in conversation with the customer
        if (employee_names[employee_name]) {
          employee_names[employee_name] += 1
        } else {
          employee_names[employee_name] = 1
        }

        active_conversation_count += 1;
      }

      if(queued_conversation_count > 0) {
        return_string += queued_conversation_count.toString() + " queue request";
        if (queued_conversation_count > 1) {return_string += "s";}
      }

      if(queued_conversation_count > 0 && active_conversation_count > 0) {return_string += " , ";}

      // updating message
      Object.keys(employee_names).forEach((name) => {
        if (employee_names[name] > 1) {
          active_conversation_strings.push(`You are currently in ${employee_names[name]} conversations with ${name}`);
          return;
        }

        active_conversation_strings.push("In a conversation with " + name);
      })

      return_string += active_conversation_strings.join(" , ");
      return return_string;

    },

    //populates first name, last name and sets selected consumer_uid if a phone number of an existing consumer is entered
    checkInputPhoneNumber() {

      // clear existing data in first / last name if those fields were last populated by a phone number
      if(this.DV_lastStartNewChatMobileValue !== this.DV_startNewChatMobile && 
      this.DV_lastStartNewChatMobileValue === this.DV_lastMobileAutoCompleteValue) {
        this.DV_startNewChatFirstName = "";
        this.DV_startNewChatLastName = "";
      }
      this.DV_lastStartNewChatMobileValue = this.DV_startNewChatMobile;

      //only match full numbers
      if(this.DV_startNewChatMobile.length < 10 ) { return;}
      let call_result = window.G_bi2_data.findConsumerByPhoneNumber(
        window.G_bi2_data,this.DV_startNewChatMobile);
      
      if(call_result.consumer_uid === "") { return;}
      let consumer_uid = call_result.consumer_uid;
      this.DV_lastMobileAutoCompleteValue = this.DV_startNewChatMobile;
      this.DV_startNewChatFirstName = this.DV_consumerContacts[consumer_uid][this.DV_ccIndex.first_name];
      this.DV_startNewChatLastName = this.DV_consumerContacts[consumer_uid][this.DV_ccIndex.last_name];
      this.DV_startNewChatFirstNameError = "";
      this.DV_startNewChatLastNameError = "";
    },

    setSelectedConsumer(consumer_uid) {
      if(!consumer_uid) { return;}
      this.DV_selectedConsumerUid = consumer_uid;

      // set new customer fields
      this.DV_startNewChatMobile = this.DV_consumerContacts[consumer_uid][this.DV_ccIndex.primary_phone_number].phone_number;
      this.DV_startNewChatFirstName = this.DV_consumerContacts[consumer_uid][this.DV_ccIndex.first_name];
      this.DV_startNewChatLastName = this.DV_consumerContacts[consumer_uid][this.DV_ccIndex.last_name];
    },

    async startNewChat(consumer_uid) {
      const CI = this;
      let phone_number ="";
      let first_name = "";
      let last_name = "";
      let recycler_info = {};
      this.DV_startingNewChat = true;

      if(consumer_uid) {
        phone_number = this.DV_consumerContacts[consumer_uid][this.DV_ccIndex.primary_phone_number].phone_number;
        first_name = this.DV_consumerContacts[consumer_uid][this.DV_ccIndex.first_name];
        last_name = this.DV_consumerContacts[consumer_uid][this.DV_ccIndex.last_name];
      } else {
        phone_number = CI.DV_startNewChatMobile;

        if (CI.DV_startNewChatFirstName && CI.DV_startNewChatLastName) {
          first_name = CI.DV_startNewChatFirstName;
          last_name = CI.DV_startNewChatLastName;
        }

        if (CI.DV_startNewChatFirstName && CI.DV_startNewChatLastName === "") {
          first_name = CI.DV_startNewChatFirstName;
        }

        if (CI.DV_startNewChatFirstName === "" && CI.DV_startNewChatLastName) {
          last_name = CI.DV_startNewChatLastName;
        }
      }

      //  set recycler info
      if (this.DV_activeUserInfo.recycler_location_uid &&
        this.DV_activeUserInfo.recycler_location_uid in window.G_bi2_data.IV_recyclers.value
      ) {
        recycler_info = window.G_bi2_data.IV_recyclers.value[this.DV_activeUserInfo.recycler_location_uid];
      }

      let fail_flag = false;
      let call_result = dataValidator.is_texting_number(recycler_info.text_number)
      if (call_result[CR.success] !== RC.success) {
        // set message header and body
        this.DV_textingNumberErrorHeader = "Texting Number Not Requested"
        this.DV_textingNumberErrorBody = `It appears you haven't requested your business texting number yet. To initiate conversations, please go to the Settings -> Business Information page to request your texting number.
        <br><br>
        Note: You will need your Employer Identification Number (EIN) and Legal Business Name to successfully request your Business Texting Number.
        <br><br>
        If you do not have access to the Settings page, please notify your sales manager or a Synergy admin for your business.`
        
        this.$refs.textingNumberErrorModal.show();
        fail_flag = true;
        this.DV_startingNewChat = false;
        return;
      }

      call_result = dataValidator.is_string(phone_number, 9, 10);      
      if (call_result[CR.success] !== RC.success) {
        CI.DV_startNewChatMobileError = "Phone number should be 10 digits";
        fail_flag = true;
      }

      call_result = dataValidator.is_string(first_name, 0, 30);
      
      if (call_result[CR.success] !== RC.success) {
        CI.DV_startNewChatFirstNameError = "First can be up to 30 characters";
        fail_flag = true;
      }

      call_result = dataValidator.is_string(last_name, 0, 30);
      
      if (call_result[CR.success] !== RC.success) {
        CI.DV_startNewChatLastNameError = "First can be up to 30 characters";
        fail_flag = true;
      }

      if( fail_flag === true) {
        this.DV_startingNewChat = false;
        return;
      }

      this.DV_startNewChatMobileError = "";
      this.DV_startNewChatFirstNameError = "";
      this.DV_startNewChatLastNameError = "";
      window.G_bi2_data.IV_last_new_conversation_phone_number = phone_number;
      this.DV_newlyStartingConversation["phoneNumber"] = phone_number;
      this.DV_newlyStartingConversation["message"] = "Starting conversation with " + this.formatPhoneNumber(phone_number) + " | " + first_name + " " + last_name;

      //we are using .then here to prevent using an await which stops the emit event that shows the conversation starting status
      sendConversationInvite(
        {
          recycler_uid: this.DV_activeUserInfo.business_uid,
          recycler_location_uid: this.DV_activeUserInfo.recycler_location_uid,
          employee_uid: this.DV_activeUserInfo.employee_uid,
          phone_number: phone_number,
          first_name: first_name,
          last_name: last_name,
          conversation_starter_custom_message: this.DV_selectedConversationStarter.body,
        }
      ).then((result) => {

          this.DV_startingNewChat = false;
          if (result.success === true) {
            this.DV_startNewChatMobile = "";
            this.DV_startNewChatFirstName = "";
            this.DV_startNewChatLastName = "";
            this.DV_selectedConsumerUid ="";

            // showing message for the started conversation
            if (result.response["max_open_conversation_count_reached"]) {
              this.DV_newlyStartingConversation["status"] = 400
              this.DV_newlyStartingConversation["message"] = `This phone number(${phone_number}) currently has the maximum limit of 3 open conversations.`;

              setTimeout(() => {
                this.DV_newlyStartingConversation = {
                  status: 200,
                  message: "",
                  phoneNumber: ""
                }
              }, 2000)
              return;
            }

            this.DV_newlyStartingConversation["status"] = 200;
            this.DV_newlyStartingConversation["message"] = "";
          } else {
            this.$awn.alert('There was an issue while queuing new conversation. Please reload page and try again.');
          }
        });
    },

    closeTextingNumberErrorModal() {
      this.$refs.textingNumberErrorModal.hide();
    }
  }
};
</script>
