<template>
  <div id="p1s1c1c7">
    <div v-show="customerInfo == 'customerInformation'">

      <!-- Heading -->
      <div class="flex flex-row items-center justify-between">
        <svg @click="$emit('queue_list')" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"/>
        </svg>
        <h1 class="font-bold text-sm">Customer Information</h1>
        <svg @click="$emit('queue_list')" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"/>
        </svg>
      </div>

      <!-- Conversation details -->
      <div class="grid grid-cols-7 gap-2 px-2 items-center cursor-pointer my-5">
        <div class="col-span-1 h-full items-start">
          <h1 class="font-bold text-lg text-left">B Q</h1>
        </div>
        <div class="col-span-6">
          <p class="text-left text-sm my-1">
            John Miller | Downtown Repair | Body Shop | Quotes: 12435653($1000)
            132456($100)
          </p>
        </div>
      </div>

      <!-- View buttons -->
      <div class="grid grid-cols-2 items-center gap-2 mb-3">
        <button @click="customerInfo = 'profile'" class="view-btn">
          Customer Profile
        </button>
        <button @click="customerInfo = 'paymentLinkHistory'" class="view-btn">
          Payment Link History
        </button>
      </div>
      <div class="grid grid-cols-2 items-center gap-2 mb-3">
        <button @click="customerInfo = 'conversationHistory'" class="view-btn">
          Conversation History
        </button>
        <button @click="customerInfo = 'customerFeedbackLog'" class="view-btn">
          Customer Notes
        </button>
      </div>
      <div class="grid grid-cols-2 items-center gap-2 mb-3">
        <button class="view-btn">
          Customer Vehicle History
        </button>
      </div>
      <div class="grid grid-cols-1 items-center gap-2 mb-3 mt-6">
        <button @click="$emit('queue_list')" class="view-btn">
          Return To Queue List
        </button>
      </div>
    </div>

    <c1c6CustomerProfile
      v-show="customerInfo == 'profile'"
      @show-customer-information="customerInfo = 'customerInformation'"
      :customerInformation="true"
    />

    <c1c4ConversationHistory
      v-show="customerInfo == 'conversationHistory'"
      @show-customer-information="customerInfo = 'customerInformation'"
      :customerInformation="true"
    />

    <p1s1c2c4PaymentLinksHistory
      v-show="customerInfo == 'paymentLinkHistory'"
      @show-customer-information="customerInfo = 'customerInformation'"
      :customerInformation="true"
    />

    <c1c5CustomerFeedbackLog
      v-show="customerInfo == 'customerFeedbackLog'"
      @show-customer-information="customerInfo = 'customerInformation'"
      :customerInformation="true"
    />
  </div>
</template>

<script>
import p1s1c2c4PaymentLinksHistory from "../p1/p1s1c2c4PaymentLinksHistory.vue";
import c1c5CustomerFeedbackLog from "../c1/c1c5CustomerFeedbackLog.vue";
import c1c4ConversationHistory from "../c1/c1c4ConversationHistory.vue";
import c1c6CustomerProfile from "../c1/c1c6CustomerProfile.vue";

export default {
  name: "p1s1c1c7CustomerInformation",

  components: { c1c6CustomerProfile, c1c4ConversationHistory, p1s1c2c4PaymentLinksHistory, c1c5CustomerFeedbackLog },

  data() {
    return {
      customerInfo: "customerInformation",
    };
  },
};
</script>
