<template>
  <!-- Customer queue response time pie chart -->
  <div id="p1s2c3" class="h-5/6">
    <div class="h-full">
      <div class="rounded-xl overflow-hidden h-full">
        <canvas class="p-10" id="chartBar"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

export default {
  name: "p1s2c3QueueResponseTime",

  data() {
    return {
      dataBarChart: {
        labels: ["January", "February", "March", "April", "May", "June"],
        datasets: [
          {
            label: "Response Time",
            data: [0, 10, 11, 0, 9, 7],
            backgroundColor: "hsl(252, 82.9%, 67.8%)",
            borderColor: "hsl(252, 82.9%, 67.8%)",
            hoverOffset: 4,
          },
        ],
      },
      configBarChart: {
        type: "bar",
        data: {},
        options: {
          responsive: true,
          maintainAspectRatio: false,
        },
      },
    };
  },

  mounted() {
    this.configBarChart["data"] = this.dataBarChart;
    var chartBar = new Chart(
      document.getElementById("chartBar"),
      this.configBarChart
    );
  },
};
</script>
