import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import p2LoginView from "../views/p2LoginView.vue"
import p3AccountSetup from "../views/p3AccountSetup.vue";
import p4SettingsView from "../views/p4SettingsView.vue";
import p1s1MyConversations from "../components/p1/p1s1MyConversations.vue";
import c2CustomerProfile from "../components/c1/c2CustomerProfile.vue";
import c3CustomerFeedback from "../components/c1/c3CustomerFeedback.vue";
import c4CustomerHistory from "../components/c1/c4CustomerHistory.vue";
import c8RecyclerOnlyChat from "../components/c1/c8RecyclerOnlyChat.vue";
import c7CustomerChat from "../components/c1/c7CustomerChat.vue";
import c1ChatModule from "../components/c1/c1ChatModule.vue";
import p1s2Reporting from "../components/p1/p1s2Reporting.vue";
import p1s3UserManagement from "../components/p1/p1s3UserManagement.vue";
import p1s3c3BusinessesManagement from "../components/p1/p1s3c3BusinessesManagement.vue";
import p1s1c2Conversations from "../components/p1/p1s1c2Conversations.vue";
import p5HelpAndSupport from "../views/p5HelpAndSupport.vue"
import p1s4Transactions from "@/components/p1/p1s4Transactions.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "home",
    component: p2LoginView,
  },
  {
    path: "/login",
    name: "login",
    component: p2LoginView
  },
  {
    path: "/account-setup",
    name: "account-setup",
    component: p3AccountSetup,
  },
  {
    path: "/axe-account-setup",
    name: "axe-account-setup",
    component: p3AccountSetup,
  },
  {
    path: "/slimcd-account-setup",
    name: "slimcd-account-setup",
    component: p3AccountSetup,
  },
  {
    path: "/contacts",
    name: "p1s3UserManagement",
    component: p1s3UserManagement,
  },
  {
    path: "/transactions",
    name: "p1s4Transactions",
    component: p1s4Transactions,
  },
  {
    path: "/sales-dashboard",
    name: "p1s1MyConversations",
    component: p1s1MyConversations,
  },
  {
    path: "/reporting-dashboard",
    name: "p1s2Reporting",
    component: p1s2Reporting,
  },
  {
    path: "/settings",
    name: "p4SettingsView",
    component: p4SettingsView,
  },
  {
    path: "/help_&_support",
    name: "p5HelpAndSupport",
    component: p5HelpAndSupport,
  },
  {
    path: "/customerProfile",
    name: "customerProfile",
    component: c2CustomerProfile,
  },
  {
    path: "/customerFeedback",
    name: "customerFeedback",
    component: c3CustomerFeedback,
  },
  {
    path: "/customerHistory",
    name: "customerHistory",
    component: c4CustomerHistory,
  },
  {
    path: "/customerChat",
    name: "customerChat",
    component: c7CustomerChat,
  },
  {
    path: "/recyclerOnlyChat",
    name: "recyclerOnlyChat",
    component: c8RecyclerOnlyChat,
  },
  {
    path: "/liveChat",
    name: "liveChat",
    component: c1ChatModule,
  },
  {
    path: "/businessesManagement",
    name: "p1s3c3BusinessesManagement",
    component: p1s3c3BusinessesManagement,
  },
  {
    path: "/conversations",
    name: "p1s1c2Conversations",
    component: p1s1c2Conversations,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
