<template>
  <div>
    <h2 class="text-2xl font-bold text-center">Synergy Auto Solutions: Integration Documents</h2>
    <ol class="list-number text-left">
  <!--
      <li>
        <a class="underline text-blue-500 block cursor-pointer" @click="downloadDocument('Business Texting Number Activation Guide', 'https://storage.googleapis.com/synergy-auto-solutions-public-documents/integration_documents/Business%20Texting%20Number%20Activation%20Guide.pdf')">Business Texting Number Activation Guide</a>
      </li>
  -->
      <li class="!mt-0">
        <a class="underline text-blue-500 block cursor-pointer" @click="downloadDocument('Team Member Invitation Guide', 'https://storage.googleapis.com/synergy-auto-solutions-public-documents/integration_documents/Team%20Member%20Invitation%20Guide.pdf')">Team Member Invitation Guide </a>
      </li>
      <li class="!mt-0">
        <a class="underline text-blue-500 block cursor-pointer" @click="downloadDocument('Website Widget Integration Guide', 'https://storage.googleapis.com/synergy-auto-solutions-public-documents/integration_documents/Website%20Widget%20Integration%20Guide.pdf')">Website Widget Integration Guide</a>
      </li>
      <li class="!mt-0">
        <a class="underline text-blue-500 block cursor-pointer" @click="downloadDocument('Stripe Identity Verification Integration Guide', 'https://storage.googleapis.com/synergy-auto-solutions-public-documents/integration_documents/Stripe%20Identity%20Verification%20Integration%20Guide.pdf')">Stripe Identity Verification Integration Guide</a>
      </li>
      <li class="!mt-0">
        <a class="underline text-blue-500 block cursor-pointer" @click="downloadDocument('SlimCD Integration Guide', 'https://storage.googleapis.com/synergy-auto-solutions-public-documents/integration_documents/SlimCD%20Integration%20Guide.pdf')">SlimCD Integration Guide</a>
      </li>
      <li class="!mt-0">
        <a class="underline text-blue-500 block cursor-pointer" @click="downloadDocument('Axe Payments Integration Guide', 'https://storage.googleapis.com/synergy-auto-solutions-public-documents/integration_documents/Axe%20Payments%20Integration%20Guide.pdf')">Axe Payments Integration Guide</a>
      </li>
      <li class="!mt-0">
        <a class="underline text-blue-500 block cursor-pointer" @click="downloadDocument('Online Reviews Integration Guide', 'https://storage.googleapis.com/synergy-auto-solutions-public-documents/integration_documents/Online%20Reviews%20Integration%20Guide.pdf')">Online Reviews Integration Guide</a>
      </li>
    </ol>
  </div>
</template>

<script>
import generalFunctions from "../../mixins/generalFunctions.js";

export default {
  name: "p5s5integrateWithSynergy",

  mixins: [ generalFunctions ]
}
</script>