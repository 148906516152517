/* eslint-disable */
import { ajax } from "noquery-ajax";
import { httpsLogger } from "../p1s7/p1s7e1";

function publishReviewAccountCreationRequest(
  recycler_uid: string,
  recycler_location_uid: string,
  email: string
) {
  return new Promise(function (resolve, reject) {
    let params = {
      firebase_uid: window.G_firebase_auth.IV_uid,
      firebase_token: window.G_firebase_auth.IV_id_token,
      recycler_uid: recycler_uid,
      recycler_location_uid: recycler_location_uid,
      p1s3e30_email_address: email
    };

    ajax({
      url: window.G_cloud_p1s3_base_url + "p1s3e30PublishReviewAccountCreationRequest",
      headers: {
        Accept: "application/json",
      },
      method: "POST",
      dataType: "json",
      data: params,
      success: function (result) {
        let response_data = {
          success: true,
          response: result,
          request_params: params
        }
        resolve(response_data);
      },
      error: function (error) {
        let error_data = {
          success: false,
          request_object: error,
          request_params: params,
          file_name: "p1s3e30.ts",
          class_name: "p1s3e30",
          method_name: "publishReviewAccountCreationRequest",
          error_title: "Failed to create review account.",
          error_description: "The server responded with an error when creating review account for recycler location.",
        };

        httpsLogger(error_data);
        reject(error_data);
      },
    });
  });
}

export default publishReviewAccountCreationRequest;
