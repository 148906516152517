/* eslint-disable */
import { ajax } from "noquery-ajax";
import { httpsLogger } from "../p1s7/p1s7e1";

interface Arguments {
  recycler_uid: string;
  billing_document_id: string;
  area_code: Number;
  name: string;
  email: string;
  address_line_1: string;
  address_line_2?: string;
  postal_code: string;
  state: string;
  city: string;
  phone: string;
  website: string;
  google_maps_url?: string;
  facebook_url?: string;
}

function createRecyclerLocation({
  recycler_uid,
  billing_document_id,
  area_code,
  name,
  email,
  address_line_1,
  address_line_2,
  postal_code,
  state,
  city,
  phone,
  website,
  google_maps_url,
  facebook_url,
}: Arguments) {
  return new Promise(function (resolve, reject) {
    let params = {
      firebase_uid: window.G_firebase_auth.IV_uid,
      firebase_token: window.G_firebase_auth.IV_id_token,
      firebase_email: window.G_firebase_auth.IV_email_address,
      recycler_uid: recycler_uid,
      p1s3e12_billing_document_id: billing_document_id,
      p1s3e12_area_code: area_code,
      p1s3e12_name: name,
      p1s3e12_email: window.G_firebase_auth.IV_email_address,
      p1s3e12_address_line_1: address_line_1,
      ...(address_line_2 && { p1s3e12_address_line_2: address_line_2 }),
      p1s3e12_postal_code: postal_code,
      p1s3e12_state: state,
      p1s3e12_city: city,
      p1s3e12_phone: phone,
      p1s3e12_website: website,
      ...(google_maps_url && { p1s3e12_google_maps_url: google_maps_url }),
      ...(facebook_url && { p1s3e12_facebook_url: facebook_url }),
    };

    ajax({
      url: window.G_cloud_p1s3_base_url + "p1s3e12CreateRecyclerLocation",
      headers: {
        Accept: "application/json",
      },
      method: "POST",
      dataType: "json",
      data: params,
      success: function (result) {
        let response_data = {
          success: true,
          response: result,
          request_params: params
        }
        resolve(response_data);
      },
      error: function (error) {
        let error_data = {
          success: false,
          request_object: error,
          request_params: params,
          file_name: "p1s3e12.ts",
          class_name: "p1s3e12",
          method_name: "createRecyclerLocation",
          error_title: "Failed to create new Recycler Store Location",
          error_description:
            "The server responded with an error when trying to create a new Recycler Store Location",
        };

        httpsLogger(error_data);
        reject(error_data);
      },
    });
  });
}

export default createRecyclerLocation;
