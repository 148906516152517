<template>
  <div class="text-left">
    <h2 class="text-2xl font-bold text-center">Synergy Auto Solutions: Contacts User Guide</h2>

    <p class="mt-8">In the Synergy Auto Solutions platform, the Contacts page serves as a centralized hub where you can effortlessly locate and manage all your Customer Contacts and Businesses. In addition to general access, Administrators and Managers have additional access, including the ability to oversee staff via the Employees tab and the capability to reactivate any previously deactivated Customer Contacts or Businesses</p>

    <p class="mt-2">
      <span class="font-bold block">Mastering Customer Contacts</span>
      Upon entering the Customer Contacts tab within the Contacts page, you’ll immediately notice two main sections: a versatile filter panel on the left and a comprehensive table of Customer Contacts on the right. The table displays key information from each Customer Profile, such as name, contact details, business affiliations, customer type, and rating.

      <p class="mt-2 pl-5">
        <span class="font-bold block">Interactive Options:</span>
        <ol class="list-disc list-inside list-bullets">
          <li class="!mt-0 list-item">
            <span class="font-bold">Start New Chat:</span>Initiates a chat with the selected customer and redirects you to the Sales Dashboard for live conversation.
          </li>
          <li class="!mt-0 list-item">
            <span class="font-bold">Conversations:</span>
            Allows you to peruse your historical chats with the customer.
          </li>
          <li class="!mt-0 list-item">
            <span class="font-bold">Profile:</span>
            Provides access to the customer’s profile, enabling you to view and modify stored details.
          </li>
          <li class="!mt-0 list-item">
            <span class="font-bold">Deactivate:</span>
            Moves the customer to a deactivated list. Only Administrators and Managers have access to reactivate contacts that are deactivated.
          </li>
        </ol>
      </p>
      
      <p class="mt-2 pl-5">
        <span class="font-bold block">Filtering and Search Capabilities:</span>
        <ol class="list-disc list-inside list-bullets">
          <li class="!mt-0 list-item">
            <span class="font-bold">Business Associations:</span>Refine your list by choosing a specific business from the dropdown menu.
          </li>
          <li class="!mt-0 list-item">
            <span class="font-bold">Location:</span>
            Drill down by City or State, based on the details within each Customer Profile
          </li>
          <li class="!mt-0 list-item">
            <span class="font-bold">Customer Metrics:</span>
            Filter by Customer Type or average Customer Rating for targeted navigation.
          </li>
          <li class="!mt-0 list-item">
            <span class="font-bold">Search Bar:</span>
            A quick-search feature is located above the table for keyword-based queries.
          </li>
        </ol>
      </p>
      
      <p class="mt-2 pl-5">
        <span class="font-bold block">New Customer Entry:</span>
        To add a Customer Contact, simply click the <i>“Create New Customer Contact”</i> button located at the top-right corner of the page. Input the required details and finalize by selecting the <i>“Create New Customer”</i> button.
      </p>
    </p>



    <p class="mt-2">
      <span class="font-bold block">Mastering Businesses</span>
      Every team member within your organization has visibility into the Queue. However, only one team member can initially claim a request to engage with a customer. Team members have the flexibility to sort Queue requests based on the customer’s wait time or their average rating from prior interactions.

      <p class="mt-2 pl-5">
        <span class="font-bold block">Interactive Options:</span>
        <ol class="list-disc list-inside list-bullets">
          <li class="!mt-0 list-item">
            <span class="font-bold">Profile:</span>Access an in-depth view of each business profile for additional information.
          </li>
          <li class="!mt-0 list-item">
            <span class="font-bold">Deactivate:</span>
            Archive a business listing, rendering it inactive. Note that only Managers and Administrators have the authority to reactivate deactivated businesses.
          </li>
        </ol>
      </p>

      <p class="mt-2 pl-5">
        <span class="font-bold block">Filtering and Search Capabilities:</span>
        <ol class="list-disc list-inside list-bullets">
          <li class="!mt-0 list-item">
            <span class="font-bold">Business Criteria:</span>Refine the list based on business type, city, or state.
          </li>
          <li class="!mt-0 list-item">
            <span class="font-bold">Search Bar:</span>
            Located above the right-hand table, this feature allows for keyword-based searches to quickly find businesses of interest.
          </li>
        </ol>
      </p>

      <p class="mt-2 pl-5">
        <span class="font-bold block">Adding a New Business:</span>
        Should you wish to register a new business, click the <i>“Create New Business”</i> button located at the top-right corner of the page. Fill in the requisite details and confirm the entry by selecting the <i>“Create New Business”</i> button once more.
      </p>
    </p>

    <p class="mt-2">
      <span class="font-bold block">Managing Employees: A Guide for Admins & Managers</span>
      Exclusively accessible for Managers and Administrators, the Employees tab within the Contacts page presents a structured layout with a detailed employee table on the right side of the interface, displaying essential information such as names, roles, and email addresses. On the left, a filtering panel enables focused searches among your team members.

      <p class="mt-2 pl-5">
        <span class="font-bold block">Interactive Options:</span>
        <ol class="list-disc list-inside list-bullets">
          <li class="!mt-0 list-item">
            <span class="font-bold">Profile:</span>Click to delve into an employee’s comprehensive profile for further details.
          </li>
          <li class="!mt-0 list-item">
            <span class="font-bold">Deactivate:</span>
            Disable an employee’s access to the platform. Note: Only Managers and Administrators hold the power to reactivate an account.
          </li>
        </ol>
      </p>

      <p class="mt-2 pl-5">
        <span class="font-bold block">Filtering and Search Features:</span>
        <ol class="list-disc list-inside list-bullets">
          <li class="!mt-0 list-item">
            <span class="font-bold">Role-based Filters:</span>Utilize the left-hand filter panel to sort employees based on their assigned roles.
          </li>
          <li class="!mt-0 list-item">
            <span class="font-bold">Search Bar:</span>
            Positioned above the table, this search bar supports keyword-driven queries for fast employee lookup.
          </li>
        </ol>
      </p>

      <p class="mt-2 pl-5">
        <span class="font-bold block">Inviting New Employees:</span>
        To extend an invitation, click on the <i>“Invite An Employee”</i> button at the top right. Fill out their email address, name, and roles, and then hit the <i>“Invite New Employee”</i> button. An invitation will be dispatched to their email. If the recipient does not find the email in their inbox within 5 minutes, advise them to check their junk/spam folders. A clickable link within the email allows them to accept the invitation and formally join your organization. Subsequently, the new recruit will appear in your Employees list.
      </p>
    </p>

    <p class="mt-2">
      If you have any questions or require assistance, feel free to reach out to us at <a class="text-blue-500 hover:text-blue-800 underline" href="mailto:info@texnrewards.com">info@texnrewards.com</a> or submit a support request on our Help & Support page.
    </p>
  </div>
</template>

<script>
export default {
  name: "p5s4contacts"
}
</script>