import { FirestoreDB } from "./include/creds";
import {
  DocPath,
  DataProblem,
  RemoveDocResult,
  GetDocResult,
} from "./include/misc";

import {
  collection,
  doc,
  setDoc,
  getDoc,
  deleteDoc,
} from "firebase/firestore";

export type fs131EmployeeActivityStatuses = {
  last_activity_time: Date;
};

export function getDataProblems_fs131EmployeeActivityStatuses(
  data: fs131EmployeeActivityStatuses
): DataProblem[] {
  const dataproblems: DataProblem[] = [];
  return dataproblems;
}

// We assume that this function is fast and will not block.
export function isValidData(data: fs131EmployeeActivityStatuses): boolean {
  return true;
}


export async function setDocIn_fs131EmployeeActivityStatuses(
  fs1_id: string,
  fs2_id: string,
  fs3_id: string,
  data: fs131EmployeeActivityStatuses
): Promise<DocPath> {
  const result: DocPath = {
    id: "",
    path: "",
  };
  if (!isValidData(data)) {
    return await Promise.resolve(result);
  }

  const col = collection(
    FirestoreDB,
    "fs1RecyclerBusiness",
    fs1_id,
    "fs2RecyclerLocation",
    fs2_id,
    "fs3Employees",
    fs3_id,
    "fs131EmployeeActivityStatuses"
  );


  const doc_ref = doc(col, "1");

  await setDoc(doc_ref, data);

  result.id = doc_ref.id;
  result.path = doc_ref.path;
  return await Promise.resolve(result);
}
