<template>
  <div>
    <!-- Header tabs -->
    <ul class="flex w-10/12 m-auto -mt-8 flex-wrap w-full">
      <li class="w-1/3">
        <a
          @click.stop="DV_activeTab = 'payment'"
          :class="{ active: DV_activeTab == 'payment' }"
          class="tab-header-button left w-full truncate"
          >Mobile Payments</a
        >
      </li>
      <li class="w-1/3">
        <a
          @click.stop="DV_activeTab = 'review'"
          :class="{ active: DV_activeTab == 'review' }"
          class="tab-header-button w-full truncate"
          >Online Reviews</a
        >
      </li>
      <li class="w-1/3">
        <a
          @click.stop="DV_activeTab = 'online-widget'"
          :class="{ active: DV_activeTab == 'online-widget' }"
          class="tab-header-button right w-full truncate"
          >Online Widgets</a
        >
      </li>
    </ul>

    <div class="w-10/12 m-auto">

      <!-- Payment Processor -->
      <div class="mt-4" v-if="DV_loadingPaymentApiKeysInformation">
        Loading...
      </div>
      <div class="mt-4" v-if="!DV_loadingPaymentApiKeysInformation && DV_recyclerLocationNotFound">
        Unable to load recycler location information. Please reload page and try again.
      </div>
      <div v-if="!DV_loadingPaymentApiKeysInformation && !DV_recyclerLocationNotFound && DV_activeTab == 'payment'" class="mt-12 text-left">
        
        <!-- Axe -->
        <span v-if="DV_activeRecyclerLocation.assigned_payment_method !== 'slimcd'" class="flex items-center">
          <input autocomplete="off" name="payment-method" type="radio" id="axe-payment-processor" value="axe" v-model="DV_selectedPaymentMethod" class="cursor-pointer">
          <label for="axe-payment-processor" class="font-bold ml-2 cursor-pointer">Axe Pay Credentials</label>
        </span>

        <div v-if="DV_selectedPaymentMethod == 'axe' && DV_activeRecyclerLocation.assigned_payment_method !== 'slimcd'">
          <div class="flex w-full grow mt-2 text-center border-2 border-slate-300 rounded-xl p-2">
            <span class="w-full">Public API Key*:</span>
            <div class="w-full">
              <input autocomplete="off" v-model="DV_axePaymentAccountInfo.publicApiKey" @input="DV_axePaymentAccountInfo.publicApiKeyError = ''" type="text">
              <div class="text-left w-full">
                <span v-if="DV_axePaymentAccountInfo.publicApiKeyError !== ''" class="text-sm text-red-400">{{ DV_axePaymentAccountInfo.publicApiKeyError }}</span>
              </div>
            </div>
          </div>

          <div class="flex w-full grow mt-2 text-center border-2 border-slate-300 rounded-xl p-2">
            <span class="w-full">Private API Key*:</span>
            <div class="w-full">
              <div class="w-full flex items-center justify-between relative">
                <input autocomplete="off" v-model="DV_axePaymentAccountInfo.privateApiKey" @input="DV_axePaymentAccountInfo.privateApiKeyError = ''" :type="C_privateAxePaymentInputType">
                <div class="cursor-pointer text-blue-400 absolute right-1" @click.stop="toggleShowAxePaymentPrivateKey">
                  {{DV_axePaymentAccountInfo.showPrivateKey ? '(Conceal)' : '(Reveal)'}}
                </div>
              </div>
              <div class="text-left w-full">
                <span v-if="DV_axePaymentAccountInfo.privateApiKeyError !== ''" class="text-sm text-red-400">{{ DV_axePaymentAccountInfo.privateApiKeyError }}</span>
              </div>
            </div>
          </div>

          <!-- Toggle switch -->
          <div class="flex items-center mt-4">
            <label 
              aria-pressed="false" 
              aria-labelledby="toggleLabel" 
              class="toggle-label toggle-btn"
              :class="[DV_axeAVSEnabled ? 'bg-blue-600' : 'bg-gray-200']"
            >
              <span class="sr-only">Use setting</span>
              
              <input 
                type="checkbox" 
                hidden="true"
                class="opacity-0 w-0 h-0"
                v-model="DV_axeAVSEnabled"
                :class="[DV_axeAVSEnabled ? 'toggle bg-blue-600' : 'bg-gray-200']"
              >
              <span aria-hidden="true" class="slider toggle-btn-slider"></span>
            </label>

            <span class="flex-grow flex flex-col ml-4" id="toggleLabel">
              <span style="line-height: 1rem" class="font-bold text-gray-900">
                Address Verification Service
              </span>
            </span>
          </div>

          <div class="ml-4 mt-2" v-show="DV_axeAVSEnabled">
            <input v-model="DV_axeAVSoption" autocomplete="off" name="avs-option" type="radio" id="avs-complete-billing" value="2" class="cursor-pointer">
            <label for="avs-complete-billing" class="font-bold ml-2 cursor-pointer">Complete Billing Address</label>
          </div>

          <div class="ml-4 mt-2" v-show="DV_axeAVSEnabled">
            <input v-model="DV_axeAVSoption" autocomplete="off" name="avs-option" type="radio" id="avs-zip-code-only" value="1" class="cursor-pointer">
            <label for="avs-zip-code-only" class="font-bold ml-2 cursor-pointer">Zip Code Only</label>
          </div>
        </div>

        <!-- SlimCD -->
        <span v-if="DV_activeRecyclerLocation.assigned_payment_method !== 'axe'" class="flex items-center mt-4">
          <input autocomplete="off" name="payment-method" type="radio" id="slimcd-payment-processor" value="slimcd" v-model="DV_selectedPaymentMethod" class="cursor-pointer">
          <label for="slimcd-payment-processor" class="font-bold ml-2 cursor-pointer">SlimCD Payment Processor</label>
        </span>

        <div v-if="DV_selectedPaymentMethod == 'slimcd' && DV_activeRecyclerLocation.assigned_payment_method !== 'axe'">
          <div class="flex w-full grow mt-2 text-center border-2 border-slate-300 rounded-xl p-2">
            <span class="w-full">Client ID*:</span>
            <div class="w-full">
              <input autocomplete="off" v-model="DV_slimCdAccountInfo.clientId" @input="DV_slimCdAccountInfo.clientIdError = ''" type="text">
              <div class="text-left w-full">
                <span v-if="DV_slimCdAccountInfo.clientIdError !== ''" class="text-sm text-red-400">{{ DV_slimCdAccountInfo.clientIdError }}</span>
              </div>
            </div>
          </div>

          <div class="flex w-full grow mt-2 text-center border-2 border-slate-300 rounded-xl p-2">
            <span class="w-full">Price ID*:</span>
            <div class="w-full">
              <input autocomplete="off" v-model="DV_slimCdAccountInfo.priceId" @input="DV_slimCdAccountInfo.priceIdError = ''" type="text">
              <div class="text-left w-full">
                <span v-if="DV_slimCdAccountInfo.priceIdError !== ''" class="text-sm text-red-400">{{ DV_slimCdAccountInfo.priceIdError }}</span>
              </div>
            </div>
          </div>

          <div class="flex w-full grow mt-2 text-center border-2 border-slate-300 rounded-xl p-2">
            <span class="w-full">Private API Key*:</span>
            <div class="w-full">
              <div class="w-full flex items-center justify-between relative">
                <input autocomplete="off" v-model="DV_slimCdAccountInfo.privateApiKey" @input="DV_slimCdAccountInfo.privateApiKeyError = ''" :type="C_privateSlimCDInputType">
                <div class="cursor-pointer text-blue-400 absolute right-1" @click.stop="toggleShowSlimCdPrivateKey">
                  {{DV_slimCdAccountInfo.showPrivateKey ? '(Conceal)' : '(Reveal)'}}
                </div>
              </div>
              <div class="text-left w-full">
                <span v-if="DV_slimCdAccountInfo.privateApiKeyError !== ''" class="text-sm text-red-400">{{ DV_slimCdAccountInfo.privateApiKeyError }}</span>
              </div>
            </div>
          </div>

          <div class="flex w-full grow mt-2 text-center border-2 border-slate-300 rounded-xl p-2">
            <span class="w-full">Public API key*:</span>
            <div class="w-full">
              <input autocomplete="off" v-model="DV_slimCdAccountInfo.publicApiKey" @input="DV_slimCdAccountInfo.publicApiKeyError = ''" type="text">
              <div class="text-left w-full">
                <span v-if="DV_slimCdAccountInfo.publicApiKeyError !== ''" class="text-sm text-red-400">{{ DV_slimCdAccountInfo.publicApiKeyError }}</span>
              </div>
            </div>
          </div>

          <div class="flex w-full grow mt-2 text-center border-2 border-slate-300 rounded-xl p-2">
            <span class="w-full">Site ID*:</span>
            <div class="w-full">
              <input autocomplete="off" v-model="DV_slimCdAccountInfo.siteId" @input="DV_slimCdAccountInfo.siteIdError = ''" type="text">
              <div class="text-left w-full">
                <span v-if="DV_slimCdAccountInfo.siteIdError !== ''" class="text-sm text-red-400">{{ DV_slimCdAccountInfo.siteIdError }}</span>
              </div>
            </div>
          </div>

          <!-- Toggle switch -->
          <div class="flex items-center mt-4">
            <label 
              aria-pressed="false" 
              aria-labelledby="toggleLabel" 
              class="toggle-label toggle-btn"
              :class="[DV_slimcdAVSEnabled ? 'bg-blue-600' : 'bg-gray-200']"
            >
              <span class="sr-only">Use setting</span>
              
              <input 
                type="checkbox" 
                hidden="true"
                class="opacity-0 w-0 h-0"
                v-model="DV_slimcdAVSEnabled"
                :class="[DV_slimcdAVSEnabled ? 'toggle bg-blue-600' : 'bg-gray-200']"
              >
              <span aria-hidden="true" class="slider toggle-btn-slider"></span>
            </label>

            <span class="flex-grow flex flex-col ml-4" id="toggleLabel">
              <span style="line-height: 1rem" class="font-bold text-gray-900">
                Address Verification Service
              </span>
            </span>
          </div>

          <div class="ml-4 mt-2" v-show="DV_slimcdAVSEnabled">
            <input v-model="DV_slimcdAVSoption" autocomplete="off" name="avs-option" type="radio" id="avs-complete-billing" value="2" class="cursor-pointer">
            <label for="avs-complete-billing" class="font-bold ml-2 cursor-pointer">Complete Billing Address</label>
          </div>

          <div class="ml-4 mt-2" v-show="DV_slimcdAVSEnabled">
            <input v-model="DV_slimcdAVSoption" autocomplete="off" name="avs-option" type="radio" id="avs-zip-code-only" value="1" class="cursor-pointer">
            <label for="avs-zip-code-only" class="font-bold ml-2 cursor-pointer">Zip Code Only</label>
          </div>
        </div>

        <!-- Stripe identity verification -->
        <div class="mt-8">
          <!-- Toggle switch -->
          <div class="flex items-center">
            <label 
              aria-pressed="false" 
              aria-labelledby="toggleLabel" 
              class="toggle-label toggle-btn"
              :class="[DV_unsavedIdentityVerificationActive ? 'bg-blue-600' : 'bg-gray-200']"
            >
              <span class="sr-only">Use setting</span>
              
              <input 
                type="checkbox" 
                hidden="true"
                class="opacity-0 w-0 h-0"
                v-model="DV_unsavedIdentityVerificationActive"
                :class="[DV_unsavedIdentityVerificationActive ? 'toggle bg-blue-600' : 'bg-gray-200']"
                @change="stripeIdentityVerificationChanged"
              >
              <span aria-hidden="true" class="slider toggle-btn-slider"></span>
            </label>

            <span class="flex-grow flex flex-col ml-4" id="toggleLabel">
              <span style="line-height: 1rem" class="font-bold text-gray-900">
                Stripe Identity Verification
              </span>
            </span>
          </div>

          <!-- Input minimum transaction ammount -->
          <div v-if="DV_unsavedIdentityVerificationActive === true" class="flex items-center justify-start pl-14">
            <label>
              Minimum Transaction Amount
              <span class="tooltip">
                  ?
                  <span class="tooltiptext whitespace-pre-line" :style="DV_toolTipStyle">This represents the threshold dollar amount for a payment link to initiate the Stripe Identity Verification process. If a salesperson establishes a payment link exceeding this amount, they will be prompted for a $1.75 surcharge consent. Upon their agreement, the customer receives a Stripe Identity Verification link. Once the customer clears the verification, a payment link is then sent to them.</span>
              </span>
            </label>
            <input v-model="C_stripeVerificationMinimumCharges" type="number" class="!w-24 ml-4" name="minimum_transaction">
          </div>
        </div>

        <!-- Send Live Chat Messages via SMS-->
        <div class="flex text-left mt-8"> 
          <!-- Toggle switch -->
          <div class="flex items-center">
            <label 
              aria-pressed="false" 
              aria-labelledby="toggleLabel" 
              class="toggle-label toggle-btn"
              :class="[DV_unsavedSendLiveMessagesViaSMS ? 'bg-blue-600' : 'bg-gray-200']"
            >
              <span class="sr-only">Use setting</span>
              
              <input 
                type="checkbox" 
                hidden="true"
                class="opacity-0 w-0 h-0"
                v-model="DV_unsavedSendLiveMessagesViaSMS"
                :class="[DV_unsavedSendLiveMessagesViaSMS ? 'toggle bg-blue-600' : 'bg-gray-200']"
                @change="sendLiveChatMessagesViaSMSChanged"
              >
              <span aria-hidden="true" class="slider toggle-btn-slider"></span>
            </label>
          </div>
            
          <span class="font-bold text-gray-900 ml-4">Send Messages with SMS/MMS</span>
          <span class="tooltip ml-1">
            ?
            <span class="tooltiptext">When enabled, all messages sent from the live chat will be sent to the customer's mobile via SMS.</span>
          </span>
        </div>

        <!-- Conversation Starters -->
        
        <!-- Toggle switch -->
          <div class="flex items-center mt-4">
            <label 
              aria-pressed="false" 
              aria-labelledby="toggleLabel" 
              class="toggle-label toggle-btn"
              :class="[DV_customConversationStartersEnabled ? 'bg-blue-600' : 'bg-gray-200']"
            >
              <span class="sr-only">Use setting</span>
              
              <input 
                type="checkbox" 
                hidden="true"
                class="opacity-0 w-0 h-0"
                v-model="DV_customConversationStartersEnabled"
                :class="[DV_customConversationStartersEnabled ? 'toggle bg-blue-600' : 'bg-gray-200']"
              >
              <span aria-hidden="true" class="slider toggle-btn-slider"></span>
            </label>

            <span class="flex-grow flex flex-col ml-4" id="toggleLabel">
              <span style="line-height: 1rem" class="font-bold text-gray-900">
                Custom Conversation Starters
          
                <span class="tooltip font-normal">
                  ?
                  <span class="tooltiptext whitespace-pre-line text-lg" style="width: 650px">
                    [Business Name] = Automatically inserts the name of your business.<br>
                    [Next Open Date] = Displays the next business day when your company is open.<br>
                    [Next Open Time] = Shows the next opening time of your business, including time zone.
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div v-if="DV_customConversationStartersEnabled === true" class="w-full mt-2 pl-14">
            
            <!-- Add new custom conversation starter -->
            <label class="flex items-center mb-2">
              <input type="radio" class="mr-2" value="new" v-model="DV_customConversationStartersOperation"/>
              Add new Custom Conversation Starter
            </label>

            <!-- Edit existing conversation starter -->
            <label v-show="Object.values(DV_conversationStarters).length > 0" class="flex items-center mb-2">
              <input type="radio" class="mr-2" value="edit" v-model="DV_customConversationStartersOperation"/>
              Edit existing Conversation Starter
            </label>

            <!-- Delete existing converstaion starter -->
            <label v-show="Object.values(DV_conversationStarters).length > 0" class="flex items-center">
              <input type="radio" class="mr-2" value="delete" v-model="DV_customConversationStartersOperation"/>
              Remove Conversation Starter
            </label>

            <!-- show option for existing conversation starters and add new is not checked -->
            <div v-show="DV_customConversationStartersOperation == 'edit'" class="mt-4">
              <span class="block mt-2 text-sm">Existing conversation starter options</span>
              <select class="shadow" v-model="DV_unsavedCustomConversationStarters.id" @change="setSelectedConversationStarter">
                <option v-for="[key, value] in Object.entries(DV_conversationStarters)" :value="value.id">{{ value.subject }}</option>
              </select>
            </div>

            <div v-show="DV_customConversationStartersOperation == 'delete'" class="mt-4">
              <div v-for="[key, value] in Object.entries(DV_conversationStarters)" class="mb-2 ml-4">
                <!-- Remove Slot Button -->
                <button :disabled="DV_savingPaymentInformation == true" @click="deleteConversationStarter(value.id)" class="mr-4 btn btn-red chat-action-button">Remove</button>
                
                <span>{{ value.subject }}</span>
              </div>
            </div>

            <div
              v-if="DV_customConversationStartersOperation != 'delete'" 
              class="relative"
            >
              <input 
                type="text" class="w-1/2 mt-4 text-left shadow" 
                placeholder="Subject:" 
                v-model="C_computedSubject"
                maxlength="100"  
              >
              <div class="absolute bottom-1 right-1 text-sm text-gray-500">
                {{ C_subjectCharacterCount }}/100
              </div>
            </div>

            <div
              v-if="DV_customConversationStartersOperation != 'delete'" 
              class="relative"
            >
              <textarea 
                rows="4" 
                class="w-full mt-2 rounded-md border-gray-300" 
                placeholder="Body:" 
                v-model="C_computedBody"
                maxlength="320"
              ></textarea>
              <div class="absolute bottom-1 right-1 text-sm text-gray-500">
                {{ C_bodyCharacterCount }}/320
              </div>
            </div>
            <button 
              v-if="DV_customConversationStartersOperation != 'delete'" 
              :disabled="DV_savingConversationStarters || DV_unsavedCustomConversationStarters.subject == '' || DV_unsavedCustomConversationStarters.body == ''" 
              @click.stop="addConversationStarter" class="btn btn-blue text-sm mt-2 text-right"
            >
              {{DV_savingConversationStarters ? "Saving Conversation Starter..." : "Save Conversation Starter"}}
            </button>
          </div>

        <!-- Terms and conditions -->
        <div class="mt-4">
          <!-- Toggle switch -->
          <div class="flex items-center">
            <label 
              aria-pressed="false" 
              aria-labelledby="toggleLabel" 
              class="toggle-label toggle-btn"
              :class="[DV_showTransactionTermsAndConditions ? 'bg-blue-600' : 'bg-gray-200']"
            >
              <span class="sr-only">Use setting</span>
              
              <input 
                type="checkbox" 
                hidden="true"
                class="opacity-0 w-0 h-0"
                v-model="DV_showTransactionTermsAndConditions"
                :class="[DV_showTransactionTermsAndConditions ? 'toggle bg-blue-600' : 'bg-gray-200']"
              >
              <span aria-hidden="true" class="slider toggle-btn-slider"></span>
            </label>

            <span class="flex-grow flex flex-col ml-4" id="toggleLabel">
              <span style="line-height: 1rem" class="font-bold text-gray-900">
                Transaction Terms and Conditions
                <span class="tooltip font-normal">
                  ?
                  <span class="tooltiptext whitespace-pre-line w-1/4">Define your specific and personalized transaction terms and conditions to tailor your transactions to your unique needs and preferences.</span>
                </span>
              </span>
            </span>
          </div>

          <!-- Transaction terms and conditions -->
          <div v-if="DV_showTransactionTermsAndConditions === true" class="items-center justify-start mt-4 pl-14">
            
            <!-- Add new transaction term -->
            <label class="flex items-center mb-2">
              <input type="radio" class="mr-2" value="new" v-model="DV_transactionTermsAndConditionsOperation"/>
              Add new transactions terms and conditions
            </label>

            <!-- Edit existing transaction term -->
            <label v-show="Object.values(C_transactionTermsAndConditions).length > 0" class="flex items-center mb-2">
              <input type="radio" class="mr-2" value="edit" v-model="DV_transactionTermsAndConditionsOperation"/>
              Edit existing transactions terms and conditions
            </label>

            <!-- Delete existing transaction terms
            <label class="flex items-center mb-2">
              <input type="radio" class="mr-2" value="delete" v-model="DV_transactionTermsAndConditionsOperation"/>
              Remove transactions terms and conditions
            </label> -->

            <!-- show option for existing rules and add new is not checked -->
            <div v-show="DV_transactionTermsAndConditionsOperation != 'new'" class="mt-4">
              <span class="block mt-4 text-sm">Existing terms and conditions options</span>
              <select v-model="DV_unsavedTransactionTermsAndConditionsRule.type_number" @change="setSelectedTerm">
                <option v-for="[key, value] in Object.entries(C_transactionTermsAndConditions)" :value="value.type_number">{{value.type_description}}</option>
              </select>
            </div>

            <!-- upsert are for new rules  -->
            <div class="w-full mt-8">
              <span class="block mt-4 text-sm">Enter your terms and conditions for transactions title.</span>
              <input type="text" class="w-1/2 shadow" placeholder="Enter a title or description for your Transaction Terms and Conditions" v-model="DV_unsavedTransactionTermsAndConditionsRule.title">
              <span class="block mt-4 text-sm">Enter your terms and conditions for transactions that you would like to have your customer to agree to, to complete a transaction.</span>
              <vue-editor :editorToolbar="DV_customToolbar" rows="4" class="w-full mt-2 shadow" v-model="DV_unsavedTransactionTermsAndConditionsRule.body"></vue-editor>
            </div>
          </div>
        </div>

        <div class="w-full mt-12 flex items-center justify-between">
          <button :disabled="DV_savingPaymentInformation" @click.stop="discardPaymentChanges" class="btn btn-white text-sm mr-3">Discard Changes</button>
          <button :disabled="DV_savingPaymentInformation" @click.stop="savePaymentChanges" class="btn btn-blue text-sm mr-3">{{DV_savingPaymentInformation ? "Saving Changes..." : "Save Changes"}}</button>
        </div>
      </div>

      <!-- Web widget -->
      <div v-if="DV_activeTab == 'online-widget'" class="mt-12 text-left">
        <h3 class="text-sm font-semibold">For WordPress sites or static websites built in simple HTML-CSS.</h3>
        <h4 class="my-2 text-sm font-semibold flex items-center">
          Place this code in your website's HTML
          
          Click <svg @click.stop="copyCodeToClipboard(C_iFrameHtmlLink)" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="text-gray-500 mx-1 w-6 h-6 hover:text-gray-900 cursor-pointer">
            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
          </svg> to copy.
        </h4>
        <div style="font-family: monospace;" class="overflow-x-scroll whitespace-pre border border-gray-400 rounded-xl selectable-text p-4">{{C_iFrameHtmlLink}}</div>
      </div>

      <div v-if="DV_activeTab == 'online-widget'" class="mt-12 text-left">
        <h3 class="text-sm font-semibold">For websites built in React/Vue</h3>
        <h4 class="my-2 text-sm font-semibold flex items-center">
          Place this code in your component's template
        
          Click <svg @click.stop="copyCodeToClipboard(C_iFrameFrameworkLink)" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="text-gray-500 mx-1 w-6 h-6 hover:text-gray-900 cursor-pointer">
            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
          </svg> to copy.
        </h4>
        <div style="font-family: monospace;" class="overflow-x-scroll whitespace-pre border border-gray-400 rounded-xl selectable-text p-4">{{C_iFrameFrameworkLink}}</div>

        <h4 class="my-2 text-sm font-semibold flex items-center">
          Paste this code in mounted hook of same component.
          
          Click <svg @click.stop="copyCodeToClipboard(C_iFrameFrameworkJs)" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="text-gray-500 mx-1 w-6 h-6 hover:text-gray-900 cursor-pointer">
            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
          </svg> to copy.
        </h4>
        <div style="font-family: monospace;" class="overflow-x-scroll whitespace-pre border border-gray-400 rounded-xl selectable-text p-4">{{C_iFrameFrameworkJs}}</div>
      </div>

      <!-- Review System -->
      <div v-if="DV_activeTab == 'review'" class="mt-12 text-left">

        <!-- Online Review Setup -->
        <div class="font-bold cursor-not-allowed">Online Reviews Setup</div>

        <div class="flex w-full grow mt-2 text-center border-2 border-slate-300 rounded-xl p-2">
          <span class="w-full">
            Business Google Maps URL <font color="red">*</font>
            <span class="tooltip ml-2">
              ?
              <span class="tooltiptext whitespace-pre-line">
                <ol class="list-number">
                  <li class="!mt-0">Open Google Maps and ensure you're logged in.</li>
                  <li class="!mt-0">Search for your business in the search bar.</li>
                  <li class="!mt-0">Click on your business name to open its profile.</li>
                  <li class="!mt-0">On the business profile, Click on the Share button.</li>
                  <li class="!mt-0">On the pop-up. Click on "COPY LINK".</li>
                  <li class="!mt-0">Paste the link into the field on the right and click on "Save Changes".</li>
                  <li class="!mt-0">Click "Confirm".</li>
                  <li class="!mt-0">From the displayed location candidates, select the one matching your business.</li>
                </ol>
              </span>
            </span>
          </span>
          <div class="w-full">
            <div class="w-full flex items-center justify-between relative">
              <input autocomplete="off" v-model="DV_googleMapsUrl" @input="DV_googleMapsUrlError = ''; DV_isGoogleMapsUrlChanged = true" class="truncate !pr-24" type="text" placeholder="https://maps.app.goo.gl/XXXXXXXXXXXXXXXXX">
              <div class="cursor-pointer text-blue-400 absolute right-1">
                <span v-if="DV_activeRecyclerLocation.google_maps_url !== null && DV_activeRecyclerLocation.google_maps_url !== ''" class="flex" @click.stop="toggleLocationCandidatesVisibility">
                  Confirm
                  <svg v-if="DV_showLocationCandidates" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                  </svg>
                  <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                  </svg>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div v-if="DV_showLocationCandidates" class="flex w-full grow mt-2 text-center border-2 border-slate-300 rounded-xl px-8 py-4">
          <div v-if="DV_settingReviewAccount || DV_requestingLocationCandidates">
            {{ C_reviewAccountStatusText }}
          </div>
          <div v-else-if="DV_availableLocationCandidateOptions.length == 0">
            No Location Found.
          </div>

          <div v-else class="w-full">
            <h3 class="font-bold text-left">Click to choose the correct business:</h3>
            <div class="flex flex-col text-left max-h-32 h-32 overflow-scroll">
              <span v-for="business in DV_availableLocationCandidateOptions" class="mt-1">
                <a class="underline text-blue-500" :title="business.customLabel" @click.stop="saveLocationCandidate(business)" href="Javascript:;">{{ business.customLabel }}</a>
              </span>
            </div>
          </div>
        </div>

        <div v-if="DV_activeRecyclerLocation.google_maps_url !== null && DV_activeRecyclerLocation.google_maps_url !== ''" class="flex w-full grow mt-2 text-center border-2 border-slate-300 rounded-xl p-2">
          <span class="w-full">
            Business Facebook Page URL
            <span class="tooltip">
              ?
              <span class="tooltiptext whitespace-pre-line">
                <ol class="list-number">
                  <li class="!mt-0">Log into your managing Facebook account.</li>
                  <li class="!mt-0">Click the downward arrow (top-right) > Select your business page.</li>
                  <li class="!mt-0">On the business page, the browser's address bar displays your URL.</li>
                  <li class="!mt-0">Highlight URL > Right-click > Copy.</li>
                  <li class="!mt-0">Paste into the field to the right. </li>
                </ol>
              </span>
            </span>
          </span>
          <div class="w-full">
            <input autocomplete="off" v-model="DV_facebookUrl" @input="DV_facebookUrlError = ''; DV_isFacebookPageUrlChanged = true" type="text" placeholder="https://www.facebook.com/<YOUR_BUSINESS>/reviews">
            <div class="text-left w-full">
              <span v-if="this.DV_facebookUrlError !== ''" class="text-sm text-red-400">{{ this.DV_facebookUrlError }}</span>
            </div>
          </div>
        </div>

        <div class="flex w-full grow mt-2 text-center border-2 border-slate-300 rounded-xl p-2">
          <span class="w-full">
            Review Notification Email <font color="red">*</font>
            <span class="tooltip ml-2">
              ?
              <span class="tooltiptext whitespace-pre-line">Add the email address where you'd like to receive notifications for average or below-average reviews. This way, you can promptly address any concerns. </span>
            </span>
          </span>
          <div class="w-full">
            <input autocomplete="off" v-model="DV_reviewEmail" @input="DV_reviewEmailError = ''" type="text">
            <div class="text-left w-full">
              <span v-if="this.DV_reviewEmailError !== ''" class="text-sm text-red-400">{{ this.DV_reviewEmailError }}</span>
            </div>
          </div>
        </div>

        <!-- Autosend Review Link URL -->
        <div v-show="DV_activeRecyclerLocation.review_provider_account_uid != ''" class="flex text-left mt-8"> 
          <!-- Toggle switch -->
          <div class="flex items-center">
            <label 
              aria-pressed="false" 
              aria-labelledby="toggleLabel" 
              class="toggle-label toggle-btn"
              :class="[DV_autosend_review_link ? 'bg-blue-600' : 'bg-gray-200']"
            >
              <span class="sr-only">Use setting</span>
              
              <input 
                type="checkbox" 
                hidden="true"
                class="opacity-0 w-0 h-0"
                v-model="DV_autosend_review_link"
                :class="[DV_autosend_review_link ? 'toggle bg-blue-600' : 'bg-gray-200']"
              >
              <span aria-hidden="true" class="slider toggle-btn-slider"></span>
            </label>
          </div>
            
          <span class="font-bold text-gray-900 ml-4">Send Review Links</span>
          <span class="tooltip ml-1">
            ?
            <span class="tooltiptext">When enabled, a review link will be sent after a successful payment.</span>
          </span>
        </div>

        <div class="w-full mt-12 flex items-center justify-between">
          <button :disabled="DV_savingInformation" @click.stop="discardReviewSystemChanges" class="btn btn-white text-sm mr-3">Discard Changes</button>
          <button :disabled="DV_savingInformation" @click.stop="saveReviewSystemChanges" class="btn btn-blue text-sm mr-3">{{DV_savingInformation ? "Saving Changes..." : "Save Changes"}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AWN from "awesome-notifications";
import dataValidator from "../../includes/bi1Datavalidation"
import { CR, RC } from "../../includes/bi2SuccessCodes"
import { fs83Writer } from '../../includes/firestore_writers/fs83_writer.ts'
import { fs138Writer } from '../../includes/firestore_writers/fs138_writer.ts'
import { fs109Writer } from '../../includes/firestore_writers/fs109_writer.ts'
import { fs2Writer } from '../../includes/firestore_writers/fs2_writer.ts'
import { fs130Writer } from '../../includes/firestore_writers/fs130_writer.ts'
import { fs139Writer } from '../../includes/firestore_writers/fs139_writer.ts'
import requestLocationCandidates from '../../includes/server_calls/p1s3/p1s3e40'
import updateLocationCandidate from '../../includes/server_calls/p1s3/p1s3e46'
import publishReviewAccountCreationRequest from '../../includes/server_calls/p1s3/p1s3e30'
import createConnectAccount from '../../includes/server_calls/p1s7/p1s7e7'
import { VueEditor } from 'vue2-editor';

export default {
  name: "p4s3Integrations",

  components: {
    VueEditor
  },

  props: [],

  data() {
    return {
      DV_activeTab: "payment",
      DV_selectedPaymentMethod: "slimcd",
      DV_recyclerLocationNotFound: false,
      DV_paymentApiKeysLookupUid: null,
      DV_loadingPaymentApiKeysInformation: true,
      DV_facebookUrl: "",
      DV_facebookUrlError: "",
      DV_googleMapsUrl: "",
      DV_googleMapsUrlError: "",
      DV_reviewEmail: "",
      DV_reviewEmailError: "",
      DV_activeRecyclerLocation: {},
      DV_allRecyclerLocations: {},
      DV_savingInformation: false,
      DV_avs_verification_plans: {},
      DV_axeAVSEnabled: false,
      DV_axeAVSoption: 2,
      DV_slimcdAVSEnabled: false,
      DV_slimcdAVSoption: 2,
      DV_axePaymentAccountInfo: {
        publicApiKey: "",
        publicApiKeyError: "",
        privateApiKey: "",
        privateApiKeyError: "",
        showPrivateKey: false
      },
      DV_slimCdAccountInfo: {
        clientId: "",
        clientIdError: "",
        siteId: "",
        siteIdError: "",
        priceId: "",
        priceIdError: "",
        publicApiKey: "",
        publicApiKeyError: "",
        privateApiKey: "",
        privateApiKeyError: "",
        showPrivateKey: false
      },
      DV_showLocationCandidates: false,
      DV_savingPaymentInformation: false,
      DV_requestingLocationCandidates: false,
      DV_settingReviewAccount: false,
      DV_choosenLocationCandidate: null,
      DV_isGoogleMapsUrlChanged: false,
      DV_isFacebookPageUrlChanged: false,
      DV_availableLocationCandidateOptions: [],
      DV_hasReviewAccount: false,
      DV_locationCandidatesRef: {},
      DV_sendLiveMessagesViaSMSchanged: false,
      DV_unsavedSendLiveMessagesViaSMS: true,
      DV_stripeIdentityVerificationChanged: false,
      DV_unsavedIdentityVerificationActive: false,
      DV_unsavedMinimumCharges: 1000,
      DV_toolTipStyle: {
         width: '650px',
      },
      DV_showTransactionTermsAndConditions: false,
      DV_transactionTermsAndConditionsOperation: 'new',
      DV_unsavedTransactionTermsAndConditionsRule: {
        type_number: 0,
        title: "",
        body: ""
      },
      DV_customConversationStartersEnabled: true,
      DV_savingConversationStarters: false,
      DV_unsavedCustomConversationStarters: {
        id: "",
        subject: "",
        body: ""
      },
      DV_customConversationStartersOperation: 'new',
      DV_conversationStarters: {},
      DV_autosend_review_link: false,
      DV_customToolbar: [
        [{ font: [] }],
        [
          {"header": [false, 1,2,3,4,5,6]}
        ],
        [
          { size: [false, "small", "large", "huge"] }
        ],
        ["bold", "italic", "underline", "strike"],
        [
          { align: ""}, 
          { align: "center"}, 
          { align: "right"}, 
          { align: "justify"}
        ],
        ["blockquote", "code-block"],
        [
          { list: "ordered"}, 
          { list: "bullet"}
        ],
        [
          { indent: "-1"}, 
          { indent: "+1"}
        ],
        ["link"],
      ]
    }
  },

  mounted() {
    const CI = this;

    CI.startPaymentApiKeysListener();
    CI.startAVSverificationPlansListener();
    CI.startConversationStartersListener();
    CI.DV_allRecyclerLocations = window.G_bi2_data.IV_recyclers;
    CI.DV_locationCandidatesRef = window.G_bi2_data.IV_recyclers_location_candidates;
    CI.DV_showTransactionTermsAndConditions = Object.values(CI.C_transactionTermsAndConditions).length > 0
  },

  computed: {
    
    C_computedSubject: {
      get() {
        return this.DV_unsavedCustomConversationStarters.subject;
      },
      set(value) {
        this.DV_unsavedCustomConversationStarters.subject = value.length > 100 ? value.substring(0, 100) : value;
      }
    },

    C_computedBody: {
      get() {
        return this.DV_unsavedCustomConversationStarters.body;
      },
      set(value) {
        this.DV_unsavedCustomConversationStarters.body = value.length > 320 ? value.substring(0, 320) : value;
      }
    },
    
    C_subjectCharacterCount() {
      return this.DV_unsavedCustomConversationStarters.subject.length;
    },
    
    C_bodyCharacterCount() {
      return this.DV_unsavedCustomConversationStarters.body.length;
    },
    
    C_iFrameHtmlLink() {
      let activeBusinessUid = window.G_bi2_data.IV_active_employee_info.business_uid;
      let activeLocationUid = window.G_bi2_data.IV_active_employee_info.recycler_location_uid;

      let url = window.G_web_widget_base_url + "?recycler_business_uid=" + activeBusinessUid + "&recycler_location_uid=" + activeLocationUid + "&name=" + this.C_activeBusinessInfo["name"] + "&type=part"
      return `<!-- Synergy-Auto-Solutions widget begin -->\n<meta name="iframeURL" content="${url}" />\n<link href='${G_web_widget_base_url}widget/iframe_popup_css.css' rel='stylesheet' type='text/css'>\n<script src='${G_web_widget_base_url}widget/iframe_popup.js'><\/script>\n<!-- Synergy-Auto-Solutions widget end -->`
    },

    C_iFrameFrameworkLink() {
      let activeBusinessUid = window.G_bi2_data.IV_active_employee_info.business_uid;
      let activeLocationUid = window.G_bi2_data.IV_active_employee_info.recycler_location_uid;

      let url = window.G_web_widget_base_url + "?recycler_business_uid=" + activeBusinessUid + "&recycler_location_uid=" + activeLocationUid + "&name=" + this.C_activeBusinessInfo["name"] + "&type=part"
      return `<!-- Synergy-Auto-Solutions widget begin -->\n<meta name="iframeURL" content="${url}" />\n<link href='${G_web_widget_base_url}widget/iframe_popup_css.css' rel='stylesheet' type='text/css'>\n<!-- Synergy-Auto-Solutions widget end -->`
    },

    C_iFrameFrameworkJs() {
      return `<!-- Synergy-Auto-Solutions widget begin -->\nlet scriptTag = document.createElement("script");\nscriptTag.src = '${G_web_widget_base_url}widget/iframe_popup.js';\ndocument.body.appendChild(scriptTag);\n<!-- Synergy-Auto-Solutions widget end -->`
    },

    C_privateSlimCDInputType() {
      return this.DV_slimCdAccountInfo.showPrivateKey ? "text" : "password";
    },

    C_privateAxePaymentInputType() {
      return this.DV_axePaymentAccountInfo.showPrivateKey ? "text" : "password";
    },

    C_activeLocationLogoUrl() {
      const CI = this;

      let activeBusinessUid = window.G_bi2_data.IV_active_employee_info.business_uid;
      let activeLocationUid = window.G_bi2_data.IV_active_employee_info.recycler_location_uid;
      let logo = null

      if (`${activeBusinessUid}-${activeLocationUid}` in window.G_bi2_data.IV_recyclers_logo_urls == true) {
        logo = window.G_bi2_data.IV_recyclers_logo_urls[`${activeBusinessUid}-${activeLocationUid}`];
      }

      return logo;
    },

    C_reviewAccountStatusText() {
      const CI = this;

      if (CI.DV_settingReviewAccount) {
        return "Creating Grade.us Account...";
      }

      if (CI.DV_requestingLocationCandidates) {
        return 'Requesting location candidates...';
      }

      return "";
    },

    C_stripeVerificationMinimumCharges: {
      get() {
        const CI = this;

        return CI.DV_unsavedMinimumCharges;
      },

      set(val) {
        const CI = this;

        CI.DV_unsavedMinimumCharges = val;
      }
    },

    C_activeBusinessInfo() {
      let activeBusinessUid = window.G_bi2_data.IV_active_employee_info.business_uid;
      return window.G_bi2_data.IV_businesses['value'][activeBusinessUid] || ""
    },

    C_transactionTermsAndConditions() {
      return window.G_bi2_data.IV_transaction_terms_and_conditions.value
    }
  },

  methods: {
    startConversationStartersListener(retry_count=0) {
      const CI = this;
      
      const recyclerLocationUid = window.G_bi2_data.IV_active_employee_info.recycler_location_uid;

      if (recyclerLocationUid in window.G_bi2_data.IV_recyclers.value == false) {
        if (retry_count >= 20) { return; }

        setTimeout(function() {
          CI.startConversationStartersListener(++retry_count)
        }, 500);
        return;
      }
     
      window.G_bi2_data.startRecyclerConversationStartersListener(
        window.G_bi2_data,
        window.G_bi2_data.IV_active_employee_info.business_uid,
        window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
      );
    
      CI.DV_conversationStarters = window.G_bi2_data.IV_recycler_conversation_starters.value;
    },

    startAVSverificationPlansListener(retry_count=0) {
      const CI = this;
      
      const recyclerLocationUid = window.G_bi2_data.IV_active_employee_info.recycler_location_uid;

      if (recyclerLocationUid in window.G_bi2_data.IV_recyclers.value == false) {
        if (retry_count >= 20) { return; }

        setTimeout(function() {
          CI.startAVSverificationPlansListener(++retry_count)
        }, 500);
        return;
      }
     
      window.G_bi2_data.startAVSverificationPlansListener(
        window.G_bi2_data,
        window.G_bi2_data.IV_active_employee_info.business_uid,
        window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
      );

      CI.DV_avs_verification_plans = window.G_bi2_data.IV_avs_verification_plans.value;
    },

    sendLiveChatMessagesViaSMSChanged(event) {
      const CI = this;
      const value = event.target.checked;

      CI.DV_sendLiveMessagesViaSMSchanged = true;

      // Disabling
      if (value === false) {
        let notifier = new AWN();
        notifier.confirm(
          // Label
          "Are you sure you want to disable the 'Send Messages via SMS' feature?",
          // On OK
          function() {
            CI.DV_unsavedSendLiveMessagesViaSMS = value;
            CI.savePaymentChanges();
            // 
          },

          // On Cancel
          function() {
            CI.$nextTick(() => {
              CI.DV_unsavedSendLiveMessagesViaSMS = true;
            });
          },

          // Other options
          {
            labels: {
              confirm: 'Disable This Feature',
              confirmOk: "Yes, Confirm",
              confirmCancel: "No, Cancel"
            }
          }
        );
      }

      // Enabling
      if (value === true) {
        let notifier = new AWN();
        notifier.confirm(
          // Label
          "Are you sure you want all messages sent by sales reps from the sales dashboard to be delivered via SMS to the customer? Each SMS costs $0.02, while live chat messages incur no additional charges. However, consider that customers with poor cell phone reception might struggle to access live chat links, or you might simply prefer the consistency of sending all messages via SMS.",
          // On OK
          function() {
            CI.DV_unsavedSendLiveMessagesViaSMS = value
            CI.savePaymentChanges();
          },

          // On Cancel
          function() {
            CI.$nextTick(() => {
              CI.DV_unsavedSendLiveMessagesViaSMS = false
            });
          },

          // Other options
          {
            labels: {
              confirm: 'Enable This Feature',
              confirmOk: "Switch to SMS Only",
              confirmCancel: "Keep Live Chat"
            }
          }
        );
      }
    },

    startPaymentApiKeysListener(retry_count=0) {
      const CI = this;

      const recyclerLocationUid = window.G_bi2_data.IV_active_employee_info.recycler_location_uid;

      // Active business data
      if (recyclerLocationUid in window.G_bi2_data.IV_recyclers.value == false) { 
        if (retry_count >= 20) {
          CI.DV_recyclerLocationNotFound = true;
          CI.DV_loadingPaymentApiKeysInformation = false;
          return;
        }
        setTimeout(function() {
          CI.startPaymentApiKeysListener(++retry_count)
        }, 500);
        return; 
      }

      CI.DV_activeRecyclerLocation = window.G_bi2_data.IV_recyclers.value[recyclerLocationUid];

      // set recycler location settings
      CI.DV_selectedPaymentMethod = CI.DV_activeRecyclerLocation.assigned_payment_method || "slimcd";
      CI.DV_unsavedSendLiveMessagesViaSMS = CI.DV_activeRecyclerLocation.sms_only_messaging;
      CI.DV_customConversationStartersEnabled = CI.DV_activeRecyclerLocation.conversation_starters_enabled;
      
      // Online Reviews settings
      CI.DV_autosend_review_link = CI.DV_activeRecyclerLocation.autosend_review_link_url;

      CI.startLocationCandidatesListener();
      CI.resetSocialMediaReviewLinks();

      if (CI.DV_activeRecyclerLocation.payment_api_keys_lookup_uid) {
        CI.DV_paymentApiKeysLookupUid = CI.DV_activeRecyclerLocation.payment_api_keys_lookup_uid;
        
        window.G_bi2_data.startRecyclerLocationsPaymentApiListener(
          window.G_bi2_data,
          CI.DV_paymentApiKeysLookupUid,
        )

        this.populateCurrentPaymentSettings();
      } else {
        CI.DV_loadingPaymentApiKeysInformation = false;
      }
    },

    startLocationCandidatesListener() {
      const CI = this;

      if (!CI.DV_hasReviewAccount) { return; }

      window.G_bi2_data.startLocationCandidatesListenerForRecyclerLocation(
        window.G_bi2_data,
        window.G_bi2_data.IV_active_employee_info.recycler_location_uid
      )
    },

    populateCurrentPaymentSettings(retry_count=0) {
      const CI = this;

      // Active business data
      if (CI.DV_paymentApiKeysLookupUid && CI.DV_paymentApiKeysLookupUid in window.G_bi2_data.IV_recyclers_payment_api_keys.value === false) { 
        if (retry_count >= 20) { 
          CI.DV_loadingPaymentApiKeysInformation = false;
          return; 
        }
        setTimeout(function() {
          CI.populateCurrentPaymentSettings(++retry_count)
        }, 1000);
        return; 
      }

      const paymentApiKeysLookupData = window.G_bi2_data.IV_recyclers_payment_api_keys.value[CI.DV_paymentApiKeysLookupUid];

      CI.DV_slimCdAccountInfo.clientId = paymentApiKeysLookupData.client_id || "";
      CI.DV_slimCdAccountInfo.clientIdError = "";
      CI.DV_slimCdAccountInfo.siteId = paymentApiKeysLookupData.site_id || "";
      CI.DV_slimCdAccountInfo.siteIdError = "";
      CI.DV_slimCdAccountInfo.priceId = paymentApiKeysLookupData.price_id || "";
      CI.DV_slimCdAccountInfo.priceIdError = "";
      CI.DV_slimCdAccountInfo.publicApiKey = paymentApiKeysLookupData.public_api_key || "";
      CI.DV_slimCdAccountInfo.publicApiKeyError = "";
      CI.DV_slimCdAccountInfo.privateApiKey = paymentApiKeysLookupData.private_api_key || "";
      CI.DV_slimCdAccountInfo.privateApiKeyError = "";
      CI.DV_slimCdAccountInfo.showPrivateKey = false;
      
      CI.DV_selectedPaymentMethod = (paymentApiKeysLookupData.payment_processor_id === 1) ? "slimcd" : "axe"

      if (CI.DV_selectedPaymentMethod === "axe") {
        CI.DV_axePaymentAccountInfo.publicApiKey = paymentApiKeysLookupData.axe_public_api_key_reference || "";
        CI.DV_axePaymentAccountInfo.publicApiKeyError = "";
        CI.DV_axePaymentAccountInfo.privateApiKey = paymentApiKeysLookupData.axe_private_api_key_reference || "";
        CI.DV_axePaymentAccountInfo.privateApiKeyError = "";
      }

      const recyclerLocationUid = window.G_bi2_data.IV_active_employee_info.recycler_location_uid;
      if (recyclerLocationUid in window.G_bi2_data.IV_stripe_identity_verification.value) {
        const stripe_identity_info = window.G_bi2_data.IV_stripe_identity_verification.value[recyclerLocationUid];

        CI.DV_unsavedIdentityVerificationActive = stripe_identity_info.stripe_identity_verification_enabled;
        CI.DV_unsavedMinimumCharges = stripe_identity_info.minimum_transaction_amount;
      }

      CI.DV_loadingPaymentApiKeysInformation = false;  
    },

    toggleShowSlimCdPrivateKey() {
      this.DV_slimCdAccountInfo.showPrivateKey = !this.DV_slimCdAccountInfo.showPrivateKey;
    },

    toggleShowAxePaymentPrivateKey() {
      this.DV_axePaymentAccountInfo.showPrivateKey = !this.DV_axePaymentAccountInfo.showPrivateKey;
    },

    copyCodeToClipboard(code) {
       // Copy the text inside the text field
      navigator.clipboard.writeText(code);

      // Alert the copied text
      this.$awn.success("Copied code to your clipboard.");
    },

    discardPaymentChanges() {
      const CI = this;

      let notifier = new AWN();
      
      notifier.confirm(
        
        // Label
        "Are you sure you want to discard the changes?",
        
        // On OK
        CI.populateCurrentPaymentSettings,

        // On cancel
        function() {},

        // Other options
        {
          labels: {
            confirm: 'Discard Changes',
            confirmOk: "Confirm",
            confirmCancel: "Cancel"
          }
        }
      );
    },

    resetSocialMediaReviewLinks() {
      const CI = this;

      if ("facebook_url" in CI.DV_activeRecyclerLocation) {
        CI.DV_facebookUrl = CI.DV_activeRecyclerLocation.facebook_url;
        CI.DV_facebookUrlError = ""
      }

      if ("google_maps_url" in CI.DV_activeRecyclerLocation) {
        CI.DV_googleMapsUrl = CI.DV_activeRecyclerLocation.google_maps_url;
        CI.DV_googleMapsUrlError = ""
      }

      if ("email" in CI.DV_activeRecyclerLocation) {
        CI.DV_reviewEmail = CI.DV_activeRecyclerLocation.email;
        CI.DV_reviewEmailError = ""
      }
    },

    async deleteConversationStarter(starterId) {
      const CI = this;
      CI.DV_savingPaymentInformation = true;

      let result = await fs139Writer(
        'd',
        window.G_bi2_data.IV_active_employee_info.business_uid,
        window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
        starterId,
      )
      
      if (result.success === true) {
        // 
      } else {
        CI.$awn.alert("There was an issue saving Conversation Starters. Please reload page and try again");
        CI.DV_savingPaymentInformation = false;
        return;
      }
      
      // delete the entry from the list
      delete window.G_bi2_data.IV_recycler_conversation_starters.value[starterId];
      
      CI.DV_savingPaymentInformation = false;
    },

    async addConversationStarter() {
      const CI = this;
      CI.DV_savingConversationStarters = true;
      
      let number = Object.keys(this.DV_conversationStarters).length

      let result = await fs139Writer(
        'n',
        window.G_bi2_data.IV_active_employee_info.business_uid,
        window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
        number + 1,
        {
          subject: this.DV_unsavedCustomConversationStarters.subject,
          body: this.DV_unsavedCustomConversationStarters.body,
        }
      )
      
      if (result.success === true) {
        this.DV_unsavedCustomConversationStarters.subject = "";
        this.DV_unsavedCustomConversationStarters.body = "";
      } else {
        CI.$awn.alert("There was an issue saving Conversation Starters. Please reload page and try again");
        CI.DV_savingConversationStarters = false;
        return;
      }
     
      CI.$awn.success("Successfully saved Conversation Starter");
      CI.DV_savingConversationStarters = false;
    },

    async savePaymentChanges() {
      const CI = this;
      CI.DV_savingPaymentInformation = true;

      let fail_flag = false;

      // Slim CD validation
      if (CI.DV_selectedPaymentMethod === "slimcd") {
        let call_result = dataValidator.is_string(CI.DV_slimCdAccountInfo.clientId, 3, 200);
        if (call_result[CR.success] !== RC.success) {
          CI.DV_slimCdAccountInfo.clientIdError = "Client ID cannot be empty or less than 3 letters.";
          fail_flag = true;
        }

        if (CI.DV_slimCdAccountInfo.siteId) {
          let call_result = dataValidator.is_string(CI.DV_slimCdAccountInfo.siteId, 3, 200);
          if (call_result[CR.success] !== RC.success) {
            CI.DV_slimCdAccountInfo.siteIdError = "Site ID cannot be empty or less than 3 letters.";
            fail_flag = true;
          }
        }

        if (CI.DV_slimCdAccountInfo.priceId) {
          let call_result = dataValidator.is_string(CI.DV_slimCdAccountInfo.priceId, 3, 200);
          if (call_result[CR.success] !== RC.success) {
            CI.DV_slimCdAccountInfo.priceIdError = "Price ID cannot be empty or less than 3 letters.";
            fail_flag = true;
          }
        }

        if (CI.DV_slimCdAccountInfo.publicApiKey) {
          let call_result = dataValidator.is_string(CI.DV_slimCdAccountInfo.publicApiKey, 3, 200);
          if (call_result[CR.success] !== RC.success) {
            CI.DV_slimCdAccountInfo.publicApiKeyError = "Public API Key cannot be empty or less than 3 letters.";
            fail_flag = true;
          }
        }

        if (CI.DV_slimCdAccountInfo.privateApiKey) {
          let call_result = dataValidator.is_string(CI.DV_slimCdAccountInfo.privateApiKey, 3, 200);
          if (call_result[CR.success] !== RC.success) {
            CI.DV_slimCdAccountInfo.privateApiKeyError = "Private API Key cannot be empty or less than 3 letters.";
            fail_flag = true;
          }
        }
      }

      // Axe Payment validation
      if (CI.DV_selectedPaymentMethod === "axe") {
        
        if (CI.DV_axePaymentAccountInfo.publicApiKey) {
          let call_result = dataValidator.is_string(CI.DV_axePaymentAccountInfo.publicApiKey, 3, 200);
          if (call_result[CR.success] !== RC.success) {
            CI.DV_axePaymentAccountInfo.publicApiKeyError = "Public API Key cannot be empty or less than 3 letters.";
            fail_flag = true;
          }
        }

        if (CI.DV_axePaymentAccountInfo.privateApiKey) {
          let call_result = dataValidator.is_string(CI.DV_axePaymentAccountInfo.privateApiKey, 3, 200);
          if (call_result[CR.success] !== RC.success) {
            CI.DV_axePaymentAccountInfo.privateApiKeyError = "Private API Key cannot be empty or less than 3 letters.";
            fail_flag = true;
          }
        }

        if (CI.DV_slimcdAVSEnabled === true) {
          let fs138_result = await fs138Writer(
            "n",
            window.G_bi2_data.IV_active_employee_info.business_uid,
            window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
            "1",
            {
              avs_verification_type: Number(CI.DV_slimcdAVSoption),
            }
          )

          if (fs138_result.success === true) {
            // 
          } else {
            CI.$awn.alert("There was an issue saving slimcd AVS verification. Please reload page and try again");
            CI.DV_savingPaymentInformation = false;
            return;
          }
        }

        if (CI.DV_axeAVSEnabled === true) {
          let fs138_result = await fs138Writer(
            "n",
            window.G_bi2_data.IV_active_employee_info.business_uid,
            window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
            "2",
            {
              avs_verification_type: Number(CI.DV_axeAVSoption),
            }
          )

          if (fs138_result.success === true) {
            // 
          } else {
            CI.$awn.alert("There was an issue saving axe AVS verification. Please reload page and try again");
            CI.DV_savingPaymentInformation = false;
            return;
          }
        }

        if (CI.DV_axeAVSEnabled === false && "2" in CI.DV_avs_verification_plans) {
          CI.DV_axeAVSoption = 1;

          let fs138_result = await fs138Writer(
            "d",
            window.G_bi2_data.IV_active_employee_info.business_uid,
            window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
            "2",
          )

          if (fs138_result.success === true) {
            // 
          } else {
            CI.$awn.alert("There was an issue disabling axe AVS verification. Please reload page and try again");
            CI.DV_savingPaymentInformation = false;
            return;
          }
        }

        if (CI.DV_slimcdAVSEnabled === false && "1" in CI.DV_avs_verification_plans) {
          CI.DV_slimcdAVSoption = 1;

          let fs138_result = await fs138Writer(
            "d",
            window.G_bi2_data.IV_active_employee_info.business_uid,
            window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
            "1",
          )

          if (fs138_result.success === true) {
            // 
          } else {
            CI.$awn.alert("There was an issue disabling slimcd AVS verification. Please reload page and try again");
            CI.DV_savingPaymentInformation = false;
            return;
          }
        }
      }

      if (fail_flag === true) {
        this.$awn.alert("Please provide complete payment information in all required fields.");
        CI.DV_savingPaymentInformation = false;
        return;
      }

      if (CI.DV_unsavedIdentityVerificationActive === true) {
        if (CI.DV_unsavedMinimumCharges === 0) {
          CI.$awn.alert("Please set minumum transaction amount for stripe identity verification.");
          CI.DV_savingPaymentInformation = false;
          return;
        }
      }

      if (this.DV_transactionTermsAndConditionsOperation == 'edit' &&
        (this.DV_unsavedTransactionTermsAndConditionsRule.title == "" ||
          this.DV_unsavedTransactionTermsAndConditionsRule.body == "")) {
        CI.$awn.alert("Please set the title and description of the transaction terms and conditions.");
        CI.DV_savingPaymentInformation = false;
        return;
      }

      let params = {};

      if (CI.DV_selectedPaymentMethod === "slimcd") {
        params = {
          business_uid: window.G_bi2_data.IV_active_employee_info.business_uid,
          recycler_location_uid: window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
          client_id: CI.DV_slimCdAccountInfo.clientId,
          site_id: CI.DV_slimCdAccountInfo.siteId,
          price_id: CI.DV_slimCdAccountInfo.priceId,
          public_api_key: CI.DV_slimCdAccountInfo.publicApiKey,
          private_api_key: CI.DV_slimCdAccountInfo.privateApiKey,
          payment_processor_id: 1 // SlimCD
        }
      } else if (CI.DV_selectedPaymentMethod === "axe") {
        params = {
          business_uid: window.G_bi2_data.IV_active_employee_info.business_uid,
          recycler_location_uid: window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
          axe_public_api_key_reference: CI.DV_axePaymentAccountInfo.publicApiKey,
          axe_private_api_key_reference: CI.DV_axePaymentAccountInfo.privateApiKey,
          payment_processor_id: 2 // Axe
        }
      }

      // Update if id present
      let result = null;

      if (CI.DV_paymentApiKeysLookupUid) {
        result = await fs83Writer("e", CI.DV_paymentApiKeysLookupUid, params)
      } else {
        result = await fs83Writer("n", null, params)
      }

      if (result.success === true) {
        // 
      } else {
        CI.$awn.alert("There was an issue saving the API key data. Please reload page and try again");
        CI.DV_savingPaymentInformation = false;
        return;
      }

      // create connect account if connect id does not exist
      /*
      let createAccountParams = {
        recycler_uid: window.G_bi2_data.IV_active_employee_info.business_uid,
        recycler_location_uid: window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
      }
      
      if (CI.DV_unsavedIdentityVerificationActive === true && !CI.DV_activeRecyclerLocation.connect_account_id) {
        let createAccountResponse = await createConnectAccount(createAccountParams)

        if (createAccountResponse.success === false) {
          CI.$awn.alert("There was an issue creating a Stripe Connect Account");
          CI.DV_savingPaymentInformation = false;
          return;
        }
      }
      */      
      result = await fs109Writer(
        "e", 
        window.G_bi2_data.IV_active_employee_info.business_uid,
        window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
        {
          stripe_identity_verification_enabled: CI.DV_unsavedIdentityVerificationActive,
          minimum_transaction_amount: CI.DV_unsavedMinimumCharges
        }
      )

      if (result.success === true) {
        // 
      } else {
        CI.$awn.alert("There was an issue saving the stripe identity verification amount. Please reload page and try again");
        CI.DV_savingPaymentInformation = false;
        return;
      }

      // upert transaction terms and conditions
      result = null;
      let operation = this.DV_transactionTermsAndConditionsOperation == 'new' ? 'n' : 'e';
      let number = Object.keys(this.C_transactionTermsAndConditions).length

      if (operation == 'n') {
        number = Object.keys(this.C_transactionTermsAndConditions).length + 1
      } else {
        number = this.DV_unsavedTransactionTermsAndConditionsRule.type_number;
      }

      if (this.DV_unsavedTransactionTermsAndConditionsRule.title !== "" && this.DV_unsavedTransactionTermsAndConditionsRule.body !== "") {
        result = await fs130Writer(
          operation,
          window.G_bi2_data.IV_active_employee_info.business_uid,
          window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
          number,
          {
            type_number: number,
            type_description: this.DV_unsavedTransactionTermsAndConditionsRule.title,
            transaction_terms_and_conditions: this.DV_unsavedTransactionTermsAndConditionsRule.body
          }
        )

        if (result.success === true) {
          // 
        } else {
          CI.$awn.alert("There was an issue saving transactions terms and conditions. Please reload page and try again");
          CI.DV_savingPaymentInformation = false;
          return;
        }
      }

      // save send messages via sms 
      try {
        let result = await fs2Writer(
          "e", 
          window.G_bi2_data.IV_active_employee_info.business_uid,
          window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
          {
            sms_only_messaging: CI.DV_unsavedSendLiveMessagesViaSMS,
          }
        )
        
        if (result.success === false) { 
          CI.$awn.alert("There was an issue saving Send Messages via SMS option. Please reload page and try again")
          CI.DV_savingPaymentInformation = false;
        }
      } catch (e) { 
        CI.$awn.alert("There was an issue saving Send Messages via SMS option. Please reload page and try again")
        CI.DV_savingPaymentInformation = false;
      }

      // save conversation starter setting 
      try {
        let result = await fs2Writer(
          "e", 
          window.G_bi2_data.IV_active_employee_info.business_uid,
          window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
          {
            conversation_starters_enabled: CI.DV_customConversationStartersEnabled,
          }
        )
        
        if (result.success === false) { 
          CI.$awn.alert("There was an issue saving Conversation Starters option. Please reload page and try again")
          CI.DV_savingPaymentInformation = false;
        }
      } catch (e) { 
        CI.$awn.alert("There was an issue saving Conversation Starters option. Please reload page and try again")
        CI.DV_savingPaymentInformation = false;
      }

      CI.$awn.success("Payment settings data saved successfully.");
      CI.DV_transactionTermsAndConditionsOperation = 'new';
      CI.DV_savingPaymentInformation = false;
    },

    discardReviewSystemChanges() {
      const CI = this;

      CI.resetSocialMediaReviewLinks()

      // TODO - discard Grade.us when working on that.
    },

    async saveReviewSystemChanges() {
      const CI = this;
      let fail_flag = false;
      
      CI.DV_savingInformation = true;

      if (!CI.DV_googleMapsUrl || CI.DV_googleMapsUrl.trim() === "") {
        CI.DV_googleMapsUrlError = "Google map's URL is required.";
        fail_flag = true;
      }

      if (CI.DV_googleMapsUrl && CI.DV_googleMapsUrl.trim() !== "") {
        let call_result = dataValidator.is_valid_url(CI.DV_googleMapsUrl);
        if (call_result[CR.success] !== RC.success) {
          CI.DV_googleMapsUrlError = "Google map's URL is not a valid URL.";
          fail_flag = true;
        }
      }

      if (CI.DV_facebookUrl && CI.DV_facebookUrl.trim() !== "") {
        let call_result = dataValidator.is_valid_url(CI.DV_facebookUrl);
        if (call_result[CR.success] !== RC.success) {
          CI.DV_facebookUrlError = "Facebook URL is not a valid URL.";
          fail_flag = true;
        }
      }

      if (!CI.DV_reviewEmail || CI.DV_reviewEmail.trim() === "") {
        CI.DV_reviewEmailError = "Review Notification Email is required.";
        fail_flag = true;
      }
      
      if (CI.DV_reviewEmail && CI.DV_reviewEmail.trim() !== "") {        
        let call_result = dataValidator.is_email_address(CI.DV_reviewEmail.trim());
        if (call_result[CR.success] !== RC.success) {
          CI.DV_reviewEmailError = "Review email is not a valid email.";
          fail_flag = true;
        }
      }

      if (fail_flag) {
        CI.$awn.alert("Please provide valid data for incorrect fields.");
        CI.DV_savingInformation = false;
        return
      }

      // Verify if facebook review link can be saved to grade.us
      if (CI.DV_isFacebookPageUrlChanged) {
        
        // If grade.us account not created
        if (!CI.DV_hasReviewAccount) {
          if (!CI.C_activeLocationLogoUrl) {
            CI.$awn.alert("Business logo is required to create the grade.us account. First set the business logo, on the Business Information tab from left side, and please try again.");
            CI.DV_savingInformation = false;
            return;
          }

          let resp = await publishReviewAccountCreationRequest(
            window.G_bi2_data.IV_active_employee_info.business_uid,
            window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
            CI.DV_activeRecyclerLocation.email
          )

          if (resp.success === true) {
            // 
          } else {
            CI.$awn.alert("Unable to create grade.us account to save the facebook review URL, please try again reloading page and if issue persists please contact support.");
            CI.DV_savingInformation = false;
            return;
          }
        }
      }

      let activeBusinessUid = window.G_bi2_data.IV_active_employee_info.business_uid;
      let activeLocationUid = window.G_bi2_data.IV_active_employee_info.recycler_location_uid;
      let failed_save_location = false;

      try {
        let result = await fs2Writer(
          "e", 
          activeBusinessUid, 
          activeLocationUid, 
          {
            facebook_url: CI.DV_facebookUrl,
            google_maps_url: CI.DV_googleMapsUrl,
            email: CI.DV_reviewEmail,
            autosend_review_link_url: CI.DV_autosend_review_link,
          }
        )
        
        if (result.success === false) { failed_save_location = true; }
      } catch (e) { failed_save_location = true; }

      if (failed_save_location === true) {
        CI.$awn.alert("Unable to update 'Social Media Review Links'. Please reload page and try again.");
        CI.DV_savingInformation = false;
        return;
      } else {
        CI.$awn.success("Successfully updated 'Social Media Review Links'.");
      }

      // Save facebook URL to grade.us 
      if (CI.DV_isFacebookPageUrlChanged) {
        CI.saveFacebookReviewUrlToGradeUs(0);
      } else {
        CI.DV_savingInformation = false;
      }
    },

    initSettingUpGradeUsAccount() {
      const CI = this;

      if (!CI.C_activeLocationLogoUrl) {
        CI.$awn.alert("Business logo is required to create the grade.us account. First set the business logo, on the Business Information tab from left side, and please try again.");
        return;
      }

      CI.DV_settingReviewAccount = true;

      publishReviewAccountCreationRequest(
        window.G_bi2_data.IV_active_employee_info.business_uid,
        window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
        CI.DV_activeRecyclerLocation.email
      ).then((resp) => {
        CI.DV_settingReviewAccount = false;  
        if (resp.success === true) {
          CI.requestGoogleLocationCandidates(0);
        } else {
          CI.$awn.alert("Unable to create grade.us account for the location, please try again reloading page and if issue persists please contact support.");
        }
        
      }).catch((error) => {
        CI.$awn.alert("Unable to create grade.us account for the location, please try again reloading page and if issue persists please contact support.");
        CI.DV_settingReviewAccount = false;
      });
    },

    saveFacebookReviewUrlToGradeUs(retry_count=0) {
      const CI = this;

      if (!CI.DV_hasReviewAccount) {
        if (retry_count < 50) {
          setTimeout(() => {
            CI.saveFacebookReviewUrlToGradeUs(++retry_count);
          }, 500)
          return;
        }
        CI.$awn.alert("Unable to find the review account info to save the facebook URL, please try again reloading page and if issue persists please contact support.");
        CI.DV_savingInformation = false;
        return;
      }

      updateLocationCandidate(
        window.G_bi2_data.IV_active_employee_info.business_uid,
        window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
        2,
      ).then((resp) => {
        CI.DV_savingInformation = false; 
      }).catch((error) => {
        CI.$awn.alert("Unable to save the facebook review URL to grade.us, please try again reloading page and if issue persists please contact support.");
        CI.DV_savingInformation = false;
      });
    },

    requestGoogleLocationCandidates(retry_count=0) {
      const CI = this;

      CI.DV_requestingLocationCandidates = true;

      if (!CI.DV_hasReviewAccount) {
        if (retry_count < 50) {
          setTimeout(() => {
            CI.requestGoogleLocationCandidates(++retry_count);
          }, 500)
          return;
        }
        CI.$awn.alert("Unable to find the review account info to fetch location candidates, please try again reloading page and if issue persists please contact support.");
        CI.DV_requestingLocationCandidates = false;
        return;
      }

      requestLocationCandidates(
        window.G_bi2_data.IV_active_employee_info.business_uid,
        window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
      ).then((resp) => {
        CI.DV_requestingLocationCandidates = false;
        CI.startLocationCandidatesListener();
      }).catch((error) => {
        CI.$awn.alert("Unable to get the location options from google map, please make sure you put try again reloading page and if issue persists please contact support.");
        CI.DV_requestingLocationCandidates = false;
      });
    },

    toggleLocationCandidatesVisibility() {
      const CI = this;

      CI.DV_showLocationCandidates = !CI.DV_showLocationCandidates;

      if (!CI.DV_showLocationCandidates) { return; }

      if (!CI.DV_hasReviewAccount) {
        CI.initSettingUpGradeUsAccount();
      }

      if (CI.DV_isGoogleMapsUrlChanged) {
        CI.requestGoogleLocationCandidates();
      }
    },

    saveLocationCandidate(candidate) {
      const CI = this;

      let notifier = new AWN();

      notifier.confirm(
        // Label
        candidate.customLabel,
        
        // On OK
        function() {
          updateLocationCandidate(
            window.G_bi2_data.IV_active_employee_info.business_uid,
            window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
            1,
            candidate.candidateCategory,
            candidate.id
          ).then((resp) => {
            CI.$awn.success("Location updated successfully.");
          }).catch((error) => {
            CI.$awn.alert("Unable to save the location options to grade.us, please try again reloading page and if issue persists please contact support.");
          });
        },

        // On Cancel
        function() {},

        // Other options
        {
          labels: {
            confirm: 'Do you wish to save this location?',
            confirmCancel: "No, Cancel",
            confirmOk: "Yes, Save"
          }
        }
      );
    },

    stripeIdentityVerificationChanged(event) {
      const CI = this;
      const value = event.target.checked;

      CI.DV_stripeIdentityVerificationChanged = true;

      // Disabling
      if (value === false) {
        let notifier = new AWN();

        notifier.confirm(
          // Label
          "Are you sure, you want to disable the 'Stripe Identity Verification'?",
          // On OK
          function() {
            CI.DV_unsavedIdentityVerificationActive = value;
            // 
          },

          // On Cancel
          function() {
            CI.$nextTick(() => {
              CI.DV_unsavedIdentityVerificationActive = true;
            });
          },

          // Other options
          {
            labels: {
              confirm: 'Disable This Feature',
              confirmOk: "Yes, Confirm",
              confirmCancel: "No, Cancel"
            }
          }
        );
      }

      // Enabling
      if (value === true) {
        CI.DV_unsavedIdentityVerificationActive = value;
      }
    },

    setSelectedTerm() {
      let id = `${window.G_bi2_data.IV_active_employee_info.business_uid}-${window.G_bi2_data.IV_active_employee_info.recycler_location_uid}-${this.DV_unsavedTransactionTermsAndConditionsRule.type_number}`
      let termsList = window.G_bi2_data.IV_transaction_terms_and_conditions.value

      if (termsList[id]) {
        this.DV_unsavedTransactionTermsAndConditionsRule.title = termsList[id].type_description
        this.DV_unsavedTransactionTermsAndConditionsRule.body = termsList[id].transaction_terms_and_conditions
      }
    },

    setSelectedConversationStarter() {
      let selectedId = this.DV_unsavedCustomConversationStarters.id
      if (window.G_bi2_data.IV_recycler_conversation_starters.value[selectedId]) {
        this.DV_unsavedCustomConversationStarters.subject = window.G_bi2_data.IV_recycler_conversation_starters.value[selectedId].subject;
        this.DV_unsavedCustomConversationStarters.body = window.G_bi2_data.IV_recycler_conversation_starters.value[selectedId].body;
      }
    }
  },

  watch: {
    DV_locationCandidatesRef: {
      handler() {
        const CI = this;
        const recyclerLocationUid = window.G_bi2_data.IV_active_employee_info.recycler_location_uid;
        let all_locations = [];

        if (recyclerLocationUid in CI.DV_locationCandidatesRef === true) {
          const candidates = CI.DV_locationCandidatesRef[recyclerLocationUid].candidates || [];
          
          if (candidates && candidates.length > 0) {
            for (let candidate of candidates) {
              if (!candidate.locations || candidate.locations.length == 0) { continue; }

              for (let location of candidate.locations) {
                location.customLabel = `${location.title}, ${location.address1}, ${location.locality}, ${location.region}`;
                location.candidateCategory = candidate.category;
                all_locations.push(location);
              }
            }
          }
        }

        CI.DV_availableLocationCandidateOptions = all_locations;
      },

      deep: true
    },

    DV_allRecyclerLocations: {
      handler() {
        const CI = this;
        const recyclerLocationUid = window.G_bi2_data.IV_active_employee_info.recycler_location_uid;
        CI.DV_activeRecyclerLocation = window.G_bi2_data.IV_recyclers.value[recyclerLocationUid];

        if (("review_provider_account_uid" in CI.DV_activeRecyclerLocation == true) && CI.DV_activeRecyclerLocation.review_provider_account_uid) {
          CI.DV_hasReviewAccount = true;
        } else {
          CI.DV_hasReviewAccount = false;
        }
      },

      deep: true
    },

    DV_transactionTermsAndConditionsOperation() {
      if (this.DV_transactionTermsAndConditionsOperation == 'edit') {
        let firstItem = Object.values(window.G_bi2_data.IV_transaction_terms_and_conditions.value)[0]
        this.DV_unsavedTransactionTermsAndConditionsRule = {
          type_number: firstItem.type_number,
          title: firstItem.type_description,
          body: firstItem.transaction_terms_and_conditions
        }
      } else {
        this.DV_unsavedTransactionTermsAndConditionsRule = {
          type_number: 0,
          title: "",
          body: ""
        } 
      }
    },

    DV_avs_verification_plans: {
      handler() {
        const CI = this;

        CI.DV_avs_verification_plans = window.G_bi2_data.IV_avs_verification_plans.value;

        if ("1" in CI.DV_avs_verification_plans) {
          if (CI.DV_slimcdAVSEnabled === false && CI.DV_savingPaymentInformation === true) { return; }
          CI.DV_slimcdAVSEnabled = true;
          
          let entry = CI.DV_avs_verification_plans["1"];
          CI.DV_slimcdAVSoption = entry.avs_verification_type;
        }
    
        if ("2" in CI.DV_avs_verification_plans) {
          if (CI.DV_axeAVSEnabled === false && CI.DV_savingPaymentInformation === true) { return; }
          CI.DV_axeAVSEnabled = true;
        
          let entry = CI.DV_avs_verification_plans["2"];
          CI.DV_axeAVSoption = entry.avs_verification_type;
        }
      },
      deep: true
    },

    DV_conversationStarters: {
      handler() {
        const CI = this;

        CI.DV_conversationStarters = window.G_bi2_data.IV_recycler_conversation_starters.value;
      },
      deep: true
    },

    DV_customConversationStartersOperation() {
      const CI = this;

      if (CI.DV_customConversationStartersOperation != 'new') {
        let firstItem = Object.values(window.G_bi2_data.IV_recycler_conversation_starters.value)[0]
        CI.DV_unsavedCustomConversationStarters = {
          id: firstItem.id,
          subject: firstItem.subject,
          body: firstItem.body,
        }
      } else {
        CI.DV_unsavedCustomConversationStarters = {
          id: "",
          subject: "",
          body: "",
        }
      }
    }
  }
}
</script>
