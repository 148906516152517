<template>
  <div class="text-left">
    <h2 class="text-2xl font-bold text-center">Synergy Auto Solutions: New Conversation User Guide</h2>

    <p class="mt-8">The New Conversation interface seamlessly integrates features from both the Sales Dashboard and Contacts pages, while also providing the enhanced capability to initiate conversations with new customers using just their phone number. The layout is organized into three distinct sections: New Customer, Existing Customers, and Queue.</p>

    <p class="mt-2">
      <span class="font-bold block">Section 1: How to Initiate New Customer Conversations</span>
      In the New Customer section, you can initiate a conversation by inputting the customer’s 10-digit phone number, which is mandatory, along with the optional fields for their first and last names. Click the “Start New Chat” button to begin. The system will initiate a new chat provided the customer doesn’t have three open conversations already. Upon starting the chat, you’ll be redirected to the Sales Dashboard to engage with your customer. Should the phone number be new to the system, a fresh Customer Contact will automatically be generated.
    </p>

    <p class="mt-2">
      <span class="font-bold block">Section 2: Engaging with Existing Customers</span>
      In the Existing Customers section, a searchable table displays all registered Customer Contacts. This table shows stored data for each contact, including their name, texting phone number, business affiliations, customer type, rating, and location. If a business affiliation exists, clicking the associated business name will lead you to that business’s profile.
      <br><br>
      In the Customer Engagement & Insights column, you’ll find key metrics like the number of active queue requests, open conversations, and the team member currently engaged with the customer. Note that each customer can have a maximum of three open conversations.
      <br><br>
      By clicking the dropdown arrow in the Customer Engagement & Insights column, additional action buttons appear. A <i>“Start New Chat”</i> button will be available if the customer has fewer than three open conversations. Clicking this will initiate a new chat and redirect you to the Sales Dashboard. The <i>“Profile”</i> button allows for viewing and editing the Customer Profile, while <i>“Customer Notes”</i> provides access to historical annotations about the customer. The <i>“Chat History”</i> button reveals past interactions, which you can individually click to review. 
    </p>

    <p class="mt-2">
      <span class="font-bold block">Section 3: Optimizing Customer Engagement through the Queue</span>
      The Queue serves as a real-time list of customer inquiries generated through the web widget on your website. The web widget functions as a lead capture tool, gathering essential details such as the customer’s name, phone number, vehicle specifics, and the auto parts they’re interested in. Upon completing the form, customers are placed in the Queue, awaiting engagement from your team.
    </p>

    <p class="mt-2">
      <span class="font-bold block">Queue Access and Sorting</span>
      Every team member within your organization has visibility into the Queue. However, only one team member can initially claim a request to engage with a customer. Team members have the flexibility to sort Queue requests based on the customer’s wait time or their average rating from prior interactions.
    </p>

    <p class="mt-2">
      <span class="font-bold block">Customer Information</span>
      For each queued request, team members can see the customer's phone number, name, vehicle and part details, and the timestamp indicating when the request was made. An expandable drop-down arrow icon next to each request reveals additional information.
    </p>

    <p class="mt-2">
      <span class="font-bold block">Queue Options</span>
      Expanding a Queue entry presents four action buttons:
      <ol class="list-disc list-inside list-bullets">
        <li class="!mt-0 list-item">
          <span class="font-bold">Chat Now:</span>Initiates a real-time conversation with the customer. Clicking this moves the request to the team member’s My Conversations panel on the right side, making it inaccessible to others in the Queue.
        </li>
        <li class="!mt-0 list-item">
          <span class="font-bold">Profile:</span>
          Displays comprehensive customer information. The only mandatory field is the <i>“Texting Phone Number”</i>; other fields are optional. Always remember to hit <i>“Save Changes”</i> if any updates are made. 
        </li>
        <li class="!mt-0 list-item">
          <span class="font-bold">Customer Notes:</span>
          Displays historical notes about the customer from prior interactions.
        </li>
        <li class="!mt-0 list-item">
          <span class="font-bold">Chat History:</span>
          Opens Conversation History, detailing past dialogues with the customer. This includes conversation start and end times, total messages, customer ratings, notes, identity verification status, and payment link status, if applicable.
        </li>
      </ol>
    </p>

    <p class="mt-2">
      <span class="font-bold block">Chat Initiation</span>
      Once a team member clicks the “Chat Now” button, the request shifts to their My Conversations panel on the right. The conversation automatically opens to display the initial message inviting the customer to chat, along with a URL link for the chat session. A follow-up message indicates that the team member has joined the chat. If the customer has closed their chat window by this point, an additional message is sent to notify them that a team member is ready to assist.
    </p>

    <p class="mt-2">
      By streamlining these functionalities, the Queue ensures that you can efficiently manage and prioritize your customer customer interactions, all within a unified dashboard.
    </p>

    <p class="mt-2">
      If you have any questions or require assistance, feel free to reach out to us at <a class="text-blue-500 hover:text-blue-800 underline" href="mailto:info@texnrewards.com">info@texnrewards.com</a> or submit a support request on our Help & Support page.
    </p>
  </div>
</template>

<script>
export default {
  name: "p5s3newConversations"
}
</script>