import { FirestoreDB } from "./include/creds";
import {
  DocPath,
  DataProblem,
  GetDocResult,
  RemoveDocResult,
} from "./include/misc";

import { collection, doc, deleteDoc, setDoc, getDoc } from "firebase/firestore";

export type fs73EmployeeConversationInviteFolderEntries = {
  consumer_uid: string;
  conversation_uid: string;
  invited_timestamp: Date;
  inviting_employee_uid: string;
  last_response?: 1 | 2 | null;
  response_timestamp?: Date | null;
};

export function isValidData(
  data: fs73EmployeeConversationInviteFolderEntries
): boolean {
  return true;
}

export async function getDocFrom_fs73EmployeeConversationInviteFolderEntries(
  fs1_id: string,
  fs2_id: string,
  fs72_id: string,
  fs73_id: string
): Promise<GetDocResult> {
  const result: GetDocResult = {
    debug_data: [],
    return_msg:
      "fs73EmployeeConversationInviteFolderEntries:getDocFrom_fs73EmployeeConversationInviteFolderEntries",
    success: true,
    doc_data: {},
  };

  if (!fs1_id || !fs2_id || !fs72_id || !fs73_id) {
    result.success = false;
    result.return_msg += "Invalid function arguments.";
    return await Promise.resolve(result);
  }

  const doc_ref = doc(
    collection(
      FirestoreDB,
      "fs1RecyclerBusiness",
      fs1_id,
      "fs2RecyclerLocation",
      fs2_id,
      "fs72EmployeeConversationInvitesFolder",
      fs72_id,
      "fs73EmployeeConversationInviteFolderEntries"
    ),
    fs73_id
  );

  const doc_data = (await getDoc(doc_ref)).data();
  if (doc_data === undefined) {
    result.success = false;
    result.return_msg +=
      "Could not find a document with the passed-in path arguments.";
    return await Promise.resolve(result);
  }

  result.doc_data = doc_data;
  return await Promise.resolve(result);
}

export async function setDocIn_fs73EmployeeConversationInviteFolderEntries(
  fs1_id: string,
  fs2_id: string,
  fs72_id: string,
  fs73_id: string,
  data: fs73EmployeeConversationInviteFolderEntries
): Promise<DocPath> {
  const result: DocPath = {
    id: "",
    path: "",
  };
  if (!isValidData(data)) {
    return await Promise.resolve(result);
  }

  const doc_ref = doc(
    collection(
      FirestoreDB,
      "fs1RecyclerBusiness",
      fs1_id,
      "fs2RecyclerLocation",
      fs2_id,
      "fs72EmployeeConversationInvitesFolder",
      fs72_id,
      "fs73EmployeeConversationInviteFolderEntries"
    ),
    fs73_id
  );

  await setDoc(doc_ref, data);

  result.id = doc_ref.id;
  result.path = doc_ref.path;
  return await Promise.resolve(result);
}

export async function removeDocFrom_fs73EmployeeConversationInviteFolderEntries(
  fs1_id: string,
  fs2_id: string,
  fs72_id: string,
  fs73_id: string
): Promise<RemoveDocResult> {
  const result: RemoveDocResult = {
    debug_data: [],
    return_msg:
      "fs73EmployeeConversationInviteFolderEntries:removeDocFrom_fs73EmployeeConversationInviteFolderEntries ",
    success: true,
  };

  if (!fs1_id || !fs2_id || !fs72_id || !fs73_id) {
    result.success = false;
    result.return_msg += "Invalid function arguments.";
    return await Promise.resolve(result);
  }

  const doc_ref = doc(
    collection(
      FirestoreDB,
      "fs1RecyclerBusiness",
      fs1_id,
      "fs2RecyclerLocation",
      fs2_id,
      "fs72EmployeeConversationInvitesFolder",
      fs72_id,
      "fs73EmployeeConversationInviteFolderEntries",
      fs73_id
    )
  );

  await deleteDoc(doc_ref);
  return await Promise.resolve(result);
}
