<template>
  <div id="p1s3c2" class="h-full">

    <!-- Heading -->
    <div v-if="DV_activeTab == 'customerContacts'" class="h-full">
      <div class="flex items-center justify-between m-3">

        <!-- Search area -->
        <SearchBar containerClasses="w-5/12 my-2" inputClasses="w-full" v-model="DV_searchText"/>

        <div class="flex items-center justify-between">
          <!-- Toggle deactivated view -->
          <div v-if="C_isAdminOrManager" class="flex items-center">
            <span class="flex-grow flex flex-col mr-4" id="toggleLabel">
              <span style="line-height: 1rem" class="font-bold text-gray-900">
                Show Deactivated Contacts
              </span>
            </span>

            <label 
              aria-pressed="false" 
              aria-labelledby="toggleLabel" 
              class="toggle-label toggle-btn"
              :class="[DV_showDeactivatedContacts ? 'bg-blue-600' : 'bg-gray-200']"
            >
              <span class="sr-only">Use setting</span>
              
              <input 
                type="checkbox" 
                hidden="true"
                class="opacity-0 w-0 h-0"
                v-model="DV_showDeactivatedContacts"
                :class="[DV_showDeactivatedContacts ? 'toggle bg-blue-600' : 'bg-gray-200']"
              >
              <span aria-hidden="true" class="slider toggle-btn-slider"></span>
            </label>
          </div>

          <!-- Import Consumer Contacts button -->
          <button @click="DV_activeTab = 'importNewContacts'" class="btn btn-lime ml-4">
            Import Contacts
          </button>
          
          <!-- Export Contacts button -->
          <button v-show="C_isAdminOrManager" @click="showExportOptions" class="btn btn-lime ml-4">
            Export Contacts
          </button>

          <!-- Create new customer button -->
          <button @click="DV_activeTab = 'newContact'" class="btn btn-lime ml-4">
            Create New Customer Contact
          </button>
        </div>
      </div>

      <!-- Export Options -->
      <div id="exportOptions" @click.stop="DV_isExportOptionsOpened = !DV_isExportOptionsOpened" @blur="closeExportOptions" class="absolute right-5 top-5 cursor-pointer">
        <transition
          enter-active-class="transform transition duration-500 ease-custom"
          enter-class="-translate-y-1/2 scale-y-0 opacity-0"
          enter-to-class="translate-y-0 scale-y-100 opacity-100"
          leave-active-class="transform transition duration-300 ease-custom"
          leave-class="translate-y-0 scale-y-100 opacity-100"
          leave-to-class="-translate-y-1/2 scale-y-0 opacity-0"
        >
          <div class="absolute right-0 top-8 mb-4 bg-white divide-y rounded-b-lg drop-shadow-lg overflow-hidden w-[30rem] z-10" v-show="DV_isExportOptionsOpened">
            
            <!-- First Row -->
            <div class="flex flex-row items-center justify-between p-2">
              <strong>Export Contacts as:</strong>
              <svg @click.stop="closeExportOptions" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </div>

            <!-- List of Spreadsheet Formats -->
            <ul class="formats-list">
              <!-- Sales Dashboard -->
              <li 
                class="flex items-start justify-between font-normal border-t menu-list-item text-left font-medium cursor-pointer transition-colors duration-300 hover:bg-gray-200 z-10 w-full !mt-0"
                @click="DV_selectedExportType = 'csv'"
                @click.stop="exportConsumerContacts"
              >Text CSV (.csv)
              </li>

              <li 
                class="flex items-start justify-between font-normal border-t menu-list-item text-left font-medium cursor-pointer transition-colors duration-300 hover:bg-gray-200 z-10 w-full !mt-0"
                @click="DV_selectedExportType = 'xlsx'"
                @click.stop="exportConsumerContacts"
              >Excel 2007-365 (.xlsx)
              </li>
              <li 
                class="flex items-start justify-between font-normal border-t menu-list-item text-left font-medium cursor-pointer transition-colors duration-300 hover:bg-gray-200 z-10 w-full !mt-0"
                @click="DV_selectedExportType = 'xls'"
                @click.stop="exportConsumerContacts"
              >Excel 97-2003 (.xls)
              </li>
            </ul>
          </div>
        </transition>
      </div>


      <!-- Users details table -->
      <div class="overflow-hidden border border-gray-400 rounded-xl m-3 h-[84vh] overflow-scroll hide-scrollbar">
        <table class="w-full rounded-xl text-center">
          <thead class="border-b">
            <tr class="text-center font-medium bg-gray-100">
              <th scope="col" class="text-center">Customer Name</th>
              <th scope="col" class="text-center">Phone Numbers</th>
              <th scope="col" class="text-center">Business Associations</th>
              <th scope="col" class="text-center">Customer Type</th>
              <th scope="col" class="text-center">Rating</th>
              <th scope="col" class="text-center">Customer Engagement & Insights</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="contact in C_filterConsumerContacts" :key="contact.uid" class="border-b bg-white h-20">
              <td>{{ contact.full_name }}</td>
              <td>{{ formatPhoneNumber(contact.phone_number) }}</td>
              <td>{{ contact.business_name }}</td>
              <td class="capitalize">{{ contact.customer_types.length > 0 ? contact.customer_types.join(", ") : ""}}</td>
              <td class="capitalize">{{ classifyCustomerRating(contact.rating) }}</td>
              <td>
                <div v-if="customerConversationsInLimit(contact.uid)" class="flex justify-center flex-wrap">
                  <button
                    v-if="DV_startingNewChat && (DV_startChatStatus['phoneNumber'] == contact.phone_number)"
                    :disabled="DV_startingNewChat"
                    class="btn btn-lime text-sm px-2 mr-2 mb-2 w-32"
                  >
                    <svg 
                      class="w-6 h-5 spin-clockwise w-full h-full mx-auto"
                      xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                    >
                      <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                    </svg>
                  </button>

                  <button
                    v-else
                    @click="startNewChat(contact.uid)" 
                    :class="[DV_startingNewChat ? 'cursor-not-allowed' : '']"
                    class="btn btn-lime text-sm px-2 mr-2 mb-2 w-32"
                    :disabled="DV_startingNewChat"
                  >
                    Start New Chat
                  </button>

                  <button @click="showConversations(contact)" class="btn btn-lime text-sm px-2 mr-2 mb-2 w-32">
                    Conversations
                  </button>
                </div>

                <div class="flex justify-center flex-wrap">
                  <button v-if="!customerConversationsInLimit(contact.uid)" @click="showConversations(contact)" class="btn btn-lime text-sm px-2 mr-2 mb-2">
                    Conversations
                  </button>
                  <button @click="editContact(contact)" class="btn btn-lime text-sm px-2 mr-2 mb-2" :class="[customerConversationsInLimit(contact.uid) ? 'w-32' : '']">
                    Profile
                  </button>
                  <button v-if="contact.is_deactivated !== true" @click.stop="deactivateConsumer(contact)" class="btn btn-lime text-sm px-2 mb-2 mr-2" :class="[customerConversationsInLimit(contact.uid) ? 'w-32' : '']">
                    Deactivate
                  </button>
                  <button v-if="C_isAdminOrManager && contact.is_deactivated === true" @click.stop="activateConsumer(contact)" class="btn btn-lime text-sm px-2 mb-2 mr-2" :class="[customerConversationsInLimit(contact.uid) ? 'w-32' : '']">
                    Reactivate
                  </button>
                </div>

                <span 
                  v-if="DV_startChatStatus['message'] != '' && DV_startChatStatus['phoneNumber'] == contact.phone_number"
                  class="text-sm font-bold w-72 block break-words whitespace-normal"
                  :class="[DV_startChatStatus['status'] == 200 ? 'text-lime-500' : 'text-red-700']"
                >
                  {{DV_startChatStatus['message']}}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    
    <!-- previousConversation -->
    <div v-if="DV_activeTab == 'previousConversation'" class="m-3">
      <div class="flex items-center justify-between p-3">
        <nav class="flex" aria-label="Breadcrumb">
          <ol class="inline-flex items-center space-x-1 md:space-x-3">
            <li class="inline-flex items-center">
              <svg @click="DV_activeTab = 'customerContacts'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 mr-7 cursor-pointer">
                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"/>
              </svg>
              <span @click="DV_activeTab = 'customerContacts'" class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 cursor-pointer">
                Customer Contacts
              </span>
            </li>
            <li aria-current="page">
              <div class="flex items-center">
                <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path>
                </svg>
                <span class="text-sm font-medium text-gray-500 dark:text-gray-400">
                  Previous Conversation
                </span>
              </div>
            </li>
          </ol>
        </nav>
        
        <svg @click="DV_activeTab = 'customerContacts'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer">
          <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
        </svg>
      </div>

      <c4CustomerHistory :P_consumerUid="DV_selectedProfile.uid" />
    </div>
    
    <!-- Edit profile -->
    <div v-if="DV_activeTab == 'editContact'" class="m-3">
      <div class="flex items-center justify-between p-3">
        <nav class="flex" aria-label="Breadcrumb">
          <ol class="inline-flex items-center space-x-1 md:space-x-3">
            <li class="inline-flex items-center">
              <svg @click="DV_activeTab = 'customerContacts'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 mr-7 cursor-pointer">
                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"/>
              </svg>

              <span @click="DV_activeTab = 'customerContacts'" class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 cursor-pointer">
                Customer Contacts
              </span>
            </li>

            <li aria-current="page">
              <div class="flex items-center">
                <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path>
                </svg>
                <span class="text-sm font-medium text-gray-500 dark:text-gray-400">
                  Edit Customer Contact
                </span>
              </div>
            </li>
          </ol>
        </nav>
        
        <svg @click="DV_activeTab = 'customerContacts'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer">
          <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
        </svg>
      </div>
      <c2CustomerProfile 
        :P_viewType="'editView'"
        :P_consumerUid="DV_selectedProfile.uid"
        @event-back="DV_activeTab = 'customerContacts'"
        :businessesList="businessesList"
      />
    </div>

    <!-- Import Consumer Contacts -->
    <div v-if="DV_activeTab == 'importNewContacts'" class="m-3">
      <div class="flex items-center justify-between p-3">
        <nav class="flex" aria-label="Breadcrumb">
          <ol class="inline-flex items-center space-x-1 md:space-x-3">
            <li class="inline-flex items-center">
              <svg @click="DV_activeTab = 'customerContacts'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 mr-7 cursor-pointer">
                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"/>
              </svg>
              <span @click="DV_activeTab = 'customerContacts'" class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 cursor-pointer">
                Customer Contacts
              </span>
            </li>
            <li aria-current="page">
              <div class="flex items-center">
                <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path>
                </svg>
                <span class="text-sm font-medium text-gray-500 dark:text-gray-400">
                  Import Consumer Contacts
                </span>
              </div>
            </li>
          </ol>
        </nav>

        <svg @click="DV_activeTab = 'customerContacts'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer">
          <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
        </svg>
      </div>

      <c10ImportContacts
        :P_viewType="'importNewContacts'"
        @event-back="DV_activeTab = 'customerContacts'"
      />
    </div>

    <!-- New customer contact -->
    <div v-if="DV_activeTab == 'newContact'" class="m-3">
      <div class="flex items-center justify-between p-3">
        <nav class="flex" aria-label="Breadcrumb">
          <ol class="inline-flex items-center space-x-1 md:space-x-3">
            <li class="inline-flex items-center">
              <svg @click="DV_activeTab = 'customerContacts'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 mr-7 cursor-pointer">
                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"/>
              </svg>
              <span @click="DV_activeTab = 'customerContacts'" class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 cursor-pointer">
                Customer Contacts
              </span>
            </li>
            <li aria-current="page">
              <div class="flex items-center">
                <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path>
                </svg>
                <span class="text-sm font-medium text-gray-500 dark:text-gray-400">
                  Create New Customer Contact
                </span>
              </div>
            </li>
          </ol>
        </nav>

        <svg @click="DV_activeTab = 'customerContacts'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer">
          <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
        </svg>
      </div>

      <c2CustomerProfile
        :P_viewType="'newCustomer'"
        @event-back="DV_activeTab = 'customerContacts'"
      />
    </div>

    <!-- Texting number error -->
    <PopupModal ref="textingNumberErrorModal" :P_showClose="true" :P_mediumModal="true" @close-modal="closeTextingNumberErrorModal">
      <div slot="icon">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-14 h-14 mx-auto text-red-500">
          <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
        </svg>
      </div>

      <div slot="title">
        <h3 class="font-bold text-xl">{{ DV_textingNumberErrorHeader }}</h3>
      </div>
      <div slot="body">
        <div class="text-base" v-html="DV_textingNumberErrorBody"></div>
      </div>
    </PopupModal>
  </div>
</template>

<script>
import c10ImportContacts from "../c1/c10ImportContacts.vue";
import c2CustomerProfile from "../c1/c2CustomerProfile.vue";
import c4CustomerHistory from "../c1/c4CustomerHistory.vue";
import SearchBar from "../shared/SearchBar.vue";
import _ from "lodash";
import m2MiscDataFetchers from "../../mixins/m2MiscDataFetchers";
import generalFunctions from "../../mixins/generalFunctions";
import { fs4Writer } from "../../includes/firestore_writers/fs4_writer.ts"
import AWN from "awesome-notifications";
import sendConversationInvite from "../../includes/server_calls/p1s3/p1s3e16";
import exportConsumerContactsAsSpreadsheet from "../../includes/server_calls/p1s3/p1s3e51";
import dataValidator from "../../includes/bi1Datavalidation";
import {CR,RC} from "../../includes/bi2SuccessCodes";
import PopupModal from "../shared/PopupModal.vue";

export default {
  name: "p1s3c2CustomersManagement",

  props: ["customerRatingFilter", "consumerCityFilter", "consumerStateFilter", "consumerZipCodeFilter", "consumerSalesPersonFilter", "consumerBusinessFilter", "consumerTypeFilter", "businessesList"],

  mixins: [m2MiscDataFetchers, generalFunctions],

  components: { c4CustomerHistory, c2CustomerProfile, c10ImportContacts, SearchBar, PopupModal },

  data() {
    return {
      DV_searchText: "",
      DV_activeTab: "customerContacts",
      DV_importRecords: {},
      DV_importRecordsLastChanged: null,
      DV_selectedProfile: {},
      DV_consumerContacts: [],
      DV_consumerContactsPending: {},
      DV_consumerContactsRaw: {},
      DV_consumerBusinesses: {},
      DV_consumerContactsArrayLookup: window.G_bi2_data.IV_al_consumer_contacts,
      DV_employeePermissions: {},
      DV_employeeInfo: {},
      DV_showDeactivatedContacts: false,
      DV_startChatStatus: {
        code: 200,
        phoneNumber: "",
        message: ""
      },
      DV_textingNumberErrorHeader: "",
      DV_textingNumberErrorBody: "",
      DV_activeUserInfo: window.G_bi2_data.IV_active_employee_info,
      DV_startingNewChat: false,
      DV_isExportOptionsOpened: false,
      DV_selectedExportType: ""
    };
  },

  mounted() {
    const CI = this;

    CI.startConsumerContactAndBusinessesListeners();
    CI.startImportedConsumerRecordsListener();
    CI.DV_employeeInfo = window.G_bi2_data.IV_active_employee_info;
    CI.DV_employeePermissions = window.G_bi2_data.IV_active_employee_info.permissions;
    
    CI.DV_importRecordsLastChanged = window.G_bi2_data.IV_imported_contacts_history_last_changed.value;
    CI.DV_importRecords = window.G_bi2_data.IV_imported_contacts_history.value
  },

  computed: {
    C_filterConsumerContacts() {
      const CI = this;
      let consumers = CI.DV_consumerContacts

      let reviewsRating = {
        "amazing": _.range(90, 101),
        "good": _.range(80, 89),
        "average": _.range(70, 79), 
        "below-average": _.range(60, 69),
        "bad": _.range(50, 59)
      }

      if (CI.customerRatingFilter.length != 6) {
        let rating = []
        CI.customerRatingFilter.forEach((item) => {rating.push(reviewsRating[item])})
        rating = rating.flat()

        consumers = consumers.filter( consumer => {
          return (rating.includes(consumer.rating) || 
            (CI.customerRatingFilter.includes("none-specified") && !consumer.rating)
          )
        })
      }

      if (CI.consumerCityFilter != "Any") {
        consumers = consumers.filter( consumer => consumer.city === CI.consumerCityFilter)
      }

      if (CI.consumerStateFilter != "Any") {
        consumers = consumers.filter( consumer => consumer.state === CI.consumerStateFilter)
      }

      if (CI.consumerZipCodeFilter.length > 0) {
        consumers = consumers.filter( consumer => consumer.zip_code === parseInt(CI.consumerZipCodeFilter))
      }

      if (CI.consumerSalesPersonFilter != "Any") {
        consumers = consumers.filter( consumer => consumer.sales_person === CI.consumerSalesPersonFilter)
      }

      if (CI.consumerBusinessFilter != "Any") {
        consumers = consumers.filter( consumer => consumer.business_name.includes(CI.consumerBusinessFilter))
      }

      // Type filters
      if (CI.consumerTypeFilter.length != 7) {
        consumers = consumers.filter( (consumer) => {
          if ((consumer.customer_types.length == 0) && CI.consumerTypeFilter.includes("none-specified")) {
            return true;
          } else {
            return consumer.customer_types.some((type) => {
              return CI.consumerTypeFilter.includes(type.toLowerCase().replace(" ", "-"))
            })
          }
        })
      }

      if (CI.DV_searchText != "") {
        consumers = consumers.filter( (consumer) => {
          
          if (consumer.full_name && consumer.full_name.toLowerCase().includes(CI.DV_searchText.toLowerCase())) { return true; }
          if (consumer.business_name && consumer.business_name.toLowerCase().includes(CI.DV_searchText.toLowerCase())) { return true; }
          if (consumer.customer_types && consumer.customer_types.join(", ").toLowerCase().includes(CI.DV_searchText.toLowerCase())) { return true; }
          if (consumer.phone_number && consumer.phone_number.toLowerCase().includes(CI.DV_searchText.toLowerCase())) { return true; }
          if (consumer.rating && Object.keys(reviewsRating).includes(CI.DV_searchText.toLowerCase()) && reviewsRating[CI.DV_searchText.toLowerCase()].includes(consumer.rating)) { return true;}
          if (consumer.city && consumer.city.toLowerCase().includes(CI.DV_searchText.toLowerCase())) { return true; }
          if (consumer.state && consumer.state.toLowerCase().includes(CI.DV_searchText.toLowerCase())) { return true; }

          return false;
        })
      }

      // Filter the deactivated according to DV_showDeactivatedContacts
      if (CI.DV_showDeactivatedContacts) {
        consumers = consumers.filter((consumer) => {
          return consumer.is_deactivated;
        })
      } else {
        consumers = consumers.filter((consumer) => {
          return !consumer.is_deactivated;
        })
      }

      // sort consumers in alphabetical order
      let consumerWithNames = consumers.filter(consumer => consumer.full_name.trim() != "")
      let ConsumerWithNoNames = consumers.filter(consumer => consumer.full_name.trim() == "")
      consumerWithNames = _.sortBy(consumerWithNames, consumer => consumer.full_name);

      return [...consumerWithNames, ...ConsumerWithNoNames]
    },

    C_isAdminOrManager() {
      return (window.G_bi2_data.IV_active_employee_info.permissions.admin === true || window.G_bi2_data.IV_active_employee_info.permissions.manager === true);
    }
  },

  methods: {
    startImportedConsumerRecordsListener(retry_count=0) {
      const CI = this;
      if (retry_count >= 10) { return; }

      if (!window.G_bi2_data.IV_active_employee_info.business_uid || !window.G_bi2_data.IV_active_employee_info.recycler_location_uid) {
        setTimeout( function() {CI.startImportedConsumerRecordsListener(++retry_count)}, 500);
        return
      }

      window.G_bi2_data.startImportedContactsHistoryListener(
        window.G_bi2_data,
        window.G_bi2_data.IV_active_employee_info.business_uid,
        window.G_bi2_data.IV_active_employee_info.recycler_location_uid
      )

    },
    
    startConsumerContactAndBusinessesListeners(retry_count=0) {
      const CI = this;

      if (!window.G_bi2_data.IV_active_employee_info.business_uid ||
          !window.G_bi2_data.IV_active_employee_info.recycler_location_uid) { 
          
        if (retry_count >= 500) { return; }
        setTimeout(function() { CI.startConsumerContactAndBusinessesListeners(++retry_count)}, 500);
        
        return; 
      }

      window.G_bi2_data.startAllConsumerContactsListener(
        window.G_bi2_data,
        window.G_bi2_data.IV_active_employee_info.business_uid,
        window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
      );
      window.G_bi2_data.startConsumerBusinessesListener(
        window.G_bi2_data,
        window.G_bi2_data.IV_active_employee_info.business_uid,
        window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
      );

      this.DV_consumerContactsPending = window.G_bi2_data.IV_consumer_contacts_pending.value;
      this.DV_consumerContactsRaw = window.G_bi2_data.IV_consumer_contacts.value;
      this.DV_consumerBusinesses = window.G_bi2_data.IV_consumer_businesses.value;
      this.populateConsumerContactsList();
    },
    editContact(contact) {
      this.DV_selectedProfile = contact
      this.DV_activeTab = 'editContact'
    },

    showConversations(contact) {
      this.DV_selectedProfile = contact
      this.DV_activeTab = 'previousConversation'
    },

    populateConsumerContactsList() {
      const CI = this;
      const index = CI.DV_consumerContactsArrayLookup;
      let consumersList = [];


      // Create customer business joins
      for (let uid in CI.DV_consumerContactsRaw) {
        let consumerContact = {}
        let consumer = CI.DV_consumerContactsRaw[uid]

        consumerContact.full_name = consumer[index.first_name] + " " + consumer[index.last_name]
        consumerContact.email = consumer[index.email]
        consumerContact.phone_number = CI.formatPhoneNumber(consumer[index.primary_phone_number].phone_number)
        consumerContact.rating = consumer[index.average_consumer_rating]
        consumerContact.city = consumer[index.address_info_city_2d[0]][index.address_info_city_2d[1]]
        consumerContact.state = consumer[index.address_info_state_2d[0]][index.address_info_state_2d[1]]
        consumerContact.zip_code = consumer[index.address_info_postal_code_2d[0]][index.address_info_postal_code_2d[1]]
        consumerContact.customer_types = consumer[index.customer_types] || [""]
        consumerContact.uid = uid
        consumerContact.is_deactivated = consumer[index.is_deactivated]

        let business_name = ""
        
        for (let business_uid in CI.DV_consumerBusinesses) {
          const businessInfo = CI.DV_consumerBusinesses[business_uid];

          if (!businessInfo.name || !businessInfo.consumer_contacts_uids_list) { continue; };

          if (businessInfo.consumer_contacts_uids_list.includes(uid)) {
            let prefix = (business_name == "" ? '' : ", ");
            business_name += `${prefix}${businessInfo.name}`;
          }
        }

        consumerContact.business_name = business_name
        consumerContact.sales_person = ""

        consumersList.push(consumerContact)
      }

      CI.DV_consumerContacts = consumersList;
      CI.$forceUpdate();
    },

    deactivateConsumer(consumer) {
      const CI = this;

      let notifier = new AWN();

      notifier.confirm(
        // Label
        "Are you sure you want to deactivate this contact? Once deactivated, only Managers or Admins can reactivate them.",
        
        // On OK
        async function() {
          fs4Writer(
            "e",
            window.G_bi2_data.IV_active_employee_info.business_uid,
            window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
            consumer.uid,
            {is_deactivated: true}
          ).then((resp) => {
            if (resp.success === true) {
              CI.$awn.success("Contact deactivated successfully.")
            } else {
              CI.$awn.success("There was an issue while deactivating the contact.")
            }
          }).catch((error) => {
            CI.$awn.success("There was an issue while deactivating the contact.")
          });
        },

        // On cancel
        function() {},

        // Other options
        {
          labels: {
            confirm: 'Deactivate Contact',
            confirmOk: "Confirm",
            confirmCancel: "Cancel"
          }
        }
      );
    },

    activateConsumer(consumer) {
      const CI = this;

      if (!CI.C_isAdminOrManager) { return; }

      let notifier = new AWN();

      notifier.confirm(
        // Label
        "Are you sure you want to reactivate this contact? Once reactivated, it will be visible in the normal tab.",
        
        // On OK
        async function() {
          fs4Writer(
            "e",
            window.G_bi2_data.IV_active_employee_info.business_uid,
            window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
            consumer.uid,
            {is_deactivated: false}
          ).then((resp) => {
            if (resp.success === true) {
              CI.$awn.success("Contact reactivated successfully.")
            } else {
              CI.$awn.success("There was an issue while reactivating the contact.")
            }
          }).catch((error) => {
            CI.$awn.success("There was an issue while reactivating the contact.")
          });
        },

        // On cancel
        function() {},

        // Other options
        {
          labels: {
            confirm: 'Reactivate Contact',
            confirmOk: "Confirm",
            confirmCancel: "Cancel"
          }
        }
      );
    },

    async startNewChat(customer_uid) {
      const CI = this;
      let phone_number ="";
      let first_name = "";
      let last_name = "";
      let recycler_info = {};
      let customer = CI.DV_consumerContactsRaw[customer_uid]
      this.DV_startingNewChat = true;

      if (!customer) { return; }

      phone_number = customer[CI.DV_consumerContactsArrayLookup.primary_phone_number].phone_number;
      first_name = customer[CI.DV_consumerContactsArrayLookup.first_name];
      last_name = customer[CI.DV_consumerContactsArrayLookup.last_name];

      //  set recycler info
      if (this.DV_activeUserInfo.recycler_location_uid &&
        this.DV_activeUserInfo.recycler_location_uid in window.G_bi2_data.IV_recyclers.value
      ) {
        recycler_info = window.G_bi2_data.IV_recyclers.value[this.DV_activeUserInfo.recycler_location_uid];
      }

      let call_result = dataValidator.is_texting_number(recycler_info.text_number)
      if (call_result[CR.success] !== RC.success) {
        // set message header and body
        this.DV_textingNumberErrorHeader = "Texting Number Not Requested"
        this.DV_textingNumberErrorBody = `It appears you haven't requested your business texting number yet. To initiate conversations, please go to the Settings -> Business Information page to request your texting number.
        <br><br>
        Note: You will need your Employer Identification Number (EIN) and Legal Business Name to successfully request your Business Texting Number.
        <br><br>
        If you do not have access to the Settings page, please notify your sales manager or a Synergy admin for your business.`
        
        this.$refs.textingNumberErrorModal.show();
        this.DV_startingNewChat = false;
        return;
      }

      call_result = dataValidator.is_string(recycler_info.text_number, 10)
      if (call_result[CR.success] !== RC.success) {
        // set message header and body
        this.DV_textingNumberErrorHeader = "Texting Number Not Yet Verified"
        this.DV_textingNumberErrorBody = `Your business texting number is currently awaiting verification by the campaign registry. Until verified, you won't be able to initiate new chats using this number.
        <br><br>
        Once approved, we'll send an email to the business email address on file to inform you that your account is ready for texting.
        <br><br>
        If we encounter any challenges during the approval process, we'll connect with the Primary Contact listed on your account or call the provided business phone number to address and resolve any issues, ensuring your account is set up for texting.
        <br><br>
        We appreciate your patience and understanding.`
        
        this.$refs.textingNumberErrorModal.show();
        this.DV_startingNewChat = false;
        return;
      }

      window.G_bi2_data.IV_last_new_conversation_phone_number = phone_number;
      this.DV_startChatStatus["status"] = 200;
      this.DV_startChatStatus["phoneNumber"] = this.formatPhoneNumber(phone_number);
      this.DV_startChatStatus["message"] = "Starting conversation with " + this.formatPhoneNumber(phone_number) + " | " + first_name + " " + last_name;

      //we are using .then here to prevent using an await which stops the emit event that shows the conversation starting status
      sendConversationInvite(
        {
          recycler_uid: this.DV_activeUserInfo.business_uid,
          recycler_location_uid: this.DV_activeUserInfo.recycler_location_uid,
          employee_uid: this.DV_activeUserInfo.employee_uid,
          phone_number: phone_number,
          first_name: first_name,
          last_name: last_name
        }
      ).then((result) => {

          this.DV_startingNewChat = false;
          if (result.success === true) {
            // showing message for the started conversation
            if (result.response["max_open_conversation_count_reached"]) {
              this.DV_startChatStatus["status"] = 400
              this.DV_startChatStatus["message"] = `This phone number(${phone_number}) currently has the maximum limit of 3 open conversations.`;

              setTimeout(() => {this.DV_startChatStatus["message"] = '';}, 2000)
              return;
            }

            this.DV_startChatStatus["status"] = 200;
            this.DV_startChatStatus["message"] = "";
            this.$router.push('/sales-dashboard');
          } else {
            this.$awn.alert('There was an issue while queuing new conversation. Please reload page and try again.');
          }
      });
    },

    closeTextingNumberErrorModal() {
      this.$refs.textingNumberErrorModal.hide();
    },

    showExportOptions() {
      const CI = this;

      CI.DV_isExportOptionsOpened = true;
      CI.DV_selectedExportType = "";
    },

    closeExportOptions() {
      const CI = this;

      CI.DV_isExportOptionsOpened = false;
    },

    exportConsumerContacts() {
      const CI = this;

      const exportType = CI.DV_selectedExportType;
      CI.DV_selectedExportType = "";
      
      this.closeExportOptions();
  
      exportConsumerContactsAsSpreadsheet(
        window.G_bi2_data.IV_active_employee_info.business_uid,
        window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
        exportType,
      ).then((resp) => {
        try {
          if (resp.response.spreadsheet_content) {
            // Decode the Base64 data
            const spreadsheet_content = resp.response.spreadsheet_content;
            const preTextCSV = "data:text/csv;base64,";
            const preTextXLSX = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
            const preTextXLS = "data:application/vnd.ms-excel;base64,";
            const fileExtension = exportType.toLowerCase();
            //const decodedData = atob(resp.response.spreadsheet_content);
          
            // Create a Blob with decoded data
            //const blob = new Blob([decodedData], { type: '' });
          
            // Create download link
            let downloadLink = document.createElement('a');
            downloadLink.classList.add('hide');
          
            let recycler_name =
              window.G_bi2_data.IV_recyclers.value[window.G_bi2_data.IV_active_employee_info.recycler_location_uid]
                .recycler_business_name;
            let fileName = recycler_name + ' Contacts.' + fileExtension;
            //downloadLink.href = URL.createObjectURL(blob);
          
            // Determine the file extension based on the selected export type
            if (fileExtension === 'csv') {
              // For CSV format
              downloadLink.href = preTextCSV + spreadsheet_content;
            } else if (fileExtension === 'xlsx') {
              // For XLSX format
              downloadLink.href = preTextXLSX + spreadsheet_content;
            } else if (fileExtension === 'xls') {
              // For XLS format
              downloadLink.href = preTextXLS + spreadsheet_content;
            } else {
              // Unsupported format
              CI.$awn.alert('Unsupported export format');
              return;
            }
          
            downloadLink.download = fileName;
            downloadLink.textContent = 'Download Now';
          
            // Append the link to the document
            document.body.appendChild(downloadLink);
          
            // Trigger a click event to start the download
            downloadLink.click();
            downloadLink.remove();
          
            // Clean up by revoking the blob URL
            URL.revokeObjectURL(downloadLink.href);
          }
        } catch (e) {
          CI.$awn.alert(
            'Failed to download the file. Please reload the page and try again. Or contact support if the issue persists.'
          );
        }
      })
      .catch((error) => {
        const errorMessage = error.message;
        CI.$awn.alert(
          'Failed to download the file. Please reload the page and try again. Or contact support if the issue persists.'
        );
      });  
    } 
  },

  watch: {
    DV_importRecordsLastChanged: { 
      handler() {
        const CI = this;

        CI.DV_importRecordsLastChanged = window.G_bi2_data.IV_imported_contacts_history_last_changed.value;
       
        const filteredimportRecords = {};
        Object.keys(CI.DV_importRecords).forEach(key => {
          if (key !== 'null' && key !== null) {
            filteredimportRecords[key] = CI.DV_importRecords[key];
          }
        });
        
        window.G_bi2_data.IV_imported_contacts_history.value = filteredimportRecords;
        CI.DV_importRecords = filteredimportRecords;
      },
    },
    DV_employeeInfo: {
      handler() {
        const CI = this;
        CI.DV_employeePermissions = window.G_bi2_data.IV_active_employee_info.permissions;
      },
      deep: true
    },

    DV_consumerContactsRaw: {
      handler() {
        this.populateConsumerContactsList();
      },

      deep: true
    },

    DV_consumerBusinesses: {
      handler() {
        this.populateConsumerContactsList();
      },

      deep: true
    }
  }
};
</script>
