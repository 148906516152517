<template>
  <div id="c1c4">

    <!-- Conversation detail -->
    <div class="grid grid-cols-7 gap-2 px-2 items-center cursor-pointer border-b">
      <div class="col-span-1 h-full items-start">
        <h1 class="font-bold text-lg text-left">B Q</h1>
      </div>
      <div class="col-span-6">
        <p class="text-left text-sm my-1">
          John Miller | Downtown Repair | Body Shop | Quotes: 12435653($1000)
          132456($100)
        </p>
      </div>
    </div>

    <!-- Heading -->
    <div class="flex flex-row items-center justify-between my-3">
      <svg @click="returnToParent" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"/>
      </svg>
      <h1 class="font-bold text-sm">Conversation History List</h1>
      <svg @click="returnToParent" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer">
        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"/>
      </svg>
    </div>

    <!-- Search Area -->
    <SearchBar containerClasses="my-2" inputClasses="w-full" />

    <!-- Previous converstions list -->
    <div class="border border-gray-400 rounded-xl p-2">
      <div class="border border-gray-400 rounded-xl pb-2 overflow-y-scroll">
        <div v-for="index in 7" :key="index + Math.random()" @click="toggleMessages(index)" :class="{ active: DV_userClick[index] == true }" class="border-b border-gray-400 px-2 text-left">
          <p v-if="DV_userClick[index] == false" class="font-medium text-sm">
            Honda Civic 2003 <span>Alternator</span> <span>$100</span>
            <span>Pending</span>, Created on <span>09/10/2022</span>
          </p>
          <div v-if="DV_userClick[index] == true">
            <div class="grid grid-cols-6 gap-2">
              <div class="col-span-4">
                <p class="font-medium text-sm">
                  Started on <span>01/09/2022</span> <br />
                  Finished on <span>02/09/2022</span> <br />
                  <span>$150</span> in Payment Links
                </p>
              </div>
              <button @click.stop="" class="col-span-2 btn btn-lime px-1 w-full my-2 text-sm">
                View Conversation
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchBar from '../shared/SearchBar.vue';

export default {
  name: "c1c4ConversationHistory",

  components: { SearchBar },

  data() {
    return {
      DV_userClick: {
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
        7: false,
      },
    };
  },

  props: ["customerInformation"],

  methods: {
    toggleMessages(index) {
      this.DV_userClick[index] = !this.DV_userClick[index];
    },

    returnToParent() {
      if (this.customerInformation == true) {
        this.$emit("show-customer-information");
      } else {
        this.$emit("show-more-actions");
      }
    },
  },
};
</script>
