/* eslint-disable */
import { ajax } from "noquery-ajax";
import { httpsLogger } from "../p1s7/p1s7e1";

interface Params {
  firebase_uid: any;
  firebase_token: any;
  recycler_uid: string;
  recycler_location_uid: string;
  p1s3e46_action_type: number;
  p1s3e46_candidate_category?: string;  // Optional, since you're adding it conditionally
  p1s3e46_candidate_location_id?: string;  // Optional, since you're adding it conditionally
}

function updateLocationCandidate(
  recycler_uid: string,
  recycler_location_uid: string,
  action_type: number,
  candidate_category: string | undefined,
  candidate_location_id: string | undefined,
) {
  return new Promise(function (resolve, reject) {
    let params: Params = {
      firebase_uid: window.G_firebase_auth.IV_uid,
      firebase_token: window.G_firebase_auth.IV_id_token,
      recycler_uid: recycler_uid,
      recycler_location_uid: recycler_location_uid,
      p1s3e46_action_type: action_type,
    };

    if (action_type === 1) {
      params["p1s3e46_candidate_category"] = candidate_category;
      params["p1s3e46_candidate_location_id"] = candidate_location_id;
    }

    ajax({
      url: window.G_cloud_p1s3_base_url + "p1s3e46UpdateLocationCandidate",
      headers: {
        Accept: "application/json",
      },
      method: "POST",
      dataType: "json",
      data: params,
      success: function (result) {
        let response_data = {
          success: true,
          response: result,
          request_params: params
        }
        resolve(response_data);
      },
      error: function (error) {
        let error_data = {
          success: false,
          request_object: error,
          request_params: params,
          file_name: "p1s3e46.ts",
          class_name: "p1s3e46",
          method_name: "updateLocationCandidate",
          error_title: "Failed to update location candidate.",
          error_description: "The server responded with an error when updating the location candidate.",
        };

        httpsLogger(error_data);
        reject(error_data);
      },
    });
  });
}

export default updateLocationCandidate;
