import { FirestoreDB } from "./include/creds";
import {
  DocPath,
  DataProblem,
  GetDocResult,
  RemoveDocResult,
} from "./include/misc";

import {
  collection,
  doc,
  setDoc,
  updateDoc,
  getDoc,
  deleteDoc,
  deleteField
} from "firebase/firestore";

type MetaMatch = `ac_${string}`;

export type fs46EmployeeActiveConsumerConversationJoins = {
  not_empty_document: boolean;
  [conversation_added_time: MetaMatch]: Date;
};

export function getDataProblems_fs46EmployeeActiveConsumerConversationJoins(
  data: fs46EmployeeActiveConsumerConversationJoins
): DataProblem[] {
  const dataproblems: DataProblem[] = [];
  return dataproblems;
}

export function isValidData(
  data: fs46EmployeeActiveConsumerConversationJoins
): boolean {
  return true;
}

export async function getDocFrom_fs46EmployeeActiveConsumerConversationJoins(
  fs1_id: string,
  fs2_id: string,
  fs3_employee_uid: string
): Promise<GetDocResult> {
  const result: GetDocResult = {
    debug_data: [],
    return_msg:
      "fs46EmployeeActiveConsumerConversationJoins:getDocFrom_fs46EmployeeActiveConsumerConversationJoins ",
    success: true,
    doc_data: {},
  };

  if (!fs1_id || !fs2_id || !fs3_employee_uid) {
    result.success = false;
    result.return_msg += "Invalid function arguments.";
    return await Promise.resolve(result);
  }

  const doc_ref = doc(
    collection(
      FirestoreDB,
      "fs1RecyclerBusiness",
      fs1_id,
      "fs2RecyclerLocation",
      fs2_id,
      "fs46EmployeeActiveConsumerConversationJoins",
      fs3_employee_uid
    )
  );

  const doc_data = (await getDoc(doc_ref)).data();
  if (doc_data === undefined) {
    result.success = false;
    result.return_msg +=
      "Could not find a document with the passed-in path arguments.";
    return await Promise.resolve(result);
  }

  result.doc_data = doc_data;
  return await Promise.resolve(result);
}

export async function setDocIn_fs46EmployeeActiveConsumerConversationJoins(
  fs1_id: string,
  fs2_id: string,
  fs3_employee_uid: string,
  data: fs46EmployeeActiveConsumerConversationJoins
): Promise<DocPath> {
  const result: DocPath = {
    id: "",
    path: "",
  };
  if (!isValidData(data)) {
    return await Promise.resolve(result);
  }

  const collectionRef = collection(
    FirestoreDB,
    "fs1RecyclerBusiness",
    fs1_id,
    "fs2RecyclerLocation",
    fs2_id,
    "fs46EmployeeActiveConsumerConversationJoins"
  )

  const doc_ref = doc(collectionRef, fs3_employee_uid);

  await setDoc(doc_ref, data);

  result.id = doc_ref.id;
  result.path = doc_ref.path;
  return await Promise.resolve(result);
}

export async function addDocIn_fs46EmployeeActiveConsumerConversationJoins(
  fs1_id: string,
  fs2_id: string,
  fs3_employee_uid: string,
  data: fs46EmployeeActiveConsumerConversationJoins
): Promise<DocPath> {
  const result: DocPath = {
    id: "",
    path: "",
  };
  if (!isValidData(data)) {
    return await Promise.resolve(result);
  }

  const collectionRef = collection(
    FirestoreDB,
    "fs1RecyclerBusiness",
    fs1_id,
    "fs2RecyclerLocation",
    fs2_id,
    "fs46EmployeeActiveConsumerConversationJoins"
  )

  const doc_ref = doc(collectionRef, fs3_employee_uid);

  const docSnap = await getDoc(doc_ref);
  if (docSnap.exists()) {
    // Document exists, update it
    await updateDoc(doc_ref, data);
  } else {
    // Document does not exist, create it
    await setDoc(doc_ref, data);
  }

  result.id = doc_ref.id;
  result.path = doc_ref.path;
  return await Promise.resolve(result);
}

export async function removeDocFrom_fs46EmployeeActiveConsumerConversationJoins(
  fs1_id: string,
  fs2_id: string,
  fs3_employee_uid: string,
  fs4_consumer_contact_uid: string,
  fs6_conversation_uid: string
): Promise<RemoveDocResult> {
  const result: RemoveDocResult = {
    debug_data: [],
    return_msg:
      "fs46EmployeeActiveConsumerConversationJoins:removeDocFrom_fs46EmployeeActiveConsumerConversationJoins ",
    success: true,
  };

  if (!fs1_id || !fs2_id || !fs3_employee_uid) {
    result.success = false;
    result.return_msg += "Invalid function arguments.";
    return await Promise.resolve(result);
  }

  const doc_ref = doc(
    collection(
      FirestoreDB,
      "fs1RecyclerBusiness",
      fs1_id,
      "fs2RecyclerLocation",
      fs2_id,
      "fs46EmployeeActiveConsumerConversationJoins"
    ),
    fs3_employee_uid
  );

  const dynamicKey = `ac_${fs4_consumer_contact_uid}_${fs6_conversation_uid}`;
  const docSnap = await getDoc(doc_ref);
  
  if (docSnap.exists()) {
    await updateDoc(doc_ref, {[dynamicKey]: deleteField()});
  }

  return await Promise.resolve(result);
}
