<template>
  <div id="app" :class="{'account-setup-main-container': C_isLoginViewActive}">

    <div v-if="DV_activeRenderMode < DV_renderModeDesktopNarrow && !C_isLoginViewActive && !C_isAccountSetupViewActive">
      <div class="mt-8 flex justify-center">
        <img class="w-20" :src="require('./assets/images/synergy_icon.png')"/>
      </div>
      
      <div class="w-10/12 mx-auto mt-24 border border-gray-400 rounded p-4 flex flex-col items-center">
        Oops! The Synergy Sales Dashboard isn't mobile-friendly yet. For the best experience, please use a desktop or laptop to access your dashboard. Thanks for your patience!
        <button class="btn btn-lime mt-8" @click.stop="logoutUser">Logout</button>
      </div>
    </div>

    <div v-else>
      <!-- Header -->
      <c1Header v-if="DV_showHeader" :P_isInitialized="DV_connectedToDB"></c1Header>    
      
      <!-- Dynamic page content -->
      <router-view v-if="DV_connectedToDB"></router-view>
      <h1 style="margin: 5vw 5vh" v-else>Loading...</h1>

      <PopupModal ref="employeeInviteModal" :P_showClose="true" :P_largeModal="true" @close-modal="closeInviteModal">
        <div slot="title"> <h3 class="font-bold">Invites to Recycler Location(s)</h3> </div>
      
        <template slot="body">
          <table class="table-auto w-full">
            <thead>
              <tr>
                <th>Invited At</th>
                <th>Permissions</th>
                <th>Recycler Business</th>
                <th>Recycler Location</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="invite in DV_usersActiveInvites">
                <td>{{moment(invite.invite_time.seconds * 1000).format('MM-DD-YYYY h:mm A')}}</td>
                <td>{{invite.humanizedPermissions}}</td>
                <td>{{invite.recycler_business_name || invite.recycler_business_uid}}</td>
                <td>{{invite.recycler_location_name || invite.recycler_location_uid}}</td>
                <td>
                  <div class="flex justify-around">
                    <button @click.stop="acceptInvite(invite)" class="btn btn-lime mr-2">Accept</button>
                    <button @click.stop="ignoreInvite" class="btn btn-lime">Ignore</button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </template>
      </PopupModal>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import AWN from "awesome-notifications";
import route from "vue-router"
import c1Header from "./components/c1Header.vue";
import PopupModal from "./components/shared/PopupModal.vue"
import { fs30Writer } from "./includes/firestore_writers/fs30_writer.ts"
import { fs3Writer } from "./includes/firestore_writers/fs3_writer.ts"
import { fs131Writer } from "./includes/firestore_writers/fs131_writer.ts"
import { fs76Writer } from "./includes/firestore_writers/fs76_writer.ts"
import { getDocByEmailFrom_fs3Employees } from "./includes/firestore_schemas/fs3Employees.ts"
import m3RenderingSettings from "./mixins/m3RenderingSettings"
import moment from "moment"
import _ from "lodash"

export default Vue.extend({
  name: "App",

  components: { c1Header, PopupModal },
  mixins:[m3RenderingSettings],

  data() {
    return {
      DV_connectedToDB: false,
      DV_showHeader: true,
      DV_usersActiveInvites: [],
      DV_usersActiveInvitesRef: {},
      moment: moment,
      DV_permissionsMapping: {
        "a": "Admin",
        "b": "Manager",
        "c": "Sales person",
        "d": "Report viewer"
      }
    }
  },

  mounted() {
    setTimeout(this.checkConnection, 1000);
      
    // Listen for every possible event to check user's interaction.
    const eventsList = ["click", "mouseover", "mousemove", "touchmove", "focus"];    
    eventsList.forEach((eventName)=>{
      window.addEventListener(eventName, () => {
        this.lazyUpdateActiveStatus();
      }); 
    });
  },

  computed: {
    C_isLoginViewActive() {
      return (this.$route.path == "/" || this.$route.path == "/login");
    },

    C_isAccountSetupViewActive() {
      if(this.$route.path === "/account-setup" || 
      this.$route.path === "/axe-account-setup" ||
      this.$route.path === "/slimcd-account-setup"
      ) {
        return true;
      }
      return false;
      
    }
  },

  methods: {
    checkConnection(retry_count=0) {
      const CI = this;

      if (window.G_firebase_auth.IV_initialized === false) {
        if (retry_count >= 10) {
          localStorage.clear();
          const redirect_url = (location.pathname + location.search).substr(1);
          
          location.assign(window.location.origin + `/login?redirect_to=${redirect_url}`);
          return;
        }

        setTimeout(function() { CI.checkConnection(++retry_count) }, 1000);
        return;
      }

      this.DV_connectedToDB = true;
      this.DV_usersActiveInvitesRef = window.G_bi2_data.IV_loggedin_user_active_invites.value
    },

    checkAndShowEmplyeeInvite() {      
      //don't show the ability to have multiple locations until all UI elements related to that feature are ready
      if (this.DV_showUnfinishedFeatures === false &&  window.G_bi2_data.IV_active_employee_info.recycler_location_uid !== "") { return; }

      const invites = window.G_bi2_data.IV_loggedin_user_active_invites.value;
      this.DV_usersActiveInvites = [];

      if (!invites) { return; }

      for (let recycler_location_uid in invites) {
        let invites_array = invites[recycler_location_uid];

        for (let invite of invites_array) {
          let inviteCopy = Object.assign({}, invite);
          let permissions = "";

          for (let perm of invite.permissions) {
            if (this.DV_permissionsMapping[perm]) {
              permissions += `${this.DV_permissionsMapping[perm]}, `;
            }
          }

          inviteCopy.humanizedPermissions = permissions.substring(0, permissions.length - 2)
          this.DV_usersActiveInvites.push(inviteCopy);
        }
      }
      if (this.DV_usersActiveInvites.length > 0) {
        this.$refs.employeeInviteModal.show();
      }
    },

    closeInviteModal() {
      this.$refs.employeeInviteModal.hide();
    },

    ignoreInvite() {
      this.closeInviteModal();
    },

    async acceptInvite(invite) {
      // Update the fs3 document for this user with their name and other information.
      const employeeRecord = await getDocByEmailFrom_fs3Employees(
        invite.recycler_business_uid,
        invite.recycler_location_uid,
        window.G_bi2_data.IV_loggedin_user_info.email
      )

      if (employeeRecord.success == false) {
        this.$awn.alert("There was an issue accepting the invite. Please reload the page and try again.")
        return;
      }

      let firstName = window.G_firebase_auth.IV_first_name;
      let lastName = window.G_firebase_auth.IV_last_name;

      const fs3Resp = await fs3Writer(
        "e",
        invite.recycler_business_uid,
        invite.recycler_location_uid,
        employeeRecord.doc_data.employee_uid,
        { 
          firebase_account_uid: window.G_bi2_data.IV_loggedin_user_info.firebase_uid,
          first_name: firstName || window.G_bi2_data.IV_loggedin_user_info.email.split("@")[0],
          last_name: lastName || "",
          email_address: window.G_bi2_data.IV_loggedin_user_info.email,
          phone_number: "",
          extension: ""
        },
        {
          admin: invite.permissions.includes("a"),
          manager: invite.permissions.includes("b"),
          sales_person: invite.permissions.includes("c"),
          report_viewer: invite.permissions.includes("d")
        }
      )

      if (fs3Resp.success == false) {
        this.$awn.alert("There was an issue accepting the invite. Please reload the page and try again.")
        return;
      }

      // Check or create the new fs30 document for this invite.
      const fs30Resp = await fs30Writer(
        "n",
        window.G_bi2_data.IV_loggedin_user_info.firebase_uid,
        employeeRecord.doc_data.employee_uid,
        invite.recycler_location_uid,
        invite.recycler_business_uid
      )

      if (fs30Resp.success == false) {
        this.$awn.alert("There was an issue accepting the invite. Please reload the page and try again.")
        return;
      }

      // Remove the fs5 and fs71 invite information
      const fs76Resp = await fs76Writer(
        "d",
        window.G_bi2_data.IV_loggedin_user_info.email,
        invite.subcollection_doc_id
      )

      if (fs76Resp.success == false) {
        this.$awn.alert("There was an issue accepting the invite. Please reload the page and try again.")
        return;
      } else {
        location.assign(window.location.origin + "/sales-dashboard");
      }
    },

    logoutUser() {
      window.G_firebase_auth.signOut();
    },

    lazyUpdateActiveStatus: _.debounce(function() {
      
      // Return in case business_uid, recycler_location_uid or employee_uid is not set
      if (!window.G_bi2_data.IV_active_employee_info.business_uid ||
      !window.G_bi2_data.IV_active_employee_info.recycler_location_uid ||
      !window.G_bi2_data.IV_active_employee_info.employee_uid
      ) {
        return;
      }

      fs131Writer(
        "e",
        window.G_bi2_data.IV_active_employee_info.business_uid,
        window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
        window.G_bi2_data.IV_active_employee_info.employee_uid,
        {
          last_activity_time: new Date()
        }
      )
    }, 5000),
  },

  watch: {
    "$route": {
      handler(to, from) {
          this.DV_showHeader = (to.path == '/' || to.path == '/login')
        }, 
        immediate: true
    },

    DV_usersActiveInvitesRef: {
      handler() {
        this.checkAndShowEmplyeeInvite();
      },

      deep: true
    }
  } 
});
</script>

<style lang="less">
@import '~vue-awesome-notifications/dist/styles/style.css';

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
