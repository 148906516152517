import { FirestoreDB } from "./include/creds";
import {
  DocPath,
  DataProblem,
  // isValidRatingNumber,
  // isUrlValid,
  // isEmailValid,
  GetDocResult,
  RemoveDocResult,
} from "./include/misc";

import {
  collection,
  doc,
  setDoc,
  getDoc,
  deleteDoc,
} from "firebase/firestore";

export type fs138AVSverificationPlans = {
  avs_verification_type: number;
};

// We assume that this function is fast and will not block.
export function isValidData(data: fs138AVSverificationPlans): boolean {
  // if (
  //   data.average_consumer_rating !== undefined &&
  //   !isValidRatingNumber(data.average_consumer_rating)
  // ) {
  //   return false;
  // }

  // if (
  //   data.average_employee_rating !== undefined &&
  //   !isValidRatingNumber(data.average_employee_rating)
  // ) {
  //   return false;
  // }

  // if (data.email !== undefined && !isEmailValid(data.email)) {
  //   return false;
  // }

  // if (
  //   data.facebook_profile_url !== undefined &&
  //   !isUrlValid(data.facebook_profile_url)
  // ) {
  //   return false;
  // }

  return true;
}

export async function getDocFrom_fs138AVSverificationPlans(
  fs1_id: string,
  fs2_id: string,
  fs138_id: string
): Promise<GetDocResult> {
  const result: GetDocResult = {
    debug_data: [],
    return_msg: "fs138AVSverificationPlans:getDocFrom_fs138AVSverificationPlans",
    success: true,
    doc_data: {},
  };

  if (!fs1_id || !fs2_id || !fs138_id) {
    result.success = false;
    result.return_msg += "Invalid function arguments.";
    return await Promise.resolve(result);
  }

  const doc_ref = doc(
    collection(
      FirestoreDB,
      "fs1RecyclerBusiness",
      fs1_id,
      "fs2RecyclerLocation",
      fs2_id,
      "fs138AVSverificationPlans"
    ),
    fs138_id
  );

  const doc_data = (await getDoc(doc_ref)).data();
  if (doc_data === undefined) {
    result.success = false;
    result.return_msg +=
      "Could not find a document with the passed-in path arguments.";
    return await Promise.resolve(result);
  }

  result.doc_data = doc_data;
  return await Promise.resolve(result);
}

export async function addDocIn_fs138AVSverificationPlans(
  fs1_id: string,
  fs2_id: string,
  fs138_id: string,
  data: fs138AVSverificationPlans
): Promise<DocPath> {
  const result: DocPath = {
    id: "",
    path: "",
  };
  if (!isValidData(data)) {
    return await Promise.resolve(result);
  }

  const col_ref = collection(
    FirestoreDB,
    "fs1RecyclerBusiness",
    fs1_id,
    "fs2RecyclerLocation",
    fs2_id,
    "fs138AVSverificationPlans"
  )
  const doc_ref = doc(col_ref, fs138_id);

  await setDoc(doc_ref, data);

  result.id = doc_ref.id;
  result.path = doc_ref.path;
  return await Promise.resolve(result);
}

export async function setDocIn_fs138AVSverificationPlans(
  fs1_id: string,
  fs2_id: string,
  fs138_id: string,
  data: fs138AVSverificationPlans
): Promise<DocPath> {
  const result: DocPath = {
    id: "",
    path: "",
  };
  if (!isValidData(data)) {
    return await Promise.resolve(result);
  }

  const doc_ref = doc(
    collection(
      FirestoreDB,
      "fs1RecyclerBusiness",
      fs1_id,
      "fs2RecyclerLocation",
      fs2_id,
      "fs138AVSverificationPlans"
    ),
    fs138_id
  );

  await setDoc(doc_ref, data);

  result.id = doc_ref.id;
  result.path = doc_ref.path;
  return await Promise.resolve(result);
}

export async function removeDocFrom_fs138AVSverificationPlans(
  fs1_id: string,
  fs2_id: string,
  fs138_id: string
): Promise<RemoveDocResult> {
  const result: RemoveDocResult = {
    debug_data: [],
    return_msg: "fs138AVSverificationPlans:removeDocFrom_fs138AVSverificationPlans ",
    success: true,
  };

  if (!fs1_id || !fs2_id || !fs138_id) {
    result.success = false;
    result.return_msg += "Invalid function arguments.";
    return await Promise.resolve(result);
  }

  const doc_ref = doc(
    collection(
      FirestoreDB,
      "fs1RecyclerBusiness",
      fs1_id,
      "fs2RecyclerLocation",
      fs2_id,
      "fs138AVSverificationPlans"
    ),
    fs138_id
  );

  await deleteDoc(doc_ref);
  return await Promise.resolve(result);
}
