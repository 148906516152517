/* eslint-disable */
import { ajax } from "noquery-ajax";
import { httpsLogger } from "../p1s7/p1s7e1";

interface Arguments {
  recycler_uid: string;
  recycler_location_uid: string;
  first_name: string;
  last_name: string;
  email?: string;
  address_line_1?: string;
  address_line_2?: string;
  postal_code: string;
  state?: string;
  city?: string;
  primary_phone_number: string;
  landline_numbers?: string;
  customer_types?: string;
  facebook_url?: string;
  assigned_employee: string
}

function createConsumer({
  recycler_uid,
  recycler_location_uid,
  first_name,
  last_name,
  email,
  address_line_1,
  address_line_2,
  postal_code,
  state,
  city,
  primary_phone_number,
  landline_numbers,
  customer_types,
  facebook_url,
  assigned_employee
}: Arguments) {
  return new Promise(function (resolve, reject) {
    let params = {
      firebase_uid: window.G_firebase_auth.IV_uid,
      firebase_token: window.G_firebase_auth.IV_id_token,
      firebase_email: window.G_firebase_auth.IV_email_address,
      recycler_uid: recycler_uid,
      recycler_location_uid: recycler_location_uid,
      p1s3e26_phone_number: primary_phone_number,
      p1s3e26_first_name: first_name,
      p1s3e26_last_name: last_name,
      employee_uid: assigned_employee,
      ...(landline_numbers && { p1s3e26_landline_numbers: landline_numbers }),
      ...(customer_types && { p1s3e26_customer_types: customer_types }),
      ...(email && { p1s3e26_email: email }),
      ...(facebook_url && { p1s3e26_facebook_profile_url: facebook_url }),
      ...(city && { p1s3e26_city: city }),
      ...(state && { p1s3e26_state: state }),
      ...(postal_code && { p1s3e26_postal_code: postal_code }),
      ...(address_line_1 && { p1s3e26_address_line_1: address_line_1 }),
      ...(address_line_2 && { p1s3e26_address_line_2: address_line_2 }),
    };

    ajax({
      url: window.G_cloud_p1s3_base_url + "p1s3e26CreateConsumer",
      headers: {
        Accept: "application/json",
      },
      method: "POST",
      dataType: "json",
      data: params,
      success: function (result) {
        let response_data = {
          success: true,
          response: result,
          request_params: params
        }
        resolve(response_data);
      },
      error: function (error) {
        let error_data = {
          success: false,
          request_object: error,
          request_params: params,
          file_name: "p1s3e26.ts",
          class_name: "p1s3e26",
          method_name: "createConsumer",
          error_title: "Failed to create a new Consumer",
          error_description: "The server responded with an error when trying to create a new Consumer"
        };

        httpsLogger(error_data);
        reject(error_data);
      },
    });
  });
}

export default createConsumer;
