/* eslint-disable */
import { ajax } from "noquery-ajax";
import { httpsLogger } from "../p1s7/p1s7e1";

function requestLocationCandidates(
  recycler_uid: string,
  recycler_location_uid: string,
) {
  return new Promise(function (resolve, reject) {
    let params = {
      firebase_uid: window.G_firebase_auth.IV_uid,
      firebase_token: window.G_firebase_auth.IV_id_token,
      recycler_uid: recycler_uid,
      recycler_location_uid: recycler_location_uid
    };

    ajax({
      url: window.G_cloud_p1s3_base_url + "p1s3e40RequestLocationCandidates",
      headers: {
        Accept: "application/json",
      },
      method: "POST",
      dataType: "json",
      data: params,
      success: function (result) {
        let response_data = {
          success: true,
          response: result,
          request_params: params
        }
        resolve(response_data);
      },
      error: function (error) {
        let error_data = {
          success: false,
          request_object: error,
          request_params: params,
          file_name: "p1s3e40.ts",
          class_name: "p1s3e40",
          method_name: "requestLocationCandidates",
          error_title: "Failed to request location candidates.",
          error_description: "The server responded with an error when requesting for location candidates.",
        };

        httpsLogger(error_data);
        reject(error_data);
      },
    });
  });
}

export default requestLocationCandidates;
