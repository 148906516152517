<template>

  <!-- Customers filters  -->
  <div id="p1s3c5" class="p-3 overflow-y-scroll h-[62vh] hide-scrollbar">
    <h1 class="text-lg font-bold text-center">Filters</h1>

    <!-- Business filter -->
    <div class="text-center my-4 items-center">
      <label class="font-medium text-sm block" for="business">Business Associations</label>
      <select
        id="business"
        name="business"
        class="dropdown-select w-[70%] mx-auto"
        @change="$emit('event-customer-business-filter-selected', $event.target.value)"
      >
        <option>Any</option>
        <option v-for="buss in businessesList" :key="buss.uid">
          {{buss.name}}
        </option>
      </select>
    </div>

    <div class="grid grid-cols-2 gap-4 items-center">

      <!-- City filter -->
      <div class="text-center">
        <label class="font-medium text-sm" for="city">City</label>
        <select id="city" name="city" class="dropdown-select" @change="selectCustomerCityFilter($event.target.value)">
          <option v-for="city in citiesList">
            {{city}}
          </option>
        </select>
      </div>

      <!-- State filter -->
      <div class="text-center">
        <label class="font-medium text-sm" for="state">State</label>
        <select id="state" name="state" class="dropdown-select" @change="selectCustomerStateFilter($event.target.value)"
        >
          <option v-for="state in statesList">
            {{ state }}
          </option>
        </select>
      </div>
    </div>

    <!-- Customer type filter -->
    <div class="my-4">
      <h1 class="text-sm font-medium text-left mb-2">Customer Type</h1>
      <span class="flex flex-row flex-wrap justify-between lg:whitespace-nowrap gap-4">
        <span class="flex items-center">
          <input type="checkbox" id="customer-type-none-specified" value="none-specified" v-model="DV_customersTypeFilter" class="cursor-pointer">
          <label for="customer-type-all" class="ml-2 cursor-pointer">None Specified</label>
        </span>

        <span class="flex items-center">
          <input type="checkbox" id="body-shop" value="body-shop" v-model="DV_customersTypeFilter" class="cursor-pointer">
          <label for="body-shop" class="ml-2 cursor-pointer">Body Shop</label>
        </span>

        <span class="flex items-center">
          <input type="checkbox" id="exporter" value="exporter" v-model="DV_customersTypeFilter" class="cursor-pointer">
          <label for="exporter" class="ml-2 cursor-pointer">Exporter</label>
        </span>

        <span class="flex items-center">
          <input type="checkbox" id="insurance-adjuster" value="insurance-adjuster" name="insurance-adjuster" v-model="DV_customersTypeFilter" class="cursor-pointer">
          <label for="insurance-adjuster" class="ml-2 cursor-pointer">Insurance Adjuster</label>
        </span>

        <span class="flex items-center">
          <input type="checkbox" id="mechanic" value="mechanic" v-model="DV_customersTypeFilter" class="cursor-pointer">
          <label for="mechanic" class="ml-2 cursor-pointer">Mechanic</label>
        </span>

        <span class="flex items-center">
          <input type="checkbox" id="reseller" value="reseller" v-model="DV_customersTypeFilter" class="cursor-pointer">
          <label for="reseller" class="ml-2 cursor-pointer">Reseller</label>
        </span>

        <span class="flex items-center">
          <input type="checkbox" id="retail" value="retail" v-model="DV_customersTypeFilter" class="cursor-pointer">
          <label for="retail" class="ml-2 cursor-pointer">Retail</label>
        </span>
      </span>
    </div>

    <!-- Customer rating filter -->
    <div class="my-4">
      <h1 class="text-sm font-medium text-left mb-2">Customer Rating</h1>
      <span class="flex justify-between">
        <span class="flex items-center">
          <input type="checkbox" id="customer-rating-none-specified" value="none-specified" v-model="DV_customersRatingFilter" class="cursor-pointer">
          <label for="customer-rating-none-specified" class="ml-2 cursor-pointer">None Specified</label>
        </span>

        <span class="flex items-center">
          <input type="checkbox" id="amazing" value="amazing" v-model="DV_customersRatingFilter" class="cursor-pointer">
          <label for="amazing" class="ml-2 cursor-pointer">Amazing</label>
        </span>

        <span class="flex items-center">
          <input type="checkbox" id="average" value="average" v-model="DV_customersRatingFilter" class="cursor-pointer">
          <label for="average" class="ml-2 cursor-pointer">Average</label>
        </span>
      </span>

      <span class="flex justify-between">
        <span class="flex items-center">
          <input type="checkbox" id="bad" value="bad" v-model="DV_customersRatingFilter" class="cursor-pointer">
          <label for="bad" class="ml-2 cursor-pointer">Bad</label>
        </span>

        <span class="flex items-center">
          <input type="checkbox" id="below-average" value="below-average" v-model="DV_customersRatingFilter" class="cursor-pointer">
          <label for="below-average" class="ml-2 cursor-pointer">Below Average</label>
        </span>

        <span class="flex items-center">
          <input type="checkbox" id="good" value="good" v-model="DV_customersRatingFilter" class="cursor-pointer">
          <label for="good" class="ml-2 cursor-pointer">Good</label>
        </span>
      </span>
    </div>

    <!-- Interaction filter -->
    <div v-if="DV_showUnfinishedFeatures" class="my-5">
      <h1 class="text-sm font-medium text-left mb-2">
        Last Completed Interaction
      </h1>

      <span class="isolate inline-flex rounded-md shadow-sm w-full mb-2">
        <button type="button" class="filter-button left" :class="{'active': DV_lastInteractionFilter == 'all'}" @click="selectLastInteractionFilter('all')">
          All Time
        </button>
        <button type="button" class="filter-button center" :class="{'active': DV_lastInteractionFilter == '8-hours'}" @click="selectLastInteractionFilter('8-hours')">
          8 Hours
        </button>
        <button type="button" class="filter-button center" :class="{'active': DV_lastInteractionFilter == '3-days'}" @click="selectLastInteractionFilter('3-days')">
          3 Days
        </button>
        <button type="button" class="filter-button right" :class="{'active': DV_lastInteractionFilter == '1-week'}" @click="selectLastInteractionFilter('1-week')">
          1 Week
        </button>
      </span>

      <span class="isolate inline-flex rounded-md shadow-sm w-full mb-2">
        <button type="button" class="filter-button left" :class="{'active': DV_lastInteractionFilter == '1-month'}" @click="selectLastInteractionFilter('1-month')">
          1 Month
        </button>
        <button type="button" class="filter-button center" :class="{'active': DV_lastInteractionFilter == '3-month'}" @click="selectLastInteractionFilter('3-month')">
          3 Month
        </button>
        <button type="button" class="filter-button center" :class="{'active': DV_lastInteractionFilter == '1-year'}" @click="selectLastInteractionFilter('1-year')">
          1 Year
        </button>
        <button type="button" class="filter-button right" :class="{'active': DV_lastInteractionFilter == 'custom-range'}" @click="selectLastInteractionFilter('custom-range')">
          Custom Range
        </button>
      </span>
    </div>
  </div>
</template>

<script>
import _ from "lodash"

export default {
  name: "p1s3c5CustomerFilters",

  props: ["businessesList", "salesEmployeesList", "citiesList", "statesList"],

  data() {
    return {
      DV_zipCode: "",
      DV_customersTypeFilter: ["none-specified", "exporter", "insurance-adjuster", "retail", "body-shop", "mechanic", "reseller"],
      DV_customersRatingFilter: ["none-specified", "bad", "below-average", "average", "good", "amazing"],
      DV_lastInteractionFilter: "all",
      DV_showUnfinishedFeatures: window.G_show_unfinished_features,

    };
  },

  methods: {
    selectLastInteractionFilter(filter) {
      this.DV_lastInteractionFilter = filter;
      this.$emit("event-last-interaction-filter-selected", filter)
    },

    selectCustomerCityFilter(filter) {
      this.$emit("event-customer-city-filter-selected", filter)
    },

    selectCustomerStateFilter(filter) {
      this.$emit("event-customer-state-filter-selected", filter)
    }
  },

  watch: {
    DV_customersTypeFilter() {
      this.$emit("event-customer-type-filter-selected", this.DV_customersTypeFilter);
    },

    DV_customersRatingFilter() {
      this.$emit("event-customer-rating-filter-selected", this.DV_customersRatingFilter);
    }
  }
};
</script>
