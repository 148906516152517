<template>
  <div id="p3s2">
    <div>
    <h3 class="-mt-8 account-setup-step-heading">Enter Card Details</h3>
    <p 
    v-if="DV_activeRenderMode > DV_renderModeTablet"
     style="margin-bottom: 0px; margin-top: 5px;" class="text-base pl-2
     text-left leading-snug text-gray-600">Welcome to our innovative automotive recycling communication platform! Upon subscribing, you'll access exceptional features that transform auto part transactions through text messaging. Streamlined communication channels simplify your connections with recyclers, and our platform's review request feature facilitates easy reputation building. Join us now for a seamless, future-oriented automotive recycling experience!</p>
     <div
     v-else
      class="flex flex-col">
      <span class="text-center">Discover <b>Synergy Auto Solutions</b></span>
      <p class="text-left pl-3 pr-2">Streamline auto part transactions via text.<br> <b>Features:</b> Sales Dashboard, secure transactions, queue & customer management, integrated payments, and social media integration. Committed to continuous improvement. Join for a premier automotive recycling journey!
      </p>
     </div>
    </div>
    
    <div class="mx-auto max-w-2xl lg:mx-0 lg:flex lg:max-w-none">
      <div v-if="DV_activeRenderMode > DV_renderModeTablet"
      class="pr-4 pl-2 lg:flex-auto">
        
        <div class="mt-4 flex items-center gap-x-4">
          <h4 class="flex-none text-sm font-semibold leading-6 text-indigo-600">What’s included</h4>
          <div class="h-px flex-auto bg-gray-100"></div>
        </div>
        
        <ul role="list" class="grid grid-cols-1 gap-3 leading-6 text-gray-600 sm:grid-cols-2">
          <li class="flex gap-x-3 text-left mt-2">
            <svg class="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
            </svg>
            <span class="text-sm"> <b class="mr-2">Sales Dashboard:</b>Empower your sales reps to initiate conversations with customers through our web app, facilitating seamless 1-on-1 communication and driving sales. Enjoy various features designed to enhance sales efforts directly from the Sales Dashboard.</span>
          </li>
          <li class="flex gap-x-3 text-left mt-2">
            <svg class="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
            </svg>
            <span class="text-sm"> <b class="mr-2">Contacts:</b>Access a centralized page within the software to conveniently view and manage all your customer contacts and businesses. Easily initiate conversations, review customer information, and track conversation history.</span>
          </li>
          <li class="flex gap-x-3 text-left mt-2">
            <svg class="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
            </svg>
            <span class="text-sm"> <b class="mr-2">Secure Transaction Processing:</b>Conduct secure transactions via text message with built-in identity theft verification, ensuring peace of mind for both you and your customers.</span>
          </li>
          <li class="flex gap-x-3 text-left mt-2">
            <svg class="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
            </svg>
            <span class="text-sm"> <b class="mr-2">Efficient Queue Management:</b>Streamline your operations by organizing the queue based on customer wait times or ratings, enabling you to prioritize and serve customers effectively.</span>
          </li>
          <li class="flex gap-x-3 text-left mt-2">
            <svg class="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
            </svg>
            <span class="text-sm"> <b class="mr-2">Centralized Customer and Business Information:</b>Store and manage vital customer details such as names, emails, associations, phone numbers, customer types, Facebook URLs, and addresses, ensuring easy access and effective customer relationship management.</span>
          </li>
          <li class="flex gap-x-3 text-left mt-2">
            <svg class="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
            </svg>
            <span class="text-sm"> <b class="mr-2">CRM Capabilities:</b>Utilize Synergy Auto Solutions as a powerful CRM tool, leveraging its messaging component, conversation history, customer feedback, and yard-only chat for efficient customer communication and management.</span>
          </li>
          <li class="flex gap-x-3 text-left mt-2">
            <svg class="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
            </svg>
            <span v-if="C_prefferedPaymentMethod === 'axe'" class="text-sm"> <b class="mr-2">Integrated with Axe Payments:</b>Elevate your Automotive Recycling business operations with seamless integration to Axe Payments. Maintain flexibility and convenience as you continue using your preferred payment solution, ensuring smooth financial transactions every time. Simplify your payment process and offer a streamlined experience to your customers. Choose Axe Payments for trusted, reliable, and efficient payment processing tailored for the Automotive Recycling Industry.</span>
            <span v-else-if="C_prefferedPaymentMethod === 'slimcd'" class="text-sm"> <b class="mr-2">Integrated with SlimCD:</b>Elevate your Automotive Recycling business operations with seamless integration to SlimCD. Maintain flexibility and convenience as you continue using your preferred payment solution, ensuring smooth financial transactions every time. Simplify your payment process and offer a streamlined experience to your customers. Choose SlimCD for trusted, reliable, and efficient payment processing tailored for the Automotive Recycling Industry.</span>
            <span v-else class="text-sm"> <b class="mr-2">Integrated Payment Processors:</b>Seamlessly integrate with payment processors commonly used in the Automotive Recycling Industry, allowing you to continue using your preferred payment processor. Benefit from identity theft verification to minimize fraud during sales transactions.</span>
          </li>
          <li class="flex gap-x-3 text-left mt-2">
            <svg class="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
            </svg>
            <span class="text-sm"> <b class="mr-2">Automated Review Requests:</b>After successful transactions, automatically send review requests to customers, enabling you to build your reputation and gather valuable feedback. Strengthen your business's online presence and enhance customer satisfaction.</span>
          </li>
          <li class="flex gap-x-3 text-left mt-2">
            <svg class="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
            </svg>
            <span class="text-sm"> <b class="mr-2">Website Chat and Facebook Widget Integration:</b>Capture auto part requests directly from your website and Facebook page. Sales reps can manage and respond to these requests by initiating conversations from the Sales Dashboard.</span>
          </li>
          <li class="flex gap-x-3 text-left mt-2">
            <svg class="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
            </svg>
            <span class="text-sm"> <b class="mr-2">Commitment to Industry Excellence:</b>Synergy Auto Solutions is unwaveringly committed to the automotive recycling industry, actively seeking feedback and participating in industry events for continual software improvement. We aim to provide you with cutting-edge tools tailored to your needs, empowering your success in the industry.</span>
          </li>
        </ul>
      </div>

      <div class="sm:mt-8 lg:w-full lg:max-w-md lg:flex-shrink-0">
        <div class="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
          <div class="px-8">
            
            <p class="flex items-center justify-between w-full">
              <span class="text-2xl">Price</span>
              <span>
                <span class="text-4xl font-bold tracking-tight text-gray-900">$170</span>
                <span class="text-sm font-semibold leading-6 tracking-wide text-gray-600">/month</span>
              </span>
            </p>

            <p class="flex items-center justify-end w-full">
              <a class="underline text-blue-500 cursor-pointer" @click.stop="showPricingModal">+ usage</a>
            </p>

            <div class="flex flex-col items-start mt-4 w-full">
              <div class="flex flex-col w-full mt-2">
                <span class="text-left">Full Name: </span>
                <input autocomplete="off" type="text">
              </div>

              <div class="flex flex-col w-full mt-8">
                  <span class="text-left">Card Details:</span> 
                  <div class="w-full block w-full rounded py-1 border border-gray-300 px-3 sm:text-sm" id="card-element"></div>
                  <div id="card-errors" role="alert"></div>
              </div>

              <div 
              class="w-full mt-12 gap-10 flex flex-row justify-center items-center">
                <button :disabled="DV_cardVerificationInProgress" @click.stop="$emit('back')" class="btn btn-lime text-base">Back</button>
                <button :disabled="DV_cardVerificationInProgress" @click.stop="verifyCardOrNextStep" class="btn btn-lime text-base">{{DV_cardVerificationInProgress ? 'Verifying Details...' : 'Subscribe Now'}}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <PopupModal ref="pricingModal" :P_showClose="true" :P_fullModal="true" @close-modal="closePricingModal">
      <div slot="title"> <h3 class="font-bold text-4xl">Pricing</h3> </div>
      <template slot="body">
        <div style="overflow-y: auto; height:80vh;">
        <h3 class="font-bold text-2xl">Overview:</h3>
            <ol class="list-disc pl-10">
              <li class="list-item !mt-2"><span class="font-bold">Single Location: </span>Our service is available for just $170/month for one auto recycler location.</li>
            </ol>
    

            <div 
            :class="{'flex flex-row gap-8 mt-8': DV_activeRenderMode > DV_renderModePhone,
            'flex flex-col' : DV_activeRenderMode < DV_renderModeTablet}"
            >
              <div
              :class="{'w-1/2': DV_activeRenderMode > DV_renderModePhone,
            'flex flex-col' : DV_activeRenderMode < DV_renderModeTablet}"
                class="mt-3"
               >
              <h3 class="font-bold text-2xl">Usage:</h3>
              <p class="mt-4">
                In addition to the base fee, there is also a usage fee for text/video/file storage, MMS text messages, SMS text messages, creating payment links, sending live chat text messages, sending live chat image/video/files, and creating a new business/consumer/employee. The usage fee is computed every 30 days and will be added to your invoice monthly, beginning 30 days post-registration.
              </p>
              <p 
              :class="{'mt-4': DV_activeRenderMode > DV_renderModePhone,
                'mt-0' : DV_activeRenderMode < DV_renderModeTablet}">
                Our platform primarily uses a web application for the bulk of the conversation, saving costs over traditional text messaging. While we start the conversation with text messages, we use them strategically, for example, to remind customers of an unpaid invoice or to request an online review after a successful purchase. This balance between web application and SMS results in significant cost savings.
              </p>
            </div>

            <table 
            :class="{'w-1/2 text-lg': DV_activeRenderMode > DV_renderModePhone,
                'w-full text-xs mt-2 whitespace-pre-line' : DV_activeRenderMode < DV_renderModeTablet}"
            style="table-layout: fixed; width: 100%"
            class="divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" class="py-3.5 pl-4 pr-3 text-left font-semibold text-gray-900">Service</th>
                  <th scope="col" class="px-2 py-3.5 text-left font-semibold text-gray-900">Price per Unit</th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <tr>
                  <td class="py-2 pl-4 pr-3 text-gray-500 whitespace-pre-line">Text Storage</td>
                  <td class="px-2 py-2 text-gray-500 !border-r-0">$0.27/gigabyte</td>
                </tr>
  
                <tr>
                  <td class="py-2 pl-4 pr-3 text-gray-500 whitespace-pre-line">Image/Video/File Storage</td>
                  <td class="px-2 py-2 text-gray-500 !border-r-0">$0.03/gigabyte</td>
                </tr>
  
                <tr>
                  <td class="py-2 pl-4 pr-3 text-gray-500 whitespace-pre-line">MMS Text Messages</td>
                  <td class="px-2 py-2 text-gray-500 !border-r-0">$0.03/message</td>
                </tr>
  
                <tr>
                  <td class="py-2 pl-4 pr-3 text-gray-500 whitespace-pre-line">SMS Text Messages</td>
                  <td class="px-2 py-2 text-gray-500 !border-r-0">$0.02/message</td>
                </tr>
  
                <tr>
                  <td class="py-2 pl-4 pr-3 text-gray-500 whitespace-pre-line">Creating Payment Links</td>
                  <td class="px-2 py-2 text-gray-500 !border-r-0">$0.0045/link</td>
                </tr>
  
                <tr>
                  <td class="py-2 pl-4 pr-3 text-gray-500 whitespace-pre-line">Sending Live Chat Text Messages</td>
                  <td class="px-2 py-2 text-gray-500 !border-r-0">$0.00045/message</td>
                </tr>
  
                <tr>
                  <td class="py-2 pl-4 pr-3 text-gray-500 whitespace-pre-line">Sending Live Chat Images/Videos/Files</td>
                  <td class="px-2 py-2 text-gray-500 !border-r-0">$0.00045/message</td>
                </tr>
  
                <tr>
                  <td class="py-2 pl-4 pr-3 text-gray-500 whitespace-pre-line">Creating New Business/<br v-if="DV_activeRenderMode < DV_renderModeTablet">Consumer/Employee</td>
                  <td class="px-2 py-2 text-gray-500 !border-r-0">$0.00075/contact</td>
                </tr>
                <tr>
                  <td class="py-2 pl-4 pr-3 text-gray-500 whitespace-pre-line">Verifying A Customer's<br v-if="DV_activeRenderMode < DV_renderModeTablet"> Identity</td>
                  <td class="px-2 py-2 text-gray-500 !border-r-0">$1.75/verification"</td>
                </tr>
  
              </tbody>
            </table>

            
          </div>
        </div>
        </template>
    </PopupModal>
  </div>

</template>

<script>
import PopupModal from "../shared/PopupModal.vue"
import m3RenderingSettings from "../../mixins/m3RenderingSettings"
import verifyCardDetails from "../../includes/server_calls/p1s7/p1s7e3"
import { reactive } from "vue";
import AWN from "awesome-notifications";

  export default {
    name: "p3s2AccountSetupPaymentDetails",

    mixins: [ m3RenderingSettings ],

    components: { PopupModal },

    props: ['P_loggedInFlag','P_formData'],

    data() {
      return {
        stripe: null,
        cardElement: null,
        DV_cardVerificationInProgress: false,
        cardStyle: {
          iconStyle: 'solid',
          style: {
            base: {
              fontSmoothing: 'antialiased',
              '::placeholder': {
                color: "#bbb"
              },
            },
          },
        },
        DV_states: [],
        DV_paymentActionType: 1
      }
    },

    mounted() {
      this.includeStripe('js.stripe.com/v3/', function() {
        this.populateStripeElement();
      }.bind(this) );
      setTimeout(function() {
        document.getElementById("p3s2").scrollIntoView();
      }, 100);
    },

    methods: {
      includeStripe( URL, callback ){
        let documentTag = document, tag = 'script',
          object = documentTag.createElement(tag),
          scriptTag = documentTag.getElementsByTagName(tag)[0];
        
        object.src = '//' + URL;
        
        if (callback) { object.addEventListener('load', function (e) { callback(null, e); }, false); }
        scriptTag.parentNode.insertBefore(object, scriptTag);
      },

      populateStripeElement() {
        // We only get stripe key if user has permissions to get payments

        this.stripe = Stripe("pk_live_51MAej2LhOYag7rNDyo0sUg6zBxUMbE0JxuHvFL36VNOGdcvA84UOaFJrYHZmqZpwHyv2tCjaynsSV7tWIXevNBsx00Dlx2q5T8");
        var elements = this.stripe.elements();
        this.cardElement = elements.create('card', this.cardStyle);
        this.cardElement.mount('#card-element',);
      },

      showPricingModal() {
        this.$refs.pricingModal.show();
      },

      closePricingModal() {
        this.$refs.pricingModal.hide();
      },

      verifyCardOrNextStep() {
        const CI = this;

        if (CI.P_loggedInFlag &&
          CI.P_formData.businessInfo.businessUid !== null &&
          CI.P_formData.businessInfo.businessUid !== ''
        ) {
          CI.DV_paymentActionType = 2
          CI.verifyCard()
          return;
        }

        CI.$emit('next');
      },

      verifyCard() {
        const CI = this;
        CI.DV_cardVerificationInProgress = true;

        if (window.G_trial_token.length > 0) {
          CI.DV_paymentActionType = 3;
          CI.stripeTokenHandler({id: window.G_trial_token});
        } else {  
          CI.stripe.createToken(CI.cardElement).then(function(result) {
            if (result.error) {
              CI.$awn.alert("There was an issue verifying the card details. Please make sure you are typing in the correct details.");
              CI.DV_cardVerificationInProgress = false;
              CI.$emit("payment-failed",result);
            } else {
              // Send the token to your server
              CI.stripeTokenHandler(result.token);
            }
          });
        }
      },

      stripeTokenHandler(token) {
        const CI = this;

        let error_text = "There was an issue verifying the card details. Please make sure you are typing in the correct details.";

        verifyCardDetails(
         token.id,
         CI.DV_paymentActionType,
         CI.P_formData.businessInfo.businessUid,
         window.G_trial_token
        ).then((resp) => {
          // Success
          console.log(resp)
          if (resp.success === true) {
            CI.DV_cardVerificationInProgress = false;
            CI.$emit("payment-success");
            return;
          }

          // Fail
          CI.DV_cardVerificationInProgress = false;
          if (resp.msg) { error_text = resp.msg };
          CI.$awn.alert(error_text);
          CI.$emit("payment-failed",resp);
        }).catch((error) => {
          // Fail
          CI.DV_cardVerificationInProgress = false;
          if (error.error_msg) { error_text = error.error_msg };
          CI.$awn.alert(error_text);
          CI.$emit("payment-failed",error);
        });
      }
    },

    computed: {
      C_prefferedPaymentMethod() {
        if (window.location.pathname.includes('axe')) {
          return "axe";
        }

        if (window.location.pathname.includes('slimcd')) {
          return "slimcd";
        }

        return ""
      }
    }
  }
</script>