/* eslint-disable */
import { ajax } from "noquery-ajax";
import { httpsLogger } from "../p1s7/p1s7e1";

interface Arguments {
  recycler_uid: string;
  recycler_location_uid: string;
  consumer_contact_uid: string;
  conversation_uid: string;
  fs36_doc_id: string;
}

function processRefundForAxe( {
  recycler_uid,
  recycler_location_uid,
  conversation_uid,
  consumer_contact_uid,
  fs36_doc_id,
}: Arguments) {
  return new Promise(function (resolve, reject) {
    let params = {
      firebase_uid: window.G_firebase_auth.IV_uid,
      firebase_token: window.G_firebase_auth.IV_id_token,
      firebase_email: window.G_firebase_auth.IV_email_address,
      recycler_uid: recycler_uid,
      recycler_location_uid: recycler_location_uid,
      consumer_contact_uid: consumer_contact_uid,
      conversation_uid: conversation_uid,
      p1s3e54_fs36_doc_id: fs36_doc_id,
    };

    ajax({
      url: window.G_cloud_p1s3_base_url + "p1s3e54ProcessRefundForAxe",
      headers: {
        Accept: "application/json",
      },
      method: "POST",
      dataType: "json",
      data: params,

      success: function (result) {
        let response_data = {
          success: true,
          response: result,
          request_params: params
        }
        resolve(response_data);
      },

      error: function (error) {
        let error_data = {
          success: false,
          request_object: error,
          request_params: params,
          file_name: "p1s3e54.ts",
          class_name: "p1s3e54",
          method_name: "processRefundForAxe",
          error_title: "Failed to refund payment",
          error_description:
            "The server responded with an error when trying to refund a payment",
        };

        httpsLogger(error_data);
        reject(error_data);
      }
    });
  });
}

export default processRefundForAxe;
