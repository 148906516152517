import { fs139RecyclerConversationStarters } from "../firestore_schemas/fs139RecyclerConversationStarters"
import { addDocIn_fs139RecyclerConversationStarters } from "../firestore_schemas/fs139RecyclerConversationStarters"
import { getDocFrom_fs139RecyclerConversationStarters } from "../firestore_schemas/fs139RecyclerConversationStarters"
import { setDocIn_fs139RecyclerConversationStarters } from "../firestore_schemas/fs139RecyclerConversationStarters"
import { removeDocFrom_fs139RecyclerConversationStarters } from "../firestore_schemas/fs139RecyclerConversationStarters"
import { setValueOfOptionalFields } from "../firestore_writers/include/general"

type Result = {
  debug_data: Array<string>;
  return_msg: string;
  success: boolean;
  error_title: string;
  error_summary: string;
  error_next_steps: string;
};

export async function fs139Writer(
  w_type: string,
  fs1_id: string,
  fs2_id: string,
  fs139_id: string,
  data: any
) {

  const retval: Result = {
    debug_data: [],
    return_msg: "fs139_writer:fs139Writer ",
    success: true,
    error_title: "",
    error_summary: "",
    error_next_steps: "",
  };

  /// Validate arguments according to write type
  if (!fs1_id || !fs2_id) {
    retval.success = false;
    retval.return_msg += "Insufficient arguments.";
    retval.error_title = "Insufficient arguments.";
    retval.error_summary = "business_uid or recycler_uid not set.";
    retval.error_next_steps = "Please provide correct business_uid snd recycler_uid.";
    return retval;
  }

  /// <END> Validate arguments according to write type

  // Delete record
  if (w_type == "d") {
    await removeDocFrom_fs139RecyclerConversationStarters(fs1_id, fs2_id, fs139_id)
    return retval
  }

  // Update existing record
  if (w_type == "e") {
    let response_data = await getDocFrom_fs139RecyclerConversationStarters(
      fs1_id,
      fs2_id,
      fs139_id,
    )

    if (response_data.success === false) {
      retval.success = false;
      retval.return_msg += "Record not found.";
      retval.error_title = "Record not found.";
      retval.error_summary = "fs139 record not found in firestore.";
      retval.error_next_steps = "Please reload page and try again.";
      return retval;
    }
    const fs139Data: fs139RecyclerConversationStarters = {
      subject: data.subject || response_data['doc_data'].subject || "",
      body: data.body || response_data['doc_data'].body || "",
    } as fs139RecyclerConversationStarters;

    let response = await setDocIn_fs139RecyclerConversationStarters(
      fs1_id,
      fs2_id,
      fs139_id,
      fs139Data
    )

    if (!response.id || response.id == "" ) {
      retval.success = false;
      retval.return_msg += "Firestore write error.";
      retval.error_title = "Firestore write error.";
      retval.error_summary = "fs139 record not updated in firestore.";
      retval.error_next_steps = "Please reload page and try again.";
      return retval;
    }

    return retval
  }

  
  if (w_type === "n") {
    const fs139Data: fs139RecyclerConversationStarters = {
      subject: data.subject || "",
      body: data.body || "",
    } as fs139RecyclerConversationStarters;

    let response = await addDocIn_fs139RecyclerConversationStarters(
      fs1_id,
      fs2_id,
      fs139_id,
      fs139Data
    )
    if (!response.id || response.id == "" ) {
      retval.success = false;
      retval.return_msg += "Firestore write error.";
      retval.error_title = "Firestore write error.";
      retval.error_summary = "fs139 record not created in firestore.";
      retval.error_next_steps = "Please reload page and try again.";
      return retval;
    }

    return retval
  }
  // TODO - handle new operations
}


function firestoreWriteError(error:any) {
  // TODO - complete this
}
