<template>
  <div id="p1s2c7" class="p-3">

    <!-- Customer type filters -->
    <div class="">
      <h1 class="text-sm font-medium text-left mb-2">Customer Type</h1>
      <span class="isolate inline-flex rounded-md shadow-sm w-full mb-2">
        <button type="button" class="filter-button left">
          Mechanic
        </button>
        <button type="button" class="filter-button">
          Body Shop
        </button>
        <button type="button" class="filter-button right">
          Reseller
        </button>
      </span>

      <span class="isolate inline-flex rounded-md shadow-sm w-full mb-2">
        <button type="button" class="filter-button left">
          Retail
        </button>
        <button type="button" class="filter-button">
          Insurance Adjuster
        </button>
        <button type="button" class="filter-button right">
          Exporter
        </button>
      </span>
    </div>

    <!-- Customer rating filters -->
    <div class="my-5">
      <h1 class="text-sm font-medium text-left mb-2">Customer Rating</h1>
      <span class="isolate inline-flex rounded-md shadow-sm w-full mb-2">
        <button type="button" class="filter-button left">
          Any
        </button>
        <button type="button" class="filter-button">
          Amazing
        </button>
        <button type="button" class="filter-button right">
          Good
        </button>
      </span>

      <span class="isolate inline-flex rounded-md shadow-sm w-full mb-2">
        <button type="button" class="filter-button left">
          Average
        </button>
        <button type="button" class="filter-button">
          Below Average
        </button>
        <button type="button" class="filter-button right">
          Bad
        </button>
      </span>
    </div>

    <!-- Last interaction filter -->
    <div class="my-5">
      <h1 class="text-sm font-medium text-left mb-2">Last Interaction</h1>
      <span class="isolate inline-flex rounded-md shadow-sm w-full mb-2">
        <button type="button" class="filter-button left">
          All Time
        </button>
        <button type="button" class="filter-button">
          8 Hours
        </button>
        <button type="button" class="filter-button">
          3 Days
        </button>
        <button type="button" class="filter-button right">
          1 Week
        </button>
      </span>

      <span class="isolate inline-flex rounded-md shadow-sm w-full mb-2">
        <button type="button" class="filter-button left">
          1 Month
        </button>
        <button type="button" class="filter-button">
          3 Month
        </button>
        <button type="button" class="filter-button">
          1 Year
        </button>
        <button type="button" class="filter-button right">
          Custom Range
        </button>
      </span>
    </div>

    <!-- Sales persons list -->
    <div class="flex items-center justify-between">
      <label for="salePerson" class="font-medium text-sm text-left mr-1">
        Sales Person:
      </label>
      <div class="w-5/12">
        <select
          id="salePerson"
          name="salePerson"
          class="w-full rounded border border-gray-400 py-1 pl-2 pr-5 text-gray-500 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        >
          <option>All</option>
          <option>Web Widget</option>
          <option>Sales Person Initiated</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "p1s2c7CustomerResponseTimeFilters",

  data() {
    return {
      //
    };
  },
};
</script>
