import { FirestoreDB } from "./include/creds";
import {
  DocPath,
  DataProblem,
  GetDocResult,
  RemoveDocResult,
} from "./include/misc";

import { collection, doc, deleteDoc, setDoc, getDoc } from "firebase/firestore";

export type fs112ConsumerConversationNotifications = {
  type: number;
  timestamp?: Date;
  summary?: string;
  description?: string;
  json_data?: string;
  consumer_conversation_uid: string;
  consumer_uid: string;
  conversation_uid: string;
  payment_link_uid?: string;
  force_mark_unread? :boolean;
};

export async function getDocFrom_fs112ConsumerConversationNotifications(
  fs1_uid: string,
  fs2_uid: string,
  fs112_uid: string
): Promise<GetDocResult> {
  const result: GetDocResult = {
    debug_data: [],
    return_msg:
      "fs112ConsumerConversationNotifications:getDocFrom_fs112ConsumerConversationNotifications",
    success: true,
    doc_data: {},
  };

  if (!fs1_uid || !fs2_uid || !fs112_uid) {
    result.success = false;
    result.return_msg += "Invalid function arguments.";
    return await Promise.resolve(result);
  }

  const doc_ref = doc(
    collection(FirestoreDB, "fs1RecyclerBusiness", fs1_uid, "fs2RecyclerLocation", fs2_uid, "fs112ConsumerConversationNotifications"), fs112_uid
  );

  const doc_data = (await getDoc(doc_ref)).data();
  if (doc_data === undefined) {
    result.success = false;
    result.return_msg +=
      "Could not find a document with the passed-in path arguments.";
    return await Promise.resolve(result);
  }

  result.doc_data = doc_data;
  return await Promise.resolve(result);
}

export async function setDocIn_fs112ConsumerConversationNotifications(
  fs1_uid: string,
  fs2_uid: string,
  fs112_uid: string,
  data: fs112ConsumerConversationNotifications
): Promise<DocPath> {
  
  const result: DocPath = {
    id: "",
    path: "",
  };

  const doc_ref = doc(
    collection(FirestoreDB, "fs1RecyclerBusiness", fs1_uid, "fs2RecyclerLocation", fs2_uid, "fs112ConsumerConversationNotifications"), fs112_uid
  );

  await setDoc(doc_ref, data);

  result.id = doc_ref.id;
  result.path = doc_ref.path;
  return await Promise.resolve(result);
}

export async function removeDocFrom_fs112ConsumerConversationNotifications(
  fs1_uid: string,
  fs2_uid: string,
  fs112_uid: string
): Promise<RemoveDocResult> {
  
  const result: RemoveDocResult = {
    debug_data: [],
    return_msg:
      "fs112ConsumerConversationNotifications:removeDocFrom_fs112ConsumerConversationNotifications ",
    success: true,
  };

  if (!fs1_uid || !fs2_uid || !fs112_uid) {
    result.success = false;
    result.return_msg += "Invalid function arguments.";
    return await Promise.resolve(result);
  }

  const doc_ref = doc(
    collection(FirestoreDB, "fs1RecyclerBusiness", fs1_uid, "fs2RecyclerLocation", fs2_uid, "fs112ConsumerConversationNotifications"),
    fs112_uid
  );

  await deleteDoc(doc_ref);
  return await Promise.resolve(result);
}