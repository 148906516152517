<template>
  <div id="p1s1c2c4">
    <div v-show="DV_showHistoryEntry == false" class="mx-2">

      <!-- Conversation info -->
      <div class="grid grid-cols-7 gap-2 px-2 items-center cursor-pointer border-b">
        <div class="col-span-1 h-full items-start">
          <h1 class="font-bold text-lg text-left">B Q</h1>
        </div>
        <div class="col-span-6">
          <p class="text-left text-sm my-1">
            John Miller | Downtown Repair | Body Shop | Quotes: 12435653($1000)
            132456($100)
          </p>
        </div>
      </div>

      <!-- Heading -->
      <div class="flex flex-row items-center justify-between my-3">
        <svg @click="returnToParentInfo" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"/>
        </svg>
        <h1 class="font-bold text-sm">Payment Link History</h1>
        <svg @click="returnToParentInfo" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"/>
        </svg>
      </div>

      <!-- Search area  -->
      <SearchBar containerClasses="my-2" inputClasses="shadow-sm" />

      <!-- User payment history -->
      <div class="border border-gray-400 rounded-xl p-2">
        <div class="border border-gray-400 rounded-xl pb-2 overflow-y-scroll">
          <div
            v-for="index in 7"
            :key="index + Math.random()"
            @click="toggleMessages(index)"
            :class="{ active: DV_userClick[index] == true }"
            class="border-b border-gray-400 p-2 text-left"
          >
            <p class="font-medium text-sm">
              Honda Civic 2003 <span>Alternator</span> <span>$100</span>
              <span>Pending</span>, Created on <span>09/10/2022</span>
            </p>
            <div v-if="DV_userClick[index] == true">
              <button @click.stop="DV_showHistoryEntry = true" class="btn btn-lime px-3 my-2">
                View Conversation
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <c1c7ConversationHistoryEntry
      v-show="DV_showHistoryEntry == true"
      @returnToParent="DV_showHistoryEntry = false"
      @paymentLinkHistory="DV_showHistoryEntry = false"
    />
  </div>
</template>

<script>
import c1c7ConversationHistoryEntry from "../c1/c1c7ConversationHistoryEntry.vue";
import SearchBar from "../shared/SearchBar.vue"

export default {
  name: "p1s1c2c4PaymentLinksHistory",

  props: ["customerInformation"],

  components: { c1c7ConversationHistoryEntry, SearchBar },

  data() {
    return {
      DV_userClick: {
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
        7: false,
      },
      DV_showHistoryEntry: false,
      DV_customerInfo: this.customerInformation,
    };
  },

  methods: {
    toggleMessages(index) {
      this.DV_userClick[index] = !this.DV_userClick[index];
    },

    returnToParentInfo() {
      if (this.DV_customerInfo == true) {
        this.$emit("show-customer-information");
      }

      if (this.DV_customerInfo == undefined) {
        this.$emit("show-more-actions");
      }
    },
  },
};
</script>
