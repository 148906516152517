/* eslint-disable */
import { CR, RC } from './bi2SuccessCodes.js'
import { usaAreaCodes } from './us_area_codes.js'
import { tollFreeNumberCodes } from './us_area_codes.js'

class bi1DataValidation {

  public static is_email_address(email_address: any) {
    let debug_data:any = [];
    var return_msg = "bi1DataValidation:email_address ";

    if (typeof email_address !== 'string') {
      return_msg += "email address should be a string but this value is a '" + typeof email_address + "'";
      return { 'success': RC.input_validation_failed, 'return_msg': return_msg, 'debug_data': debug_data };
    };

    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)$/.test(email_address) !== true) {
      return_msg += "email address failed regexp check. email:'" + email_address + "'";
      return { 'success': RC.input_validation_failed, 'return_msg': return_msg, 'debug_data': debug_data };
    } else {
      return { 'success': RC.success, 'return_msg': return_msg, 'debug_data': debug_data };
    }
  };

  public static is_string(value: any, min_length?: number, max_length?: number) {
    let debug_data:any = [];
    var return_msg = "bi1DataValidation:is_string ";

    if (typeof value !== 'string') {
      return_msg += "string should be a string but this value is a '" + typeof value + "'. value:" + JSON.stringify(value);
      return { 'success': RC.input_validation_failed, 'return_msg': return_msg, 'debug_data': debug_data };
    };

    if (typeof min_length === 'number' && value.length < min_length) {
      return_msg += "string should more than " + min_length.toString() + " in length, however its " + value.length + ". value:" + typeof value;
      return { 'success': RC.input_validation_failed, 'return_msg': return_msg, 'debug_data': debug_data };
    }

    if (typeof max_length === 'number' && value.length > max_length) {
      return_msg += "string should less than " + max_length.toString() + " in length, however its " + value.length + ". value:" + typeof value;
      return { 'success': RC.input_validation_failed, 'return_msg': return_msg, 'debug_data': debug_data };
    }
    return { 'success': RC.success, 'return_msg': return_msg, 'debug_data': debug_data };
  }


  public static is_string_number(value:any, include_float:any, including_negative=false) {
    let debug_data:any = [];
    var return_msg = "bi1DataValidation:is_string_number ";
    let call_result:any = {};

    //verify its a string
    call_result = bi1DataValidation.is_string(value);
    debug_data.push(call_result);
    if (call_result[CR.success] !== RC.success) {
      return { 'success': call_result[CR.success], 'return_msg': return_msg, 'debug_data': debug_data };
    }
    
    if (typeof include_float !== "undefined") {
      if (typeof include_float !== "boolean") {
        return_msg += "argument include_float should be boolean; instead it is '" + typeof include_float + "'.";
        return {'success': RC.input_validation_failed, 'return_msg': return_msg, 'debug_data': debug_data};
      }
      value = value.replace(".", "")
    }
    
    if (typeof including_negative !== "undefined") {
      if (typeof including_negative !== "boolean") {
        return_msg += "argument including_negative should be boolean; instead it is '" + typeof include_float + "'.";
        return {'success': RC.input_validation_failed, 'return_msg': return_msg, 'debug_data': debug_data};
      }
      value = value.replace("-", "")
    }

    if (/^\d+$/.test(value) !== true){
      return_msg += "string should be all numbers " +
        (include_float ? "with max 1 point " : "") +
        "but it is '" + value + "'" ;
      return { 'success': RC.input_validation_failed, 'return_msg': return_msg, 'debug_data': debug_data };
    }

    return { 'success': RC.success, 'return_msg': return_msg, 'debug_data': debug_data };
  }

  public static is_number(value: any, min_length?: number, max_length?: number) {
    let debug_data:any = [];
    var return_msg = "bi1DataValidation:is_number ";
    
    if (typeof value !== 'number') {
        return_msg += "number should be a number but this value is a '" + typeof value + "'." ;
        return { 'success': RC.input_validation_failed, 'return_msg': return_msg, 'debug_data': debug_data };
    };

    if (typeof min_length === 'number' && value.toString().length < min_length) {
      return_msg += "number should more than " + min_length.toString() + " in length, however its " + value.toString().length + ". value:" + typeof value;
      return { 'success': RC.input_validation_failed, 'return_msg': return_msg, 'debug_data': debug_data };
    }

    if (typeof max_length === 'number' && value.toString().length > max_length) {
      return_msg += "number should less than " + max_length.toString() + " in length, however its " + value.toString().length + ". value:" + typeof value;
      return { 'success': RC.input_validation_failed, 'return_msg': return_msg, 'debug_data': debug_data };
    }

    return { 'success': RC.success, 'return_msg': return_msg, 'debug_data': debug_data };
  }

  public static is_function(value: any) {
    let debug_data:any = [];
    var return_msg = "bi1DataValidation:is_function ";
    
    if (typeof value !== 'function') {
      return_msg += "a function should be type function but this value is a '" + typeof value + "'";
      return { 'success': RC.input_validation_failed, 'return_msg': return_msg, 'debug_data': debug_data };
    }
    return { 'success': RC.success, 'return_msg': return_msg, 'debug_data': debug_data };
  }

  public static is_array(value:any, min_length?: number, max_length?:number) {
    let debug_data:any = [];
    var return_msg = "bi1DataValidation:is_array ";
    
    if (!Array.isArray(value)) {
      return_msg += "array should be an array but this value is a '" + typeof value + "'";
      return { 'success': RC.input_validation_failed, 'return_msg': return_msg, 'debug_data': debug_data };
    };

    if (typeof min_length === 'number' && value.length < min_length) {
      return_msg += "array should more than " + min_length.toString() + " in length, however its " + value.length + ". value:" + typeof value;
      return { 'success': RC.input_validation_failed, 'return_msg': return_msg, 'debug_data': debug_data };

    }
    
    if (typeof max_length === 'number' && value.length > max_length) {
      return_msg += "array should less than " + max_length.toString() + " in length, however its " + value.length + ". value:" + typeof value;
      return { 'success': RC.input_validation_failed, 'return_msg': return_msg, 'debug_data': debug_data };

    }

    return { 'success': RC.success, 'return_msg': return_msg, 'debug_data': debug_data };
  }

  public static is_not_null(value:any, value_name="Value") {
    let debug_data:any = [];
    var return_msg = "bi1DataValidation:is_not_null ";
    
    if (value === null || value === undefined) {
      return_msg += "Value should not be null or undefined. " + value_name + " is undefined or null";
      return { 'success': RC.input_validation_failed, 'return_msg': return_msg, 'debug_data': debug_data };
    }

    return { 'success': RC.success, 'return_msg': return_msg, 'debug_data': debug_data };
  }

  public static is_bool(value: any) {
    let debug_data:any = [];
    var return_msg = "bi1DataValidation:is_not_bool";
    
    if (typeof(value) !== "boolean") {
      return_msg += "Value must be true or false. But" + value + " is a : " + typeof(value);
      return { 'success': RC.input_validation_failed, 'return_msg': return_msg, 'debug_data': debug_data };
    }

    return { 'success': RC.success, 'return_msg': return_msg, 'debug_data': debug_data };
  }

  public static is_valid_url(value: any) {
    let debug_data:any = [];
    var return_msg = "bi1DataValidation:is_valid_url";
    
    if (typeof(value) !== "string") {
      return_msg += "Value must be a string. But" + value + " is a : " + typeof(value);
      return { 'success': RC.input_validation_failed, 'return_msg': return_msg, 'debug_data': debug_data };
    }

    var regex = new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_\+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?"); 
    var without_protocol_regex = new RegExp("^([0-9A-Za-z-\\.@:%_\+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?");

    if (regex.test(value) || without_protocol_regex.test(value)) {
      return { 'success': RC.success, 'return_msg': return_msg, 'debug_data': debug_data };
    }

    return_msg += "Value must be a valid URL. But" + value + " is not a valid URL";
    return { 'success': RC.input_validation_failed, 'return_msg': return_msg, 'debug_data': debug_data };
  }

  public static is_us_phone_number(phone_number: any) {
    let debug_data:any = [];
    var return_msg = "bi1DataValidation:is_us_phone_number";

    if (typeof(phone_number) !== "string") {
      return_msg += "Phone number must be a string. But" + phone_number + " is a : " + typeof(phone_number);
      return { 'success': RC.input_validation_failed, 'return_msg': return_msg, 'debug_data': debug_data };
    }

    if (!usaAreaCodes.includes(phone_number.substring(0, 3))) {
      return_msg += "Invalid area code. Please enter a number with a valid area code";
      return { 'success': RC.input_validation_failed, 'return_msg': return_msg, 'debug_data': debug_data };
    }

    return { 'success': RC.success, 'return_msg': return_msg, 'debug_data': debug_data };
  }

  public static is_not_a_toll_free_number(phone_number: any) {
    let debug_data:any = [];
    var return_msg = "bi1DataValidation:is_not_a_toll_free_number";

    if (typeof(phone_number) !== "string") {
      return_msg += "Phone number must be a string. But" + phone_number + " is a : " + typeof(phone_number);
      return { 'success': RC.input_validation_failed, 'return_msg': return_msg, 'debug_data': debug_data };
    }

    if (!usaAreaCodes.includes(phone_number.substring(0, 3))) {
      return_msg += "Invalid area code. Please enter a number with a valid area code";
      return { 'success': RC.input_validation_failed, 'return_msg': return_msg, 'debug_data': debug_data };
    }

    if (tollFreeNumberCodes.includes(phone_number.substring(0, 3))) {
      return_msg += "Toll free number. Please enter a business phone number";
      return { 'success': RC.input_validation_failed, 'return_msg': return_msg, 'debug_data': debug_data };
    }

    return { 'success': RC.success, 'return_msg': return_msg, 'debug_data': debug_data };
  }

  public static is_texting_number(value: string) {
    let debug_data:any = [];
    var return_msg = "bi1DataValidation:is_texting_number";
    let call_result:any = {};
    //verify its a string
    call_result = bi1DataValidation.is_string(value, 10,10);
    debug_data.push(call_result);
    if (call_result[CR.success] !== RC.success) {
      return { 'success': call_result[CR.success], 'return_msg': return_msg, 'debug_data': debug_data };
    }
    // verify all numbers are not zeros
    if (/^0+$/.test(value)) {
      return_msg += "Texting number is not set.";
      return {'success': RC.input_validation_failed, 'return_msg': return_msg, 'debug_data': debug_data};
    }
    return { 'success': RC.success, 'return_msg': return_msg, 'debug_data': debug_data };
  }
};



export default bi1DataValidation;
//once the file is loaded put it in the list of loaded includes
if (typeof window.G_loaded_includes === "undefined") { window.G_loaded_includes = {} }
window.G_loaded_includes['base_i1'] = true;
